// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var CheckboxStatus$ReactHooksTemplate = require("./checkboxStatus.bs.js");
var PermissionTree$ReactHooksTemplate = require("./permissionTree.bs.js");

function fetchPermissions(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/permissions", PermissionTree$ReactHooksTemplate.decode);
}

function getInputValue(x) {
  return x._0;
}

function getValidationError(x) {
  switch (x.TAG | 0) {
    case /* Invalid */1 :
        return x._1;
    case /* Valid */0 :
    case /* NotValidated */2 :
        return /* [] */0;
    
  }
}

var emptyState_name = {
  TAG: /* NotValidated */2,
  _0: Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, undefined)
};

var emptyState_description = {
  TAG: /* NotValidated */2,
  _0: Curry._1(Domain$ReactHooksTemplate.$$String.Description.empty, undefined)
};

var emptyState_permissions = {
  TAG: /* NotValidated */2,
  _0: /* [] */0
};

var emptyState = {
  name: emptyState_name,
  description: emptyState_description,
  permissions: emptyState_permissions
};

function displayErrors(field) {
  switch (field.TAG | 0) {
    case /* Invalid */1 :
        return React.createElement("div", undefined, $$Array.of_list(Belt_List.mapWithIndex(field._1, (function (key, error) {
                              return React.createElement("p", {
                                          key: String(key),
                                          className: "text-red-600 font-medium"
                                        }, error);
                            }))));
    case /* Valid */0 :
    case /* NotValidated */2 :
        return React.createElement(React.Fragment, undefined);
    
  }
}

function RoleCreate(Props) {
  var match = React.useState(function () {
        return emptyState;
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/permissions", PermissionTree$ReactHooksTemplate.decode).then(function (permissions) {
                return Promise.resolve(Curry._1(setState, (function (state) {
                                  return {
                                          name: state.name,
                                          description: state.description,
                                          permissions: {
                                            TAG: /* NotValidated */2,
                                            _0: permissions
                                          }
                                        };
                                })));
              });
          
        }), []);
  var toggleCheckboxState = function (name) {
    return Curry._1(setState, (function (state) {
                  return {
                          name: state.name,
                          description: state.description,
                          permissions: {
                            TAG: /* NotValidated */2,
                            _0: Belt_List.map(state.permissions._0, (function (param) {
                                    return PermissionTree$ReactHooksTemplate.toggle(name, param);
                                  }))
                          }
                        };
                }));
  };
  var capabilityCheckBoxItem = function (capability, status) {
    return React.createElement("div", {
                key: capability,
                className: "pl-5"
              }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                    checked: CheckboxStatus$ReactHooksTemplate.toBool(status),
                    label: capability,
                    handleChange: (function (param) {
                        return toggleCheckboxState(capability);
                      })
                  }));
  };
  var groupCheckboxTree = function (group, status, children) {
    return React.createElement("div", {
                key: group
              }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                    checked: CheckboxStatus$ReactHooksTemplate.toBool(status),
                    label: group,
                    handleChange: (function (param) {
                        return toggleCheckboxState(group);
                      }),
                    indeterminate: CheckboxStatus$ReactHooksTemplate.isIndeterminate(status)
                  }), React.createElement("div", {
                    className: "pl-5"
                  }, Belt_List.toArray(children)));
  };
  var permissionCheckboxTree = function (permissionTree) {
    return PermissionTree$ReactHooksTemplate.postOrder(capabilityCheckBoxItem, groupCheckboxTree, permissionTree);
  };
  var handleNameChange = function ($$event) {
    var value = Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, Utils$ReactHooksTemplate.formTargetValue($$event).toLowerCase());
    return Curry._1(setState, (function (state) {
                  return {
                          name: Curry._1(Domain$ReactHooksTemplate.$$String.Name.isEmpty, value) ? ({
                                TAG: /* Invalid */1,
                                _0: value,
                                _1: {
                                  hd: "can't be empty",
                                  tl: /* [] */0
                                }
                              }) : ({
                                TAG: /* Valid */0,
                                _0: value
                              }),
                          description: state.description,
                          permissions: state.permissions
                        };
                }));
  };
  var handleDescriptionChange = function ($$event) {
    var value = Curry._1(Domain$ReactHooksTemplate.$$String.Description.create, Utils$ReactHooksTemplate.formTargetValue($$event));
    return Curry._1(setState, (function (state) {
                  return {
                          name: state.name,
                          description: {
                            TAG: /* NotValidated */2,
                            _0: value
                          },
                          permissions: state.permissions
                        };
                }));
  };
  var encodePermission = function (xs) {
    return Json_encode.object_({
                hd: [
                  "role",
                  Json_encode.object_({
                        hd: [
                          "name",
                          Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, state.name._0)
                        ],
                        tl: {
                          hd: [
                            "description",
                            Curry._1(Domain$ReactHooksTemplate.$$String.Description.show, state.description._0)
                          ],
                          tl: {
                            hd: [
                              "permissions",
                              Json_encode.object_({
                                    hd: [
                                      "add",
                                      Json_encode.list((function (prim) {
                                              return prim;
                                            }), xs)
                                    ],
                                    tl: /* [] */0
                                  })
                            ],
                            tl: /* [] */0
                          }
                        }
                      })
                ],
                tl: /* [] */0
              });
  };
  var decodeApiResponseStatus = function (json) {
    return Json_decode.field("status", Json_decode.string, json).toLowerCase();
  };
  var nameDecoder = function (json) {
    return Json_decode.field("name", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json);
  };
  var descriptionDecoder = function (json) {
    return Json_decode.field("description", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json);
  };
  var permissionDecoder = function (json) {
    return Json_decode.field("permissions", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json);
  };
  var fieldErrors = function (fieldDecoder, json) {
    return Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("errors", fieldDecoder, param);
                    }), json), /* [] */0);
  };
  var handleSubmit = function ($$event) {
    $$event.preventDefault();
    Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/roles", encodePermission(List.fold_left(PermissionTree$ReactHooksTemplate.enabledCapabilities, /* [] */0, state.permissions._0))).then(function (json) {
          if (decodeApiResponseStatus(json) === "success") {
            RescriptReactRouter.push("/roles/all");
          } else {
            Curry._1(setState, (function (param) {
                    var xs = fieldErrors(nameDecoder, json);
                    var xs$1 = fieldErrors(descriptionDecoder, json);
                    var xs$2 = fieldErrors(permissionDecoder, json);
                    return {
                            name: xs ? ({
                                  TAG: /* Invalid */1,
                                  _0: state.name._0,
                                  _1: xs
                                }) : ({
                                  TAG: /* Valid */0,
                                  _0: state.name._0
                                }),
                            description: xs$1 ? ({
                                  TAG: /* Invalid */1,
                                  _0: state.description._0,
                                  _1: xs$1
                                }) : ({
                                  TAG: /* Valid */0,
                                  _0: state.description._0
                                }),
                            permissions: xs$2 ? ({
                                  TAG: /* Invalid */1,
                                  _0: state.permissions._0,
                                  _1: xs$2
                                }) : ({
                                  TAG: /* Valid */0,
                                  _0: state.permissions._0
                                })
                          };
                  }));
          }
          return Promise.resolve(undefined);
        });
    
  };
  var permissionTree = Belt_List.toArray(Belt_List.map(state.permissions._0, permissionCheckboxTree));
  return React.createElement("div", undefined, Curry._1(match$1[0], /* AddRole */10) ? React.createElement("div", {
                    className: "p-30-px"
                  }, React.createElement("h1", {
                        className: "text-32-px mb-30-px"
                      }, "Add New Role"), React.createElement("form", {
                        className: "p-4",
                        onSubmit: handleSubmit
                      }, React.createElement("div", {
                            className: "mb-30-px"
                          }, React.createElement("label", {
                                className: "block mb-10-px"
                              }, React.createElement("p", {
                                    className: "mb-10-px font-semibold"
                                  }, "Name"), React.createElement("input", {
                                    className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none w-400-px py-10-px px-20-px",
                                    type: "text",
                                    value: Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, state.name._0),
                                    onChange: handleNameChange
                                  })), displayErrors(state.name)), React.createElement("div", {
                            className: "mb-30-px"
                          }, React.createElement("label", {
                                className: "block mb-10-px"
                              }, React.createElement("p", {
                                    className: "mb-10-px font-semibold"
                                  }, "Description"), React.createElement("input", {
                                    className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none w-400-px py-10-px px-20-px",
                                    type: "text",
                                    value: Curry._1(Domain$ReactHooksTemplate.$$String.Description.show, state.description._0),
                                    onChange: handleDescriptionChange
                                  }))), React.createElement("div", {
                            className: "mb-30-px"
                          }, React.createElement("label", {
                                className: "block mb-10-px"
                              }, React.createElement("p", {
                                    className: "mb-10-px font-semibold"
                                  }, "Permissions: "), displayErrors(state.permissions), React.createElement("div", {
                                    className: "ml-20-px"
                                  }, permissionTree))), React.createElement("div", undefined, React.createElement("input", {
                                className: "text-white bg-primary hover:bg-teal-700 py-6-px px-20-px rounded shadow",
                                type: "submit",
                                value: "Add Role"
                              })))) : React.createElement("div", {
                    className: "h-screen w-full animated fadeIn"
                  }, React.createElement(Loading$ReactHooksTemplate.make, {
                        img: Image$ReactHooksTemplate.unAuthorized,
                        text: "401: Unauthorized!!",
                        loading: false
                      })));
}

var make = RoleCreate;

exports.fetchPermissions = fetchPermissions;
exports.getInputValue = getInputValue;
exports.getValidationError = getValidationError;
exports.emptyState = emptyState;
exports.displayErrors = displayErrors;
exports.make = make;
/* emptyState Not a pure module */
