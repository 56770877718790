// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var NpsLabel$ReactHooksTemplate = require("./NpsLabel.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function decode(json) {
  return {
          text: Json_decode.field("text", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          label: NpsLabel$ReactHooksTemplate.make(Json_decode.field("left_label", Json_decode.string, json), Json_decode.field("center_label", Json_decode.string, json), Json_decode.field("right_label", Json_decode.string, json)),
          veryUnlikely: Json_decode.withDefault("Very Unhappy!", (function (param) {
                  return Json_decode.field("very_unlikely", Json_decode.string, param);
                }), json),
          unlikely: Json_decode.withDefault("Unhappy!", (function (param) {
                  return Json_decode.field("unlikely", Json_decode.string, param);
                }), json),
          maybe: Json_decode.withDefault("It was ok!", (function (param) {
                  return Json_decode.field("maybe", Json_decode.string, param);
                }), json),
          likely: Json_decode.withDefault("Happy!", (function (param) {
                  return Json_decode.field("likely", Json_decode.string, param);
                }), json),
          extremelyLikely: Json_decode.withDefault("Super Happy!", (function (param) {
                  return Json_decode.field("extremely_likely", Json_decode.string, param);
                }), json)
        };
}

function empty(param) {
  return {
          id: undefined,
          uuid: RandomId$ReactHooksTemplate.generate(undefined),
          text: "",
          scale: /* TenScale */1,
          includeZero: true,
          description: "",
          label: NpsLabel$ReactHooksTemplate.empty(undefined),
          representation: /* Plain */0,
          autoAdvance: false,
          translations: /* [] */0,
          answer: undefined
        };
}

function make(text, description, representation, autoAdvance, label, uuid) {
  return {
          id: undefined,
          uuid: uuid,
          text: text,
          scale: /* TenScale */1,
          includeZero: true,
          description: description,
          label: label,
          representation: representation,
          autoAdvance: autoAdvance,
          translations: /* [] */0,
          answer: undefined
        };
}

function setText(t, text) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: text,
          scale: t.scale,
          includeZero: t.includeZero,
          description: t.description,
          label: t.label,
          representation: t.representation,
          autoAdvance: t.autoAdvance,
          translations: t.translations,
          answer: t.answer
        };
}

function setDescription(t, description) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          scale: t.scale,
          includeZero: t.includeZero,
          description: description,
          label: t.label,
          representation: t.representation,
          autoAdvance: t.autoAdvance,
          translations: t.translations,
          answer: t.answer
        };
}

function setLabel(t, label) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          scale: t.scale,
          includeZero: t.includeZero,
          description: t.description,
          label: label,
          representation: t.representation,
          autoAdvance: t.autoAdvance,
          translations: t.translations,
          answer: t.answer
        };
}

function setRepresentation(t, representation) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          scale: t.scale,
          includeZero: t.includeZero,
          description: t.description,
          label: t.label,
          representation: representation,
          autoAdvance: t.autoAdvance,
          translations: t.translations,
          answer: t.answer
        };
}

function setAutoAdvance(autoAdvance, t) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          scale: t.scale,
          includeZero: t.includeZero,
          description: t.description,
          label: t.label,
          representation: t.representation,
          autoAdvance: autoAdvance,
          translations: t.translations,
          answer: t.answer
        };
}

function $eq(t, x) {
  var y = t.answer;
  if (y !== undefined) {
    return y === x;
  } else {
    return false;
  }
}

function $great$eq(t, x) {
  var y = t.answer;
  if (y !== undefined) {
    return y >= x;
  } else {
    return false;
  }
}

function $less$eq(t, x) {
  var y = t.answer;
  if (y !== undefined) {
    return y <= x;
  } else {
    return false;
  }
}

function setAnswer(t, scale) {
  var match = t.answer;
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          scale: t.scale,
          includeZero: t.includeZero,
          description: t.description,
          label: t.label,
          representation: t.representation,
          autoAdvance: t.autoAdvance,
          translations: t.translations,
          answer: match !== undefined && $eq(t, scale) ? undefined : scale
        };
}

function answered(t) {
  return t.answer !== undefined;
}

function id(t) {
  return t.id;
}

function uuid(t) {
  return t.uuid;
}

function text(t) {
  return t.text;
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].text;
  } else {
    return t.text;
  }
}

function description(t) {
  return t.description;
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].description;
  } else {
    return t.description;
  }
}

function label(t) {
  return t.label;
}

function getLabelTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].label;
  } else {
    return t.label;
  }
}

function getVeryUnlikelyTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  console.log("sdfsdf");
  console.log(translation);
  if (translation !== undefined) {
    return translation[1].veryUnlikely;
  } else {
    return "Very Unhappy!";
  }
}

function getUnlikelyTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].unlikely;
  } else {
    return "Unhappy!";
  }
}

function getMaybeTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].maybe;
  } else {
    return "It was ok!";
  }
}

function getLikelyTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].likely;
  } else {
    return "Happy!";
  }
}

function getExtremelyLikelyTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].extremelyLikely;
  } else {
    return "Super Happy!";
  }
}

function representation(t) {
  return t.representation;
}

function scale(t) {
  return t.scale;
}

function includeZero(t) {
  return t.includeZero;
}

function autoAdvance(t) {
  return t.autoAdvance;
}

function answer(t) {
  return t.answer;
}

function rating(t) {
  var answer = t.answer;
  if (answer !== undefined) {
    return answer;
  }
  
}

function answerToString(t) {
  var answer = t.answer;
  if (answer !== undefined) {
    return String(answer);
  } else {
    return "";
  }
}

function answerToInt(t) {
  var answer = t.answer;
  if (answer !== undefined) {
    return answer;
  }
  
}

function isAnswer(t, x) {
  var match = t.answer;
  if (match !== undefined) {
    return $eq(t, x);
  } else {
    return false;
  }
}

function answerRange(t) {
  var min = t.includeZero ? 0 : 1;
  var match = t.scale;
  var max = match ? 10 : 5;
  return [
          min,
          max
        ];
}

function toJson(withAnswerOpt, t) {
  var withAnswer = withAnswerOpt !== undefined ? withAnswerOpt : false;
  var encodeArray_0 = [
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t.id)
  ];
  var encodeArray_1 = {
    hd: [
      "text",
      t.text
    ],
    tl: {
      hd: [
        "description",
        t.description
      ],
      tl: {
        hd: [
          "left_label",
          NpsLabel$ReactHooksTemplate.left(t.label)
        ],
        tl: {
          hd: [
            "center_label",
            NpsLabel$ReactHooksTemplate.center(t.label)
          ],
          tl: {
            hd: [
              "right_label",
              NpsLabel$ReactHooksTemplate.right(t.label)
            ],
            tl: {
              hd: [
                "start_from_zero",
                t.includeZero
              ],
              tl: {
                hd: [
                  "scale",
                  t.scale ? 10 : 5
                ],
                tl: /* [] */0
              }
            }
          }
        }
      }
    }
  };
  var encodeArray = {
    hd: encodeArray_0,
    tl: encodeArray_1
  };
  return Json_encode.object_(withAnswer ? ({
                  hd: [
                    "answer",
                    Belt_Option.getWithDefault(t.answer, -1)
                  ],
                  tl: encodeArray
                }) : encodeArray);
}

function decodeTranslation(json) {
  return [
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  var match = Json_decode.field("category", (function (param) {
          return Json_decode.field("scale", (function (param) {
                        return Json_decode.withDefault(10, Json_decode.$$int, param);
                      }), param);
        }), json);
  return {
          id: Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          uuid: Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          text: Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          scale: match !== 5 ? /* TenScale */1 : /* FiveScale */0,
          includeZero: Json_decode.field("category", (function (param) {
                  return Json_decode.field("start_from_zero", (function (param) {
                                return Json_decode.withDefault(true, Json_decode.bool, param);
                              }), param);
                }), json),
          description: Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          label: NpsLabel$ReactHooksTemplate.make(Json_decode.field("category", (function (param) {
                      return Json_decode.field("left_label", Json_decode.string, param);
                    }), json), Json_decode.field("category", (function (param) {
                      return Json_decode.field("center_label", Json_decode.string, param);
                    }), json), Json_decode.field("category", (function (param) {
                      return Json_decode.field("right_label", Json_decode.string, param);
                    }), json)),
          representation: /* Plain */0,
          autoAdvance: false,
          translations: Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          answer: undefined
        };
}

var categoryType = "Nps";

exports.categoryType = categoryType;
exports.empty = empty;
exports.make = make;
exports.setText = setText;
exports.getTextTranslation = getTextTranslation;
exports.setDescription = setDescription;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.setLabel = setLabel;
exports.getLabelTranslation = getLabelTranslation;
exports.getVeryUnlikelyTranslation = getVeryUnlikelyTranslation;
exports.getUnlikelyTranslation = getUnlikelyTranslation;
exports.getMaybeTranslation = getMaybeTranslation;
exports.getLikelyTranslation = getLikelyTranslation;
exports.getExtremelyLikelyTranslation = getExtremelyLikelyTranslation;
exports.setRepresentation = setRepresentation;
exports.setAutoAdvance = setAutoAdvance;
exports.$eq = $eq;
exports.$great$eq = $great$eq;
exports.$less$eq = $less$eq;
exports.text = text;
exports.description = description;
exports.label = label;
exports.representation = representation;
exports.scale = scale;
exports.includeZero = includeZero;
exports.autoAdvance = autoAdvance;
exports.answer = answer;
exports.answered = answered;
exports.isAnswer = isAnswer;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.id = id;
exports.uuid = uuid;
exports.setAnswer = setAnswer;
exports.rating = rating;
exports.answerToString = answerToString;
exports.answerRange = answerRange;
exports.answerToInt = answerToInt;
/* RandomId-ReactHooksTemplate Not a pure module */
