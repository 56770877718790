// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var SearchForm$ReactHooksTemplate = require("./SearchForm.bs.js");
var AlertComponent$ReactHooksTemplate = require("../common/AlertComponent.bs.js");

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

var searchPlaceholder = "Search Projects";

function ProjectList(Props) {
  var searchKey = Props.searchKey;
  var setSearchKey = Props.setSearchKey;
  var handleSearch = Props.handleSearch;
  var projects = Props.projects;
  var selectedProjectId = Props.selectedProjectId;
  var setSelectedProject = Props.setSelectedProject;
  var alert = Props.alert;
  var toggleProjectCreate = Props.toggleProjectCreate;
  var match = React.useState(function () {
        return false;
      });
  var setclose = match[1];
  var close = match[0];
  var handleClose = function (param) {
    return Curry._1(setclose, (function (param) {
                  return !close;
                }));
  };
  return React.createElement("div", undefined, alert && !close ? React.createElement(AlertComponent$ReactHooksTemplate.make, {
                    alertType: {
                      TAG: /* Failure */2,
                      _0: "Project not found!"
                    },
                    handleClose: handleClose
                  }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                      className: "h-10 w-60 m-4 mt-80 py-3 pl-3 bg-white border border-gray-400 flex justify-center rounded"
                    }, React.createElement(SearchForm$ReactHooksTemplate.make, {
                          searchKey: searchKey,
                          setSearchKey: setSearchKey,
                          handleSearch: handleSearch,
                          searchPlaceholder: searchPlaceholder
                        })), React.createElement("div", {
                      className: "m-6 text-darkcharcoal text-xl font-semibold"
                    }, "Projects"), React.createElement("div", {
                      className: "align-middle items-center mt-6"
                    }, $$Array.of_list(Belt_List.mapWithIndex(projects, (function (index, project) {
                                return React.createElement("div", {
                                            key: String(index),
                                            className: "px-6 py-3 h-10 " + (
                                              selectedProjectId === project.id ? "text-darkcharcoal font-semibold bg-lavenderweb" : "text-aurometalsaurus text-base"
                                            ),
                                            onClick: (function (param) {
                                                Curry._1(setSelectedProject, (function (param) {
                                                        return project;
                                                      }));
                                                var id = project.id;
                                                if (searchKey !== undefined) {
                                                  return RescriptReactRouter.push("/projects/all?selectedProjectId=" + id + "&search=" + searchKey);
                                                } else {
                                                  return RescriptReactRouter.push("/projects/all?selectedProjectId=" + id);
                                                }
                                              })
                                          }, React.createElement("div", {
                                                className: "flex justify-between w-full items-center"
                                              }, React.createElement("div", undefined, project.name), React.createElement("div", {
                                                    className: "font-normal text-xs align-middle"
                                                  }, React.createElement("div", {
                                                        className: "m-auto"
                                                      }, String(project.surveyCount)))));
                              })))), React.createElement("a", {
                      className: "bg-ghostwhite border border-gray-400 mx-4 my-6\n              shadow-inner flex justify-center rounded py-2 px-3 text-sm",
                      id: "add-survey",
                      onClick: toggleProjectCreate
                    }, React.createElement("img", {
                          className: "h-14-px mr-1",
                          src: Image$ReactHooksTemplate.plusCircle
                        }), "New Project")));
}

var emptyPageInfo = {
  total: "1",
  per_page: "1"
};

var make = ProjectList;

exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.emptyPageInfo = emptyPageInfo;
exports.searchPlaceholder = searchPlaceholder;
exports.make = make;
/* react Not a pure module */
