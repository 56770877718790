// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Dashboard$ReactHooksTemplate = require("./dashboard.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../../common/CheckboxInput.bs.js");
var DashboardShare$ReactHooksTemplate = require("./dashboardShare.bs.js");

function DashboardShareForm(Props) {
  var dashboard = Props.dashboard;
  var dashboardShares = Props.dashboardShares;
  var onClose = Props.onClose;
  var initialState = {
    dashboardShares: dashboardShares,
    deletedDashboardShares: /* [] */0,
    newDashboardShares: /* [] */0,
    resourceType: undefined,
    searchKey: undefined,
    users: /* [] */0,
    roles: /* [] */0,
    notification: undefined
  };
  var match = React.useState(function () {
        return initialState;
      });
  var setState = match[1];
  var state = match[0];
  var fetchUsers = function (param) {
    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/users", (function (param) {
              return Json_decode.field("users", (function (param) {
                            return Json_decode.list(DashboardShare$ReactHooksTemplate.decodeUser, param);
                          }), param);
            })).then(function (users) {
          return Promise.resolve(Curry._1(setState, (function (param) {
                            return {
                                    dashboardShares: state.dashboardShares,
                                    deletedDashboardShares: state.deletedDashboardShares,
                                    newDashboardShares: state.newDashboardShares,
                                    resourceType: state.resourceType,
                                    searchKey: state.searchKey,
                                    users: users,
                                    roles: state.roles,
                                    notification: state.notification
                                  };
                          })));
        });
    
  };
  var fetchRoles = function (param) {
    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/roles", (function (param) {
              return Json_decode.field("roles", (function (param) {
                            return Json_decode.list(DashboardShare$ReactHooksTemplate.decodeRole, param);
                          }), param);
            })).then(function (roles) {
          return Promise.resolve(Curry._1(setState, (function (param) {
                            return {
                                    dashboardShares: state.dashboardShares,
                                    deletedDashboardShares: state.deletedDashboardShares,
                                    newDashboardShares: state.newDashboardShares,
                                    resourceType: state.resourceType,
                                    searchKey: state.searchKey,
                                    users: state.users,
                                    roles: roles,
                                    notification: state.notification
                                  };
                          })));
        });
    
  };
  var removeShare = function (dashboardShare) {
    var remainingDashboardShares = Belt_List.keep(state.dashboardShares, (function (x) {
            return Caml_obj.caml_notequal(DashboardShare$ReactHooksTemplate.id(x), DashboardShare$ReactHooksTemplate.id(dashboardShare));
          }));
    var deletedDashboardShares = List.append(state.deletedDashboardShares, {
          hd: dashboardShare,
          tl: /* [] */0
        });
    return Curry._1(setState, (function (param) {
                  return {
                          dashboardShares: remainingDashboardShares,
                          deletedDashboardShares: deletedDashboardShares,
                          newDashboardShares: state.newDashboardShares,
                          resourceType: state.resourceType,
                          searchKey: state.searchKey,
                          users: state.users,
                          roles: state.roles,
                          notification: state.notification
                        };
                }));
  };
  React.useEffect((function () {
          var resourceType = state.resourceType;
          if (resourceType === undefined) {
            return ;
          }
          if (resourceType === "Role") {
            var match = state.roles;
            if (match) {
              
            } else {
              fetchRoles(undefined);
            }
          } else {
            var match$1 = state.users;
            if (match$1) {
              
            } else {
              fetchUsers(undefined);
            }
          }
          
        }), [state.resourceType]);
  var match$1 = state.resourceType;
  var tmp;
  if (match$1 !== undefined) {
    var partial_arg = state.resourceType;
    var match$2 = state.resourceType;
    var tmp$1;
    tmp$1 = match$2 !== undefined ? (
        match$2 === "Role" ? $$Array.of_list(List.map((function (role) {
                      return React.createElement("option", {
                                  key: String(role.id),
                                  value: role.name
                                }, role.name);
                    }), state.roles)) : $$Array.of_list(List.map((function (user) {
                      return React.createElement("option", {
                                  key: String(user.id),
                                  value: user.email
                                }, user.email);
                    }), state.users))
      ) : React.createElement(React.Fragment, undefined);
    tmp = React.createElement("div", {
          className: "flex items-center"
        }, React.createElement("div", {
              className: "mt-2 font-medium text-base w-3/11"
            }, "Select " + (Belt_Option.getWithDefault(state.resourceType, "") + ":")), React.createElement("div", {
              className: "mt-2 font-medium text-base w-3/6"
            }, React.createElement("select", {
                  className: "block border px-3 py-2 border-gray-400\n                        bg-white rounded capitalize mr-6 text-sm w-full",
                  onChange: (function (param) {
                      var selectedName = param.target.value;
                      if (partial_arg === undefined) {
                        return ;
                      }
                      if (partial_arg === "Role") {
                        var newDashboardShares = Belt_List.concat({
                              hd: {
                                id: undefined,
                                resourceType: {
                                  TAG: /* Role */0,
                                  _0: List.find((function (x) {
                                          return x.name === selectedName;
                                        }), state.roles)
                                }
                              },
                              tl: /* [] */0
                            }, state.newDashboardShares);
                        return Curry._1(setState, (function (param) {
                                      return {
                                              dashboardShares: state.dashboardShares,
                                              deletedDashboardShares: state.deletedDashboardShares,
                                              newDashboardShares: newDashboardShares,
                                              resourceType: state.resourceType,
                                              searchKey: state.searchKey,
                                              users: state.users,
                                              roles: state.roles,
                                              notification: state.notification
                                            };
                                    }));
                      }
                      var newDashboardShares$1 = Belt_List.concat({
                            hd: {
                              id: undefined,
                              resourceType: {
                                TAG: /* User */1,
                                _0: List.find((function (x) {
                                        return x.email === selectedName;
                                      }), state.users)
                              }
                            },
                            tl: /* [] */0
                          }, state.newDashboardShares);
                      return Curry._1(setState, (function (param) {
                                    return {
                                            dashboardShares: state.dashboardShares,
                                            deletedDashboardShares: state.deletedDashboardShares,
                                            newDashboardShares: newDashboardShares$1,
                                            resourceType: state.resourceType,
                                            searchKey: state.searchKey,
                                            users: state.users,
                                            roles: state.roles,
                                            notification: state.notification
                                          };
                                  }));
                    })
                }, tmp$1)));
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  return React.createElement("div", {
              className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
              style: {
                height: "calc(100vh + 5rem)"
              }
            }, React.createElement("div", {
                  className: "bg-white modal-dialog z-10 shadow relative\n                      border border-gray-300 pointer-events-auto w-3/6 min-h-1/2"
                }, React.createElement("div", {
                      className: "p-8 text-lg font-bold flex justify-between"
                    }, React.createElement("div", undefined, "Share Dashboard"), React.createElement("img", {
                          className: "h-14-px float-right",
                          src: Image$ReactHooksTemplate.closeIcon,
                          onClick: onClose
                        })), React.createElement("div", {
                      className: "overflow-y-auto",
                      style: {
                        height: "calc(100vh - 40rem)"
                      }
                    }, React.createElement("div", {
                          className: "py-10 p-8 border-t border-gray-200 text-base font-medium"
                        }, React.createElement("div", undefined, React.createElement("div", {
                                  className: "flex flex-col w-full"
                                }, React.createElement("div", {
                                      className: "flex items-center"
                                    }, React.createElement("p", {
                                          className: "w-3/11"
                                        }, "Share Dashboard with: "), React.createElement("div", {
                                          className: "flex items-center"
                                        }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                              checked: Belt_Option.getWithDefault(state.resourceType, "") === "Role",
                                              label: "Role",
                                              handleChange: (function (param) {
                                                  if (Belt_Option.getWithDefault(state.resourceType, "") === "Role") {
                                                    return Curry._1(setState, (function (param) {
                                                                  return {
                                                                          dashboardShares: state.dashboardShares,
                                                                          deletedDashboardShares: state.deletedDashboardShares,
                                                                          newDashboardShares: state.newDashboardShares,
                                                                          resourceType: undefined,
                                                                          searchKey: state.searchKey,
                                                                          users: state.users,
                                                                          roles: state.roles,
                                                                          notification: state.notification
                                                                        };
                                                                }));
                                                  } else {
                                                    return Curry._1(setState, (function (param) {
                                                                  return {
                                                                          dashboardShares: state.dashboardShares,
                                                                          deletedDashboardShares: state.deletedDashboardShares,
                                                                          newDashboardShares: state.newDashboardShares,
                                                                          resourceType: "Role",
                                                                          searchKey: state.searchKey,
                                                                          users: state.users,
                                                                          roles: state.roles,
                                                                          notification: state.notification
                                                                        };
                                                                }));
                                                  }
                                                }),
                                              indeterminate: false,
                                              className: "mr-3"
                                            }), React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                              checked: Belt_Option.getWithDefault(state.resourceType, "") === "User",
                                              label: "User",
                                              handleChange: (function (param) {
                                                  if (Belt_Option.getWithDefault(state.resourceType, "") === "User") {
                                                    return Curry._1(setState, (function (param) {
                                                                  return {
                                                                          dashboardShares: state.dashboardShares,
                                                                          deletedDashboardShares: state.deletedDashboardShares,
                                                                          newDashboardShares: state.newDashboardShares,
                                                                          resourceType: undefined,
                                                                          searchKey: state.searchKey,
                                                                          users: state.users,
                                                                          roles: state.roles,
                                                                          notification: state.notification
                                                                        };
                                                                }));
                                                  } else {
                                                    return Curry._1(setState, (function (param) {
                                                                  return {
                                                                          dashboardShares: state.dashboardShares,
                                                                          deletedDashboardShares: state.deletedDashboardShares,
                                                                          newDashboardShares: state.newDashboardShares,
                                                                          resourceType: "User",
                                                                          searchKey: state.searchKey,
                                                                          users: state.users,
                                                                          roles: state.roles,
                                                                          notification: state.notification
                                                                        };
                                                                }));
                                                  }
                                                }),
                                              indeterminate: false,
                                              className: ""
                                            }))), React.createElement("div", undefined, React.createElement("div", {
                                          className: "pb-16 pt-2 border-gray-200 text-base font-medium"
                                        }, tmp)))), React.createElement("table", {
                              className: "w-full px-30 border"
                            }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", {
                                          className: "w-3/6 py-2"
                                        }, "Who has access"), React.createElement("th", {
                                          className: "w-2/6 py-2"
                                        }, "Type"), React.createElement("th", {
                                          className: "w-1/6 py-2"
                                        }))), React.createElement("tbody", {
                                  className: "text-center border py-2"
                                }, $$Array.of_list(List.map((function (x) {
                                            var role = DashboardShare$ReactHooksTemplate.resourceType(x);
                                            if (role.TAG === /* Role */0) {
                                              return React.createElement("tr", {
                                                          className: ""
                                                        }, React.createElement("td", {
                                                              className: "w-3/6 py-2"
                                                            }, role._0.name), React.createElement("td", {
                                                              className: "w-2/6 py-2"
                                                            }, "Role"), React.createElement("td", {
                                                              className: "w-1/6 py-2"
                                                            }, React.createElement("button", {
                                                                  id: "filter_reset",
                                                                  onClick: (function (param) {
                                                                      return removeShare(x);
                                                                    })
                                                                }, React.createElement("img", {
                                                                      className: "w-3",
                                                                      src: Image$ReactHooksTemplate.closeIcon
                                                                    }))));
                                            } else {
                                              return React.createElement("tr", undefined, React.createElement("td", {
                                                              className: "w-3/6 py-2"
                                                            }, role._0.email), React.createElement("td", {
                                                              className: "w-2/6 py-2"
                                                            }, "User"), React.createElement("td", {
                                                              className: "w-1/6 py-2"
                                                            }, React.createElement("button", {
                                                                  id: "filter_reset",
                                                                  onClick: (function (param) {
                                                                      return removeShare(x);
                                                                    })
                                                                }, React.createElement("img", {
                                                                      className: "w-3",
                                                                      src: Image$ReactHooksTemplate.closeIcon
                                                                    }))));
                                            }
                                          }), state.dashboardShares)))))), React.createElement("div", {
                      className: "p-8 flex border-t border-gray-200 justify-end gap-3 font-medium\n                bottom-0 text-base w-full"
                    }, React.createElement("button", {
                          className: "bg-ghostwhite border border-gray-400\n                  flex rounded py-3 px-6 text-xs",
                          id: "close_modal",
                          onClick: onClose
                        }, "Discard"), React.createElement("button", {
                          className: "bg-palatinateblue border border-gray-400\n                  flex rounded py-3 px-6 text-xs text-white",
                          id: "save",
                          onClick: (function (param) {
                              var partial_arg = true;
                              var deletedDashboardShares = Belt_List.map(state.deletedDashboardShares, (function (param) {
                                      return DashboardShare$ReactHooksTemplate.encode(partial_arg, param);
                                    }));
                              var newDashboardShares = Belt_List.map(state.newDashboardShares, (function (x) {
                                      return DashboardShare$ReactHooksTemplate.encode(undefined, x);
                                    }));
                              var params = Json_encode.object_({
                                    hd: [
                                      "dashboard_shares_attributes",
                                      Belt_List.toArray(List.append(deletedDashboardShares, newDashboardShares))
                                    ],
                                    tl: /* [] */0
                                  });
                              Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/dashboards/" + (String(Dashboard$ReactHooksTemplate.id(dashboard)) + "/share"), params).then(function (response) {
                                    var status = Json_decode.field("status", Json_decode.string, response);
                                    if (status === "success") {
                                      Curry._1(setState, (function (state) {
                                              return {
                                                      dashboardShares: Json_decode.field("dashboard_shares", (function (param) {
                                                              return Json_decode.list(DashboardShare$ReactHooksTemplate.decode, param);
                                                            }), response),
                                                      deletedDashboardShares: /* [] */0,
                                                      newDashboardShares: /* [] */0,
                                                      resourceType: state.resourceType,
                                                      searchKey: state.searchKey,
                                                      users: state.users,
                                                      roles: state.roles,
                                                      notification: [
                                                        "Access updated",
                                                        false
                                                      ]
                                                    };
                                            }));
                                    } else {
                                      Curry._1(setState, (function (state) {
                                              return {
                                                      dashboardShares: state.dashboardShares,
                                                      deletedDashboardShares: state.deletedDashboardShares,
                                                      newDashboardShares: state.newDashboardShares,
                                                      resourceType: state.resourceType,
                                                      searchKey: state.searchKey,
                                                      users: state.users,
                                                      roles: state.roles,
                                                      notification: [
                                                        "Updation\n           failed. Please contact Admin",
                                                        true
                                                      ]
                                                    };
                                            }));
                                    }
                                    return Promise.resolve(undefined);
                                  });
                              
                            })
                        }, "Share"))));
}

var make = DashboardShareForm;

exports.make = make;
/* react Not a pure module */
