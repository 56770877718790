// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");

function ThankYouForm(Props) {
  var $$default = Props.default;
  var thankYou = Props.thankYou;
  var withDefault = function ($$default, x) {
    if (x.length === 0) {
      return $$default;
    } else {
      return x;
    }
  };
  var urlPath = ThankYou$ReactHooksTemplate.image(thankYou);
  return React.createElement("div", {
              className: "flex flex-col w-full mt-48-px small:mt-40-px"
            }, urlPath !== undefined ? React.createElement("img", {
                    className: "w-full h-275-px small:h-200-px mb-48-px",
                    src: window.location.origin + urlPath
                  }) : React.createElement(React.Fragment, undefined), React.createElement("p", {
                  className: " mb-8-px font-semibold text-2xl leading-8 text-center\n      text-black small:text-lg mix-blend-normal"
                }, "Thank you for your feedback!"), React.createElement("p", {
                  className: " pb-16-px font-normal leading-6 text-base text-center small:text-lg tracking-0.8"
                }, withDefault(ThankYou$ReactHooksTemplate.text($$default), ThankYou$ReactHooksTemplate.text(thankYou))));
}

var make = ThankYouForm;

exports.make = make;
/* react Not a pure module */
