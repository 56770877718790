// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Modal$ReactHooksTemplate = require("../../surveys/Modal.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var ChartId$ReactHooksTemplate = require("./chartId.bs.js");
var Filters$ReactHooksTemplate = require("../filters/filters.bs.js");
var Loading$ReactHooksTemplate = require("./Loading.bs.js");
var Dashboard$ReactHooksTemplate = require("./dashboard.bs.js");
var HtmlToPdf$ReactHooksTemplate = require("./htmlToPdf.bs.js");
var DateFilter$ReactHooksTemplate = require("../filters/dateFilter.bs.js");
var ChartComponent$ReactHooksTemplate = require("./ChartComponent.bs.js");
var DashboardShare$ReactHooksTemplate = require("./dashboardShare.bs.js");
var DashboardShareForm$ReactHooksTemplate = require("./DashboardShareForm.bs.js");
var BreadCrumbsComponent$ReactHooksTemplate = require("../breadcrumbs/BreadCrumbsComponent.bs.js");

var initialState = {
  loading: true,
  charts: undefined,
  toggleFilter: false,
  enlargedChart: undefined,
  dateRange: undefined,
  shareDetails: /* [] */0,
  showShareModal: false
};

function reducer(state, x) {
  if (typeof x === "number") {
    switch (x) {
      case /* FetchChartsInProgress */0 :
          return {
                  loading: true,
                  charts: state.charts,
                  toggleFilter: state.toggleFilter,
                  enlargedChart: state.enlargedChart,
                  dateRange: state.dateRange,
                  shareDetails: state.shareDetails,
                  showShareModal: state.showShareModal
                };
      case /* ToggleFilter */1 :
          return {
                  loading: state.loading,
                  charts: state.charts,
                  toggleFilter: !state.toggleFilter,
                  enlargedChart: state.enlargedChart,
                  dateRange: state.dateRange,
                  shareDetails: state.shareDetails,
                  showShareModal: state.showShareModal
                };
      case /* ToggleShareModal */2 :
          return {
                  loading: state.loading,
                  charts: state.charts,
                  toggleFilter: state.toggleFilter,
                  enlargedChart: state.enlargedChart,
                  dateRange: state.dateRange,
                  shareDetails: state.shareDetails,
                  showShareModal: !state.showShareModal
                };
      
    }
  } else {
    switch (x.TAG | 0) {
      case /* FetchChartsCompleted */0 :
          return {
                  loading: false,
                  charts: x._0,
                  toggleFilter: state.toggleFilter,
                  enlargedChart: state.enlargedChart,
                  dateRange: state.dateRange,
                  shareDetails: state.shareDetails,
                  showShareModal: state.showShareModal
                };
      case /* UpdateDateRange */1 :
          return {
                  loading: state.loading,
                  charts: state.charts,
                  toggleFilter: state.toggleFilter,
                  enlargedChart: state.enlargedChart,
                  dateRange: x._0,
                  shareDetails: state.shareDetails,
                  showShareModal: state.showShareModal
                };
      case /* SetShareDetails */2 :
          return {
                  loading: state.loading,
                  charts: state.charts,
                  toggleFilter: state.toggleFilter,
                  enlargedChart: state.enlargedChart,
                  dateRange: state.dateRange,
                  shareDetails: x._0,
                  showShareModal: state.showShareModal
                };
      case /* EnlargeChart */3 :
          return {
                  loading: state.loading,
                  charts: state.charts,
                  toggleFilter: state.toggleFilter,
                  enlargedChart: x._0,
                  dateRange: state.dateRange,
                  shareDetails: state.shareDetails,
                  showShareModal: state.showShareModal
                };
      
    }
  }
}

function DashboardComponent(Props) {
  var dashboard = Props.dashboard;
  var dashboards = Props.dashboards;
  var drillDownChartDetails = Props.drillDownChartDetails;
  var onClick = Props.onClick;
  var onDashboardChange = Props.onDashboardChange;
  var breadCrumbs = Props.breadCrumbs;
  var currentRef = Props.currentRef;
  var breadCrumbsOnClick = Props.breadCrumbsOnClick;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var fetchVisualizations = function (param) {
    Curry._1(dispatch, /* FetchChartsInProgress */0);
    return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/dashboards/" + (String(Dashboard$ReactHooksTemplate.id(currentRef)) + "/visualizations"), ChartId$ReactHooksTemplate.fromJson).then(function (xs) {
                      console.log(xs);
                      return Promise.resolve(xs);
                    }).then(function (xs) {
                    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/filters/date_range", DateFilter$ReactHooksTemplate.fromJson).then(function (dateRange) {
                          return Promise.resolve(Curry._1(dispatch, {
                                          TAG: /* UpdateDateRange */1,
                                          _0: Caml_option.some(dateRange)
                                        }));
                        });
                    return Promise.resolve(xs);
                  }).then(function (xs) {
                  return Promise.resolve((setTimeout((function (param) {
                                      return Curry._1(dispatch, {
                                                  TAG: /* FetchChartsCompleted */0,
                                                  _0: xs
                                                });
                                    }), Utils$ReactHooksTemplate.timeout), undefined));
                }).catch(function (error) {
                console.log(error);
                return Promise.resolve(Curry._1(dispatch, {
                                TAG: /* FetchChartsCompleted */0,
                                _0: undefined
                              }));
              });
  };
  React.useEffect((function () {
          fetchVisualizations(undefined).then(function (param) {
                return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/dashboards/" + (String(Dashboard$ReactHooksTemplate.id(currentRef)) + "/shares"), (function (json) {
                                return Json_decode.field("dashboard_shares", (function (param) {
                                              return Json_decode.list(DashboardShare$ReactHooksTemplate.decode, param);
                                            }), json);
                              })).then(function (shares) {
                            return Promise.resolve((console.log(shares), Curry._1(dispatch, {
                                              TAG: /* SetShareDetails */2,
                                              _0: shares
                                            })));
                          });
              });
          
        }), [currentRef]);
  var onApplyFilter = function (param) {
    fetchVisualizations(undefined);
    
  };
  var toggleFilter = function (param) {
    return Curry._1(dispatch, /* ToggleFilter */1);
  };
  var dashboardSelectBox = function (param) {
    return React.createElement("details", {
                className: "custom-select"
              }, React.createElement("summary", {
                    className: "flex radios"
                  }, $$Array.of_list(List.map((function (x) {
                              return React.createElement("div", {
                                          key: String(Dashboard$ReactHooksTemplate.id(x)),
                                          className: "flex items-center"
                                        }, React.createElement("img", {
                                              className: Dashboard$ReactHooksTemplate.name(x) !== Dashboard$ReactHooksTemplate.name(dashboard) ? "hidden" : "h-8 w-8 mr-2",
                                              src: Image$ReactHooksTemplate.dashboardIcon
                                            }), React.createElement("input", {
                                              className: "font-semibold text-lg text-charcoal mr-3",
                                              id: String(Dashboard$ReactHooksTemplate.id(x)),
                                              title: Dashboard$ReactHooksTemplate.name(x),
                                              checked: Dashboard$ReactHooksTemplate.name(x) === Dashboard$ReactHooksTemplate.name(dashboard),
                                              name: "item",
                                              type: "radio"
                                            }));
                            }), dashboards))), React.createElement("ul", {
                    className: "list"
                  }, $$Array.of_list(List.map((function (x) {
                              return React.createElement("li", {
                                          key: String(Dashboard$ReactHooksTemplate.id(x)),
                                          className: "flex"
                                        }, React.createElement("label", {
                                              htmlFor: Dashboard$ReactHooksTemplate.name(x),
                                              value: Dashboard$ReactHooksTemplate.name(x),
                                              onClick: (function (param) {
                                                  var dashboardName = Dashboard$ReactHooksTemplate.name(x);
                                                  var dashboardId = Dashboard$ReactHooksTemplate.id(x);
                                                  var selectedDashboard = List.find((function (y) {
                                                          return Dashboard$ReactHooksTemplate.name(y) === dashboardName;
                                                        }), dashboards);
                                                  Curry._1(onDashboardChange, selectedDashboard);
                                                  return RescriptReactRouter.push("/dashboard/" + dashboardId);
                                                })
                                            }, Dashboard$ReactHooksTemplate.name(x)));
                            }), dashboards))));
  };
  var chartId = state.enlargedChart;
  var tmp;
  if (chartId !== undefined) {
    var chartId$1 = Caml_option.valFromOption(chartId);
    tmp = React.createElement(Modal$ReactHooksTemplate.make, {
          show: true,
          children: React.createElement("div", {
                className: "w-screen bg-white pt-6 pb-10"
              }, React.createElement(ChartComponent$ReactHooksTemplate.make, {
                    chartId: chartId$1,
                    drillDownChartDetails: drillDownChartDetails,
                    onClick: onClick,
                    onEnlarge: (function (param) {
                        return Curry._1(dispatch, {
                                    TAG: /* EnlargeChart */3,
                                    _0: Caml_option.some(chartId$1)
                                  });
                      }),
                    enlarged: true,
                    key: String(ChartId$ReactHooksTemplate.id(chartId$1))
                  })),
          handleClose: (function (param) {
              return Curry._1(dispatch, {
                          TAG: /* EnlargeChart */3,
                          _0: undefined
                        });
            })
        });
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  var tmp$1;
  if (state.loading) {
    tmp$1 = React.createElement("div", {
          className: "h-screen w-full"
        }, React.createElement(Loading$ReactHooksTemplate.make, {
              img: Image$ReactHooksTemplate.dashboardLoading,
              text: "Loading Charts"
            }));
  } else {
    var chartIds = state.charts;
    tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
              id: "dashboard"
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "flex justify-between items-center p-16-px border border-gray-400 mb-4"
                    }, List.length(breadCrumbs) > 1 ? React.createElement("div", {
                            className: "flex justify-between items-center"
                          }, React.createElement(BreadCrumbsComponent$ReactHooksTemplate.make, {
                                breadCrumbs: breadCrumbs,
                                breadCrumbsOnClick: breadCrumbsOnClick
                              })) : dashboardSelectBox(undefined), React.createElement("div", {
                          className: "flex"
                        }, React.createElement("button", {
                              className: "p-2",
                              id: "share",
                              onClick: (function (param) {
                                  return Curry._1(dispatch, /* ToggleShareModal */2);
                                })
                            }, React.createElement("img", {
                                  className: "h-16-px",
                                  src: Image$ReactHooksTemplate.share
                                })), React.createElement("button", {
                              className: "p-2 mr-4",
                              id: "pdf_download",
                              onClick: (function (param) {
                                  var element = Belt_Option.getExn(Caml_option.nullable_to_opt(document.querySelector("#dashboard")));
                                  HtmlToPdf$ReactHooksTemplate.generateDashboardPdf(element);
                                  
                                })
                            }, React.createElement("img", {
                                  className: "h-16-px",
                                  src: Image$ReactHooksTemplate.download
                                })), React.createElement("div", {
                              className: "bg-white"
                            }, React.createElement("div", {
                                  className: "capitalize tracking-wide"
                                }, React.createElement("div", undefined, React.createElement(Filters$ReactHooksTemplate.make, {
                                          onClose: toggleFilter,
                                          onApply: onApplyFilter,
                                          showDateFilter: true,
                                          showFilterPills: false
                                        })))))), React.createElement("div", undefined, chartIds !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                className: "px-16-px flex justify-between"
                              }, React.createElement("div", {
                                    className: "flex flex-wrap gap-2"
                                  }, React.createElement("div", {
                                        className: "flex items-center font-normal text-sm h-8"
                                      }, "Active Filters"), React.createElement(Filters$ReactHooksTemplate.make, {
                                        onClose: toggleFilter,
                                        onApply: onApplyFilter,
                                        showDateFilter: false,
                                        showFilterPills: true
                                      })), React.createElement("div", {
                                    className: "float-right"
                                  }, React.createElement("button", {
                                        className: "bg-ghostwhite border border-gray-400\n                              shadow-inner flex rounded py-2 px-3 text-xs w-20",
                                        id: "filters",
                                        onClick: (function (param) {
                                            return Curry._1(dispatch, /* ToggleFilter */1);
                                          })
                                      }, React.createElement("img", {
                                            className: "h-14-px mr-2-px",
                                            src: Image$ReactHooksTemplate.filterButton
                                          }), "Filters"))), React.createElement("div", {
                                className: "flex flex-wrap p-16-px gap-4"
                              }, $$Array.of_list(Belt_List.map(Belt_List.keep(chartIds, (function (xs) {
                                              return ChartId$ReactHooksTemplate.visualizationType(xs) === /* ScoreCard */1;
                                            })), (function (chartId) {
                                          return React.createElement(ChartComponent$ReactHooksTemplate.make, {
                                                      chartId: chartId,
                                                      drillDownChartDetails: drillDownChartDetails,
                                                      onClick: onClick,
                                                      onEnlarge: (function (param) {
                                                          return Curry._1(dispatch, {
                                                                      TAG: /* EnlargeChart */3,
                                                                      _0: Caml_option.some(chartId)
                                                                    });
                                                        }),
                                                      scoreCard: true,
                                                      key: String(ChartId$ReactHooksTemplate.id(chartId))
                                                    });
                                        })))), React.createElement("div", {
                                className: "flex flex-wrap p-16-px"
                              }, $$Array.of_list(Belt_List.map(Belt_List.keep(chartIds, (function (xs) {
                                              return ChartId$ReactHooksTemplate.visualizationType(xs) !== /* ScoreCard */1;
                                            })), (function (chartId) {
                                          return React.createElement(ChartComponent$ReactHooksTemplate.make, {
                                                      chartId: chartId,
                                                      drillDownChartDetails: drillDownChartDetails,
                                                      onClick: onClick,
                                                      onEnlarge: (function (param) {
                                                          return Curry._1(dispatch, {
                                                                      TAG: /* EnlargeChart */3,
                                                                      _0: Caml_option.some(chartId)
                                                                    });
                                                        }),
                                                      key: String(ChartId$ReactHooksTemplate.id(chartId))
                                                    });
                                        }))))) : "No Charts Loaded for the Dashboard"))), state.showShareModal ? React.createElement(DashboardShareForm$ReactHooksTemplate.make, {
                dashboard: dashboard,
                dashboardShares: state.shareDetails,
                onClose: (function (param) {
                    return Curry._1(dispatch, /* ToggleShareModal */2);
                  })
              }) : React.createElement(React.Fragment, undefined));
  }
  return React.createElement(React.Fragment, undefined, tmp, React.createElement("div", undefined, state.toggleFilter ? React.createElement(Filters$ReactHooksTemplate.make, {
                        onClose: toggleFilter,
                        onApply: onApplyFilter,
                        showDateFilter: false,
                        showFilterPills: false
                      }) : React.createElement(React.Fragment, undefined), tmp$1));
}

var make = DashboardComponent;

exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
