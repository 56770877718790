// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var $$String = require("rescript/lib/js/string.js");
var ListCondition$ReactHooksTemplate = require("./ListCondition.bs.js");

function ListConditionForm(Props) {
  var listCondition = Props.listCondition;
  var updateCondition = Props.updateCondition;
  return React.createElement("div", {
              className: "flex items-center"
            }, React.createElement("div", {
                  className: "pl-2"
                }, React.createElement("div", {
                      className: "w-48-px h-20-px font-normal text-xs text-aurometalsaurus "
                    }, "Condition"), React.createElement("input", {
                      defaultValue: ListCondition$ReactHooksTemplate.operator(listCondition),
                      className: "h-40-px w-110-px py-2 px-1 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs",
                      placeholder: "Select Operator",
                      readOnly: true,
                      type: "text",
                      onChange: (function (param) {
                          return Curry._1(updateCondition, {
                                      TAG: /* ListCondition */2,
                                      _0: listCondition
                                    });
                        })
                    })), React.createElement("div", {
                  className: "pl-2"
                }, React.createElement("div", {
                      className: "w-48-px h-20-px font-normal text-xs text-aurometalsaurus "
                    }, "Answer"), React.createElement("input", {
                      defaultValue: $$String.concat(", ", List.map((function (param) {
                                  return param[1];
                                }), ListCondition$ReactHooksTemplate.rhs(listCondition))),
                      className: "h-40-px w-80-px py-2 px-1 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs",
                      placeholder: "Select Answer",
                      readOnly: true,
                      type: "text",
                      onChange: (function (param) {
                          return Curry._1(updateCondition, {
                                      TAG: /* ListCondition */2,
                                      _0: listCondition
                                    });
                        })
                    })));
}

var make = ListConditionForm;

exports.make = make;
/* react Not a pure module */
