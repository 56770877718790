// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Kpi$ReactHooksTemplate = require("./kpi.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");

function fieldId(t) {
  return t.fieldId;
}

function name(t) {
  return t.name;
}

function decode(json) {
  return {
          fieldId: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

var KpiFields = {
  fieldId: fieldId,
  name: name,
  emptyFields: /* [] */0,
  decode: decode
};

function userId(t) {
  return t.userId;
}

function email(t) {
  return t.email;
}

function decode$1(json) {
  return {
          userId: Json_decode.field("id", Json_decode.$$int, json),
          email: Json_decode.field("email", Json_decode.string, json)
        };
}

var KpiUser = {
  userId: userId,
  email: email,
  emptyUsers: /* [] */0,
  decode: decode$1
};

function KpiCrud(Props) {
  var handleChange = Props.handleChange;
  var kpi = Props.kpi;
  var action = Props.action;
  var toggleModal = Props.toggleModal;
  var match = React.useState(function () {
        return Kpi$ReactHooksTemplate.emptyKpi(undefined);
      });
  var setFormKpi = match[1];
  var formKpi = match[0];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setUsers = match$1[1];
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setFields = match$2[1];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setValues = match$3[1];
  React.useEffect((function () {
          if (kpi !== undefined) {
            Curry._1(setFormKpi, (function (param) {
                    return kpi;
                  }));
            var filterFieldId = Kpi$ReactHooksTemplate.filterFieldId(kpi);
            Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/kpis/field_values?field_id=" + filterFieldId, (function (param) {
                      return Json_decode.field("field_values", (function (param) {
                                    return Json_decode.list(Json_decode.string, param);
                                  }), param);
                    })).then(function (values) {
                  return Promise.resolve(Curry._1(setValues, (function (param) {
                                    return values;
                                  })));
                });
            Curry._1(setValues, (function (param) {
                    return {
                            hd: Kpi$ReactHooksTemplate.filterValue(kpi),
                            tl: /* [] */0
                          };
                  }));
          } else {
            Curry._1(setFormKpi, (function (param) {
                    return Kpi$ReactHooksTemplate.emptyKpi(undefined);
                  }));
          }
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/kpis/users", (function (param) {
                    return Json_decode.field("users", (function (param) {
                                  return Json_decode.list(decode$1, param);
                                }), param);
                  })).then(function (users) {
                return Promise.resolve(Curry._1(setUsers, (function (param) {
                                  return users;
                                })));
              });
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/kpis/kpi_fields", (function (param) {
                    return Json_decode.field("fields", (function (param) {
                                  return Json_decode.list(decode, param);
                                }), param);
                  })).then(function (fields) {
                return Promise.resolve((console.log(fields), Curry._1(setFields, (function (param) {
                                    return fields;
                                  }))));
              });
          
        }), []);
  var handleEmailChange = function ($$event) {
    var email = Utils$ReactHooksTemplate.formTargetValue($$event);
    console.log(email);
    return Curry._1(setFormKpi, (function (formKpi) {
                  return {
                          id: formKpi.id,
                          userId: formKpi.userId,
                          email: email,
                          filterFieldId: formKpi.filterFieldId,
                          filterField: formKpi.filterField,
                          filterValue: formKpi.filterValue,
                          kpiValue: formKpi.kpiValue
                        };
                }));
  };
  var handleFieldChange = function ($$event) {
    var filterFieldId = Utils$ReactHooksTemplate.formTargetValue($$event);
    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/kpis/field_values?field_id=" + filterFieldId, (function (param) {
              return Json_decode.field("field_values", (function (param) {
                            return Json_decode.list(Json_decode.string, param);
                          }), param);
            })).then(function (values) {
          return Promise.resolve(Curry._1(setValues, (function (param) {
                            return values;
                          })));
        });
    return Curry._1(setFormKpi, (function (formKpi) {
                  return {
                          id: formKpi.id,
                          userId: formKpi.userId,
                          email: formKpi.email,
                          filterFieldId: filterFieldId,
                          filterField: formKpi.filterField,
                          filterValue: formKpi.filterValue,
                          kpiValue: formKpi.kpiValue
                        };
                }));
  };
  var handleValueChange = function ($$event) {
    var filterValue = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setFormKpi, (function (formKpi) {
                  return {
                          id: formKpi.id,
                          userId: formKpi.userId,
                          email: formKpi.email,
                          filterFieldId: formKpi.filterFieldId,
                          filterField: formKpi.filterField,
                          filterValue: filterValue,
                          kpiValue: formKpi.kpiValue
                        };
                }));
  };
  var handleKpiChange = function ($$event) {
    var kpiValue = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setFormKpi, (function (formKpi) {
                  return {
                          id: formKpi.id,
                          userId: formKpi.userId,
                          email: formKpi.email,
                          filterFieldId: formKpi.filterFieldId,
                          filterField: formKpi.filterField,
                          filterValue: formKpi.filterValue,
                          kpiValue: kpiValue
                        };
                }));
  };
  var tmp;
  switch (action) {
    case "create" :
        tmp = React.createElement("div", undefined, "New Kpi");
        break;
    case "delete" :
        tmp = React.createElement("div", undefined, "Delete Kpi");
        break;
    case "edit" :
        tmp = React.createElement("div", undefined, "Edit Kpi");
        break;
    default:
      tmp = React.createElement(React.Fragment, undefined);
  }
  var tmp$1;
  var exit = 0;
  switch (action) {
    case "delete" :
        tmp$1 = React.createElement("div", undefined, React.createElement("div", {
                  className: "flex mt-30-px mb-30-px"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5"
                        }, "Email"), React.createElement("input", {
                          className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                          disabled: true,
                          type: "text",
                          value: Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Kpi$ReactHooksTemplate.email(formKpi))
                        }))), React.createElement("div", {
                  className: "flex mb-30-px"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5"
                        }, "Field"), React.createElement("input", {
                          className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                          disabled: true,
                          type: "text",
                          value: Kpi$ReactHooksTemplate.filterField(formKpi)
                        }))), React.createElement("div", {
                  className: "flex mb-30-px"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5"
                        }, "Value"), React.createElement("input", {
                          className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                          disabled: true,
                          type: "text",
                          value: Kpi$ReactHooksTemplate.filterValue(formKpi)
                        }))), React.createElement("div", {
                  className: "flex mb-30-px"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5"
                        }, "KPI"), React.createElement("input", {
                          className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                          disabled: true,
                          type: "text",
                          value: String(Kpi$ReactHooksTemplate.kpiValue(formKpi))
                        }))));
        break;
    case "create" :
    case "edit" :
        exit = 1;
        break;
    default:
      tmp$1 = React.createElement(React.Fragment, undefined);
  }
  if (exit === 1) {
    var tmp$2 = action === "edit" ? React.createElement("input", {
            className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
            disabled: true,
            type: "text",
            value: Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Kpi$ReactHooksTemplate.email(formKpi))
          }) : React.createElement("select", {
            className: "block border px-3 py-2 border-gray-400\n                                   w-3/5 bg-white rounded capitalize mr-6 text-sm",
            id: Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Kpi$ReactHooksTemplate.email(formKpi)),
            placeholder: "Select Email",
            value: Caml_obj.caml_equal(Kpi$ReactHooksTemplate.email(formKpi), Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, undefined)) ? "Select Email" : Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Kpi$ReactHooksTemplate.email(formKpi)),
            onChange: handleEmailChange
          }, React.createElement("option", {
                key: "0",
                disabled: true,
                value: "Select Email"
              }, "Select Email"), $$Array.of_list(Belt_List.map(match$1[0], (function (user) {
                      return React.createElement("option", {
                                  key: String(user.userId),
                                  disabled: action === "edit",
                                  value: String(user.userId)
                                }, user.email);
                    }))));
    var tmp$3 = action === "edit" ? React.createElement("input", {
            className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
            disabled: true,
            type: "text",
            value: Kpi$ReactHooksTemplate.filterField(formKpi)
          }) : React.createElement("select", {
            className: "block border px-3 py-2 border-gray-400\n                                  w-3/5 bg-white rounded capitalize mr-6 text-sm",
            value: Kpi$ReactHooksTemplate.filterFieldId(formKpi) === "" ? "Select Field" : Kpi$ReactHooksTemplate.filterFieldId(formKpi),
            onChange: handleFieldChange
          }, React.createElement("option", {
                key: "0",
                disabled: true,
                value: "Select Field"
              }, "Select Field"), $$Array.of_list(Belt_List.map(match$2[0], (function (field) {
                      return React.createElement("option", {
                                  key: String(field.fieldId),
                                  value: String(field.fieldId)
                                }, field.name);
                    }))));
    var tmp$4 = action === "edit" ? React.createElement("input", {
            className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
            disabled: true,
            type: "text",
            value: Kpi$ReactHooksTemplate.filterValue(formKpi)
          }) : React.createElement("select", {
            className: "block border px-3 py-2 border-gray-400\n                                  w-3/5 bg-white rounded capitalize mr-6 text-sm",
            value: Kpi$ReactHooksTemplate.filterValue(formKpi) === "" ? "Select Value" : Kpi$ReactHooksTemplate.filterValue(formKpi),
            onChange: handleValueChange
          }, React.createElement("option", {
                key: "00",
                disabled: true,
                value: "Select Value"
              }, "Select Value"), $$Array.of_list(Belt_List.mapWithIndex(match$3[0], (function (id, value) {
                      return React.createElement("option", {
                                  key: String(id + 1 | 0),
                                  value: value
                                }, value);
                    }))));
    tmp$1 = React.createElement("div", undefined, React.createElement("div", {
              className: "flex mt-30-px mb-30-px items-center"
            }, React.createElement("div", {
                  className: "flex gap-1 items-center w-full"
                }, React.createElement("div", {
                      className: "text-sm font-medium w-1/5"
                    }, "Email"), tmp$2)), React.createElement("div", {
              className: "flex mb-30-px items-center"
            }, React.createElement("div", {
                  className: "flex gap-1 items-center w-full"
                }, React.createElement("div", {
                      className: "text-sm font-medium w-1/5"
                    }, "Field"), tmp$3)), React.createElement("div", {
              className: "flex mb-30-px items-center"
            }, React.createElement("div", {
                  className: "flex gap-1 items-center w-full"
                }, React.createElement("div", {
                      className: "text-sm font-medium w-1/5"
                    }, "Value"), tmp$4)), React.createElement("div", {
              className: "flex mb-30-px items-center"
            }, React.createElement("div", {
                  className: "flex gap-1 items-center w-full"
                }, React.createElement("div", {
                      className: "text-sm font-medium w-1/5"
                    }, "KPI"), React.createElement("input", {
                      className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                      type: "text",
                      value: String(Kpi$ReactHooksTemplate.kpiValue(formKpi)),
                      onChange: handleKpiChange
                    }))));
  }
  var tmp$5 = action === "delete" ? "Delete" : "Save";
  return React.createElement("div", {
              className: "bg-white modal-campaign z-10 shadow relative border border-gray-300 pointer-events-auto"
            }, React.createElement("div", {
                  className: "flex flex-col justify-between h-full"
                }, React.createElement("div", {
                      className: "p-8 text-lg font-bold flex justify-between border-b border-gray-200"
                    }, tmp, React.createElement("img", {
                          className: "h-14-px float-right ",
                          src: Image$ReactHooksTemplate.closeIcon,
                          onClick: toggleModal
                        })), React.createElement("div", {
                      className: "h-full text-base p-8"
                    }, tmp$1), React.createElement("div", {
                      className: "p-8 flex gap-3 justify-end border-t border-gray-200"
                    }, React.createElement("button", {
                          className: "bg-ghostwhite border border-gray-400\n          flex rounded py-3 px-6 text-xs",
                          id: "close_modal",
                          onClick: toggleModal
                        }, "Discard"), React.createElement("input", {
                          className: "border border-gray-400\n            flex rounded py-3 px-6 text-xs text-white " + (
                            action === "delete" ? "bg-deepcarminepink" : "bg-palatinateblue"
                          ),
                          type: "submit",
                          value: tmp$5,
                          onClick: (function (param) {
                              return Curry._2(handleChange, formKpi, action);
                            })
                        }))));
}

var make = KpiCrud;

exports.KpiFields = KpiFields;
exports.KpiUser = KpiUser;
exports.make = make;
/* react Not a pure module */
