// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var DropDown$ReactHooksTemplate = require("./DropDown.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");
var MultipleSelect$ReactHooksTemplate = require("./MultipleSelect.bs.js");

function lhs(t) {
  return t.lhs;
}

function operator(t) {
  return t.operator;
}

function rhs(t) {
  return t.rhs;
}

function setLhs(t, lhs) {
  return {
          lhs: lhs,
          operator: t.operator,
          rhs: t.rhs
        };
}

function setOperator(t, operator) {
  return {
          lhs: t.lhs,
          operator: operator,
          rhs: t.rhs
        };
}

function setRhs(t, rhs) {
  return {
          lhs: t.lhs,
          operator: t.operator,
          rhs: rhs
        };
}

function availableOperators(param) {
  return {
          hd: "equals",
          tl: {
            hd: "member_of",
            tl: {
              hd: "is_any_of",
              tl: {
                hd: "not_any_of",
                tl: {
                  hd: "is_every_of",
                  tl: /* [] */0
                }
              }
            }
          }
        };
}

function decode(json, questions) {
  var lhsId = Json_decode.field("lhs_id", Json_decode.$$int, json);
  var lhs = List.find((function (n) {
          return Belt_Option.getExn(Question$ReactHooksTemplate.id(n)) === lhsId;
        }), questions);
  var rhsIds = Json_decode.field("rhs", (function (param) {
          return Json_decode.list(Json_decode.string, param);
        }), json);
  var rhs = Belt_List.map(rhsIds, (function (x) {
          var y = Question$ReactHooksTemplate.questionType(lhs);
          switch (y.TAG | 0) {
            case /* MultipleChoice */5 :
                return List.find((function (param) {
                              return param[0] === x;
                            }), MultipleChoice$ReactHooksTemplate.choices(y._0));
            case /* MultipleSelect */9 :
                return List.find((function (param) {
                              return param[0] === x;
                            }), Belt_List.map(MultipleSelect$ReactHooksTemplate.choices(y._0), (function (choice) {
                                  return [
                                          RandomId$ReactHooksTemplate.toString(choice.id),
                                          choice.text
                                        ];
                                })));
            case /* DropDown */10 :
                return List.find((function (param) {
                              return param[0] === x;
                            }), Belt_List.map(DropDown$ReactHooksTemplate.choices(y._0), (function (choice) {
                                  return [
                                          RandomId$ReactHooksTemplate.toString(choice.id),
                                          choice.text
                                        ];
                                })));
            default:
              return [
                      "",
                      ""
                    ];
          }
        }));
  return {
          lhs: lhs,
          operator: Json_decode.field("operator", Json_decode.string, json),
          rhs: rhs
        };
}

function choiceIds(choices) {
  return List.map((function (param) {
                return param[0];
              }), choices);
}

function encodeRhs(t) {
  var choiceIds$1 = choiceIds(t.rhs);
  return Belt_List.toArray(Belt_List.map(choiceIds$1, (function (x) {
                    return x;
                  })));
}

exports.lhs = lhs;
exports.operator = operator;
exports.rhs = rhs;
exports.setLhs = setLhs;
exports.setOperator = setOperator;
exports.setRhs = setRhs;
exports.availableOperators = availableOperators;
exports.decode = decode;
exports.encodeRhs = encodeRhs;
/* DropDown-ReactHooksTemplate Not a pure module */
