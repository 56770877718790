// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");

function SearchForm(Props) {
  var searchKey = Props.searchKey;
  var setSearchKey = Props.setSearchKey;
  var handleSearch = Props.handleSearch;
  var searchPlaceholder = Props.searchPlaceholder;
  var handleSearchKeyChange = function ($$event) {
    var searchKey = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setSearchKey, (function (param) {
                  return searchKey;
                }));
  };
  var tmp;
  tmp = searchKey !== undefined && searchKey !== "" ? React.createElement(React.Fragment, undefined, React.createElement("button", {
              className: "search-cancel-button",
              type: "button",
              onClick: (function (param) {
                  return Curry._1(setSearchKey, (function (param) {
                                
                              }));
                })
            }, React.createElement("img", {
                  className: "w-2",
                  alt: "searchcancelbutton",
                  src: Image$ReactHooksTemplate.closeIcon
                }))) : React.createElement(React.Fragment, undefined);
  return React.createElement("div", {
              className: "flex"
            }, React.createElement("form", {
                  className: "flex items-center w-full",
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      return Curry._1(handleSearch, undefined);
                    })
                }, React.createElement("button", {
                      type: "submit"
                    }, React.createElement("img", {
                          className: "h-14-px mr-1",
                          src: Image$ReactHooksTemplate.searchGray
                        })), React.createElement("input", {
                      className: "text-sm text-aurometalsaurus w-full pl-2",
                      placeholder: searchPlaceholder,
                      type: "text",
                      value: Belt_Option.getWithDefault(searchKey, ""),
                      onChange: handleSearchKeyChange
                    })), React.createElement("div", {
                  className: "relative w-300-px"
                }, tmp));
}

var make = SearchForm;

exports.make = make;
/* react Not a pure module */
