// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");
var OptionGroups$ReactHooksTemplate = require("../common/optionGroups.bs.js");

function FilteredSelectBox(Props) {
  var options = Props.options;
  var placeholderOpt = Props.placeholder;
  var onChange = Props.onChange;
  var classNameOpt = Props.className;
  var selectedOption = Props.selectedOption;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useState(function () {
        return {
                open_: false
              };
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setPosition = match$1[1];
  var position = match$1[0];
  var domElement = React.useRef(null);
  var handleOutsideClick = function ($$event) {
    var targetElement = $$event.target;
    var selectBoxElement = Belt_Option.getExn(Caml_option.nullable_to_opt(domElement.current));
    if (!selectBoxElement.contains(targetElement)) {
      return Curry._1(setState, (function (param) {
                    return {
                            open_: false
                          };
                  }));
    }
    
  };
  React.useEffect((function () {
          document.addEventListener("mousedown", handleOutsideClick);
          return (function (param) {
                    document.removeEventListener("mousedown", handleOutsideClick);
                    
                  });
        }), []);
  var handleClick = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    return Curry._1(setState, (function (state) {
                  return {
                          open_: !state.open_
                        };
                }));
  };
  var handleOptionClick = function (optionItem, index) {
    var updatedSelectedOptions;
    if (optionItem.TAG === /* Questions */0) {
      var xs = optionItem._0;
      updatedSelectedOptions = Caml_obj.caml_equal(selectedOption, {
            TAG: /* Questions */0,
            _0: xs
          }) ? ({
            TAG: /* Questions */0,
            _0: /* [] */0
          }) : ({
            TAG: /* Questions */0,
            _0: xs
          });
    } else {
      var xs$1 = optionItem._0;
      updatedSelectedOptions = Caml_obj.caml_equal(selectedOption, {
            TAG: /* ThankYous */1,
            _0: xs$1
          }) ? ({
            TAG: /* ThankYous */1,
            _0: /* [] */0
          }) : ({
            TAG: /* ThankYous */1,
            _0: xs$1
          });
    }
    Curry._1(setPosition, (function (param) {
            return index;
          }));
    return Curry._1(onChange, updatedSelectedOptions);
  };
  var renderItem = function (prefix, id, text) {
    return React.createElement("div", {
                className: "flex items-center"
              }, React.createElement("div", {
                    className: "w-6 h-6 flex items-center text-white mr-3 rounded-full bg-pigmentblue"
                  }, React.createElement("div", {
                        className: "m-auto"
                      }, prefix + String(id + 1 | 0))), React.createElement("div", {
                    className: "flex items-center"
                  }, text));
  };
  var renderPlaceholder = function (placeholder) {
    return React.createElement("div", {
                className: "flex items-center"
              }, React.createElement("div", {
                    className: "w-6 h-6 flex items-center"
                  }, React.createElement("div", {
                        className: "m-auto"
                      })), React.createElement("div", {
                    className: "flex items-center"
                  }, placeholder));
  };
  var placeholderText;
  if (selectedOption.TAG === /* Questions */0) {
    var question = Belt_List.head(selectedOption._0);
    if (question !== undefined) {
      var x = List.find_opt((function (y) {
              return Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(y), Question$ReactHooksTemplate.uuid(question));
            }), OptionGroups$ReactHooksTemplate.questions(options));
      placeholderText = x !== undefined ? renderItem("Q", position, Question$ReactHooksTemplate.text(x)) : renderPlaceholder(placeholder);
    } else {
      placeholderText = renderPlaceholder(placeholder);
    }
  } else {
    var thankYou = Belt_List.head(selectedOption._0);
    if (thankYou !== undefined) {
      var thankYou$1 = Caml_option.valFromOption(thankYou);
      var x$1 = List.find_opt((function (z) {
              return Caml_obj.caml_equal(ThankYou$ReactHooksTemplate.uuid(z), ThankYou$ReactHooksTemplate.uuid(thankYou$1));
            }), OptionGroups$ReactHooksTemplate.thankYous(options));
      placeholderText = x$1 !== undefined ? renderItem("T", position, ThankYou$ReactHooksTemplate.text(Caml_option.valFromOption(x$1))) : renderPlaceholder(placeholder);
    } else {
      placeholderText = renderPlaceholder(placeholder);
    }
  }
  var listBoxCSS = state.open_ ? "block" : "hidden";
  return React.createElement("div", {
              ref: domElement,
              className: "relative"
            }, React.createElement("div", {
                  className: "border cursor-pointer rounded p-4" + className,
                  onClick: handleClick
                }, React.createElement("div", undefined, state.open_ ? React.createElement("img", {
                            className: "float-right py-2",
                            src: Image$ReactHooksTemplate.chevronUp
                          }) : React.createElement("img", {
                            className: "float-right py-2",
                            src: Image$ReactHooksTemplate.chevronDown
                          })), React.createElement("div", undefined, React.createElement("div", {
                          className: "h-6 text-sm"
                        }, React.createElement("div", {
                              className: "flex flex-wrap"
                            }, React.createElement("p", undefined, placeholderText))))), React.createElement("div", {
                  className: "flex flex-col border z-50 bg-white w-full overflow-auto cursor-pointer rounded " + listBoxCSS,
                  style: {
                    maxHeight: "40vh",
                    position: "absolute"
                  }
                }, Belt_List.length(OptionGroups$ReactHooksTemplate.questions(options)) > 0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: "text-base font-medium text-darkcharcoal m-2 p-1"
                          }, "Questions"), $$Array.of_list(Belt_List.mapWithIndex(OptionGroups$ReactHooksTemplate.questions(options), (function (i, x) {
                                  var selected;
                                  if (selectedOption.TAG === /* Questions */0) {
                                    var xs = selectedOption._0;
                                    selected = xs ? List.exists((function (y) {
                                              return Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(y), Question$ReactHooksTemplate.uuid(x));
                                            }), xs) : false;
                                  } else {
                                    selected = false;
                                  }
                                  return React.createElement("div", {
                                              key: String(i),
                                              className: selected ? "text-left bg-ghostwhiteblue px-2 py-1" : "hover:bg-gray-300 text-left px-2 py-1",
                                              value: Question$ReactHooksTemplate.text(x),
                                              onClick: (function (param) {
                                                  return handleOptionClick({
                                                              TAG: /* Questions */0,
                                                              _0: {
                                                                hd: x,
                                                                tl: /* [] */0
                                                              }
                                                            }, i);
                                                })
                                            }, React.createElement("div", {
                                                  className: "flex text-sm items-center border-b border-lavenderweb border-x-8 p-2"
                                                }, React.createElement("div", {
                                                      className: "w-6 h-6 flex items-center text-white mr-3 rounded-full bg-pigmentblue"
                                                    }, React.createElement("div", {
                                                          className: "m-auto"
                                                        }, String(i + 1 | 0))), React.createElement("div", {
                                                      className: "flex items-center"
                                                    }, Question$ReactHooksTemplate.text(x))));
                                })))) : React.createElement(React.Fragment, undefined), Belt_List.length(OptionGroups$ReactHooksTemplate.thankYous(options)) > 0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: "text-base font-medium text-darkcharcoal m-2 p-1"
                          }, "Thank Yous"), $$Array.of_list(Belt_List.mapWithIndex(OptionGroups$ReactHooksTemplate.thankYous(options), (function (i, x) {
                                  var selected;
                                  if (selectedOption.TAG === /* Questions */0) {
                                    selected = false;
                                  } else {
                                    var xs = selectedOption._0;
                                    selected = xs ? List.exists((function (y) {
                                              return Caml_obj.caml_equal(ThankYou$ReactHooksTemplate.uuid(y), ThankYou$ReactHooksTemplate.uuid(x));
                                            }), xs) : false;
                                  }
                                  return React.createElement("div", {
                                              key: String(i),
                                              className: selected ? "text-left bg-ghostwhiteblue px-2 py-1" : "hover:bg-gray-300 text-left px-2 py-1",
                                              value: ThankYou$ReactHooksTemplate.text(x),
                                              onClick: (function (param) {
                                                  return handleOptionClick({
                                                              TAG: /* ThankYous */1,
                                                              _0: {
                                                                hd: x,
                                                                tl: /* [] */0
                                                              }
                                                            }, i);
                                                })
                                            }, React.createElement("div", {
                                                  className: "flex text-sm items-center border-b border-lavenderweb border-x-8 p-2"
                                                }, React.createElement("div", {
                                                      className: "w-6 h-6 flex items-center text-white mr-3 rounded-full bg-pigmentblue"
                                                    }, React.createElement("div", {
                                                          className: "m-auto"
                                                        }, String(i + 1 | 0))), React.createElement("div", {
                                                      className: "flex items-center"
                                                    }, ThankYou$ReactHooksTemplate.text(x))));
                                })))) : React.createElement(React.Fragment, undefined)));
}

var make = FilteredSelectBox;

exports.make = make;
/* react Not a pure module */
