// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function emptyFlags(param) {
  return /* [] */0;
}

function flagNameDecode(flagName) {
  switch (flagName) {
    case "general_settings_ui" :
        return /* GeneralSettingsUI */3;
    case "insights_dashboard" :
        return /* InsightsDashboard */5;
    case "manual_text_categorization" :
        return /* ManualTextCategorization */1;
    case "reports" :
        return /* Reports */2;
    case "survey_logic_new_implementation" :
        return /* SurveyLogicNewImplementation */6;
    case "telephonic_survey" :
        return /* TelephonicSurvey */4;
    default:
      return /* DummyFlag */0;
  }
}

function fromJson(json) {
  return {
          flag: flagNameDecode(Json_decode.field("flag", Json_decode.string, json)),
          description: Json_decode.field("description", Json_decode.string, json),
          enabled: Json_decode.field("enabled", Json_decode.bool, json)
        };
}

function decode(json) {
  return Json_decode.field("release_flags", (function (param) {
                return Json_decode.list(fromJson, param);
              }), json);
}

function isEnabled(releaseFlags, flagName) {
  var requiredFlag = List.find_opt((function (rf) {
          return rf.flag === flagName;
        }), releaseFlags);
  if (requiredFlag !== undefined) {
    return requiredFlag.enabled;
  } else {
    return false;
  }
}

exports.emptyFlags = emptyFlags;
exports.isEnabled = isEnabled;
exports.decode = decode;
/* No side effect */
