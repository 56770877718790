// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Csat$ReactHooksTemplate = require("./csat.bs.js");
var NpsForm$ReactHooksTemplate = require("./NpsForm.bs.js");
var CsatForm$ReactHooksTemplate = require("./csatForm.bs.js");
var DropDown$ReactHooksTemplate = require("./DropDown.bs.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var NpsEditor$ReactHooksTemplate = require("./NpsEditor.bs.js");
var ShortText$ReactHooksTemplate = require("./ShortText.bs.js");
var CsatEditor$ReactHooksTemplate = require("./csatEditor.bs.js");
var NpsPreview$ReactHooksTemplate = require("./NpsPreview.bs.js");
var StarRating$ReactHooksTemplate = require("./StarRating.bs.js");
var CsatPreview$ReactHooksTemplate = require("./CsatPreview.bs.js");
var RatingGroup$ReactHooksTemplate = require("./RatingGroup.bs.js");
var DropDownForm$ReactHooksTemplate = require("./DropDownForm.bs.js");
var LongTextForm$ReactHooksTemplate = require("./LongTextForm.bs.js");
var MobileNumber$ReactHooksTemplate = require("./MobileNumber.bs.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var SmileyRating$ReactHooksTemplate = require("./smileyRating.bs.js");
var ShortTextForm$ReactHooksTemplate = require("./ShortTextForm.bs.js");
var DropDownEditor$ReactHooksTemplate = require("./DropDownEditor.bs.js");
var LongTextEditor$ReactHooksTemplate = require("./LongTextEditor.bs.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");
var MultipleSelect$ReactHooksTemplate = require("./MultipleSelect.bs.js");
var StarRatingForm$ReactHooksTemplate = require("./StarRatingForm.bs.js");
var DropDownPreview$ReactHooksTemplate = require("./DropDownPreview.bs.js");
var LongTextPreview$ReactHooksTemplate = require("./LongTextPreview.bs.js");
var RatingGroupForm$ReactHooksTemplate = require("./RatingGroupForm.bs.js");
var ShortTextEditor$ReactHooksTemplate = require("./ShortTextEditor.bs.js");
var MobileNumberForm$ReactHooksTemplate = require("./MobileNumberForm.bs.js");
var OpinionScaleForm$ReactHooksTemplate = require("./OpinionScaleForm.bs.js");
var ShortTextPreview$ReactHooksTemplate = require("./ShortTextPreview.bs.js");
var SmileyRatingForm$ReactHooksTemplate = require("./SmileyRatingForm.bs.js");
var StarRatingEditor$ReactHooksTemplate = require("./StarRatingEditor.bs.js");
var RatingGroupEditor$ReactHooksTemplate = require("./RatingGroupEditor.bs.js");
var StarRatingPreview$ReactHooksTemplate = require("./StarRatingPreview.bs.js");
var MobileNumberEditor$ReactHooksTemplate = require("./MobileNumberEditor.bs.js");
var MultipleChoiceForm$ReactHooksTemplate = require("./MultipleChoiceForm.bs.js");
var MultipleSelectForm$ReactHooksTemplate = require("./MultipleSelectForm.bs.js");
var OpinionScaleEditor$ReactHooksTemplate = require("./OpinionScaleEditor.bs.js");
var RatingGroupPreview$ReactHooksTemplate = require("./RatingGroupPreview.bs.js");
var SmileyRatingEditor$ReactHooksTemplate = require("./SmileyRatingEditor.bs.js");
var MobileNumberPreview$ReactHooksTemplate = require("./MobileNumberPreview.bs.js");
var OpinionScalePreview$ReactHooksTemplate = require("./OpinionScalePreview.bs.js");
var SmileyRatingPreview$ReactHooksTemplate = require("./SmileyRatingPreview.bs.js");
var MultipleChoiceEditor$ReactHooksTemplate = require("./MultipleChoiceEditor.bs.js");
var MultipleSelectEditor$ReactHooksTemplate = require("./MultipleSelectEditor.bs.js");
var MultipleChoicePreview$ReactHooksTemplate = require("./MultipleChoicePreview.bs.js");
var MultipleSelectPreview$ReactHooksTemplate = require("./MultipleSelectPreview.bs.js");

function id(t) {
  return t.id;
}

function uuid(t) {
  var opinionScale = t.questionType;
  switch (opinionScale.TAG | 0) {
    case /* OpinionScale */0 :
        return OpinionScale$ReactHooksTemplate.uuid(opinionScale._0);
    case /* StarRating */1 :
        return StarRating$ReactHooksTemplate.uuid(opinionScale._0);
    case /* LongText */2 :
        return LongText$ReactHooksTemplate.uuid(opinionScale._0);
    case /* ShortText */3 :
        return ShortText$ReactHooksTemplate.uuid(opinionScale._0);
    case /* Nps */4 :
        return Nps$ReactHooksTemplate.uuid(opinionScale._0);
    case /* MultipleChoice */5 :
        return MultipleChoice$ReactHooksTemplate.uuid(opinionScale._0);
    case /* RatingGroup */6 :
        return RatingGroup$ReactHooksTemplate.uuid(opinionScale._0);
    case /* SmileyRating */7 :
        return SmileyRating$ReactHooksTemplate.uuid(opinionScale._0);
    case /* Csat */8 :
        return Csat$ReactHooksTemplate.uuid(opinionScale._0);
    case /* MultipleSelect */9 :
        return MultipleSelect$ReactHooksTemplate.uuid(opinionScale._0);
    case /* DropDown */10 :
        return DropDown$ReactHooksTemplate.uuid(opinionScale._0);
    case /* MobileNumber */11 :
        return MobileNumber$ReactHooksTemplate.uuid(opinionScale._0);
    
  }
}

function text(t) {
  var opinionScale = t.questionType;
  switch (opinionScale.TAG | 0) {
    case /* OpinionScale */0 :
        return OpinionScale$ReactHooksTemplate.text(opinionScale._0);
    case /* StarRating */1 :
        return StarRating$ReactHooksTemplate.text(opinionScale._0);
    case /* LongText */2 :
        return LongText$ReactHooksTemplate.text(opinionScale._0);
    case /* ShortText */3 :
        return ShortText$ReactHooksTemplate.text(opinionScale._0);
    case /* Nps */4 :
        return Nps$ReactHooksTemplate.text(opinionScale._0);
    case /* MultipleChoice */5 :
        return MultipleChoice$ReactHooksTemplate.text(opinionScale._0);
    case /* RatingGroup */6 :
        return RatingGroup$ReactHooksTemplate.text(opinionScale._0);
    case /* SmileyRating */7 :
        return SmileyRating$ReactHooksTemplate.text(opinionScale._0);
    case /* Csat */8 :
        return Csat$ReactHooksTemplate.text(opinionScale._0);
    case /* MultipleSelect */9 :
        return MultipleSelect$ReactHooksTemplate.text(opinionScale._0);
    case /* DropDown */10 :
        return DropDown$ReactHooksTemplate.text(opinionScale._0);
    case /* MobileNumber */11 :
        return MobileNumber$ReactHooksTemplate.text(opinionScale._0);
    
  }
}

function categoryType(t) {
  var match = t.questionType;
  switch (match.TAG | 0) {
    case /* OpinionScale */0 :
        return "OpinionScale";
    case /* StarRating */1 :
        return "StarRating";
    case /* LongText */2 :
        return "LongText";
    case /* ShortText */3 :
        return "ShortText";
    case /* Nps */4 :
        return "Nps";
    case /* MultipleChoice */5 :
        return "MultipleChoice";
    case /* RatingGroup */6 :
        return "RatingGroup";
    case /* SmileyRating */7 :
        return "SmileyRating";
    case /* Csat */8 :
        return "Csat";
    case /* MultipleSelect */9 :
        return "MultipleSelect";
    case /* DropDown */10 :
        return "DropDown";
    case /* MobileNumber */11 :
        return "MobileNumber";
    
  }
}

function questionType(t) {
  return t.questionType;
}

function mandatory(t) {
  return t.mandatory;
}

function setQuestionType(t, questionType) {
  return {
          mandatory: t.mandatory,
          questionType: questionType,
          id: t.id
        };
}

function answered(t) {
  var opinionScale = t.questionType;
  switch (opinionScale.TAG | 0) {
    case /* OpinionScale */0 :
        return OpinionScale$ReactHooksTemplate.answered(opinionScale._0);
    case /* StarRating */1 :
        return StarRating$ReactHooksTemplate.answered(opinionScale._0);
    case /* LongText */2 :
        return LongText$ReactHooksTemplate.answered(opinionScale._0);
    case /* ShortText */3 :
        return ShortText$ReactHooksTemplate.answered(opinionScale._0);
    case /* Nps */4 :
        return Nps$ReactHooksTemplate.answered(opinionScale._0);
    case /* MultipleChoice */5 :
        return MultipleChoice$ReactHooksTemplate.answered(opinionScale._0);
    case /* RatingGroup */6 :
        return RatingGroup$ReactHooksTemplate.answered(opinionScale._0, t.mandatory);
    case /* SmileyRating */7 :
        return SmileyRating$ReactHooksTemplate.answered(opinionScale._0);
    case /* Csat */8 :
        return Csat$ReactHooksTemplate.answered(opinionScale._0);
    case /* MultipleSelect */9 :
        return MultipleSelect$ReactHooksTemplate.answered(opinionScale._0);
    case /* DropDown */10 :
        return DropDown$ReactHooksTemplate.answered(opinionScale._0);
    case /* MobileNumber */11 :
        return MobileNumber$ReactHooksTemplate.answered(opinionScale._0);
    
  }
}

function fromCategoryType(x) {
  var questionType = x === OpinionScale$ReactHooksTemplate.categoryType ? ({
        TAG: /* OpinionScale */0,
        _0: OpinionScale$ReactHooksTemplate.empty(undefined)
      }) : (
      x === StarRating$ReactHooksTemplate.categoryType ? ({
            TAG: /* StarRating */1,
            _0: StarRating$ReactHooksTemplate.empty(undefined)
          }) : (
          x === LongText$ReactHooksTemplate.categoryType ? ({
                TAG: /* LongText */2,
                _0: LongText$ReactHooksTemplate.empty(undefined)
              }) : (
              x === ShortText$ReactHooksTemplate.categoryType ? ({
                    TAG: /* ShortText */3,
                    _0: ShortText$ReactHooksTemplate.empty(undefined)
                  }) : (
                  x === Nps$ReactHooksTemplate.categoryType ? ({
                        TAG: /* Nps */4,
                        _0: Nps$ReactHooksTemplate.empty(undefined)
                      }) : (
                      x === MultipleChoice$ReactHooksTemplate.categoryType ? ({
                            TAG: /* MultipleChoice */5,
                            _0: MultipleChoice$ReactHooksTemplate.empty(undefined)
                          }) : (
                          x === RatingGroup$ReactHooksTemplate.categoryType ? ({
                                TAG: /* RatingGroup */6,
                                _0: RatingGroup$ReactHooksTemplate.empty(undefined)
                              }) : (
                              x === SmileyRating$ReactHooksTemplate.categoryType ? ({
                                    TAG: /* SmileyRating */7,
                                    _0: SmileyRating$ReactHooksTemplate.empty(undefined)
                                  }) : (
                                  x === Csat$ReactHooksTemplate.categoryType ? ({
                                        TAG: /* Csat */8,
                                        _0: Csat$ReactHooksTemplate.empty(undefined)
                                      }) : (
                                      x === MultipleSelect$ReactHooksTemplate.categoryType ? ({
                                            TAG: /* MultipleSelect */9,
                                            _0: MultipleSelect$ReactHooksTemplate.empty(undefined)
                                          }) : (
                                          x === DropDown$ReactHooksTemplate.categoryType ? ({
                                                TAG: /* DropDown */10,
                                                _0: DropDown$ReactHooksTemplate.empty(undefined)
                                              }) : (
                                              x === MobileNumber$ReactHooksTemplate.categoryType ? ({
                                                    TAG: /* MobileNumber */11,
                                                    _0: MobileNumber$ReactHooksTemplate.empty(undefined)
                                                  }) : undefined
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  if (questionType !== undefined) {
    return {
            mandatory: false,
            questionType: questionType,
            id: undefined
          };
  }
  
}

function toEditorBlock(f, question) {
  var opinionScale = question.questionType;
  switch (opinionScale.TAG | 0) {
    case /* OpinionScale */0 :
        return React.createElement(OpinionScaleEditor$ReactHooksTemplate.make, {
                    opinionScale: opinionScale._0,
                    onOpinionScaleChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* OpinionScale */0,
                                        _0: x
                                      }));
                      })
                  });
    case /* StarRating */1 :
        return React.createElement(StarRatingEditor$ReactHooksTemplate.make, {});
    case /* LongText */2 :
        return React.createElement(LongTextEditor$ReactHooksTemplate.make, {
                    longText: opinionScale._0,
                    onLongTextChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* LongText */2,
                                        _0: x
                                      }));
                      })
                  });
    case /* ShortText */3 :
        return React.createElement(ShortTextEditor$ReactHooksTemplate.make, {});
    case /* Nps */4 :
        return React.createElement(NpsEditor$ReactHooksTemplate.make, {
                    nps: opinionScale._0,
                    onNpsChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* Nps */4,
                                        _0: x
                                      }));
                      })
                  });
    case /* MultipleChoice */5 :
        return React.createElement(MultipleChoiceEditor$ReactHooksTemplate.make, {
                    multipleChoice: opinionScale._0,
                    onMultipleChoiceChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* MultipleChoice */5,
                                        _0: x
                                      }));
                      })
                  });
    case /* RatingGroup */6 :
        return React.createElement(RatingGroupEditor$ReactHooksTemplate.make, {
                    ratingGroup: opinionScale._0,
                    onRatingGroupChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* RatingGroup */6,
                                        _0: x
                                      }));
                      })
                  });
    case /* SmileyRating */7 :
        return React.createElement(SmileyRatingEditor$ReactHooksTemplate.make, {
                    smileyRating: opinionScale._0,
                    onSmileyRatingChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* SmileyRating */7,
                                        _0: x
                                      }));
                      })
                  });
    case /* Csat */8 :
        return React.createElement(CsatEditor$ReactHooksTemplate.make, {
                    csat: opinionScale._0,
                    onCsatChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* Csat */8,
                                        _0: x
                                      }));
                      })
                  });
    case /* MultipleSelect */9 :
        return React.createElement(MultipleSelectEditor$ReactHooksTemplate.make, {
                    multipleSelect: opinionScale._0,
                    onMultipleChoiceChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* MultipleSelect */9,
                                        _0: x
                                      }));
                      })
                  });
    case /* DropDown */10 :
        return React.createElement(DropDownEditor$ReactHooksTemplate.make, {
                    dropDown: opinionScale._0,
                    onDropDownChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* DropDown */10,
                                        _0: x
                                      }));
                      })
                  });
    case /* MobileNumber */11 :
        return React.createElement(MobileNumberEditor$ReactHooksTemplate.make, {
                    mobileNumber: opinionScale._0,
                    onMobileNumberChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* MobileNumber */11,
                                        _0: x
                                      }));
                      })
                  });
    
  }
}

function equals(lhs, rhs) {
  return Caml_obj.caml_equal(uuid(lhs), uuid(rhs));
}

function toForm(f, customerInfo, surveyTheme, language, client, question) {
  var opinionScale = question.questionType;
  switch (opinionScale.TAG | 0) {
    case /* OpinionScale */0 :
        return React.createElement(OpinionScaleForm$ReactHooksTemplate.make, {
                    opinionScale: opinionScale._0,
                    customerInfo: customerInfo,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* OpinionScale */0,
                                        _0: x
                                      }));
                      })
                  });
    case /* StarRating */1 :
        return React.createElement(StarRatingForm$ReactHooksTemplate.make, {
                    starRating: opinionScale._0,
                    surveyTheme: surveyTheme,
                    client: client,
                    mandatory: question.mandatory,
                    language: language,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* StarRating */1,
                                        _0: x
                                      }));
                      })
                  });
    case /* LongText */2 :
        return React.createElement(LongTextForm$ReactHooksTemplate.make, {
                    longText: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* LongText */2,
                                        _0: x
                                      }));
                      })
                  });
    case /* ShortText */3 :
        return React.createElement(ShortTextForm$ReactHooksTemplate.make, {
                    shortText: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* ShortText */3,
                                        _0: x
                                      }));
                      })
                  });
    case /* Nps */4 :
        return React.createElement(NpsForm$ReactHooksTemplate.make, {
                    nps: opinionScale._0,
                    customerInfo: customerInfo,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* Nps */4,
                                        _0: x
                                      }));
                      })
                  });
    case /* MultipleChoice */5 :
        return React.createElement(MultipleChoiceForm$ReactHooksTemplate.make, {
                    multipleChoice: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* MultipleChoice */5,
                                        _0: x
                                      }));
                      })
                  });
    case /* RatingGroup */6 :
        return React.createElement(RatingGroupForm$ReactHooksTemplate.make, {
                    ratingGroup: opinionScale._0,
                    surveyTheme: surveyTheme,
                    client: client,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* RatingGroup */6,
                                        _0: x
                                      }));
                      })
                  });
    case /* SmileyRating */7 :
        return React.createElement(SmileyRatingForm$ReactHooksTemplate.make, {
                    smileyRating: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* SmileyRating */7,
                                        _0: x
                                      }));
                      })
                  });
    case /* Csat */8 :
        return React.createElement(CsatForm$ReactHooksTemplate.make, {
                    csat: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* Csat */8,
                                        _0: x
                                      }));
                      })
                  });
    case /* MultipleSelect */9 :
        return React.createElement(MultipleSelectForm$ReactHooksTemplate.make, {
                    multipleSelect: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* MultipleSelect */9,
                                        _0: x
                                      }));
                      })
                  });
    case /* DropDown */10 :
        return React.createElement(DropDownForm$ReactHooksTemplate.make, {
                    dropDown: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* DropDown */10,
                                        _0: x
                                      }));
                      })
                  });
    case /* MobileNumber */11 :
        return React.createElement(MobileNumberForm$ReactHooksTemplate.make, {
                    mobileNumber: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* MobileNumber */11,
                                        _0: x
                                      }));
                      })
                  });
    
  }
}

function preview(f, customerInfo, surveyTheme, language, question) {
  var opinionScale = question.questionType;
  switch (opinionScale.TAG | 0) {
    case /* OpinionScale */0 :
        return React.createElement(OpinionScalePreview$ReactHooksTemplate.make, {
                    opinionScale: opinionScale._0,
                    surveyTheme: surveyTheme,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* OpinionScale */0,
                                        _0: x
                                      }));
                      })
                  });
    case /* StarRating */1 :
        return React.createElement(StarRatingPreview$ReactHooksTemplate.make, {
                    starRating: opinionScale._0,
                    surveyTheme: surveyTheme,
                    mandatory: question.mandatory,
                    language: language,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* StarRating */1,
                                        _0: x
                                      }));
                      })
                  });
    case /* LongText */2 :
        return React.createElement(LongTextPreview$ReactHooksTemplate.make, {
                    longText: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* LongText */2,
                                        _0: x
                                      }));
                      })
                  });
    case /* ShortText */3 :
        return React.createElement(ShortTextPreview$ReactHooksTemplate.make, {
                    shortText: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* ShortText */3,
                                        _0: x
                                      }));
                      })
                  });
    case /* Nps */4 :
        return React.createElement(NpsPreview$ReactHooksTemplate.make, {
                    nps: opinionScale._0,
                    customerInfo: customerInfo,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* Nps */4,
                                        _0: x
                                      }));
                      })
                  });
    case /* MultipleChoice */5 :
        return React.createElement(MultipleChoicePreview$ReactHooksTemplate.make, {
                    multipleChoice: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* MultipleChoice */5,
                                        _0: x
                                      }));
                      })
                  });
    case /* RatingGroup */6 :
        return React.createElement(RatingGroupPreview$ReactHooksTemplate.make, {
                    ratingGroup: opinionScale._0,
                    surveyTheme: surveyTheme,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* RatingGroup */6,
                                        _0: x
                                      }));
                      })
                  });
    case /* SmileyRating */7 :
        return React.createElement(SmileyRatingPreview$ReactHooksTemplate.make, {
                    smileyRating: opinionScale._0,
                    surveyTheme: surveyTheme,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* SmileyRating */7,
                                        _0: x
                                      }));
                      })
                  });
    case /* Csat */8 :
        return React.createElement(CsatPreview$ReactHooksTemplate.make, {
                    csat: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* Csat */8,
                                        _0: x
                                      }));
                      })
                  });
    case /* MultipleSelect */9 :
        return React.createElement(MultipleSelectPreview$ReactHooksTemplate.make, {
                    multipleSelect: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* MultipleSelect */9,
                                        _0: x
                                      }));
                      })
                  });
    case /* DropDown */10 :
        return React.createElement(DropDownPreview$ReactHooksTemplate.make, {
                    dropDown: opinionScale._0,
                    surveyTheme: surveyTheme,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* DropDown */10,
                                        _0: x
                                      }));
                      })
                  });
    case /* MobileNumber */11 :
        return React.createElement(MobileNumberPreview$ReactHooksTemplate.make, {
                    mobileNumber: opinionScale._0,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question.mandatory,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, {
                                        TAG: /* MobileNumber */11,
                                        _0: x
                                      }));
                      })
                  });
    
  }
}

function toJson(withAnswerOpt, position, destroyOpt, t) {
  var withAnswer = withAnswerOpt !== undefined ? withAnswerOpt : false;
  var destroy = destroyOpt !== undefined ? destroyOpt : false;
  var opinionScale = t.questionType;
  var questionTypeAttributes;
  switch (opinionScale.TAG | 0) {
    case /* OpinionScale */0 :
        questionTypeAttributes = {
          hd: [
            "category_type",
            OpinionScale$ReactHooksTemplate.categoryType
          ],
          tl: {
            hd: [
              "category_attributes",
              OpinionScale$ReactHooksTemplate.toJson(withAnswer, opinionScale._0)
            ],
            tl: /* [] */0
          }
        };
        break;
    case /* StarRating */1 :
        questionTypeAttributes = {
          hd: [
            "category_type",
            StarRating$ReactHooksTemplate.categoryType
          ],
          tl: {
            hd: [
              "category_attributes",
              StarRating$ReactHooksTemplate.toJson(withAnswer, opinionScale._0)
            ],
            tl: /* [] */0
          }
        };
        break;
    case /* LongText */2 :
        questionTypeAttributes = {
          hd: [
            "category_type",
            LongText$ReactHooksTemplate.categoryType
          ],
          tl: {
            hd: [
              "category_attributes",
              LongText$ReactHooksTemplate.toJson(withAnswer, opinionScale._0)
            ],
            tl: /* [] */0
          }
        };
        break;
    case /* ShortText */3 :
        questionTypeAttributes = {
          hd: [
            "category_type",
            ShortText$ReactHooksTemplate.categoryType
          ],
          tl: {
            hd: [
              "category_attributes",
              ShortText$ReactHooksTemplate.toJson(withAnswer, opinionScale._0)
            ],
            tl: /* [] */0
          }
        };
        break;
    case /* Nps */4 :
        questionTypeAttributes = {
          hd: [
            "category_type",
            Nps$ReactHooksTemplate.categoryType
          ],
          tl: {
            hd: [
              "category_attributes",
              Nps$ReactHooksTemplate.toJson(withAnswer, opinionScale._0)
            ],
            tl: /* [] */0
          }
        };
        break;
    case /* MultipleChoice */5 :
        questionTypeAttributes = {
          hd: [
            "category_type",
            MultipleChoice$ReactHooksTemplate.categoryType
          ],
          tl: {
            hd: [
              "category_attributes",
              MultipleChoice$ReactHooksTemplate.toJson(withAnswer, opinionScale._0)
            ],
            tl: /* [] */0
          }
        };
        break;
    case /* RatingGroup */6 :
        questionTypeAttributes = {
          hd: [
            "category_type",
            RatingGroup$ReactHooksTemplate.categoryType
          ],
          tl: {
            hd: [
              "category_attributes",
              RatingGroup$ReactHooksTemplate.toJson(withAnswer, opinionScale._0)
            ],
            tl: /* [] */0
          }
        };
        break;
    case /* SmileyRating */7 :
        questionTypeAttributes = {
          hd: [
            "category_type",
            SmileyRating$ReactHooksTemplate.categoryType
          ],
          tl: {
            hd: [
              "category_attributes",
              SmileyRating$ReactHooksTemplate.toJson(withAnswer, opinionScale._0)
            ],
            tl: /* [] */0
          }
        };
        break;
    case /* Csat */8 :
        questionTypeAttributes = {
          hd: [
            "category_type",
            Csat$ReactHooksTemplate.categoryType
          ],
          tl: {
            hd: [
              "category_attributes",
              Csat$ReactHooksTemplate.toJson(withAnswer, opinionScale._0)
            ],
            tl: /* [] */0
          }
        };
        break;
    case /* MultipleSelect */9 :
        questionTypeAttributes = {
          hd: [
            "category_type",
            MultipleSelect$ReactHooksTemplate.categoryType
          ],
          tl: {
            hd: [
              "category_attributes",
              MultipleSelect$ReactHooksTemplate.toJson(withAnswer, opinionScale._0)
            ],
            tl: /* [] */0
          }
        };
        break;
    case /* DropDown */10 :
        questionTypeAttributes = {
          hd: [
            "category_type",
            DropDown$ReactHooksTemplate.categoryType
          ],
          tl: {
            hd: [
              "category_attributes",
              DropDown$ReactHooksTemplate.toJson(withAnswer, opinionScale._0)
            ],
            tl: /* [] */0
          }
        };
        break;
    case /* MobileNumber */11 :
        questionTypeAttributes = {
          hd: [
            "category_type",
            MobileNumber$ReactHooksTemplate.categoryType
          ],
          tl: {
            hd: [
              "category_attributes",
              MobileNumber$ReactHooksTemplate.toJson(withAnswer, opinionScale._0)
            ],
            tl: /* [] */0
          }
        };
        break;
    
  }
  return Json_encode.object_({
              hd: [
                "mandatory",
                t.mandatory
              ],
              tl: {
                hd: [
                  "uid",
                  RandomId$ReactHooksTemplate.toJson(uuid(t))
                ],
                tl: {
                  hd: [
                    "id",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), t.id)
                  ],
                  tl: {
                    hd: [
                      "position",
                      position
                    ],
                    tl: {
                      hd: [
                        "_destroy",
                        destroy
                      ],
                      tl: questionTypeAttributes
                    }
                  }
                }
              }
            });
}

function decode(json) {
  var categoryType = Json_decode.field("category_type", Json_decode.string, json);
  var questionType = categoryType === OpinionScale$ReactHooksTemplate.categoryType ? ({
        TAG: /* OpinionScale */0,
        _0: OpinionScale$ReactHooksTemplate.fromJson(json)
      }) : (
      categoryType === StarRating$ReactHooksTemplate.categoryType ? ({
            TAG: /* StarRating */1,
            _0: StarRating$ReactHooksTemplate.fromJson(json)
          }) : (
          categoryType === LongText$ReactHooksTemplate.categoryType ? ({
                TAG: /* LongText */2,
                _0: LongText$ReactHooksTemplate.fromJson(json)
              }) : (
              categoryType === ShortText$ReactHooksTemplate.categoryType ? ({
                    TAG: /* ShortText */3,
                    _0: ShortText$ReactHooksTemplate.fromJson(json)
                  }) : (
                  categoryType === Nps$ReactHooksTemplate.categoryType ? ({
                        TAG: /* Nps */4,
                        _0: Nps$ReactHooksTemplate.fromJson(json)
                      }) : (
                      categoryType === MultipleChoice$ReactHooksTemplate.categoryType ? ({
                            TAG: /* MultipleChoice */5,
                            _0: MultipleChoice$ReactHooksTemplate.fromJson(json)
                          }) : (
                          categoryType === RatingGroup$ReactHooksTemplate.categoryType ? ({
                                TAG: /* RatingGroup */6,
                                _0: RatingGroup$ReactHooksTemplate.fromJson(json)
                              }) : (
                              categoryType === SmileyRating$ReactHooksTemplate.categoryType ? ({
                                    TAG: /* SmileyRating */7,
                                    _0: SmileyRating$ReactHooksTemplate.fromJson(json)
                                  }) : (
                                  categoryType === Csat$ReactHooksTemplate.categoryType ? ({
                                        TAG: /* Csat */8,
                                        _0: Csat$ReactHooksTemplate.fromJson(json)
                                      }) : (
                                      categoryType === MultipleSelect$ReactHooksTemplate.categoryType ? ({
                                            TAG: /* MultipleSelect */9,
                                            _0: MultipleSelect$ReactHooksTemplate.fromJson(json)
                                          }) : (
                                          categoryType === DropDown$ReactHooksTemplate.categoryType ? ({
                                                TAG: /* DropDown */10,
                                                _0: DropDown$ReactHooksTemplate.fromJson(json)
                                              }) : (
                                              categoryType === MobileNumber$ReactHooksTemplate.categoryType ? ({
                                                    TAG: /* MobileNumber */11,
                                                    _0: MobileNumber$ReactHooksTemplate.fromJson(json)
                                                  }) : undefined
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  if (questionType !== undefined) {
    return {
            mandatory: Json_decode.field("mandatory", Json_decode.bool, json),
            questionType: questionType,
            id: Json_decode.optional((function (param) {
                    return Json_decode.field("id", Json_decode.$$int, param);
                  }), json)
          };
  }
  
}

function decodeQuestionPosition(json) {
  return [
          Json_decode.field("position", Json_decode.$$int, json),
          decode(json)
        ];
}

function fromJson(json) {
  var questions = Belt_List.map(Belt_List.keep(Belt_List.map(Belt_List.sort(Json_decode.field("questions", (function (param) {
                          return Json_decode.list(decodeQuestionPosition, param);
                        }), json), (function (param, param$1) {
                      return param[0] - param$1[0] | 0;
                    })), (function (param) {
                  return param[1];
                })), Belt_Option.isSome), Belt_Option.getExn);
  var decodeResponses = function (param) {
    var questionId = Json_decode.field("question_uid", RandomId$ReactHooksTemplate.fromJson, param);
    var question = List.find((function (x) {
            return Caml_obj.caml_equal(uuid(x), questionId);
          }), questions);
    var opinionScale = question.questionType;
    switch (opinionScale.TAG | 0) {
      case /* OpinionScale */0 :
          var rating = Caml_format.caml_float_of_string(Json_decode.field("response", (function (param) {
                      return Json_decode.field("rating", Json_decode.string, param);
                    }), param)) | 0;
          var opinionScale$1 = OpinionScale$ReactHooksTemplate.setAnswer(opinionScale._0, rating);
          return {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* OpinionScale */0,
                    _0: opinionScale$1
                  },
                  id: question.id
                };
      case /* StarRating */1 :
          var rating$1 = Caml_format.caml_float_of_string(Json_decode.field("response", (function (param) {
                      return Json_decode.field("rating", Json_decode.string, param);
                    }), param)) | 0;
          var starRating = StarRating$ReactHooksTemplate.setAnswer(opinionScale._0, StarRating$ReactHooksTemplate.intToRating(rating$1));
          return {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* StarRating */1,
                    _0: starRating
                  },
                  id: question.id
                };
      case /* LongText */2 :
          var answer = Json_decode.field("response", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), param);
          var longText = LongText$ReactHooksTemplate.setAnswer(opinionScale._0, answer);
          return {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* LongText */2,
                    _0: longText
                  },
                  id: question.id
                };
      case /* ShortText */3 :
          var answer$1 = Json_decode.field("response", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), param);
          var shortText = ShortText$ReactHooksTemplate.setAnswer(opinionScale._0, answer$1);
          return {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* ShortText */3,
                    _0: shortText
                  },
                  id: question.id
                };
      case /* Nps */4 :
          var rating$2 = Caml_format.caml_float_of_string(Json_decode.field("response", (function (param) {
                      return Json_decode.field("rating", Json_decode.string, param);
                    }), param)) | 0;
          var nps = Nps$ReactHooksTemplate.setAnswer(opinionScale._0, rating$2);
          return {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* Nps */4,
                    _0: nps
                  },
                  id: question.id
                };
      case /* MultipleChoice */5 :
          var choice = Json_decode.field("response", (function (param) {
                  return Json_decode.field("choice", Json_decode.string, param);
                }), param);
          var multipleChoice = MultipleChoice$ReactHooksTemplate.setAnswer(opinionScale._0, choice);
          return {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* MultipleChoice */5,
                    _0: multipleChoice
                  },
                  id: question.id
                };
      case /* RatingGroup */6 :
          var decodeSelections = function (json) {
            return [
                    Json_decode.field("id", Json_decode.string, json),
                    Json_decode.field("value", Json_decode.$$int, json)
                  ];
          };
          var answer$2 = Json_decode.field("response", (function (param) {
                  return Json_decode.field("answer", (function (param) {
                                return Json_decode.list(decodeSelections, param);
                              }), param);
                }), param);
          var ratingGroup = List.fold_left(RatingGroup$ReactHooksTemplate.setAnswer, opinionScale._0, answer$2);
          return {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* RatingGroup */6,
                    _0: ratingGroup
                  },
                  id: question.id
                };
      case /* SmileyRating */7 :
          var smileyRating = opinionScale._0;
          var rating$3 = Json_decode.field("response", (function (param) {
                  return Json_decode.field("rating", Json_decode.$$int, param);
                }), param);
          var ratingType = List.find((function (x) {
                  return SmileyRating$ReactHooksTemplate.ratingTypeToRating(x) === rating$3;
                }), SmileyRating$ReactHooksTemplate.ratingTypeCollection(smileyRating));
          var smileyRating$1 = SmileyRating$ReactHooksTemplate.setAnswer(smileyRating, ratingType);
          return {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* SmileyRating */7,
                    _0: smileyRating$1
                  },
                  id: question.id
                };
      case /* Csat */8 :
          var rating$4 = Caml_format.caml_float_of_string(Json_decode.field("response", (function (param) {
                      return Json_decode.field("rating", Json_decode.string, param);
                    }), param)) | 0;
          var csat = Csat$ReactHooksTemplate.setAnswer(opinionScale._0, Csat$ReactHooksTemplate.ratingToAnswerType(rating$4));
          return {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* Csat */8,
                    _0: csat
                  },
                  id: question.id
                };
      case /* MultipleSelect */9 :
          var decodeSelections$1 = function (json) {
            return {
                    id: Json_decode.field("id", RandomId$ReactHooksTemplate.fromJson, json),
                    text: Json_decode.field("label", Json_decode.string, json),
                    allowNonSpecifiedText: false,
                    notSpecifiedText: undefined
                  };
          };
          var answer$3 = Json_decode.field("response", (function (param) {
                  return Json_decode.field("answer", (function (param) {
                                return Json_decode.list(decodeSelections$1, param);
                              }), param);
                }), param);
          var multipleSelect = List.fold_left(MultipleSelect$ReactHooksTemplate.setAnswer, opinionScale._0, answer$3);
          return {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* MultipleSelect */9,
                    _0: multipleSelect
                  },
                  id: question.id
                };
      case /* DropDown */10 :
          var decodeSelections$2 = function (json) {
            return {
                    id: Json_decode.field("id", RandomId$ReactHooksTemplate.fromJson, json),
                    text: Json_decode.field("label", Json_decode.string, json),
                    allowNonSpecifiedText: false,
                    notSpecifiedText: undefined
                  };
          };
          var answer$4 = Json_decode.field("response", (function (param) {
                  return Json_decode.field("answer", (function (param) {
                                return Json_decode.list(decodeSelections$2, param);
                              }), param);
                }), param);
          var dropDown = List.fold_left(DropDown$ReactHooksTemplate.setAnswer, opinionScale._0, answer$4);
          return {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* DropDown */10,
                    _0: dropDown
                  },
                  id: question.id
                };
      case /* MobileNumber */11 :
          var answer$5 = Json_decode.field("response", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), param);
          var mobileNumber = MobileNumber$ReactHooksTemplate.setAnswer(opinionScale._0, answer$5);
          return {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* MobileNumber */11,
                    _0: mobileNumber
                  },
                  id: question.id
                };
      
    }
  };
  var respondedQuestions = Json_decode.withDefault(/* [] */0, (function (param) {
          return Json_decode.field("responses", (function (param) {
                        return Json_decode.list(decodeResponses, param);
                      }), param);
        }), json);
  return List.map((function (q) {
                var y = List.find_opt((function (x) {
                        return Caml_obj.caml_equal(x.id, q.id);
                      }), respondedQuestions);
                if (y !== undefined) {
                  return y;
                } else {
                  return q;
                }
              }), questions);
}

exports.toEditorBlock = toEditorBlock;
exports.toForm = toForm;
exports.preview = preview;
exports.id = id;
exports.uuid = uuid;
exports.text = text;
exports.categoryType = categoryType;
exports.questionType = questionType;
exports.mandatory = mandatory;
exports.setQuestionType = setQuestionType;
exports.fromCategoryType = fromCategoryType;
exports.fromJson = fromJson;
exports.decode = decode;
exports.toJson = toJson;
exports.equals = equals;
exports.answered = answered;
/* react Not a pure module */
