// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var ReactHtmlParser = require("react-html-parser");
var ReactHtmlParser$1 = require("react-html-parser").default;
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Mode$ReactHooksTemplate = require("./mode.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Modal$ReactHooksTemplate = require("./Modal.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var SmsMode$ReactHooksTemplate = require("./SmsMode.bs.js");
var Campaign$ReactHooksTemplate = require("./campaign.bs.js");
var EmailMode$ReactHooksTemplate = require("./EmailMode.bs.js");
var FormField$ReactHooksTemplate = require("../common/FormField.bs.js");
var AppLinkMode$ReactHooksTemplate = require("./AppLinkMode.bs.js");
var SmsLinkMode$ReactHooksTemplate = require("./SmsLinkMode.bs.js");
var SmsSenderId$ReactHooksTemplate = require("./smsSenderId.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var SurveyDetail$ReactHooksTemplate = require("./surveyDetail.bs.js");
var TextInputNew$ReactHooksTemplate = require("../common/TextInputNew.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var EmailTemplate$ReactHooksTemplate = require("./emailTemplate.bs.js");
var WebsiteLinkMode$ReactHooksTemplate = require("./WebsiteLinkMode.bs.js");
var TextAreaInputNew$ReactHooksTemplate = require("../common/TextAreaInputNew.bs.js");
var WhatsappLinkMode$ReactHooksTemplate = require("./WhatsappLinkMode.bs.js");

function domIdFortemplateIdentifiers(x) {
  if (x) {
    return "";
  } else {
    return "emailMessageBody";
  }
}

function convertDomIdToTemplateIdentifier(id) {
  if (id === "emailMessageBody") {
    return /* EmailMessageBody */0;
  } else {
    return /* Unknown */1;
  }
}

function decodeNodeAttributes(json) {
  var id = Json_decode.optional((function (param) {
          return Json_decode.field("id", Json_decode.string, param);
        }), json);
  return convertDomIdToTemplateIdentifier(Belt_Option.getWithDefault(id, ""));
}

function decodeStatus(json) {
  return Json_decode.field("status", Json_decode.string, json).toLowerCase();
}

function decodeNameErrors(json) {
  return Json_decode.field("errors", (function (param) {
                return Json_decode.field("name", (function (param) {
                              return Json_decode.list(Json_decode.string, param);
                            }), param);
              }), json);
}

function decode(json) {
  var match = decodeStatus(json);
  switch (match) {
    case "failed" :
        return /* Failure */{
                _0: decodeNameErrors(json)
              };
    case "success" :
        return /* Success */0;
    default:
      return /* Failure */{
              _0: /* [] */0
            };
  }
}

var CampaignApiResponse = {
  decodeStatus: decodeStatus,
  decodeNameErrors: decodeNameErrors,
  decode: decode
};

var availableModes_0 = {
  id: undefined,
  mode: {
    TAG: /* Email */0,
    _0: EmailMode$ReactHooksTemplate.empty(undefined)
  }
};

var availableModes_1 = {
  hd: {
    id: undefined,
    mode: {
      TAG: /* Sms */1,
      _0: SmsMode$ReactHooksTemplate.empty(undefined)
    }
  },
  tl: {
    hd: {
      id: undefined,
      mode: {
        TAG: /* SmsLink */2,
        _0: SmsLinkMode$ReactHooksTemplate.empty(undefined)
      }
    },
    tl: {
      hd: {
        id: undefined,
        mode: {
          TAG: /* WhatsappLink */3,
          _0: WhatsappLinkMode$ReactHooksTemplate.empty(undefined)
        }
      },
      tl: {
        hd: {
          id: undefined,
          mode: {
            TAG: /* AppLink */4,
            _0: AppLinkMode$ReactHooksTemplate.empty(undefined)
          }
        },
        tl: {
          hd: {
            id: undefined,
            mode: {
              TAG: /* WebsiteLink */5,
              _0: WebsiteLinkMode$ReactHooksTemplate.empty(undefined)
            }
          },
          tl: {
            hd: {
              id: undefined,
              mode: /* AnonymousLink */0
            },
            tl: {
              hd: {
                id: undefined,
                mode: /* Telephonic */1
              },
              tl: /* [] */0
            }
          }
        }
      }
    }
  }
};

var availableModes = {
  hd: availableModes_0,
  tl: availableModes_1
};

var emptyForm_name = {
  TAG: /* Pristine */2,
  _0: Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, undefined)
};

var emptyForm_survey = {
  TAG: /* Pristine */2,
  _0: undefined
};

var emptyForm = {
  name: emptyForm_name,
  surveys: /* [] */0,
  smsSenderIds: /* [] */0,
  availableModes: availableModes,
  showAddMode: false,
  survey: emptyForm_survey,
  modes: /* [] */0
};

function generateForm(campaign) {
  return {
          name: {
            TAG: /* Valid */0,
            _0: Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, Campaign$ReactHooksTemplate.name(campaign))
          },
          surveys: /* [] */0,
          smsSenderIds: /* [] */0,
          availableModes: availableModes,
          showAddMode: false,
          survey: {
            TAG: /* Pristine */2,
            _0: undefined
          },
          modes: Belt_List.map(Campaign$ReactHooksTemplate.modes(campaign), (function (x) {
                  return {
                          TAG: /* Valid */0,
                          _0: Mode$ReactHooksTemplate.toForm(x)
                        };
                }))
        };
}

function reducer(state, action) {
  if (state.TAG === /* FetchSurveysInProgress */0) {
    var form = state._0;
    if (typeof action === "number") {
      return {
              TAG: /* FetchSurveysInProgress */0,
              _0: form
            };
    }
    if (action.TAG !== /* FetchSurveyCompleted */0) {
      return {
              TAG: /* FetchSurveysInProgress */0,
              _0: form
            };
    }
    var surveys = action._0;
    var survey = Belt_List.head(surveys);
    return {
            TAG: /* Available */1,
            _0: {
              name: form.name,
              surveys: surveys,
              smsSenderIds: form.smsSenderIds,
              availableModes: form.availableModes,
              showAddMode: form.showAddMode,
              survey: {
                TAG: /* Valid */0,
                _0: survey
              },
              modes: form.modes
            }
          };
  } else {
    var form$1 = state._0;
    if (typeof action === "number") {
      return {
              TAG: /* Available */1,
              _0: {
                name: form$1.name,
                surveys: form$1.surveys,
                smsSenderIds: form$1.smsSenderIds,
                availableModes: form$1.availableModes,
                showAddMode: !form$1.showAddMode,
                survey: form$1.survey,
                modes: form$1.modes
              }
            };
    }
    switch (action.TAG | 0) {
      case /* FetchSurveyCompleted */0 :
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: form$1.name,
                    surveys: action._0,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: form$1.showAddMode,
                    survey: form$1.survey,
                    modes: form$1.modes
                  }
                };
      case /* ChangeNameInput */1 :
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: action._0,
                    surveys: form$1.surveys,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: form$1.showAddMode,
                    survey: form$1.survey,
                    modes: form$1.modes
                  }
                };
      case /* EmailModeChanged */2 :
          var emailMode = action._0;
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: form$1.name,
                    surveys: form$1.surveys,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: form$1.showAddMode,
                    survey: form$1.survey,
                    modes: Belt_List.map(form$1.modes, (function (x) {
                            var form = FormField$ReactHooksTemplate.getInputValue(x);
                            var match = form.formMode;
                            if (typeof match === "number" || match.TAG !== /* Email */0) {
                              return x;
                            } else {
                              return {
                                      TAG: /* Valid */0,
                                      _0: {
                                        id: form.id,
                                        formMode: {
                                          TAG: /* Email */0,
                                          _0: emailMode
                                        }
                                      }
                                    };
                            }
                          }))
                  }
                };
      case /* SmsLinkModeChanged */3 :
          var smsLinkMode = action._0;
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: form$1.name,
                    surveys: form$1.surveys,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: form$1.showAddMode,
                    survey: form$1.survey,
                    modes: Belt_List.map(form$1.modes, (function (x) {
                            var form = FormField$ReactHooksTemplate.getInputValue(x);
                            var match = form.formMode;
                            if (typeof match === "number" || match.TAG !== /* SmsLink */2) {
                              return x;
                            } else {
                              return {
                                      TAG: /* Valid */0,
                                      _0: {
                                        id: form.id,
                                        formMode: {
                                          TAG: /* SmsLink */2,
                                          _0: smsLinkMode
                                        }
                                      }
                                    };
                            }
                          }))
                  }
                };
      case /* WhatsappLinkModeChanged */4 :
          var whatsappLinkMode = action._0;
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: form$1.name,
                    surveys: form$1.surveys,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: form$1.showAddMode,
                    survey: form$1.survey,
                    modes: Belt_List.map(form$1.modes, (function (x) {
                            var form = FormField$ReactHooksTemplate.getInputValue(x);
                            var match = form.formMode;
                            if (typeof match === "number" || match.TAG !== /* WhatsappLink */3) {
                              return x;
                            } else {
                              return {
                                      TAG: /* Valid */0,
                                      _0: {
                                        id: form.id,
                                        formMode: {
                                          TAG: /* WhatsappLink */3,
                                          _0: whatsappLinkMode
                                        }
                                      }
                                    };
                            }
                          }))
                  }
                };
      case /* AppLinkModeChanged */5 :
          var appLinkMode = action._0;
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: form$1.name,
                    surveys: form$1.surveys,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: form$1.showAddMode,
                    survey: form$1.survey,
                    modes: Belt_List.map(form$1.modes, (function (x) {
                            var form = FormField$ReactHooksTemplate.getInputValue(x);
                            var match = form.formMode;
                            if (typeof match === "number" || match.TAG !== /* AppLink */4) {
                              return x;
                            } else {
                              return {
                                      TAG: /* Valid */0,
                                      _0: {
                                        id: form.id,
                                        formMode: {
                                          TAG: /* AppLink */4,
                                          _0: appLinkMode
                                        }
                                      }
                                    };
                            }
                          }))
                  }
                };
      case /* WebsiteLinkModeChanged */6 :
          var websiteLinkMode = action._0;
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: form$1.name,
                    surveys: form$1.surveys,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: form$1.showAddMode,
                    survey: form$1.survey,
                    modes: Belt_List.map(form$1.modes, (function (x) {
                            var form = FormField$ReactHooksTemplate.getInputValue(x);
                            var match = form.formMode;
                            if (typeof match === "number" || match.TAG !== /* WebsiteLink */5) {
                              return x;
                            } else {
                              return {
                                      TAG: /* Valid */0,
                                      _0: {
                                        id: form.id,
                                        formMode: {
                                          TAG: /* WebsiteLink */5,
                                          _0: websiteLinkMode
                                        }
                                      }
                                    };
                            }
                          }))
                  }
                };
      case /* SmsModeChanged */7 :
          var smsMode = action._0;
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: form$1.name,
                    surveys: form$1.surveys,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: form$1.showAddMode,
                    survey: form$1.survey,
                    modes: Belt_List.map(form$1.modes, (function (x) {
                            var form = FormField$ReactHooksTemplate.getInputValue(x);
                            var match = form.formMode;
                            if (typeof match === "number" || match.TAG !== /* Sms */1) {
                              return x;
                            } else {
                              return {
                                      TAG: /* Valid */0,
                                      _0: {
                                        id: form.id,
                                        formMode: {
                                          TAG: /* Sms */1,
                                          _0: smsMode
                                        }
                                      }
                                    };
                            }
                          }))
                  }
                };
      case /* AnonymousLinkModeChanged */8 :
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: form$1.name,
                    surveys: form$1.surveys,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: form$1.showAddMode,
                    survey: form$1.survey,
                    modes: Belt_List.map(form$1.modes, (function (x) {
                            FormField$ReactHooksTemplate.getInputValue(x);
                            return x;
                          }))
                  }
                };
      case /* FailedApiResponse */9 :
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: {
                      TAG: /* Invalid */1,
                      _0: FormField$ReactHooksTemplate.getInputValue(form$1.name),
                      _1: action._0
                    },
                    surveys: form$1.surveys,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: form$1.showAddMode,
                    survey: form$1.survey,
                    modes: form$1.modes
                  }
                };
      case /* UpdateSurvey */10 :
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: form$1.name,
                    surveys: form$1.surveys,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: form$1.showAddMode,
                    survey: {
                      TAG: /* Valid */0,
                      _0: action._0
                    },
                    modes: form$1.modes
                  }
                };
      case /* AddMode */11 :
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: form$1.name,
                    surveys: form$1.surveys,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: false,
                    survey: form$1.survey,
                    modes: {
                      hd: action._0,
                      tl: form$1.modes
                    }
                  }
                };
      case /* RemoveMode */12 :
          var mode = action._0;
          var modes = Belt_List.keep(form$1.modes, (function (x) {
                  return Mode$ReactHooksTemplate.modeType(Mode$ReactHooksTemplate.fromForm(FormField$ReactHooksTemplate.getInputValue(x))) !== Mode$ReactHooksTemplate.modeType(Mode$ReactHooksTemplate.fromForm(mode));
                }));
          return {
                  TAG: /* Available */1,
                  _0: {
                    name: form$1.name,
                    surveys: form$1.surveys,
                    smsSenderIds: form$1.smsSenderIds,
                    availableModes: form$1.availableModes,
                    showAddMode: form$1.showAddMode,
                    survey: form$1.survey,
                    modes: modes
                  }
                };
      
    }
  }
}

function validationErrors(field) {
  return React.createElement("div", {
              className: "my-2"
            }, $$Array.of_list(Belt_List.mapWithIndex(FormField$ReactHooksTemplate.getValidationError(field), (function (key, error) {
                        return React.createElement("p", {
                                    key: String(key),
                                    className: "text-red-500 text-xs float-right"
                                  }, error);
                      }))));
}

function isValid(field) {
  switch (field.TAG | 0) {
    case /* Valid */0 :
        return true;
    case /* Invalid */1 :
    case /* Pristine */2 :
        return false;
    
  }
}

function isValidMode(form) {
  var mode = Belt_List.head(form.modes);
  if (mode === undefined) {
    return false;
  }
  switch (mode.TAG | 0) {
    case /* Valid */0 :
        return true;
    case /* Invalid */1 :
    case /* Pristine */2 :
        return false;
    
  }
}

function isValidSurvey(survey) {
  switch (survey.TAG | 0) {
    case /* Valid */0 :
        return true;
    case /* Invalid */1 :
    case /* Pristine */2 :
        return false;
    
  }
}

function isValidForm(form) {
  if (isValid(form.name) && isValidSurvey(form.survey)) {
    return isValidMode(form);
  } else {
    return false;
  }
}

function CampaignForm(Props) {
  var toggleCampaign = Props.toggleCampaign;
  var toggleModal = Props.toggleModal;
  var selectedCampaign = Props.selectedCampaign;
  var match = React.useReducer(reducer, selectedCampaign !== undefined ? ({
            TAG: /* FetchSurveysInProgress */0,
            _0: generateForm(Caml_option.valFromOption(selectedCampaign))
          }) : ({
            TAG: /* FetchSurveysInProgress */0,
            _0: emptyForm
          }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setSenderIds = match$1[1];
  var senderIds = match$1[0];
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setEmailTemplates = match$2[1];
  var emailTempaltes = match$2[0];
  var match$3 = React.useState(function () {
        return /* GeneralSettings */0;
      });
  var setSelected = match$3[1];
  var selected = match$3[0];
  var match$4 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var match$5 = React.useState(function () {
        return false;
      });
  var setShowPreview = match$5[1];
  var showPreview = match$5[0];
  var transform = function (emailBodyMessage, node, index) {
    var match = decodeNodeAttributes(node.attribs);
    if (match) {
      return ReactHtmlParser.convertNodeToElement(node, index, (function (param, param$1) {
                    return transform(emailBodyMessage, param, param$1);
                  }));
    } else {
      return React.createElement("p", {
                  id: "emailMessageBody",
                  style: {
                    lineHeight: "2"
                  }
                }, emailBodyMessage);
    }
  };
  var fetchSurveys = function (param) {
    return fetch("/api/v1/all_surveys").then(function (json) {
                  return json.json();
                }).then(function (json) {
                return Promise.resolve(Curry._1(dispatch, {
                                TAG: /* FetchSurveyCompleted */0,
                                _0: Json_decode.field("surveys", (function (param) {
                                        return Json_decode.list(SurveyDetail$ReactHooksTemplate.decode, param);
                                      }), json)
                              }));
              });
  };
  var fetchEmailTemplates = function (param) {
    return fetch("/api/v1/email_templates").then(function (json) {
                  return json.json();
                }).then(function (json) {
                return Promise.resolve(Curry._1(setEmailTemplates, (function (param) {
                                  return Json_decode.field("email_templates", (function (param) {
                                                return Json_decode.list(EmailTemplate$ReactHooksTemplate.decode, param);
                                              }), json);
                                })));
              });
  };
  var fetchSenderIds = function (param) {
    return fetch("/api/v1/sms_sender_ids").then(function (json) {
                  return json.json();
                }).then(function (json) {
                return Promise.resolve(Curry._1(setSenderIds, (function (param) {
                                  return Json_decode.field("sms_sender_ids", (function (param) {
                                                return Json_decode.list(SmsSenderId$ReactHooksTemplate.decode, param);
                                              }), json);
                                })));
              });
  };
  React.useEffect((function () {
          fetchSurveys(undefined).then(function (param) {
                return fetchSenderIds(undefined).then(function (prim) {
                            return Promise.resolve(prim);
                          });
              });
          fetchEmailTemplates(undefined);
          
        }), []);
  var handleNameInput = function ($$event) {
    var name = Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, Utils$ReactHooksTemplate.formTargetValue($$event));
    if (Curry._1(Domain$ReactHooksTemplate.$$String.Name.isEmpty, name)) {
      return Curry._1(dispatch, {
                  TAG: /* ChangeNameInput */1,
                  _0: {
                    TAG: /* Invalid */1,
                    _0: name,
                    _1: {
                      hd: "can't be empty",
                      tl: /* [] */0
                    }
                  }
                });
    } else {
      return Curry._1(dispatch, {
                  TAG: /* ChangeNameInput */1,
                  _0: {
                    TAG: /* Valid */0,
                    _0: name
                  }
                });
    }
  };
  var handleSmsLinkMessageChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(dispatch, {
                TAG: /* SmsLinkModeChanged */3,
                _0: SmsLinkMode$ReactHooksTemplate.toForm(SmsLinkMode$ReactHooksTemplate.setMessage(value))
              });
  };
  var handleWhatsappLinkMessageChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(dispatch, {
                TAG: /* WhatsappLinkModeChanged */4,
                _0: WhatsappLinkMode$ReactHooksTemplate.toForm(WhatsappLinkMode$ReactHooksTemplate.setMessage(value))
              });
  };
  var handleAppLinkMessageChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(dispatch, {
                TAG: /* AppLinkModeChanged */5,
                _0: AppLinkMode$ReactHooksTemplate.toForm(AppLinkMode$ReactHooksTemplate.setMessage(value))
              });
  };
  var handleWebsiteLinkMessageChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(dispatch, {
                TAG: /* WebsiteLinkModeChanged */6,
                _0: WebsiteLinkMode$ReactHooksTemplate.toForm(WebsiteLinkMode$ReactHooksTemplate.setMessage(value))
              });
  };
  var handleChangeModal = function (form, selectedCampaign$1) {
    if (selectedCampaign$1 !== undefined) {
      return function (param) {
        var selectedCampaign$2 = Belt_Option.getExn(selectedCampaign);
        var modes = Belt_List.map(Belt_List.map(form.modes, FormField$ReactHooksTemplate.getInputValue), Mode$ReactHooksTemplate.fromForm);
        var buildTargetDict = function (form) {
          var params = {};
          params["campaign"] = Json_encode.object_({
                hd: [
                  "name",
                  Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, FormField$ReactHooksTemplate.getInputValue(form.name))
                ],
                tl: {
                  hd: [
                    "survey_id",
                    SurveyDetail$ReactHooksTemplate.id(param)
                  ],
                  tl: {
                    hd: [
                      "campaign_modes_attributes",
                      Json_encode.list(Mode$ReactHooksTemplate.encoder, modes)
                    ],
                    tl: /* [] */0
                  }
                }
              });
          return params;
        };
        var campaignId = Campaign$ReactHooksTemplate.id(selectedCampaign$2);
        Http$ReactHooksTemplate.fetchPutJSON("/api/v1/campaigns/" + campaignId, buildTargetDict(form)).then(function (response) {
              Curry._1(toggleCampaign, "save");
              return Promise.resolve(response);
            });
        
      };
    } else {
      return function (param) {
        var modes = Belt_List.map(Belt_List.map(form.modes, FormField$ReactHooksTemplate.getInputValue), Mode$ReactHooksTemplate.fromForm);
        var campaignEncoder = Json_encode.object_({
              hd: [
                "name",
                Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, FormField$ReactHooksTemplate.getInputValue(form.name))
              ],
              tl: {
                hd: [
                  "survey_id",
                  SurveyDetail$ReactHooksTemplate.id(param)
                ],
                tl: {
                  hd: [
                    "campaign_modes_attributes",
                    Json_encode.list(Mode$ReactHooksTemplate.encoder, modes)
                  ],
                  tl: /* [] */0
                }
              }
            });
        Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/campaigns", Json_encode.object_({
                    hd: [
                      "campaign",
                      campaignEncoder
                    ],
                    tl: /* [] */0
                  })).then(function (response) {
              Curry._1(toggleCampaign, "save");
              return Promise.resolve(response);
            });
        
      };
    }
  };
  var findChecked = function (form, modeString) {
    return Belt_List.getBy(form.modes, (function (x) {
                  return modeString === Mode$ReactHooksTemplate.modeType(Mode$ReactHooksTemplate.fromForm(FormField$ReactHooksTemplate.getInputValue(x)));
                })) !== undefined;
  };
  var modeView = function (form, mode) {
    var selected = Belt_List.getBy(form.modes, (function (x) {
            return Mode$ReactHooksTemplate.modeType(mode) === Mode$ReactHooksTemplate.modeType(Mode$ReactHooksTemplate.fromForm(FormField$ReactHooksTemplate.getInputValue(x)));
          }));
    if (selected === undefined) {
      return React.createElement(React.Fragment, undefined);
    }
    var mode$1 = FormField$ReactHooksTemplate.getInputValue(selected);
    var emailMode = mode$1.formMode;
    if (typeof emailMode === "number") {
      return React.createElement(React.Fragment, undefined);
    }
    switch (emailMode.TAG | 0) {
      case /* Email */0 :
          var emailMode$1 = emailMode._0;
          var partial_arg = EmailMode$ReactHooksTemplate.fromForm(emailMode$1);
          var partial_arg$1 = EmailMode$ReactHooksTemplate.fromForm(emailMode$1);
          var emailTemplate = Belt_List.head(emailTempaltes);
          var tmp;
          if (emailTemplate !== undefined) {
            var partial_arg$2 = FormField$ReactHooksTemplate.getInputValue(emailMode$1.body);
            tmp = React.createElement("div", {
                  className: "inline-block w-full"
                }, ReactHtmlParser$1(EmailTemplate$ReactHooksTemplate.html_template(Caml_option.valFromOption(emailTemplate)), {
                      transform: (function (param, param$1) {
                          return transform(partial_arg$2, param, param$1);
                        })
                    }));
          } else {
            tmp = React.createElement(React.Fragment, undefined);
          }
          return React.createElement("div", {
                      className: "flex mb-8"
                    }, React.createElement("div", {
                          className: "p-6 bg-ghostwhite border border-gray-300 rounded-lg w-4/5"
                        }, React.createElement("div", {
                              className: "mb-8"
                            }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                                  label: "Subject",
                                  value: FormField$ReactHooksTemplate.getInputValue(emailMode$1.subject),
                                  handleChange: (function (param) {
                                      var value = Utils$ReactHooksTemplate.formTargetValue(param);
                                      return Curry._1(dispatch, {
                                                  TAG: /* EmailModeChanged */2,
                                                  _0: EmailMode$ReactHooksTemplate.toForm(EmailMode$ReactHooksTemplate.setSubject(value, partial_arg))
                                                });
                                    }),
                                  maxLength: 100
                                }), validationErrors(emailMode$1.subject)), React.createElement("div", undefined, React.createElement(TextAreaInputNew$ReactHooksTemplate.make, {
                                  label: "Body",
                                  value: FormField$ReactHooksTemplate.getInputValue(emailMode$1.body),
                                  handleChange: (function (param) {
                                      var value = Utils$ReactHooksTemplate.formTargetValue(param);
                                      return Curry._1(dispatch, {
                                                  TAG: /* EmailModeChanged */2,
                                                  _0: EmailMode$ReactHooksTemplate.toForm(EmailMode$ReactHooksTemplate.setBody(value, partial_arg$1))
                                                });
                                    })
                                }), validationErrors(emailMode$1.body)), React.createElement("button", {
                              className: "bg-pigmentblue border border-gray-400\n                            flex rounded p-2 text-sm",
                              type: "button",
                              onClick: (function (param) {
                                  return Curry._1(setShowPreview, (function (param) {
                                                return !showPreview;
                                              }));
                                })
                            }, React.createElement("div", {
                                  className: "flex items-center justify-between gap-1 text-white"
                                }, React.createElement("img", {
                                      src: Image$ReactHooksTemplate.preview
                                    }), React.createElement("p", undefined, "Preview")))), React.createElement(Modal$ReactHooksTemplate.make, {
                          show: showPreview,
                          children: React.createElement("div", {
                                className: "w-screen bg-white pt-6"
                              }, React.createElement("div", {
                                    className: "my-6 w-full px-5 py-5 email-template overflow-auto"
                                  }, tmp)),
                          handleClose: (function (param) {
                              return Curry._1(setShowPreview, (function (param) {
                                            return !showPreview;
                                          }));
                            })
                        }));
      case /* Sms */1 :
          var smsMode = emailMode._0;
          return React.createElement("div", {
                      className: "p-6 bg-ghostwhite border border-gray-300 rounded-lg mb-8\n                         flex justify-between items-center"
                    }, React.createElement("div", {
                          className: "text-sm font-medium mb-2"
                        }, "Sender ID"), React.createElement("select", {
                          className: "h-10 w-3/5 block border px-3 py-2 border-gray-400\n                           w-52 bg-white rounded capitalize text-sm",
                          placeholder: "Select Sender",
                          value: String(SmsMode$ReactHooksTemplate.senderId(SmsMode$ReactHooksTemplate.fromForm(smsMode))),
                          onChange: (function (param) {
                              var value = Caml_format.caml_int_of_string(Utils$ReactHooksTemplate.formTargetValue(param));
                              return Curry._1(dispatch, {
                                          TAG: /* SmsModeChanged */7,
                                          _0: SmsMode$ReactHooksTemplate.toForm(SmsMode$ReactHooksTemplate.setSenderId(value))
                                        });
                            })
                        }, React.createElement("option", {
                              key: "0",
                              value: "0"
                            }, "Select SenderId"), $$Array.of_list(Belt_List.mapWithIndex(senderIds, (function (idx, x) {
                                    return React.createElement("option", {
                                                key: String(idx),
                                                selected: x.id === SmsMode$ReactHooksTemplate.senderId(SmsMode$ReactHooksTemplate.fromForm(smsMode)),
                                                value: String(x.id)
                                              }, x.senderId);
                                  })))));
      case /* SmsLink */2 :
          var smsLinkMode = emailMode._0;
          return React.createElement("div", {
                      className: "p-6 bg-ghostwhite border border-gray-300 rounded-lg mb-8"
                    }, React.createElement(TextAreaInputNew$ReactHooksTemplate.make, {
                          label: "Message",
                          value: FormField$ReactHooksTemplate.getInputValue(smsLinkMode.message),
                          handleChange: handleSmsLinkMessageChange
                        }), validationErrors(smsLinkMode.message));
      case /* WhatsappLink */3 :
          var whatsappLinkMode = emailMode._0;
          return React.createElement("div", {
                      className: "p-6 bg-ghostwhite border border-gray-300 rounded-lg mb-8"
                    }, React.createElement(TextAreaInputNew$ReactHooksTemplate.make, {
                          label: "Message",
                          value: FormField$ReactHooksTemplate.getInputValue(whatsappLinkMode.message),
                          handleChange: handleWhatsappLinkMessageChange
                        }), validationErrors(whatsappLinkMode.message));
      case /* AppLink */4 :
          var appLinkMode = emailMode._0;
          return React.createElement("div", {
                      className: "p-6 bg-ghostwhite border border-gray-300 rounded-lg mb-8"
                    }, React.createElement(TextAreaInputNew$ReactHooksTemplate.make, {
                          label: "Message",
                          value: FormField$ReactHooksTemplate.getInputValue(appLinkMode.message),
                          handleChange: handleAppLinkMessageChange
                        }), validationErrors(appLinkMode.message));
      case /* WebsiteLink */5 :
          var websiteLinkMode = emailMode._0;
          return React.createElement("div", {
                      className: "p-6 bg-ghostwhite border border-gray-300 rounded-lg mb-8"
                    }, React.createElement(TextAreaInputNew$ReactHooksTemplate.make, {
                          label: "Message",
                          value: FormField$ReactHooksTemplate.getInputValue(websiteLinkMode.message),
                          handleChange: handleWebsiteLinkMessageChange
                        }), validationErrors(websiteLinkMode.message));
      
    }
  };
  if (state.TAG === /* FetchSurveysInProgress */0) {
    return React.createElement("p", undefined, "Loading...");
  }
  var form = state._0;
  var survey = form.survey;
  var selectedSurvey;
  switch (survey.TAG | 0) {
    case /* Valid */0 :
        var survey$1 = survey._0;
        selectedSurvey = survey$1 !== undefined ? String(SurveyDetail$ReactHooksTemplate.id(survey$1)) : "";
        break;
    case /* Invalid */1 :
    case /* Pristine */2 :
        selectedSurvey = "";
        break;
    
  }
  var tmp;
  if (Curry._1(match$4[0], /* CreateCampaign */15)) {
    var tmp$1;
    if (selected) {
      var tmp$2;
      if (selectedCampaign !== undefined) {
        var selectedModes = Campaign$ReactHooksTemplate.modes(Caml_option.valFromOption(selectedCampaign));
        tmp$2 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "flex flex-col overflow-y-scroll max-h-400-px"
                }, $$Array.of_list(Belt_List.map(selectedModes, (function (mode) {
                            return React.createElement("div", undefined, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                            checked: true,
                                            label: Mode$ReactHooksTemplate.modeType(mode),
                                            handleChange: (function (param) {
                                                
                                              }),
                                            className: "mb-8",
                                            checkboxClassName: "blue_checkbox",
                                            key: Mode$ReactHooksTemplate.modeType(mode)
                                          }), modeView(form, mode));
                          })))));
      } else {
        tmp$2 = React.createElement("div", undefined, React.createElement("div", {
                  className: "flex flex-col overflow-y-scroll max-h-400-px"
                }, $$Array.of_list(Belt_List.map(form.availableModes, (function (x) {
                            var partial_arg = Mode$ReactHooksTemplate.modeType(x);
                            return React.createElement("div", undefined, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                            checked: findChecked(form, Mode$ReactHooksTemplate.modeType(x)),
                                            label: Mode$ReactHooksTemplate.modeType(x),
                                            handleChange: (function (param) {
                                                var selected = Belt_List.getBy(form.modes, (function (x) {
                                                        return partial_arg === Mode$ReactHooksTemplate.modeType(Mode$ReactHooksTemplate.fromForm(FormField$ReactHooksTemplate.getInputValue(x)));
                                                      }));
                                                if (selected === undefined) {
                                                  var mode;
                                                  switch (partial_arg) {
                                                    case "AnonymousLink" :
                                                        mode = {
                                                          TAG: /* Valid */0,
                                                          _0: {
                                                            id: undefined,
                                                            formMode: /* AnonymousLink */0
                                                          }
                                                        };
                                                        break;
                                                    case "AppLink" :
                                                        mode = {
                                                          TAG: /* Pristine */2,
                                                          _0: {
                                                            id: undefined,
                                                            formMode: {
                                                              TAG: /* AppLink */4,
                                                              _0: AppLinkMode$ReactHooksTemplate.toForm(AppLinkMode$ReactHooksTemplate.empty(undefined))
                                                            }
                                                          }
                                                        };
                                                        break;
                                                    case "Email" :
                                                        var emailTemplate = Belt_List.head(emailTempaltes);
                                                        mode = {
                                                          TAG: /* Pristine */2,
                                                          _0: {
                                                            id: undefined,
                                                            formMode: emailTemplate !== undefined ? ({
                                                                  TAG: /* Email */0,
                                                                  _0: EmailMode$ReactHooksTemplate.toForm(EmailMode$ReactHooksTemplate.setEmailTemplateId(EmailTemplate$ReactHooksTemplate.id(Caml_option.valFromOption(emailTemplate)), EmailMode$ReactHooksTemplate.empty(undefined)))
                                                                }) : ({
                                                                  TAG: /* Email */0,
                                                                  _0: EmailMode$ReactHooksTemplate.toForm(EmailMode$ReactHooksTemplate.empty(undefined))
                                                                })
                                                          }
                                                        };
                                                        break;
                                                    case "Sms" :
                                                        mode = {
                                                          TAG: /* Pristine */2,
                                                          _0: {
                                                            id: undefined,
                                                            formMode: {
                                                              TAG: /* Sms */1,
                                                              _0: SmsMode$ReactHooksTemplate.toForm(SmsMode$ReactHooksTemplate.empty(undefined))
                                                            }
                                                          }
                                                        };
                                                        break;
                                                    case "SmsLink" :
                                                        mode = {
                                                          TAG: /* Pristine */2,
                                                          _0: {
                                                            id: undefined,
                                                            formMode: {
                                                              TAG: /* SmsLink */2,
                                                              _0: SmsLinkMode$ReactHooksTemplate.toForm(SmsLinkMode$ReactHooksTemplate.empty(undefined))
                                                            }
                                                          }
                                                        };
                                                        break;
                                                    case "Telephonic" :
                                                        mode = {
                                                          TAG: /* Valid */0,
                                                          _0: {
                                                            id: undefined,
                                                            formMode: /* Telephonic */1
                                                          }
                                                        };
                                                        break;
                                                    case "WebsiteLink" :
                                                        mode = {
                                                          TAG: /* Pristine */2,
                                                          _0: {
                                                            id: undefined,
                                                            formMode: {
                                                              TAG: /* WebsiteLink */5,
                                                              _0: WebsiteLinkMode$ReactHooksTemplate.toForm(WebsiteLinkMode$ReactHooksTemplate.empty(undefined))
                                                            }
                                                          }
                                                        };
                                                        break;
                                                    case "WhatsappLink" :
                                                        mode = {
                                                          TAG: /* Pristine */2,
                                                          _0: {
                                                            id: undefined,
                                                            formMode: {
                                                              TAG: /* WhatsappLink */3,
                                                              _0: WhatsappLinkMode$ReactHooksTemplate.toForm(WhatsappLinkMode$ReactHooksTemplate.empty(undefined))
                                                            }
                                                          }
                                                        };
                                                        break;
                                                    default:
                                                      mode = {
                                                        TAG: /* Pristine */2,
                                                        _0: {
                                                          id: undefined,
                                                          formMode: {
                                                            TAG: /* Email */0,
                                                            _0: EmailMode$ReactHooksTemplate.toForm(EmailMode$ReactHooksTemplate.empty(undefined))
                                                          }
                                                        }
                                                      };
                                                  }
                                                  return Curry._1(dispatch, {
                                                              TAG: /* AddMode */11,
                                                              _0: mode
                                                            });
                                                }
                                                var mode$1 = FormField$ReactHooksTemplate.getInputValue(selected);
                                                return Curry._1(dispatch, {
                                                            TAG: /* RemoveMode */12,
                                                            _0: mode$1
                                                          });
                                              }),
                                            className: "mb-8",
                                            checkboxClassName: "blue_checkbox",
                                            key: Mode$ReactHooksTemplate.modeType(x)
                                          }), modeView(form, x));
                          })))));
      }
      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", undefined, tmp$2));
    } else {
      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                className: "mb-6"
              }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                    label: "Campaign Name",
                    value: Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, FormField$ReactHooksTemplate.getInputValue(form.name)),
                    handleChange: handleNameInput,
                    maxLength: 100
                  }), validationErrors(form.name)), React.createElement("div", {
                className: "mb-6"
              }, React.createElement("div", {
                    className: "flex justify-between items-center"
                  }, React.createElement("div", {
                        className: "text-sm font-medium"
                      }, "Select Survey"), selectedCampaign !== undefined ? React.createElement("input", {
                          className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                          disabled: true,
                          type: "text",
                          value: Campaign$ReactHooksTemplate.surveyName(Caml_option.valFromOption(selectedCampaign))
                        }) : React.createElement("select", {
                          className: "h-10 w-3/5 block border px-3 py-2 border-gray-400\n                                w-52 bg-white rounded capitalize text-sm",
                          value: selectedSurvey,
                          onChange: (function (param) {
                              var value = Utils$ReactHooksTemplate.formTargetValue(param);
                              var selectedSurvey = List.find((function (x) {
                                      return String(SurveyDetail$ReactHooksTemplate.id(x)) === value;
                                    }), form.surveys);
                              return Curry._1(dispatch, {
                                          TAG: /* UpdateSurvey */10,
                                          _0: selectedSurvey
                                        });
                            })
                        }, $$Array.of_list(Belt_List.mapWithIndex(form.surveys, (function (idx, x) {
                                    return React.createElement("option", {
                                                key: String(idx),
                                                value: String(x.id)
                                              }, x.name);
                                  })))))));
    }
    tmp = React.createElement("div", {
          className: "h-432-px"
        }, React.createElement("div", {
              className: "p-8 text-lg font-bold flex justify-between"
            }, React.createElement("div", undefined, selectedCampaign !== undefined ? React.createElement("div", undefined, "Edit Campaign") : React.createElement("div", undefined, "Create New Campaign")), React.createElement("img", {
                  className: "h-14-px float-right",
                  src: Image$ReactHooksTemplate.closeIcon,
                  onClick: toggleModal
                })), React.createElement("form", {
              className: "h-432-px",
              onSubmit: (function (param) {
                  param.preventDefault();
                  return Belt_Option.mapWithDefault(FormField$ReactHooksTemplate.getInputValue(form.survey), undefined, handleChangeModal(form, selectedCampaign));
                })
            }, React.createElement("div", {
                  className: "flex h-432-px z-20 relative"
                }, React.createElement("div", {
                      className: "w-3/10 flex flex-col border-r border-gray-300\n                                text-darkcharcoal text-base"
                    }, React.createElement("div", {
                          className: "px-6 py-5 " + (
                            selected === /* GeneralSettings */0 ? "bg-lavenderweb" : ""
                          ),
                          onClick: (function (param) {
                              return Curry._1(setSelected, (function (param) {
                                            return /* GeneralSettings */0;
                                          }));
                            })
                        }, "General Settings"), React.createElement("div", {
                          className: "px-6 py-5 " + (
                            selected === /* CampaignModes */1 ? "bg-lavenderweb" : ""
                          ),
                          onClick: (function (param) {
                              return Curry._1(setSelected, (function (param) {
                                            return /* CampaignModes */1;
                                          }));
                            })
                        }, "Campaign Modes")), React.createElement("div", {
                      className: "w-7/10 flex flex-col p-8"
                    }, tmp$1)), React.createElement("div", {
                  className: "p-8 flex border-t border-gray-200 justify-end gap-3 font-medium\n                 absolute bottom-0 text-base w-full z-30 relative" + (
                    showPreview ? " hidden" : ""
                  )
                }, React.createElement("button", {
                      className: "bg-ghostwhite border border-gray-400`\n                   flex rounded py-3 px-6 text-xs",
                      id: "close_modal",
                      onClick: toggleModal
                    }, "Discard"), React.createElement("input", {
                      className: "bg-palatinateblue border border-gray-400\n                   flex rounded py-3 px-6 text-xs text-white",
                      disabled: !isValidForm(form),
                      type: "submit",
                      value: "Save"
                    }))));
  } else {
    tmp = React.createElement("div", {
          className: "h-screen w-full animated fadeIn"
        }, React.createElement(Loading$ReactHooksTemplate.make, {
              img: Image$ReactHooksTemplate.unAuthorized,
              text: "401: Unauthorized!!",
              loading: false
            }));
  }
  return React.createElement("div", undefined, tmp);
}

var make = CampaignForm;

exports.domIdFortemplateIdentifiers = domIdFortemplateIdentifiers;
exports.convertDomIdToTemplateIdentifier = convertDomIdToTemplateIdentifier;
exports.decodeNodeAttributes = decodeNodeAttributes;
exports.CampaignApiResponse = CampaignApiResponse;
exports.availableModes = availableModes;
exports.emptyForm = emptyForm;
exports.generateForm = generateForm;
exports.reducer = reducer;
exports.validationErrors = validationErrors;
exports.isValid = isValid;
exports.isValidMode = isValidMode;
exports.isValidSurvey = isValidSurvey;
exports.isValidForm = isValidForm;
exports.make = make;
/* availableModes Not a pure module */
