// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function decodeChoice(json) {
  return [
          Json_decode.field("id", Json_decode.string, json),
          Json_decode.field("choice", Json_decode.string, json)
        ];
}

function decode(json) {
  return {
          text: Json_decode.field("text", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          choices: Json_decode.field("choices", (function (param) {
                  return Json_decode.list(decodeChoice, param);
                }), json)
        };
}

function $eq(t, x) {
  var y = t.answer;
  if (y !== undefined) {
    return x === y;
  } else {
    return false;
  }
}

function empty(param) {
  return {
          id: undefined,
          uuid: RandomId$ReactHooksTemplate.generate(undefined),
          text: "",
          description: "",
          choices: {
            hd: [
              RandomId$ReactHooksTemplate.toString(RandomId$ReactHooksTemplate.generate(undefined)),
              ""
            ],
            tl: {
              hd: [
                RandomId$ReactHooksTemplate.toString(RandomId$ReactHooksTemplate.generate(undefined)),
                ""
              ],
              tl: /* [] */0
            }
          },
          translations: /* [] */0,
          answer: undefined
        };
}

function make(text, description, choices, uuid) {
  return {
          id: undefined,
          uuid: uuid,
          text: text,
          description: description,
          choices: choices,
          translations: /* [] */0,
          answer: undefined
        };
}

function answerToString(t) {
  var answer = t.answer;
  if (answer !== undefined) {
    return answer;
  } else {
    return "";
  }
}

function text(t) {
  return t.text;
}

function description(t) {
  return t.description;
}

function choices(t) {
  return t.choices;
}

function setText(t, text) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: text,
          description: t.description,
          choices: t.choices,
          translations: t.translations,
          answer: t.answer
        };
}

function setDescription(t, description) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: description,
          choices: t.choices,
          translations: t.translations,
          answer: t.answer
        };
}

function setChoices(t, choices) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          choices: choices,
          translations: t.translations,
          answer: t.answer
        };
}

function nonEmptyChoices(t) {
  return Belt_List.keep(t.choices, (function (param) {
                return param[1] !== "";
              }));
}

function removeChoice(choiceId, choices, t) {
  var newSetOfChoices = Belt_List.keep(choices, (function (param) {
          return param[0] !== choiceId;
        }));
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          choices: newSetOfChoices,
          translations: t.translations,
          answer: t.answer
        };
}

function setAnswer(t, answer) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          choices: t.choices,
          translations: t.translations,
          answer: answer
        };
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].text;
  } else {
    return t.text;
  }
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].description;
  } else {
    return t.description;
  }
}

function getChoicesTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    var t$1 = translation[1];
    return Belt_List.keep(t$1.choices, (function (param) {
                  return param[1] !== "";
                }));
  } else {
    return nonEmptyChoices(t);
  }
}

function answer(t) {
  return t.answer;
}

function answered(t) {
  return t.answer !== undefined;
}

function id(t) {
  return t.id;
}

function uuid(t) {
  return t.uuid;
}

function encodeChoice(param) {
  return {
          hd: [
            "id",
            param[0]
          ],
          tl: {
            hd: [
              "choice",
              param[1]
            ],
            tl: /* [] */0
          }
        };
}

function encodeChoices(choices) {
  return Json_encode.list(Json_encode.object_, List.map(encodeChoice, choices));
}

function toJson(withAnswerOpt, t) {
  var withAnswer = withAnswerOpt !== undefined ? withAnswerOpt : false;
  var encodeArray_0 = [
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t.id)
  ];
  var encodeArray_1 = {
    hd: [
      "text",
      t.text
    ],
    tl: {
      hd: [
        "description",
        t.description
      ],
      tl: {
        hd: [
          "choices",
          encodeChoices(nonEmptyChoices(t))
        ],
        tl: /* [] */0
      }
    }
  };
  var encodeArray = {
    hd: encodeArray_0,
    tl: encodeArray_1
  };
  return Json_encode.object_(withAnswer ? ({
                  hd: [
                    "answer",
                    Belt_Option.getWithDefault(t.answer, "")
                  ],
                  tl: encodeArray
                }) : encodeArray);
}

function decodeChoice$1(json) {
  return [
          Json_decode.field("id", Json_decode.string, json),
          Json_decode.field("choice", Json_decode.string, json)
        ];
}

function decodeTranslation(json) {
  return [
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  return {
          id: Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          uuid: Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          text: Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          description: Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          choices: Json_decode.field("category", (function (param) {
                  return Json_decode.field("choices", (function (param) {
                                return Json_decode.list(decodeChoice$1, param);
                              }), param);
                }), json),
          translations: Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          answer: undefined
        };
}

var categoryType = "MultipleChoice";

exports.categoryType = categoryType;
exports.empty = empty;
exports.text = text;
exports.description = description;
exports.choices = choices;
exports.make = make;
exports.id = id;
exports.uuid = uuid;
exports.answer = answer;
exports.answered = answered;
exports.setText = setText;
exports.getTextTranslation = getTextTranslation;
exports.setDescription = setDescription;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.setAnswer = setAnswer;
exports.setChoices = setChoices;
exports.removeChoice = removeChoice;
exports.getChoicesTranslation = getChoicesTranslation;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.$eq = $eq;
exports.answerToString = answerToString;
exports.nonEmptyChoices = nonEmptyChoices;
/* RandomId-ReactHooksTemplate Not a pure module */
