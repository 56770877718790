// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Logic$ReactHooksTemplate = require("./Logic.bs.js");
var ActionForm$ReactHooksTemplate = require("./ActionForm.bs.js");
var ConditionForm$ReactHooksTemplate = require("./ConditionForm.bs.js");

function LogicForm(Props) {
  var survey = Props.survey;
  var logic = Props.logic;
  var updateLogic = Props.updateLogic;
  var questions = Logic$ReactHooksTemplate.availableOptionsForLhs(logic, survey.questions);
  return React.createElement("div", {
              className: "h-200-px bg-white"
            }, $$Array.of_list(List.mapi((function (index, x) {
                        return React.createElement("div", {
                                    className: "flex flex-row"
                                  }, React.createElement(ConditionForm$ReactHooksTemplate.make, {
                                        questions: questions,
                                        condition: x,
                                        updateLogicCondition: (function (param) {
                                            return Curry._1(updateLogic, Logic$ReactHooksTemplate.setConditions(Belt_List.mapWithIndex(logic.conditions, (function (i, x) {
                                                                  if (i === index) {
                                                                    return param;
                                                                  } else {
                                                                    return x;
                                                                  }
                                                                })), logic));
                                          })
                                      }), React.createElement("div", {
                                        className: "pl-4 pt-10 cursor-pointer"
                                      }, React.createElement("img", {
                                            className: "h-20-px w-20-px",
                                            src: Image$ReactHooksTemplate.plusCircle
                                          })), React.createElement("div", {
                                        className: "pl-4 pt-10 cursor-pointer"
                                      }, React.createElement("img", {
                                            className: "h-20-px w-20-px",
                                            src: Image$ReactHooksTemplate.trashGrey
                                          })));
                      }), logic.conditions)), $$Array.of_list(List.mapi((function (index, x) {
                        return React.createElement(ActionForm$ReactHooksTemplate.make, {
                                    action: x,
                                    updateLogicAction: (function (param) {
                                        return Curry._1(updateLogic, Logic$ReactHooksTemplate.setActions(Belt_List.mapWithIndex(logic.actions, (function (i, x) {
                                                              if (i === index) {
                                                                return param;
                                                              } else {
                                                                return x;
                                                              }
                                                            })), logic));
                                      })
                                  });
                      }), logic.actions)));
}

var make = LogicForm;

exports.make = make;
/* react Not a pure module */
