// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");

function Tickets(Props) {
  var content = Props.content;
  var match = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var hasPermission = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$1 = url.path;
  var selected;
  if (match$1 && match$1.hd === "tickets") {
    var match$2 = match$1.tl;
    if (match$2) {
      switch (match$2.hd) {
        case "agent_summary" :
            selected = match$2.tl ? "" : "agent_summary";
            break;
        case "downloadList" :
            selected = match$2.tl ? "" : "downloads";
            break;
        case "manage" :
            selected = match$2.tl ? "" : "overview";
            break;
        case "work" :
            selected = match$2.tl ? "" : "work_on_tickets";
            break;
        default:
          selected = "";
      }
    } else {
      selected = "";
    }
  } else {
    selected = "";
  }
  return React.createElement("div", {
              className: "flex"
            }, React.createElement("div", {
                  className: "min-h-screen w-16 bg-ghostwhite border-r border-gray-300"
                }, Curry._1(hasPermission, /* ManageTickets */1) ? React.createElement("a", {
                        href: "/tickets/manage/all"
                      }, React.createElement("div", {
                            className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                              selected === "overview" ? "bg-palatinateblue border-palatinateblue" : ""
                            )
                          }, React.createElement("img", {
                                className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                                  selected === "overview" ? "filter-white" : ""
                                ),
                                title: "Overview",
                                src: Image$ReactHooksTemplate.sidebarOverview
                              }))) : React.createElement(React.Fragment, undefined), React.createElement("a", {
                      href: "/tickets/work/all"
                    }, React.createElement("div", {
                          className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                            selected === "work_on_tickets" ? "bg-palatinateblue border-palatinateblue" : ""
                          )
                        }, React.createElement("img", {
                              className: "m-auto inline-block align-middle max-h-full\n              max-w-full w-auto h-auto inset-0 absolute " + (
                                selected === "work_on_tickets" ? "filter-white" : ""
                              ),
                              title: "Work on Tickets",
                              src: Image$ReactHooksTemplate.workOnTickets
                            }))), Curry._1(hasPermission, /* ManageTickets */1) ? React.createElement("a", {
                        href: "/tickets/agent_summary"
                      }, React.createElement("div", {
                            className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                              selected === "agent_summary" ? "bg-palatinateblue border-palatinateblue" : ""
                            )
                          }, React.createElement("img", {
                                className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                                  selected === "agent_summary" ? "filter-white" : ""
                                ),
                                title: "Agent Summary",
                                src: Image$ReactHooksTemplate.agentSummary
                              }))) : React.createElement(React.Fragment, undefined), Curry._1(hasPermission, /* ManageTickets */1) ? React.createElement("a", {
                        href: "/tickets/downloadList"
                      }, React.createElement("div", {
                            className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                              selected === "downloadsList" ? "bg-palatinateblue border-palatinateblue" : ""
                            )
                          }, React.createElement("img", {
                                className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                                  selected === "downloadsList" ? "filter-white" : ""
                                ),
                                title: "Download List",
                                src: Image$ReactHooksTemplate.downloadBox
                              }))) : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                  className: "w-screen"
                }, content));
}

var make = Tickets;

exports.make = make;
/* react Not a pure module */
