// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Filter$ReactHooksTemplate = require("../filters/filter.bs.js");
var AxisData$ReactHooksTemplate = require("./axisData.bs.js");
var ChartComponentColor$ReactHooksTemplate = require("./chartComponentColor.bs.js");

function data(t) {
  return t.data;
}

function layout(t) {
  var match = t.layout;
  if (match === "vertical") {
    return "vertical";
  } else {
    return "horizontal";
  }
}

function xAxis(t) {
  return t.xAxis;
}

function yAxis(t) {
  return t.yAxis;
}

function yAxisList(t) {
  return t.yAxisList;
}

function colors(t) {
  return t.colors;
}

function onClick(t) {
  return t.onClick;
}

function width(t) {
  return t.width;
}

function height(t) {
  return t.height;
}

var decodeFilter = Filter$ReactHooksTemplate.decodeFilterItem;

function decodeOnClick(json) {
  var onClick = Json_decode.field("action", Json_decode.string, json);
  if (onClick === "jump_to") {
    return {
            TAG: /* GotoDashboard */1,
            _0: Json_decode.field("dashboard_name", Json_decode.string, json)
          };
  } else if (onClick === "filter") {
    return {
            TAG: /* DrillDown */0,
            _0: Json_decode.field("filter", decodeFilter, json)
          };
  } else {
    return /* NoAction */0;
  }
}

function fromJson(json) {
  var data = Belt_Option.getWithDefault(Js_json.decodeObject(json), {});
  return {
          data: Js_dict.get(data, "data"),
          layout: Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("layout", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          xAxis: Json_decode.field("x_axis", AxisData$ReactHooksTemplate.decode, json),
          yAxisList: Json_decode.withDefault(/* [] */0, (function (param) {
                  return Json_decode.field("y_axis_list", (function (param) {
                                return Json_decode.list(AxisData$ReactHooksTemplate.decode, param);
                              }), param);
                }), json),
          yAxis: Json_decode.field("y_axis", (function (param) {
                  return Json_decode.list(AxisData$ReactHooksTemplate.decode, param);
                }), json),
          onClick: Json_decode.withDefault(/* NoAction */0, (function (param) {
                  return Json_decode.field("on_click", decodeOnClick, param);
                }), json),
          colors: ChartComponentColor$ReactHooksTemplate.make(Json_decode.optional((function (param) {
                      return Json_decode.field("colors", (function (param) {
                                    return Json_decode.list(Json_decode.string, param);
                                  }), param);
                    }), json)),
          width: Json_decode.optional((function (param) {
                  return Json_decode.field("width", Json_decode.string, param);
                }), json),
          height: Json_decode.optional((function (param) {
                  return Json_decode.field("height", Json_decode.string, param);
                }), json)
        };
}

exports.data = data;
exports.layout = layout;
exports.xAxis = xAxis;
exports.yAxis = yAxis;
exports.yAxisList = yAxisList;
exports.colors = colors;
exports.width = width;
exports.height = height;
exports.fromJson = fromJson;
exports.onClick = onClick;
/* Filter-ReactHooksTemplate Not a pure module */
