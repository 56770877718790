// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var MapAttribute$ReactHooksTemplate = require("./mapAttribute.bs.js");
var TableAttribute$ReactHooksTemplate = require("./tableAttribute.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");
var ScoreCardChartAttribute$ReactHooksTemplate = require("./scoreCardChartAttribute.bs.js");

function id(t) {
  return t.id;
}

function title(t) {
  return t.title;
}

function type_(t) {
  return t.type_;
}

function decodeItem(json) {
  var category = Json_decode.either((function (param) {
            return Json_decode.field("chart_type", Json_decode.string, param);
          }), (function (param) {
            return Json_decode.field("visualization_type", Json_decode.string, param);
          }))(json);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          type_: category === "radial_bar" ? ({
                TAG: /* RadialBarChart */4,
                _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
              }) : (
              category === "map" ? ({
                    TAG: /* Map */8,
                    _0: MapAttribute$ReactHooksTemplate.fromJson(json)
                  }) : (
                  category === "table" ? ({
                        TAG: /* Table */9,
                        _0: TableAttribute$ReactHooksTemplate.fromJson(json)
                      }) : (
                      category === "line" ? ({
                            TAG: /* LineChart */0,
                            _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
                          }) : (
                          category === "pie" ? ({
                                TAG: /* PieChart */2,
                                _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
                              }) : (
                              category === "area" ? ({
                                    TAG: /* AreaChart */1,
                                    _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
                                  }) : (
                                  category === "bar" ? ({
                                        TAG: /* BarChart */3,
                                        _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
                                      }) : (
                                      category === "wordcloud" ? ({
                                            TAG: /* Wordcloud */5,
                                            _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
                                          }) : (
                                          category === "score_card" ? ({
                                                TAG: /* ScoreCard */7,
                                                _0: ScoreCardChartAttribute$ReactHooksTemplate.fromJson(json)
                                              }) : (
                                              category === "composed" ? ({
                                                    TAG: /* ComposedChart */6,
                                                    _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
                                                  }) : /* EmptyChart */0
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
          title: Json_decode.field("title", Json_decode.string, json)
        };
}

function fromJson(json) {
  return Json_decode.field("visualization", decodeItem, json);
}

exports.fromJson = fromJson;
exports.title = title;
exports.type_ = type_;
exports.id = id;
/* GenericChartAttribute-ReactHooksTemplate Not a pure module */
