// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var QuestionText$ReactHooksTemplate = require("./QuestionText.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var MultipleChoiceDefaultForm$ReactHooksTemplate = require("./MultipleChoiceDefaultForm.bs.js");

function MultipleChoiceForm(Props) {
  var multipleChoice = Props.multipleChoice;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var client = Props.client;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var match = Organization$ReactHooksTemplate.name(client);
  switch (match) {
    case "bhima" :
    case "ncell" :
    case "nykaa" :
        break;
    default:
      return React.createElement(MultipleChoiceDefaultForm$ReactHooksTemplate.make, {
                  multipleChoice: multipleChoice,
                  language: language,
                  mandatory: mandatory,
                  onChange: onChange
                });
  }
  return React.createElement("div", {
              className: "flex flex-col"
            }, React.createElement(QuestionText$ReactHooksTemplate.make, {
                  text: MultipleChoice$ReactHooksTemplate.getTextTranslation(multipleChoice, SurveyLanguage$ReactHooksTemplate.name(language)),
                  description: MultipleChoice$ReactHooksTemplate.getDescriptionTranslation(multipleChoice, SurveyLanguage$ReactHooksTemplate.name(language)),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme
                }), React.createElement("div", {
                  className: "flex flex-col"
                }, React.createElement("label", {
                      className: "block mb-10-px"
                    }, $$Array.of_list(Belt_List.mapWithIndex(MultipleChoice$ReactHooksTemplate.getChoicesTranslation(multipleChoice, SurveyLanguage$ReactHooksTemplate.name(language)), (function (param, param$1) {
                                var id = param$1[0];
                                return React.createElement("div", {
                                            key: id,
                                            className: "pb-2"
                                          }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                                checked: MultipleChoice$ReactHooksTemplate.$eq(multipleChoice, id),
                                                label: param$1[1],
                                                handleChange: (function (param) {
                                                    return Curry._1(onChange, MultipleChoice$ReactHooksTemplate.setAnswer(multipleChoice, id));
                                                  }),
                                                className: " p-2 border border-white-300 rounded-full\n                    flex w-3/4 small:w-full mb-2 "
                                              }));
                              }))))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = MultipleChoiceForm;

exports.make = make;
/* react Not a pure module */
