// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var Moment = require("moment");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Color$ReactHooksTemplate = require("./color.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");

var empty_email = Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, undefined);

var empty = {
  id: 0,
  email: empty_email
};

function isEmpty(t) {
  return Caml_obj.caml_equal(t, empty);
}

function id(t) {
  return t.id;
}

function email(t) {
  return t.email;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          email: Json_decode.field("email", Domain$ReactHooksTemplate.$$String.Email.decode, json)
        };
}

var Assignee = {
  empty: empty,
  isEmpty: isEmpty,
  id: id,
  email: email,
  decode: decode
};

function empty$1(param) {
  return {
          header: [],
          contents: []
        };
}

function header(t) {
  return t.header;
}

function contents(t) {
  return t.contents;
}

function decodeResponseDetails(header, json) {
  return List.map((function (name) {
                return Json_decode.optional((function (param) {
                              return Json_decode.field(name, Json_decode.string, param);
                            }), json);
              }), header);
}

function decode$1(json) {
  var header = Json_decode.field("header", (function (param) {
          return Json_decode.array(Json_decode.string, param);
        }), json);
  return {
          header: header,
          contents: Json_decode.field("responses", (function (param) {
                  return Json_decode.array((function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json)
        };
}

var NormalResponses = {
  empty: empty$1,
  header: header,
  contents: contents,
  decodeResponseDetails: decodeResponseDetails,
  decode: decode$1
};

function name(t) {
  return t.name;
}

function decode$2(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          color: Json_decode.field("color", Color$ReactHooksTemplate.decode, json)
        };
}

var Priority = {
  name: name,
  decode: decode$2
};

function toString(t) {
  switch (t) {
    case /* Open */0 :
        return "Open";
    case /* Parked */1 :
        return "Parked";
    case /* Closed */2 :
        return "Closed";
    case /* Resolved */3 :
        return "Resolved";
    
  }
}

function fromString(x) {
  var match = x.toLowerCase();
  switch (match) {
    case "closed" :
        return /* Closed */2;
    case "open" :
        return /* Open */0;
    case "parked" :
        return /* Parked */1;
    case "resolved" :
        return /* Resolved */3;
    default:
      return /* Open */0;
  }
}

function next(x) {
  if (x !== 1) {
    if (x !== 0) {
      return /* [] */0;
    } else {
      return {
              hd: /* Parked */1,
              tl: {
                hd: /* Closed */2,
                tl: {
                  hd: /* Resolved */3,
                  tl: /* [] */0
                }
              }
            };
    }
  } else {
    return {
            hd: /* Open */0,
            tl: /* [] */0
          };
  }
}

function decode$3(json) {
  return fromString(Json_decode.string(json));
}

var Status = {
  toString: toString,
  fromString: fromString,
  next: next,
  decode: decode$3
};

function id$1(t) {
  return t.id;
}

function msisdn(t) {
  return t.msisdn;
}

function email$1(t) {
  return t.email;
}

function firstName(t) {
  return t.firstName;
}

function lastName(t) {
  return t.lastName;
}

function name$1(t) {
  return Curry._1(Domain$ReactHooksTemplate.$$String.FirstName.show, t.firstName) + (" " + Curry._1(Domain$ReactHooksTemplate.$$String.LastName.show, t.lastName));
}

function assignee(t) {
  return t.assignee;
}

function priority(t) {
  return t.priority;
}

function status(t) {
  return t.status;
}

function createdAt(t) {
  return t.createdAt;
}

function rca(t) {
  return t.rca;
}

function normalResponses(t) {
  return t.normalResponses;
}

function channel(t) {
  return t.channel;
}

function touchPoint(t) {
  return t.touchPoint;
}

function lob(t) {
  return t.lob;
}

function shopName(t) {
  return t.shopName;
}

function escalation_level_2(t) {
  return t.escalation_level_2;
}

function escalation_level_3(t) {
  return t.escalation_level_3;
}

function escalation_level_4(t) {
  return t.escalation_level_4;
}

function escalation_level_5(t) {
  return t.escalation_level_5;
}

function updateTicketStatus(status, t) {
  return {
          id: t.id,
          firstName: t.firstName,
          lastName: t.lastName,
          msisdn: t.msisdn,
          email: t.email,
          priority: t.priority,
          assignee: t.assignee,
          status: status,
          normalResponses: t.normalResponses,
          rca: t.rca,
          createdAt: t.createdAt,
          channel: t.channel,
          touchPoint: t.touchPoint,
          lob: t.lob,
          shopName: t.shopName,
          escalation_level_2: t.escalation_level_2,
          escalation_level_3: t.escalation_level_3,
          escalation_level_4: t.escalation_level_4,
          escalation_level_5: t.escalation_level_5
        };
}

function updateAssignee(assignee, t) {
  return {
          id: t.id,
          firstName: t.firstName,
          lastName: t.lastName,
          msisdn: t.msisdn,
          email: t.email,
          priority: t.priority,
          assignee: assignee,
          status: t.status,
          normalResponses: t.normalResponses,
          rca: t.rca,
          createdAt: t.createdAt,
          channel: t.channel,
          touchPoint: t.touchPoint,
          lob: t.lob,
          shopName: t.shopName,
          escalation_level_2: t.escalation_level_2,
          escalation_level_3: t.escalation_level_3,
          escalation_level_4: t.escalation_level_4,
          escalation_level_5: t.escalation_level_5
        };
}

function decode$4(json) {
  var partial_arg = Curry._1(Domain$ReactHooksTemplate.$$String.FirstName.empty, undefined);
  var partial_arg$1 = function (param) {
    return Json_decode.withDefault(partial_arg, Domain$ReactHooksTemplate.$$String.FirstName.decode, param);
  };
  var partial_arg$2 = Curry._1(Domain$ReactHooksTemplate.$$String.LastName.empty, undefined);
  var partial_arg$3 = function (param) {
    return Json_decode.withDefault(partial_arg$2, Domain$ReactHooksTemplate.$$String.LastName.decode, param);
  };
  var partial_arg$4 = Curry._1(Domain$ReactHooksTemplate.$$String.Msisdn.empty, undefined);
  var partial_arg$5 = function (param) {
    return Json_decode.withDefault(partial_arg$4, Domain$ReactHooksTemplate.$$String.Msisdn.decode, param);
  };
  var partial_arg$6 = Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, undefined);
  var partial_arg$7 = function (param) {
    return Json_decode.withDefault(partial_arg$6, Domain$ReactHooksTemplate.$$String.Email.decode, param);
  };
  var partial_arg_header = [];
  var partial_arg_contents = [];
  var partial_arg$8 = {
    header: partial_arg_header,
    contents: partial_arg_contents
  };
  var partial_arg$9 = function (param) {
    return Json_decode.withDefault(partial_arg$8, decode$1, param);
  };
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          firstName: Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("first_name", partial_arg$1, param);
                }), json),
          lastName: Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("last_name", partial_arg$3, param);
                }), json),
          msisdn: Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("msisdn", partial_arg$5, param);
                }), json),
          email: Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("email", partial_arg$7, param);
                }), json),
          priority: Json_decode.field("ticket_priority", decode$2, json),
          assignee: Json_decode.optional((function (param) {
                  return Json_decode.field("assignee", decode, param);
                }), json),
          status: Json_decode.field("status", decode$3, json),
          normalResponses: Json_decode.optional((function (param) {
                  return Json_decode.field("normal_responses", partial_arg$9, param);
                }), json),
          rca: Json_decode.optional((function (param) {
                  return Json_decode.field("rca", Json_decode.string, param);
                }), json),
          createdAt: Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
          channel: Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("channel", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          touchPoint: Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("touch_point", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          lob: Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("lob", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          shopName: Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("more_details", (function (param) {
                                return Json_decode.optional((function (param) {
                                              return Json_decode.field("shop_name", Json_decode.string, param);
                                            }), param);
                              }), param);
                }), json),
          escalation_level_2: Json_decode.optional((function (param) {
                  return Json_decode.field("customer_detail", (function (param) {
                                return Json_decode.field("more_details", (function (param) {
                                              return Json_decode.field("escalation_level_2", Json_decode.string, param);
                                            }), param);
                              }), param);
                }), json),
          escalation_level_3: Json_decode.optional((function (param) {
                  return Json_decode.field("customer_detail", (function (param) {
                                return Json_decode.field("more_details", (function (param) {
                                              return Json_decode.field("escalation_level_3", Json_decode.string, param);
                                            }), param);
                              }), param);
                }), json),
          escalation_level_4: Json_decode.optional((function (param) {
                  return Json_decode.field("customer_detail", (function (param) {
                                return Json_decode.field("more_details", (function (param) {
                                              return Json_decode.field("escalation_level_4", Json_decode.string, param);
                                            }), param);
                              }), param);
                }), json),
          escalation_level_5: Json_decode.optional((function (param) {
                  return Json_decode.field("customer_detail", (function (param) {
                                return Json_decode.field("more_details", (function (param) {
                                              return Json_decode.field("escalation_level_5", Json_decode.string, param);
                                            }), param);
                              }), param);
                }), json)
        };
}

exports.Assignee = Assignee;
exports.NormalResponses = NormalResponses;
exports.Priority = Priority;
exports.Status = Status;
exports.id = id$1;
exports.msisdn = msisdn;
exports.email = email$1;
exports.firstName = firstName;
exports.lastName = lastName;
exports.name = name$1;
exports.assignee = assignee;
exports.priority = priority;
exports.status = status;
exports.createdAt = createdAt;
exports.rca = rca;
exports.normalResponses = normalResponses;
exports.channel = channel;
exports.touchPoint = touchPoint;
exports.lob = lob;
exports.shopName = shopName;
exports.escalation_level_2 = escalation_level_2;
exports.escalation_level_3 = escalation_level_3;
exports.escalation_level_4 = escalation_level_4;
exports.escalation_level_5 = escalation_level_5;
exports.updateTicketStatus = updateTicketStatus;
exports.updateAssignee = updateAssignee;
exports.decode = decode$4;
/* empty Not a pure module */
