// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Action$ReactHooksTemplate = require("./Action.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");

function ActionForm(Props) {
  var action = Props.action;
  var updateLogicAction = Props.updateLogicAction;
  var actiontype = function (action) {
    var match = Action$ReactHooksTemplate.actionType(action);
    if (match.TAG === /* Skip */0) {
      return "Skip";
    } else {
      return "Jump";
    }
  };
  var jumpQuestion = function (action) {
    var skipTarget = Action$ReactHooksTemplate.actionType(action);
    if (skipTarget.TAG === /* Skip */0) {
      return "";
    }
    var jumpTarget = skipTarget._0;
    if (jumpTarget.TAG === /* Question */0) {
      return Question$ReactHooksTemplate.text(jumpTarget._0);
    } else {
      return ThankYou$ReactHooksTemplate.text(jumpTarget._0);
    }
  };
  return React.createElement("div", {
              className: "bg-white pt-8"
            }, React.createElement("div", {
                  className: "flex items-center gap-x-8 px-2 py-4"
                }, React.createElement("div", {
                      className: " flex items-center text-base font-semibold text-darkcharcoal pr-5 pt-4"
                    }, "then"), React.createElement("div", {
                      className: "w-110-px h-64-px"
                    }, React.createElement("div", {
                          className: "w-48-px h-20-px font-normal text-xs text-aurometalsaurus"
                        }, "Action"), React.createElement("input", {
                          defaultValue: actiontype(action),
                          className: "h-40-px py-2 px-1 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-80-px",
                          placeholder: "Action",
                          readOnly: true,
                          type: "text",
                          onChange: (function (param) {
                              return Curry._1(updateLogicAction, action);
                            })
                        }), React.createElement("span", {
                          className: "px-1 pr-2"
                        }, "to")), React.createElement("div", {
                      className: "w-110-px h-64-px"
                    }, React.createElement("div", {
                          className: "w-48-px h-20-px font-normal text-xs text-aurometalsaurus "
                        }, "Question"), React.createElement("input", {
                          defaultValue: jumpQuestion(action),
                          className: "h-40-px py-2 px-1 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-220-px",
                          placeholder: "Select Question",
                          readOnly: true,
                          type: "text",
                          onChange: (function (param) {
                              return Curry._1(updateLogicAction, action);
                            })
                        }))));
}

var make = ActionForm;

exports.make = make;
/* react Not a pure module */
