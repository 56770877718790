// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Querystringify = require("querystringify");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");
var Ticket$ReactHooksTemplate = require("./ticket.bs.js");
var Filters$ReactHooksTemplate = require("../dashboard/filters/filters.bs.js");
var Pagination$ReactHooksTemplate = require("../common/Pagination.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");

function fetchTickets(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/tickets", (function (param) {
                return Json_decode.field("tickets", (function (param) {
                              return Json_decode.list(Ticket$ReactHooksTemplate.decode, param);
                            }), param);
              }));
}

function fetchTicketWorkers(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/recovery_agents", (function (param) {
                return Json_decode.field("recovery_agents", (function (param) {
                              return Json_decode.list(Ticket$ReactHooksTemplate.Assignee.decode, param);
                            }), param);
              }));
}

function create(ticket) {
  return {
          ticket: ticket,
          enabled: false
        };
}

function ticket(t) {
  return t.ticket;
}

function ticketId(t) {
  return Ticket$ReactHooksTemplate.id(t.ticket);
}

function enabled(t) {
  return t.enabled;
}

function forceStatus(enabled, t) {
  return {
          ticket: t.ticket,
          enabled: enabled
        };
}

function toggleStatus(t) {
  return {
          ticket: t.ticket,
          enabled: !t.enabled
        };
}

var TicketStatus = {
  create: create,
  ticket: ticket,
  ticketId: ticketId,
  enabled: enabled,
  forceStatus: forceStatus,
  toggleStatus: toggleStatus
};

var emptyForm = {
  ticketStatuses: /* [] */0,
  assignees: /* [] */0,
  selected: undefined,
  selectAll: false
};

function reducer(state, action) {
  if (state.loading) {
    if (typeof action !== "number" && action.TAG === /* DisplayList */0) {
      var init = state.form;
      return {
              loading: false,
              toggleFilter: state.toggleFilter,
              scope: state.scope,
              form: {
                ticketStatuses: action._0,
                assignees: action._1,
                selected: init.selected,
                selectAll: init.selectAll
              }
            };
    }
    return {
            loading: state.loading,
            toggleFilter: state.toggleFilter,
            scope: state.scope,
            form: emptyForm
          };
  }
  if (typeof action === "number") {
    switch (action) {
      case /* ToggleSelectAll */0 :
          var partial_arg = !state.form.selectAll;
          var ticketStatuses = List.map((function (param) {
                  return forceStatus(partial_arg, param);
                }), state.form.ticketStatuses);
          var init$1 = state.form;
          return {
                  loading: state.loading,
                  toggleFilter: state.toggleFilter,
                  scope: state.scope,
                  form: {
                    ticketStatuses: ticketStatuses,
                    assignees: init$1.assignees,
                    selected: init$1.selected,
                    selectAll: !state.form.selectAll
                  }
                };
      case /* ToggleFilter */1 :
          return {
                  loading: state.loading,
                  toggleFilter: !state.toggleFilter,
                  scope: state.scope,
                  form: state.form
                };
      case /* Loading */2 :
          return {
                  loading: true,
                  toggleFilter: state.toggleFilter,
                  scope: state.scope,
                  form: state.form
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* DisplayList */0 :
          var init$2 = state.form;
          return {
                  loading: state.loading,
                  toggleFilter: state.toggleFilter,
                  scope: state.scope,
                  form: {
                    ticketStatuses: action._0,
                    assignees: action._1,
                    selected: init$2.selected,
                    selectAll: init$2.selectAll
                  }
                };
      case /* UpdateAssignee */1 :
          var init$3 = state.form;
          return {
                  loading: state.loading,
                  toggleFilter: state.toggleFilter,
                  scope: state.scope,
                  form: {
                    ticketStatuses: init$3.ticketStatuses,
                    assignees: init$3.assignees,
                    selected: action._0,
                    selectAll: init$3.selectAll
                  }
                };
      case /* ToggleTicketStatus */2 :
          var ticketStatus = action._0;
          var ticketStatuses$1 = List.map((function (x) {
                  if (Caml_obj.caml_equal(ticketStatus, x)) {
                    return toggleStatus(x);
                  } else {
                    return x;
                  }
                }), state.form.ticketStatuses);
          var init$4 = state.form;
          return {
                  loading: state.loading,
                  toggleFilter: state.toggleFilter,
                  scope: state.scope,
                  form: {
                    ticketStatuses: ticketStatuses$1,
                    assignees: init$4.assignees,
                    selected: init$4.selected,
                    selectAll: init$4.selectAll
                  }
                };
      case /* UpdateViewFilter */3 :
          return {
                  loading: state.loading,
                  toggleFilter: state.toggleFilter,
                  scope: action._0,
                  form: state.form
                };
      
    }
  }
}

function assignTicket(assignee, tickets) {
  var params = {};
  params["assignee_id"] = Ticket$ReactHooksTemplate.Assignee.id(assignee);
  var ticketIds = Belt_List.map(tickets, Ticket$ReactHooksTemplate.id);
  params["ids"] = Json_encode.list((function (prim) {
          return prim;
        }), ticketIds);
  var ticket = {};
  ticket["ticket"] = params;
  return Http$ReactHooksTemplate.fetchPostJSON("/api/v1/tickets/assign", ticket);
}

function scopeFromString(scope) {
  var match = scope.toLowerCase();
  switch (match) {
    case "all" :
        return /* All */0;
    case "assigned" :
        return /* Assigned */1;
    case "unassigned" :
        return /* Unassigned */2;
    default:
      return /* All */0;
  }
}

function scopeToString(x) {
  switch (x) {
    case /* All */0 :
        return "all";
    case /* Assigned */1 :
        return "assigned";
    case /* Unassigned */2 :
        return "unassigned";
    
  }
}

function fetchTicketDetails(param) {
  return fetchTickets(undefined).then(function (tickets) {
              return fetchTicketWorkers(undefined).then(function (workers) {
                          var ticketStatuses = List.map(create, tickets);
                          return Promise.resolve([
                                      ticketStatuses,
                                      workers
                                    ]);
                        });
            });
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function totalPages(total_records, perPage) {
  return Caml_int32.div(total_records - 1 | 0, perPage) + 1 | 0;
}

var emptyPageInfo = {
  total: "1",
  perPage: "1"
};

function s(prim) {
  return prim;
}

function TicketManager(Props) {
  var scope = Props.scope;
  var search = Props.search;
  var initialState_scope = scopeFromString(scope);
  var initialState = {
    loading: true,
    toggleFilter: false,
    scope: initialState_scope,
    form: emptyForm
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return {
                sortedColumn: "created_at",
                sortingOrder: "desc"
              };
      });
  var setSortingInfo = match$1[1];
  var sortingInfo = match$1[0];
  var match$2 = React.useState(function () {
        return emptyPageInfo;
      });
  var setPageInfo = match$2[1];
  var pageInfo = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setOpenSortModal = match$3[1];
  var queryParams = Querystringify.parse(search);
  var x = Js_dict.get(queryParams, "page");
  var currentPage;
  if (x !== undefined && x !== "") {
    var page = Belt_Int.fromString(x);
    currentPage = page !== undefined ? page : 1;
  } else {
    currentPage = 1;
  }
  var x$1 = Js_dict.get(queryParams, "sortedCol");
  var sortedColumn = x$1 !== undefined && x$1 !== "" ? x$1 : "";
  var x$2 = Js_dict.get(queryParams, "sortOrder");
  var sortingOrder = x$2 !== undefined && x$2 !== "" ? x$2 : "";
  var fetchTickets = function (param) {
    var pageNumber = String(currentPage);
    var sortedCol = sortingInfo.sortedColumn === "" ? sortedColumn : sortingInfo.sortedColumn;
    var sortOrder = sortingInfo.sortingOrder === "" ? sortingOrder : sortingInfo.sortingOrder;
    var url = sortedCol !== "" && sortOrder !== "" ? "/api/v1/tickets?page=" + pageNumber + "&scope=" + scope + "&sortedCol=" + sortedCol + "&sortOrder=" + sortOrder : "/api/v1/tickets?page=" + pageNumber + "&scope=" + scope;
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader("" + url).then(function (param) {
                var headers = param[0];
                var x = headers.get("Total");
                var total = x !== null ? (
                    x === null ? undefined : Caml_option.some(x)
                  ) : "1";
                var x$1 = headers.get("Per-Page");
                var perPage = x$1 !== null ? (
                    x$1 === null ? undefined : Caml_option.some(x$1)
                  ) : "1";
                var tickets = param[1].then(function (json) {
                      return Promise.resolve(Json_decode.field("tickets", (function (param) {
                                        return Json_decode.list(Ticket$ReactHooksTemplate.decode, param);
                                      }), json));
                    });
                return Promise.resolve([
                            tickets,
                            [
                              total,
                              perPage
                            ]
                          ]);
              });
  };
  var fetchTicketWorkers = function (param) {
    return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/recovery_agents", (function (param) {
                  return Json_decode.field("recovery_agents", (function (param) {
                                return Json_decode.list(Ticket$ReactHooksTemplate.Assignee.decode, param);
                              }), param);
                }));
  };
  var fetchTicketDetails = function (param) {
    return fetchTickets(undefined).then(function (param) {
                var pageInfo = param[1];
                var tickets = param[0];
                return fetchTicketWorkers(undefined).then(function (workers) {
                            return tickets.then(function (tickets) {
                                        var ticketStatuses = List.map(create, tickets);
                                        return Promise.resolve([
                                                    [
                                                      ticketStatuses,
                                                      workers
                                                    ],
                                                    pageInfo
                                                  ]);
                                      });
                          });
              });
  };
  React.useEffect((function () {
          Curry._1(dispatch, /* Loading */2);
          fetchTicketDetails(undefined).then(function (param) {
                var ticketDetails = param[0];
                var pageInfo = param[1];
                var perPage = pageInfo[1];
                var total = pageInfo[0];
                Curry._1(setPageInfo, (function (param) {
                        return {
                                total: total,
                                perPage: perPage
                              };
                      }));
                return Promise.resolve(Curry._1(dispatch, {
                                TAG: /* DisplayList */0,
                                _0: ticketDetails[0],
                                _1: ticketDetails[1]
                              }));
              });
          
        }), [currentPage]);
  var onApplyFilter = function (param) {
    Curry._1(dispatch, /* ToggleFilter */1);
    Curry._1(dispatch, /* Loading */2);
    fetchTicketDetails(undefined).then(function (param) {
          var ticketDetails = param[0];
          var pageInfo = param[1];
          var perPage = pageInfo[1];
          var total = pageInfo[0];
          Curry._1(setPageInfo, (function (param) {
                  return {
                          total: total,
                          perPage: perPage
                        };
                }));
          return Promise.resolve(Curry._1(dispatch, {
                          TAG: /* DisplayList */0,
                          _0: ticketDetails[0],
                          _1: ticketDetails[1]
                        }));
        });
    
  };
  var handlePageChange = function (pageNumber) {
    var scopeParam = scopeToString(state.scope);
    var pageNumber$1 = String(pageNumber);
    var sortedCol = sortingInfo.sortedColumn;
    var sortOrder = sortingInfo.sortingOrder;
    return RescriptReactRouter.push("/tickets/manage/" + scopeParam + "?page=" + pageNumber$1 + "&sortedCol=" + sortedCol + "&sortOrder=" + sortOrder);
  };
  var applySort = function (param) {
    var scopeParam = scopeToString(state.scope);
    var sortedCol = sortingInfo.sortedColumn;
    var sortOrder = sortingInfo.sortingOrder;
    RescriptReactRouter.push("/tickets/manage/" + scopeParam + "?page=1&sortedCol=" + sortedCol + "&sortOrder=" + sortOrder);
    Curry._1(dispatch, /* Loading */2);
    fetchTicketDetails(undefined).then(function (param) {
          var ticketDetails = param[0];
          var pageInfo = param[1];
          var perPage = pageInfo[1];
          var total = pageInfo[0];
          Curry._1(setPageInfo, (function (param) {
                  return {
                          total: total,
                          perPage: perPage
                        };
                }));
          return Promise.resolve(Curry._1(dispatch, {
                          TAG: /* DisplayList */0,
                          _0: ticketDetails[0],
                          _1: ticketDetails[1]
                        }));
        });
    
  };
  var selectedScope = function (param) {
    var scopeParam = scopeToString(state.scope);
    if (scopeParam === "all") {
      return "All Tickets";
    } else {
      return scopeParam;
    }
  };
  if (state.loading) {
    return React.createElement("p", undefined, "Fetching tickets...");
  }
  var emailText = function (assignee) {
    return Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Ticket$ReactHooksTemplate.Assignee.email(assignee));
  };
  var assignee = state.form.selected;
  var selectedValue = assignee !== undefined ? emailText(assignee) : "";
  var isSomeTicketSelected = Belt_List.some(state.form.ticketStatuses, enabled);
  var isSelectionEmpty = Belt_Option.isNone(state.form.selected);
  var selectionDisabledCSS = isSomeTicketSelected ? "" : "hidden";
  var submitButtonStatus = isSomeTicketSelected && !isSelectionEmpty;
  var submitDisabledCSS = submitButtonStatus ? "" : "hidden";
  var selectedTickets = Belt_List.map(Belt_List.keep(state.form.ticketStatuses, enabled), ticket);
  var postTicketAssignment = function (assignee) {
    if (selectedTickets) {
      assignTicket(assignee, selectedTickets).then(function (response) {
              return Promise.resolve((console.log(response), undefined));
            }).then(function (param) {
            return Promise.resolve((window.location.reload(), undefined));
          });
      return ;
    }
    
  };
  var handleTicketAssignment = function (_event) {
    return Belt_Option.mapWithDefault(state.form.selected, undefined, postTicketAssignment);
  };
  var handleUpdateScope = function (scope, _event) {
    Curry._1(dispatch, {
          TAG: /* UpdateViewFilter */3,
          _0: scope
        });
    var scopeParam = scopeToString(scope);
    var sortedCol = sortingInfo.sortedColumn;
    var sortOrder = sortingInfo.sortingOrder;
    RescriptReactRouter.push("/tickets/manage/" + scopeParam + "?sortedCol=" + sortedCol + "&sortOrder=" + sortOrder);
    window.location.reload();
    
  };
  var ticketScope = function (scope) {
    switch (scope) {
      case /* All */0 :
          return function (param) {
            return true;
          };
      case /* Assigned */1 :
          return function (x) {
            return Belt_Option.isSome(Ticket$ReactHooksTemplate.assignee(x.ticket));
          };
      case /* Unassigned */2 :
          return function (x) {
            return Belt_Option.isNone(Ticket$ReactHooksTemplate.assignee(x.ticket));
          };
      
    }
  };
  var ticketPriority = function (priority) {
    switch (priority) {
      case "High" :
          return "bg-orange-200 text-orange-500";
      case "Low" :
          return "bg-green-100 text-green-600";
      case "Medium" :
          return "bg-yellow-200 text-yellow-500";
      case "red_alert" :
          return "bg-lavenderblush text-firebrick";
      default:
        return "bg-white text-blackcoral";
    }
  };
  var ticketPriorityString = function (priority) {
    if (priority === "red_alert") {
      return "Red Alert";
    } else {
      return priority;
    }
  };
  var bgStatusColor = function (status) {
    switch (status) {
      case "Closed" :
          return "bg-firebrick";
      case "Open" :
          return "bg-candypink";
      case "Parked" :
          return "bg-yellow-400";
      case "Resolved" :
          return "bg-americangreen";
      default:
        return "hidden";
    }
  };
  var scopeTicketStatus = function (xs) {
    return Belt_List.keep(xs, ticketScope(state.scope));
  };
  var total = Belt_List.length(state.form.ticketStatuses);
  var enabled$1 = Belt_List.length(Belt_List.keep(state.form.ticketStatuses, enabled));
  var isIndeterminate = enabled$1 > 0 && enabled$1 !== total;
  var partial_arg = state.form;
  return React.createElement(React.Fragment, undefined, state.toggleFilter ? React.createElement(Filters$ReactHooksTemplate.make, {
                    onClose: (function (param) {
                        return Curry._1(dispatch, /* ToggleFilter */1);
                      }),
                    onApply: onApplyFilter,
                    showDateFilter: false,
                    showFilterPills: false
                  }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                      className: "flex justify-between items-center border-b border-gray-300 px-30-px py-16-px"
                    }, React.createElement("h2", {
                          className: "text-xl font-semibold"
                        }, "Overview"), React.createElement("div", {
                          className: "flex gap-4"
                        }, React.createElement("button", {
                              className: "bg-ghostwhite border border-gray-400\n              shadow-inner flex rounded py-2 px-3 text-xs",
                              id: "filters",
                              onClick: (function (param) {
                                  return Curry._1(dispatch, /* ToggleFilter */1);
                                })
                            }, React.createElement("img", {
                                  className: "h-14-px mr-2-px",
                                  src: Image$ReactHooksTemplate.filterButton
                                }), "Filters"), React.createElement("button", {
                              className: "bg-pigmentblue border border-gray-400\n                  flex rounded py-2 px-3 text-xs text-white",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  Http$ReactHooksTemplate.fetchGetJSON("/api/v1/tickets/download").then(function (file_name) {
                                        return Promise.resolve((console.log(file_name), RescriptReactRouter.push("/tickets/downloadList")));
                                      });
                                  
                                })
                            }, React.createElement("img", {
                                  className: "h-14-px mr-1 filter-white",
                                  src: Image$ReactHooksTemplate.download
                                }), "Download"))), React.createElement("div", {
                      className: "flex items-center px-30-px"
                    }, React.createElement("div", {
                          className: "dropdown mr-20-px"
                        }, React.createElement("div", {
                              className: "flex items-center text-sm font-normal capitalize justify-between"
                            }, React.createElement("p", {
                                  className: "mr-5-px"
                                }, selectedScope(undefined)), React.createElement("img", {
                                  src: Image$ReactHooksTemplate.caretDown
                                })), React.createElement("ul", {
                              className: "dropdown-list shadow text-gray-800 text-sm"
                            }, React.createElement("li", {
                                  className: "hover:bg-gray-300"
                                }, React.createElement("div", {
                                      className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                      onClick: (function (param) {
                                          return handleUpdateScope(/* All */0, param);
                                        })
                                    }, "All Tickets")), React.createElement("li", {
                                  className: "hover:bg-gray-300"
                                }, React.createElement("div", {
                                      className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                      onClick: (function (param) {
                                          return handleUpdateScope(/* Assigned */1, param);
                                        })
                                    }, "Assigned")), React.createElement("li", {
                                  className: "hover:bg-gray-300"
                                }, React.createElement("div", {
                                      className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                      onClick: (function (param) {
                                          return handleUpdateScope(/* Unassigned */2, param);
                                        })
                                    }, "Unassigned")))), React.createElement("div", {
                          className: "flex"
                        }, React.createElement(Pagination$ReactHooksTemplate.make, {
                              total: totalPages(Caml_format.caml_int_of_string(pageInfo.total), Caml_format.caml_int_of_string(pageInfo.perPage)),
                              currentPage: currentPage,
                              handlePageChange: handlePageChange
                            })), React.createElement("div", {
                          className: "flex items-center px-30-px"
                        }, React.createElement("select", {
                              className: "" + selectionDisabledCSS + " block border px-3 py-2 border-gray-400\n                  w-52 bg-white rounded capitalize mr-6 text-sm",
                              disabled: !isSomeTicketSelected,
                              value: selectedValue,
                              onChange: (function (param) {
                                  var emailText = Curry._1(Domain$ReactHooksTemplate.$$String.Email.create, Utils$ReactHooksTemplate.formTargetValue(param));
                                  if (Curry._1(Domain$ReactHooksTemplate.$$String.Email.isEmpty, emailText)) {
                                    return Curry._1(dispatch, {
                                                TAG: /* UpdateAssignee */1,
                                                _0: undefined
                                              });
                                  }
                                  var selected = List.find((function (assignee) {
                                          return Caml_obj.caml_equal(Ticket$ReactHooksTemplate.Assignee.email(assignee), emailText);
                                        }), partial_arg.assignees);
                                  return Curry._1(dispatch, {
                                              TAG: /* UpdateAssignee */1,
                                              _0: selected
                                            });
                                })
                            }, $$Array.of_list(List.map((function (assignee) {
                                        return React.createElement("option", {
                                                    key: String(Ticket$ReactHooksTemplate.Assignee.id(assignee)),
                                                    value: emailText(assignee)
                                                  }, emailText(assignee));
                                      }), {
                                      hd: Ticket$ReactHooksTemplate.Assignee.empty,
                                      tl: state.form.assignees
                                    }))), React.createElement("button", {
                              className: "" + submitDisabledCSS + " bg-ghostwhite border border-gray-400 shadow-inner\n                  rounded py-2 px-3 text-sm hover:bg-palatinateblue hover:text-white",
                              disabled: !submitButtonStatus,
                              onClick: handleTicketAssignment
                            }, "Assign Ticket"))), React.createElement("div", {
                      className: "flex px-30-px pb-30-px"
                    }, React.createElement("table", {
                          className: "text-left table-auto w-full border-separate text-xs font-medium"
                        }, React.createElement("thead", {
                              className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                            }, React.createElement("tr", undefined, React.createElement("th", {
                                      className: "px-4"
                                    }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                          checked: state.form.selectAll,
                                          label: "",
                                          handleChange: (function (param) {
                                              return Curry._1(dispatch, /* ToggleSelectAll */0);
                                            }),
                                          indeterminate: isIndeterminate,
                                          checkboxClassName: "custom_checkbox"
                                        })), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Id", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Name", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Msisdn", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Email", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "TouchPoint", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Lob", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Shop Name", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Priority", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Assignee", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Escalation Level 2", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Status", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex cursor-pointer",
                                          onClick: (function (param) {
                                              return Curry._1(setOpenSortModal, (function (param) {
                                                            return true;
                                                          }));
                                            })
                                        }, "Created At", React.createElement("img", {
                                              className: "ml-4-px sort-img",
                                              src: Image$ReactHooksTemplate.heading
                                            })), match$3[0] ? React.createElement("div", {
                                            className: "flex border border-gray-400 rounded-lg shadow-md flex-col justify-start\n                          items-start gap-2 p-4 z-10 absolute bg-white sort-modal"
                                          }, React.createElement("div", {
                                                className: "text-sm font-light text-aurometalsaurus"
                                              }, "Sort"), React.createElement("button", {
                                                className: "w-48 flex flex-row justify-start items-center p-2 rounded " + (
                                                  sortingInfo.sortedColumn !== "created_at" || sortingInfo.sortingOrder === "desc" ? "bg-lavenderweb border border-lavenderweb" : ""
                                                ),
                                                onClick: (function (param) {
                                                    return Curry._1(setSortingInfo, (function (param) {
                                                                  return {
                                                                          sortedColumn: "created_at",
                                                                          sortingOrder: "desc"
                                                                        };
                                                                }));
                                                  })
                                              }, "Sort by latest first"), React.createElement("button", {
                                                className: "w-48 flex flex-row justify-start items-center p-2 rounded " + (
                                                  sortingInfo.sortedColumn === "created_at" && sortingInfo.sortingOrder === "asc" ? "bg-lavenderweb border border-lavenderweb" : ""
                                                ),
                                                onClick: (function (param) {
                                                    return Curry._1(setSortingInfo, (function (param) {
                                                                  return {
                                                                          sortedColumn: "created_at",
                                                                          sortingOrder: "asc"
                                                                        };
                                                                }));
                                                  })
                                              }, "Sort by oldest first"), React.createElement("hr", {
                                                className: "border border-gray-200 w-full"
                                              }), React.createElement("div", {
                                                className: "flex justify-between w-full font-semibold text-sm gap-2"
                                              }, React.createElement("button", {
                                                    className: "py-2 w-1/2 border border-gray-300 text-aurometalsaurus rounded",
                                                    onClick: (function (param) {
                                                        return Curry._1(setOpenSortModal, (function (param) {
                                                                      return false;
                                                                    }));
                                                      })
                                                  }, "Cancel"), React.createElement("button", {
                                                    className: "py-2 w-1/2 bg-pigmentblue text-white rounded",
                                                    onClick: (function (param) {
                                                        applySort(undefined);
                                                        return Curry._1(setOpenSortModal, (function (param) {
                                                                      return false;
                                                                    }));
                                                      })
                                                  }, "Apply"))) : React.createElement(React.Fragment, undefined)))), React.createElement("tbody", undefined, Belt_List.toArray(Belt_List.map(scopeTicketStatus(state.form.ticketStatuses), (function (x) {
                                        var ticket = x.ticket;
                                        var touchPoint = Ticket$ReactHooksTemplate.touchPoint(ticket);
                                        var lob = Ticket$ReactHooksTemplate.lob(ticket);
                                        var x$1 = Ticket$ReactHooksTemplate.shopName(ticket);
                                        var esc_level_2 = Ticket$ReactHooksTemplate.escalation_level_2(ticket);
                                        return React.createElement("tr", {
                                                    key: String(Ticket$ReactHooksTemplate.id(ticket)),
                                                    className: "border-b border-bottom-gray-600"
                                                  }, React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                                            checked: x.enabled,
                                                            label: "",
                                                            handleChange: (function (param) {
                                                                return Curry._1(dispatch, {
                                                                            TAG: /* ToggleTicketStatus */2,
                                                                            _0: x
                                                                          });
                                                              }),
                                                            checkboxClassName: "custom_checkbox"
                                                          })), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, React.createElement("a", {
                                                            href: "/tickets/show/" + String(Ticket$ReactHooksTemplate.id(ticket))
                                                          }, String(Ticket$ReactHooksTemplate.id(ticket)))), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, Ticket$ReactHooksTemplate.name(x.ticket)), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, Curry._1(Domain$ReactHooksTemplate.$$String.Msisdn.show, Ticket$ReactHooksTemplate.msisdn(ticket))), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Ticket$ReactHooksTemplate.email(ticket))), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, touchPoint !== undefined ? touchPoint : ""), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, lob !== undefined ? lob : ""), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, x$1 !== undefined ? x$1 : ""), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, React.createElement("div", {
                                                            className: "flex justify-center items-center font-normal border border-gray-300 rounded-2xl\n                            py-1 px-3 uppercase text-10-px " + ticketPriority(Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket)))
                                                          }, ticketPriorityString(Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket))))), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, Belt_Option.mapWithDefault(Ticket$ReactHooksTemplate.assignee(ticket), "", (function (x) {
                                                              return Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Ticket$ReactHooksTemplate.Assignee.email(x));
                                                            }))), React.createElement("td", undefined, esc_level_2 !== undefined ? esc_level_2 : ""), React.createElement("td", {
                                                        className: "px-4 py-2 uppercase"
                                                      }, React.createElement("div", {
                                                            className: "text-10-px px-2 py-1 rounded-2xl flex gap-1 items-center\n                                          border border-gray-400 w-fit font-normal text-aurometalsaurus"
                                                          }, React.createElement("div", {
                                                                className: "h-3 w-3 rounded-full " + bgStatusColor(Ticket$ReactHooksTemplate.Status.toString(Ticket$ReactHooksTemplate.status(ticket)))
                                                              }), Ticket$ReactHooksTemplate.Status.toString(Ticket$ReactHooksTemplate.status(ticket)))), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, Ticket$ReactHooksTemplate.createdAt(ticket).format("lll")));
                                      }))))))));
}

var make = TicketManager;

exports.fetchTickets = fetchTickets;
exports.fetchTicketWorkers = fetchTicketWorkers;
exports.TicketStatus = TicketStatus;
exports.emptyForm = emptyForm;
exports.reducer = reducer;
exports.assignTicket = assignTicket;
exports.scopeFromString = scopeFromString;
exports.scopeToString = scopeToString;
exports.fetchTicketDetails = fetchTicketDetails;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.totalPages = totalPages;
exports.emptyPageInfo = emptyPageInfo;
exports.s = s;
exports.make = make;
/* react Not a pure module */
