// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var MomentRe = require("bs-moment/src/MomentRe.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Logic$ReactHooksTemplate = require("./Logic.bs.js");
var Action$ReactHooksTemplate = require("./Action.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");
var LogicCondition$ReactHooksTemplate = require("./LogicCondition.bs.js");

function empty(param) {
  return {
          id: undefined,
          name: "",
          creator: undefined,
          logo: undefined,
          welcome: undefined,
          deletedWelcome: undefined,
          thankYouList: /* [] */0,
          deletedThankYouList: /* [] */0,
          questions: /* [] */0,
          deletedQuestions: /* [] */0,
          surveyTheme: SurveyTheme$ReactHooksTemplate.empty(undefined),
          updatedAt: undefined,
          logics: /* [] */0
        };
}

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function logo(t) {
  return t.logo;
}

function welcome(t) {
  return t.welcome;
}

function deletedWelcome(t) {
  return t.deletedWelcome;
}

function questions(t) {
  return t.questions;
}

function deletedQuestions(t) {
  return t.deletedQuestions;
}

function thankYouList(t) {
  return t.thankYouList;
}

function updatedAt(t) {
  return t.updatedAt;
}

function deletedThankYouList(t) {
  return t.deletedThankYouList;
}

function surveyTheme(t) {
  return t.surveyTheme;
}

function logics(t) {
  return t.logics;
}

function setName(t, name) {
  return {
          id: t.id,
          name: name,
          creator: t.creator,
          logo: t.logo,
          welcome: t.welcome,
          deletedWelcome: t.deletedWelcome,
          thankYouList: t.thankYouList,
          deletedThankYouList: t.deletedThankYouList,
          questions: t.questions,
          deletedQuestions: t.deletedQuestions,
          surveyTheme: t.surveyTheme,
          updatedAt: t.updatedAt,
          logics: t.logics
        };
}

function setWelcome(t, welcome) {
  return {
          id: t.id,
          name: t.name,
          creator: t.creator,
          logo: t.logo,
          welcome: welcome,
          deletedWelcome: t.deletedWelcome,
          thankYouList: t.thankYouList,
          deletedThankYouList: t.deletedThankYouList,
          questions: t.questions,
          deletedQuestions: t.deletedQuestions,
          surveyTheme: t.surveyTheme,
          updatedAt: t.updatedAt,
          logics: t.logics
        };
}

function setDeletedWelcome(t, deletedWelcome) {
  return {
          id: t.id,
          name: t.name,
          creator: t.creator,
          logo: t.logo,
          welcome: t.welcome,
          deletedWelcome: deletedWelcome,
          thankYouList: t.thankYouList,
          deletedThankYouList: t.deletedThankYouList,
          questions: t.questions,
          deletedQuestions: t.deletedQuestions,
          surveyTheme: t.surveyTheme,
          updatedAt: t.updatedAt,
          logics: t.logics
        };
}

function setQuestions(t, questions) {
  return {
          id: t.id,
          name: t.name,
          creator: t.creator,
          logo: t.logo,
          welcome: t.welcome,
          deletedWelcome: t.deletedWelcome,
          thankYouList: t.thankYouList,
          deletedThankYouList: t.deletedThankYouList,
          questions: questions,
          deletedQuestions: t.deletedQuestions,
          surveyTheme: t.surveyTheme,
          updatedAt: t.updatedAt,
          logics: t.logics
        };
}

function setDeletedQuestions(t, deletedQuestions) {
  return {
          id: t.id,
          name: t.name,
          creator: t.creator,
          logo: t.logo,
          welcome: t.welcome,
          deletedWelcome: t.deletedWelcome,
          thankYouList: t.thankYouList,
          deletedThankYouList: t.deletedThankYouList,
          questions: t.questions,
          deletedQuestions: deletedQuestions,
          surveyTheme: t.surveyTheme,
          updatedAt: t.updatedAt,
          logics: t.logics
        };
}

function setThankYouList(t, thankYouList) {
  return {
          id: t.id,
          name: t.name,
          creator: t.creator,
          logo: t.logo,
          welcome: t.welcome,
          deletedWelcome: t.deletedWelcome,
          thankYouList: thankYouList,
          deletedThankYouList: t.deletedThankYouList,
          questions: t.questions,
          deletedQuestions: t.deletedQuestions,
          surveyTheme: t.surveyTheme,
          updatedAt: t.updatedAt,
          logics: t.logics
        };
}

function setDeletedThankYouList(t, deletedThankYouList) {
  return {
          id: t.id,
          name: t.name,
          creator: t.creator,
          logo: t.logo,
          welcome: t.welcome,
          deletedWelcome: t.deletedWelcome,
          thankYouList: t.thankYouList,
          deletedThankYouList: deletedThankYouList,
          questions: t.questions,
          deletedQuestions: t.deletedQuestions,
          surveyTheme: t.surveyTheme,
          updatedAt: t.updatedAt,
          logics: t.logics
        };
}

function setLogo(t, logo) {
  return {
          id: t.id,
          name: t.name,
          creator: t.creator,
          logo: logo,
          welcome: t.welcome,
          deletedWelcome: t.deletedWelcome,
          thankYouList: t.thankYouList,
          deletedThankYouList: t.deletedThankYouList,
          questions: t.questions,
          deletedQuestions: t.deletedQuestions,
          surveyTheme: t.surveyTheme,
          updatedAt: t.updatedAt,
          logics: t.logics
        };
}

function setSurveyTheme(t, surveyTheme) {
  return {
          id: t.id,
          name: t.name,
          creator: t.creator,
          logo: t.logo,
          welcome: t.welcome,
          deletedWelcome: t.deletedWelcome,
          thankYouList: t.thankYouList,
          deletedThankYouList: t.deletedThankYouList,
          questions: t.questions,
          deletedQuestions: t.deletedQuestions,
          surveyTheme: surveyTheme,
          updatedAt: t.updatedAt,
          logics: t.logics
        };
}

function setLogics(t, logics) {
  return {
          id: t.id,
          name: t.name,
          creator: t.creator,
          logo: t.logo,
          welcome: t.welcome,
          deletedWelcome: t.deletedWelcome,
          thankYouList: t.thankYouList,
          deletedThankYouList: t.deletedThankYouList,
          questions: t.questions,
          deletedQuestions: t.deletedQuestions,
          surveyTheme: t.surveyTheme,
          updatedAt: t.updatedAt,
          logics: logics
        };
}

function creatorDecoder(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          email: Json_decode.field("email", Json_decode.string, json),
          firstName: Json_decode.field("first_name", Json_decode.string, json),
          lastName: Json_decode.field("last_name", Json_decode.string, json)
        };
}

function decodeThankYouPosition(json) {
  return [
          Json_decode.field("position", Json_decode.$$int, json),
          ThankYou$ReactHooksTemplate.fromJson(json)
        ];
}

function decodeThankyouList(json) {
  return Belt_List.map(Belt_List.sort(Json_decode.field("thank_yous", (function (param) {
                        return Json_decode.list(decodeThankYouPosition, param);
                      }), json), (function (param, param$1) {
                    return param[0] - param$1[0] | 0;
                  })), (function (param) {
                return param[1];
              }));
}

function convertDateToMoment(date) {
  if (date !== undefined) {
    return Caml_option.some(MomentRe.moment(undefined, date));
  }
  
}

function fromJson(json) {
  var questions = Question$ReactHooksTemplate.fromJson(json);
  var thankYous = decodeThankyouList(json);
  var welcome = Json_decode.optional((function (param) {
          return Json_decode.field("welcome", Welcome$ReactHooksTemplate.decodeWelcome, param);
        }), json);
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          name: Json_decode.field("name", Json_decode.string, json),
          creator: Json_decode.field("creator", (function (param) {
                  return Json_decode.optional(creatorDecoder, param);
                }), json),
          logo: Json_decode.field("logo", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          welcome: welcome,
          deletedWelcome: undefined,
          thankYouList: thankYous,
          deletedThankYouList: /* [] */0,
          questions: questions,
          deletedQuestions: /* [] */0,
          surveyTheme: Json_decode.field("survey_theme", SurveyTheme$ReactHooksTemplate.decode, json),
          updatedAt: convertDateToMoment(Json_decode.withDefault(undefined, (function (param) {
                      return Json_decode.field("updated_at", (function (param) {
                                    return Json_decode.optional(Json_decode.string, param);
                                  }), param);
                    }), json)),
          logics: Json_decode.field("logics", (function (param) {
                  return Json_decode.list((function (json) {
                                return Logic$ReactHooksTemplate.decode(json, questions, welcome, thankYous);
                              }), param);
                }), json)
        };
}

function toJson(t) {
  var questionAttributes = Belt_List.toArray(Belt_List.concat(Belt_List.mapWithIndex(t.questions, (function (i, x) {
                  return Question$ReactHooksTemplate.toJson(undefined, i + 1 | 0, undefined, x);
                })), Belt_List.mapWithIndex(t.deletedQuestions, (function (i, x) {
                  return Question$ReactHooksTemplate.toJson(undefined, i + 1 | 0, true, x);
                }))));
  var thankyouAttributes = Belt_List.toArray(Belt_List.concat(Belt_List.mapWithIndex(t.thankYouList, (function (i, x) {
                  return ThankYou$ReactHooksTemplate.toJson(undefined, i + 1 | 0, x);
                })), Belt_List.mapWithIndex(t.deletedThankYouList, (function (i, x) {
                  return ThankYou$ReactHooksTemplate.toJson(true, i + 1 | 0, x);
                }))));
  var match = t.deletedWelcome;
  var match$1 = t.welcome;
  var welcomeAttributes;
  if (match$1 !== undefined) {
    welcomeAttributes = Json_encode.nullable((function (x) {
            return Welcome$ReactHooksTemplate.toJson(undefined, x);
          }), match$1);
  } else {
    var partial_arg = true;
    welcomeAttributes = Json_encode.nullable((function (param) {
            return Welcome$ReactHooksTemplate.toJson(partial_arg, param);
          }), match);
  }
  var logicsAttributes = Belt_List.toArray(Belt_List.map(t.logics, (function (x) {
              return Logic$ReactHooksTemplate.toJson(undefined, x);
            })));
  return Json_encode.object_({
              hd: [
                "id",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), t.id)
              ],
              tl: {
                hd: [
                  "name",
                  t.name
                ],
                tl: {
                  hd: [
                    "questions_attributes",
                    questionAttributes
                  ],
                  tl: {
                    hd: [
                      "welcome_attributes",
                      welcomeAttributes
                    ],
                    tl: {
                      hd: [
                        "thank_yous_attributes",
                        thankyouAttributes
                      ],
                      tl: {
                        hd: [
                          "logics_attributes",
                          logicsAttributes
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function emptyLogicCondition(survey, trigger) {
  if (trigger.TAG !== /* Welcome */0) {
    return LogicCondition$ReactHooksTemplate.emptyCondition(undefined, trigger._0);
  }
  var firstQuestion = List.hd(survey.questions);
  return LogicCondition$ReactHooksTemplate.emptyCondition(undefined, firstQuestion);
}

function emptyAction(survey, trigger) {
  var lastQuestion = List.hd(List.rev(survey.questions));
  if (trigger.TAG === /* Welcome */0) {
    var firstQuestion = List.hd(survey.questions);
    return Action$ReactHooksTemplate.emptyAction({
                TAG: /* Question */0,
                _0: firstQuestion
              });
  }
  var question = trigger._0;
  if (Caml_obj.caml_equal(lastQuestion, question)) {
    var firstThankYou = List.hd(survey.thankYouList);
    return Action$ReactHooksTemplate.emptyAction({
                TAG: /* ThankYou */1,
                _0: firstThankYou
              });
  }
  var getNextQuestion = Belt_List.keep(survey.questions, (function (n) {
          return Belt_Option.getExn(Question$ReactHooksTemplate.id(n)) === (Belt_Option.getExn(Question$ReactHooksTemplate.id(question)) + 1 | 0);
        }));
  var nextQuestion = List.hd(getNextQuestion);
  return Action$ReactHooksTemplate.emptyAction({
              TAG: /* Question */0,
              _0: nextQuestion
            });
}

function emptyLogic(t, priority, question) {
  return Logic$ReactHooksTemplate.make({
              TAG: /* Question */1,
              _0: question
            }, {
              hd: emptyLogicCondition(t, {
                    TAG: /* Question */1,
                    _0: question
                  }),
              tl: /* [] */0
            }, priority, {
              hd: emptyAction(t, {
                    TAG: /* Question */1,
                    _0: question
                  }),
              tl: /* [] */0
            });
}

exports.empty = empty;
exports.id = id;
exports.name = name;
exports.logo = logo;
exports.welcome = welcome;
exports.deletedWelcome = deletedWelcome;
exports.thankYouList = thankYouList;
exports.deletedThankYouList = deletedThankYouList;
exports.questions = questions;
exports.updatedAt = updatedAt;
exports.deletedQuestions = deletedQuestions;
exports.surveyTheme = surveyTheme;
exports.logics = logics;
exports.setName = setName;
exports.setWelcome = setWelcome;
exports.setDeletedWelcome = setDeletedWelcome;
exports.setQuestions = setQuestions;
exports.setDeletedQuestions = setDeletedQuestions;
exports.setThankYouList = setThankYouList;
exports.setDeletedThankYouList = setDeletedThankYouList;
exports.setLogo = setLogo;
exports.setSurveyTheme = setSurveyTheme;
exports.setLogics = setLogics;
exports.fromJson = fromJson;
exports.decodeThankyouList = decodeThankyouList;
exports.toJson = toJson;
exports.emptyLogic = emptyLogic;
/* MomentRe Not a pure module */
