// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function font(t) {
  return t.font;
}

function questionColor(t) {
  return t.questionColor;
}

function answerColor(t) {
  return t.answerColor;
}

function backButtonColor(t) {
  return t.backButtonColor;
}

function nextButtonColor(t) {
  return t.nextButtonColor;
}

function backgroundColor(t) {
  return t.backgroundColor;
}

function logoAlignment(t) {
  return t.logoAlignment;
}

function empty(param) {
  return {
          id: undefined,
          name: "",
          default: false,
          font: "",
          questionColor: "",
          answerColor: "",
          backButtonColor: "",
          nextButtonColor: "",
          backgroundColor: "",
          logoAlignment: undefined
        };
}

function decode(json) {
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          name: Json_decode.field("name", Json_decode.string, json),
          default: Json_decode.field("default", Json_decode.bool, json),
          font: Json_decode.field("font", Json_decode.string, json),
          questionColor: Json_decode.field("colors", (function (param) {
                  return Json_decode.field("question", Json_decode.string, param);
                }), json),
          answerColor: Json_decode.field("colors", (function (param) {
                  return Json_decode.field("answer", Json_decode.string, param);
                }), json),
          backButtonColor: Json_decode.field("colors", (function (param) {
                  return Json_decode.field("backButton", Json_decode.string, param);
                }), json),
          nextButtonColor: Json_decode.field("colors", (function (param) {
                  return Json_decode.field("nextButton", Json_decode.string, param);
                }), json),
          backgroundColor: Json_decode.field("colors", (function (param) {
                  return Json_decode.field("background", Json_decode.string, param);
                }), json),
          logoAlignment: Json_decode.optional((function (param) {
                  return Json_decode.field("logo_properties", (function (param) {
                                return Json_decode.field("alignment", Json_decode.string, param);
                              }), param);
                }), json)
        };
}

exports.empty = empty;
exports.id = id;
exports.name = name;
exports.font = font;
exports.questionColor = questionColor;
exports.answerColor = answerColor;
exports.backButtonColor = backButtonColor;
exports.nextButtonColor = nextButtonColor;
exports.backgroundColor = backgroundColor;
exports.logoAlignment = logoAlignment;
exports.decode = decode;
/* No side effect */
