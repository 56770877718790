// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Querystringify = require("querystringify");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");
var ShortText$ReactHooksTemplate = require("./ShortText.bs.js");
var LogicGraph$ReactHooksTemplate = require("./LogicGraph.bs.js");
var StarRating$ReactHooksTemplate = require("./StarRating.bs.js");
var LogicalJump$ReactHooksTemplate = require("./LogicalJump.bs.js");
var RatingGroup$ReactHooksTemplate = require("./RatingGroup.bs.js");
var SurveyLogic$ReactHooksTemplate = require("./SurveyLogic.bs.js");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");
var CustomerInfo$ReactHooksTemplate = require("./CustomerInfo.bs.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var SmileyRating$ReactHooksTemplate = require("./smileyRating.bs.js");
var MultipleSelect$ReactHooksTemplate = require("./MultipleSelect.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var OpinionScaleLabel$ReactHooksTemplate = require("./OpinionScaleLabel.bs.js");
var ApplicationSettings$ReactHooksTemplate = require("../general_settings/applicationSettings.bs.js");
var SurveyPreviewHtmlRenderer$ReactHooksTemplate = require("./SurveyPreviewHtmlRenderer.bs.js");

var defaultQuestiontemplate = "\n  <div class=\"h-full min-h-screen\">\n    <div class=\"max-w-840-px mx-auto px-40-px small:px-20-px pt-32-px small:pt-0\n    small:pb-80-px\">\n      <div class=\"h-84-px small:h-64-px py-16-px\">\n        <div id=\"logo\">\n          logo\n        </div>\n      </div>\n      <div class=\"pt-24-px small:pt-16-px pb-54-px\"\n      style=\"min-height: calc(100vh - 294px);\">\n        <div id=\"question\">question</div>\n      </div>\n    </div>\n    <div class=\"w-full small:fixed small:bottom-0 bg-white small:bg-opacity-75\">\n      <div class=\"flex max-w-840-px mx-auto px-40-px small:px-20-px small:pt-16-px items-center pb-100-px small:pb-16-px\">\n        <div id=\"previousButton\"></div>\n        <div id=\"nextButton\"></div>\n      </div>\n    </div>\n  </div>\n";

var bhimaQuestiontemplate = "\n  <div class=\"h-full\">\n    <div class=\"pt-40-px w-full pb-20-px absolute\">\n      <div id=\"logo\">\n        logo\n      </div>\n    </div>\n    <div class=\"max-w-840-px mx-auto px-60-px small:px-20-px min-h-screen flex flex-col\n    justify-center pt-160-px small:pt-142-px\">\n\n      <div class=\"pb-100-px\">\n        <div id=\"question\">question</div>\n      </div>\n      <div class=\"flex justify-center items-center mb-8\">\n        <div id=\"previousButton\"></div>\n        <div id=\"nextButton\"></div>\n      </div>\n    </div>\n  </div>\n";

var questionTemplates = {
  bhima: bhimaQuestiontemplate,
  default: defaultQuestiontemplate
};

function questionsOrPageToString(x) {
  switch (x) {
    case /* Welcome */0 :
        return "welcome";
    case /* ThankYou */1 :
        return "thank_you";
    case /* LongText */2 :
        return "LongText";
    case /* ShortText */3 :
        return "ShortText";
    case /* StarRating */4 :
        return "StarRating";
    case /* OpinionScale */5 :
        return "OpinionScale";
    case /* Nps */6 :
        return "Nps";
    case /* MultipleChoice */7 :
        return "MultipleChoice";
    case /* RatingGroup */8 :
        return "RatingGroup";
    case /* SmileyRating */9 :
        return "Csat";
    case /* MultipleSelect */10 :
        return "MultipleSelect";
    case /* DropDown */11 :
        return "DropDown";
    case /* MobileNumber */12 :
        return "MobileNumber";
    
  }
}

function surveyToJson(welcome, questions, thankYou, logicalJumpGroup) {
  return Json_encode.object_({
              hd: [
                "welcome",
                Belt_Option.mapWithDefault(welcome, null, (function (x) {
                        return Welcome$ReactHooksTemplate.toJson(undefined, x);
                      }))
              ],
              tl: {
                hd: [
                  "questions",
                  Belt_List.toArray(Belt_List.mapWithIndex(questions, (function (i, q) {
                              return Question$ReactHooksTemplate.toJson(undefined, i + 1 | 0, undefined, q);
                            })))
                ],
                tl: {
                  hd: [
                    "thank_you",
                    Belt_Option.mapWithDefault(thankYou, null, (function (x) {
                            return ThankYou$ReactHooksTemplate.toJson(undefined, 1, x);
                          }))
                  ],
                  tl: {
                    hd: [
                      "logic_jump",
                      LogicalJump$ReactHooksTemplate.encodeGroup(logicalJumpGroup)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function addQuestionsToGraph(questions) {
  return List.fold_left((function (graph, item) {
                return LogicGraph$ReactHooksTemplate.addNode({
                            TAG: /* Question */1,
                            _0: item
                          }, graph);
              }), LogicGraph$ReactHooksTemplate.empty(undefined), questions);
}

function surveyAnswers(questions) {
  return $$Array.of_list(Belt_List.mapWithIndex(questions, (function (i, q) {
                    return Question$ReactHooksTemplate.toJson(true, i + 1 | 0, undefined, q);
                  })));
}

function submitQuestionResponses(completedOpt, state) {
  var completed = completedOpt !== undefined ? completedOpt : false;
  Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/user_response", Json_encode.object_({
            hd: [
              "token",
              state.id
            ],
            tl: {
              hd: [
                "responses",
                surveyAnswers(Belt_List.keep(state.questions, (function (q) {
                            return List.exists((function (x) {
                                          return Caml_obj.caml_equal(x, Question$ReactHooksTemplate.uuid(q));
                                        }), state.answeredQuestions);
                          })))
              ],
              tl: {
                hd: [
                  "language_code",
                  SurveyLanguage$ReactHooksTemplate.code(state.language)
                ],
                tl: {
                  hd: [
                    "completed",
                    completed
                  ],
                  tl: /* [] */0
                }
              }
            }
          }));
  
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* PreviewWelcome */0 :
          return {
                  id: state.id,
                  name: state.name,
                  logo: state.logo,
                  preview: state.preview,
                  welcome: state.welcome,
                  questions: state.questions,
                  thankYouList: state.thankYouList,
                  answeredQuestions: state.answeredQuestions,
                  currentPreview: {
                    TAG: /* Welcome */0,
                    _0: state.welcome
                  },
                  graph: state.graph,
                  customerInfo: state.customerInfo,
                  theme: state.theme,
                  client: state.client,
                  transactionStatus: state.transactionStatus,
                  surveyLanguages: state.surveyLanguages,
                  language: state.language,
                  applicationSettings: state.applicationSettings
                };
      case /* PreviewQuestions */1 :
          return {
                  id: state.id,
                  name: state.name,
                  logo: state.logo,
                  preview: state.preview,
                  welcome: state.welcome,
                  questions: state.questions,
                  thankYouList: state.thankYouList,
                  answeredQuestions: state.answeredQuestions,
                  currentPreview: {
                    TAG: /* QuestionPage */1,
                    _0: questionTemplates,
                    _1: Belt_List.head(state.questions),
                    _2: state.customerInfo
                  },
                  graph: state.graph,
                  customerInfo: state.customerInfo,
                  theme: state.theme,
                  client: state.client,
                  transactionStatus: state.transactionStatus,
                  surveyLanguages: state.surveyLanguages,
                  language: state.language,
                  applicationSettings: state.applicationSettings
                };
      case /* PreviewThankYou */2 :
          return {
                  id: state.id,
                  name: state.name,
                  logo: state.logo,
                  preview: state.preview,
                  welcome: state.welcome,
                  questions: state.questions,
                  thankYouList: state.thankYouList,
                  answeredQuestions: state.answeredQuestions,
                  currentPreview: {
                    TAG: /* ThankYou */2,
                    _0: Belt_List.head(state.thankYouList)
                  },
                  graph: state.graph,
                  customerInfo: state.customerInfo,
                  theme: state.theme,
                  client: state.client,
                  transactionStatus: state.transactionStatus,
                  surveyLanguages: state.surveyLanguages,
                  language: state.language,
                  applicationSettings: state.applicationSettings
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* Initialize */0 :
          var customerInfo = action._6;
          var thankYouList = action._5;
          var questions = action._4;
          var welcome = action._3;
          var name = action._1;
          var graph = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions, SurveyLogic$ReactHooksTemplate.changeEdges(name, questions, thankYouList, addQuestionsToGraph(questions)));
          return {
                  id: action._0,
                  name: name,
                  logo: action._2,
                  preview: state.preview,
                  welcome: welcome,
                  questions: questions,
                  thankYouList: thankYouList,
                  answeredQuestions: state.answeredQuestions,
                  currentPreview: welcome !== undefined ? ({
                        TAG: /* Welcome */0,
                        _0: welcome
                      }) : (
                      questions ? ({
                            TAG: /* QuestionPage */1,
                            _0: questionTemplates,
                            _1: Belt_List.head(questions),
                            _2: customerInfo
                          }) : ({
                            TAG: /* ThankYou */2,
                            _0: Belt_List.head(thankYouList)
                          })
                    ),
                  graph: graph,
                  customerInfo: customerInfo,
                  theme: action._7,
                  client: action._8,
                  transactionStatus: action._9,
                  surveyLanguages: action._10,
                  language: action._11,
                  applicationSettings: action._12
                };
      case /* UpdateQuestion */1 :
          var question = action._0;
          var questions$p = Belt_List.map(state.questions, (function (x) {
                  if (Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(x), Question$ReactHooksTemplate.uuid(question))) {
                    return question;
                  } else {
                    return x;
                  }
                }));
          var graph$p = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions$p, SurveyLogic$ReactHooksTemplate.changeEdges(state.name, questions$p, state.thankYouList, addQuestionsToGraph(questions$p)));
          var answeredQuestions = Question$ReactHooksTemplate.answered(question) && !List.exists((function (x) {
                  return Caml_obj.caml_equal(x, Question$ReactHooksTemplate.uuid(question));
                }), state.answeredQuestions) ? Belt_List.concat(state.answeredQuestions, {
                  hd: Question$ReactHooksTemplate.uuid(question),
                  tl: /* [] */0
                }) : state.answeredQuestions;
          return {
                  id: state.id,
                  name: state.name,
                  logo: state.logo,
                  preview: state.preview,
                  welcome: state.welcome,
                  questions: questions$p,
                  thankYouList: state.thankYouList,
                  answeredQuestions: answeredQuestions,
                  currentPreview: {
                    TAG: /* QuestionPage */1,
                    _0: questionTemplates,
                    _1: question,
                    _2: state.customerInfo
                  },
                  graph: graph$p,
                  customerInfo: state.customerInfo,
                  theme: state.theme,
                  client: state.client,
                  transactionStatus: state.transactionStatus,
                  surveyLanguages: state.surveyLanguages,
                  language: state.language,
                  applicationSettings: state.applicationSettings
                };
      case /* ChangeLanguage */2 :
          return {
                  id: state.id,
                  name: state.name,
                  logo: state.logo,
                  preview: state.preview,
                  welcome: state.welcome,
                  questions: state.questions,
                  thankYouList: state.thankYouList,
                  answeredQuestions: state.answeredQuestions,
                  currentPreview: state.currentPreview,
                  graph: state.graph,
                  customerInfo: state.customerInfo,
                  theme: state.theme,
                  client: state.client,
                  transactionStatus: state.transactionStatus,
                  surveyLanguages: state.surveyLanguages,
                  language: action._0,
                  applicationSettings: state.applicationSettings
                };
      case /* SetPage */3 :
          var page = action._0;
          if (typeof page === "number") {
            return state;
          }
          switch (page.TAG | 0) {
            case /* QuestionPage */1 :
                return {
                        id: state.id,
                        name: state.name,
                        logo: state.logo,
                        preview: state.preview,
                        welcome: state.welcome,
                        questions: state.questions,
                        thankYouList: state.thankYouList,
                        answeredQuestions: state.answeredQuestions,
                        currentPreview: page,
                        graph: SurveyLogic$ReactHooksTemplate.addLogicToEdges(state.questions, SurveyLogic$ReactHooksTemplate.changeEdges(state.name, state.questions, state.thankYouList, addQuestionsToGraph(state.questions))),
                        customerInfo: state.customerInfo,
                        theme: state.theme,
                        client: state.client,
                        transactionStatus: state.transactionStatus,
                        surveyLanguages: state.surveyLanguages,
                        language: state.language,
                        applicationSettings: state.applicationSettings
                      };
            case /* Welcome */0 :
            case /* ThankYou */2 :
                break;
            
          }
          return {
                  id: state.id,
                  name: state.name,
                  logo: state.logo,
                  preview: state.preview,
                  welcome: state.welcome,
                  questions: state.questions,
                  thankYouList: state.thankYouList,
                  answeredQuestions: state.answeredQuestions,
                  currentPreview: page,
                  graph: state.graph,
                  customerInfo: state.customerInfo,
                  theme: state.theme,
                  client: state.client,
                  transactionStatus: state.transactionStatus,
                  surveyLanguages: state.surveyLanguages,
                  language: state.language,
                  applicationSettings: state.applicationSettings
                };
      case /* NextPage */4 :
          var page$1 = action._0;
          if (typeof page$1 === "number") {
            return {
                    id: state.id,
                    name: state.name,
                    logo: state.logo,
                    preview: state.preview,
                    welcome: state.welcome,
                    questions: state.questions,
                    thankYouList: state.thankYouList,
                    answeredQuestions: state.answeredQuestions,
                    currentPreview: {
                      TAG: /* Welcome */0,
                      _0: state.welcome
                    },
                    graph: state.graph,
                    customerInfo: state.customerInfo,
                    theme: state.theme,
                    client: state.client,
                    transactionStatus: state.transactionStatus,
                    surveyLanguages: state.surveyLanguages,
                    language: state.language,
                    applicationSettings: state.applicationSettings
                  };
          }
          switch (page$1.TAG | 0) {
            case /* Welcome */0 :
                return {
                        id: state.id,
                        name: state.name,
                        logo: state.logo,
                        preview: state.preview,
                        welcome: state.welcome,
                        questions: state.questions,
                        thankYouList: state.thankYouList,
                        answeredQuestions: state.answeredQuestions,
                        currentPreview: {
                          TAG: /* QuestionPage */1,
                          _0: questionTemplates,
                          _1: Belt_List.head(state.questions),
                          _2: state.customerInfo
                        },
                        graph: state.graph,
                        customerInfo: state.customerInfo,
                        theme: state.theme,
                        client: state.client,
                        transactionStatus: state.transactionStatus,
                        surveyLanguages: state.surveyLanguages,
                        language: state.language,
                        applicationSettings: state.applicationSettings
                      };
            case /* QuestionPage */1 :
                var question$1 = page$1._1;
                if (question$1 === undefined) {
                  return state;
                }
                var match = LogicGraph$ReactHooksTemplate.getNextNode(question$1, state.graph);
                if (match !== undefined) {
                  if (match.TAG === /* ThankYou */0) {
                    if (state.preview) {
                      
                    } else {
                      submitQuestionResponses(true, state);
                    }
                    return {
                            id: state.id,
                            name: state.name,
                            logo: state.logo,
                            preview: state.preview,
                            welcome: state.welcome,
                            questions: state.questions,
                            thankYouList: state.thankYouList,
                            answeredQuestions: state.answeredQuestions,
                            currentPreview: {
                              TAG: /* ThankYou */2,
                              _0: Caml_option.some(match._0)
                            },
                            graph: state.graph,
                            customerInfo: state.customerInfo,
                            theme: state.theme,
                            client: state.client,
                            transactionStatus: state.transactionStatus,
                            surveyLanguages: state.surveyLanguages,
                            language: state.language,
                            applicationSettings: state.applicationSettings
                          };
                  }
                  var nextQuestion = match._0;
                  var saveResponse = !state.preview && ApplicationSettings$ReactHooksTemplate.savePartialResponse(state.applicationSettings) && Question$ReactHooksTemplate.answered(question$1);
                  if (saveResponse) {
                    submitQuestionResponses(false, state);
                  }
                  var x = Question$ReactHooksTemplate.questionType(nextQuestion);
                  var question$p;
                  switch (x.TAG | 0) {
                    case /* RatingGroup */6 :
                        var x$1 = x._0;
                        var id = RatingGroup$ReactHooksTemplate.carryForwardFrom(x$1);
                        var groupsFromQuestion;
                        if (id !== undefined) {
                          var id$1 = Caml_option.valFromOption(id);
                          groupsFromQuestion = List.find((function (y) {
                                  return Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(y), id$1);
                                }), state.questions);
                        } else {
                          groupsFromQuestion = nextQuestion;
                        }
                        var y = Question$ReactHooksTemplate.questionType(groupsFromQuestion);
                        if (y.TAG === /* MultipleSelect */9) {
                          var y$1 = y._0;
                          var choices = MultipleSelect$ReactHooksTemplate.getAnswer(y$1);
                          question$p = Question$ReactHooksTemplate.setQuestionType(nextQuestion, {
                                TAG: /* RatingGroup */6,
                                _0: RatingGroup$ReactHooksTemplate.setGroups(x$1, List.map((function (z) {
                                            return [
                                                    RandomId$ReactHooksTemplate.toString(MultipleSelect$ReactHooksTemplate.choiceId(z)),
                                                    MultipleSelect$ReactHooksTemplate.getChoiceText(z)
                                                  ];
                                          }), choices !== undefined ? choices : MultipleSelect$ReactHooksTemplate.choices(y$1)))
                              });
                        } else {
                          question$p = nextQuestion;
                        }
                        break;
                    case /* MultipleSelect */9 :
                        var x$2 = x._0;
                        var id$2 = MultipleSelect$ReactHooksTemplate.carryForwardFrom(x$2);
                        if (id$2 !== undefined) {
                          var id$3 = Caml_option.valFromOption(id$2);
                          var optionsFromQuestion = List.find((function (y) {
                                  return Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(y), id$3);
                                }), state.questions);
                          var y$2 = Question$ReactHooksTemplate.questionType(optionsFromQuestion);
                          if (y$2.TAG === /* MultipleSelect */9) {
                            var y$3 = y$2._0;
                            var choices$1 = MultipleSelect$ReactHooksTemplate.getAnswer(y$3);
                            question$p = Question$ReactHooksTemplate.setQuestionType(nextQuestion, {
                                  TAG: /* MultipleSelect */9,
                                  _0: MultipleSelect$ReactHooksTemplate.setChoices(choices$1 !== undefined ? List.filter(function (z) {
                                                return List.exists((function (b) {
                                                              return Caml_obj.caml_equal(MultipleSelect$ReactHooksTemplate.choiceId(z), MultipleSelect$ReactHooksTemplate.choiceId(b));
                                                            }), choices$1);
                                              })(MultipleSelect$ReactHooksTemplate.choices(y$3)) : MultipleSelect$ReactHooksTemplate.choices(y$3), x$2)
                                });
                          } else {
                            question$p = nextQuestion;
                          }
                        } else {
                          question$p = nextQuestion;
                        }
                        break;
                    default:
                      question$p = nextQuestion;
                  }
                  var questions$p$1 = Belt_List.map(state.questions, (function (x) {
                          if (Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(x), Question$ReactHooksTemplate.uuid(question$p))) {
                            return question$p;
                          } else {
                            return x;
                          }
                        }));
                  var graph$p$1 = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions$p$1, SurveyLogic$ReactHooksTemplate.changeEdges(state.name, questions$p$1, state.thankYouList, addQuestionsToGraph(questions$p$1)));
                  var answeredQuestions$1 = Question$ReactHooksTemplate.answered(question$p) && !List.exists((function (x) {
                          return Caml_obj.caml_equal(x, Question$ReactHooksTemplate.uuid(question$p));
                        }), state.answeredQuestions) ? Belt_List.concat(state.answeredQuestions, {
                          hd: Question$ReactHooksTemplate.uuid(question$p),
                          tl: /* [] */0
                        }) : state.answeredQuestions;
                  return {
                          id: state.id,
                          name: state.name,
                          logo: state.logo,
                          preview: state.preview,
                          welcome: state.welcome,
                          questions: questions$p$1,
                          thankYouList: state.thankYouList,
                          answeredQuestions: answeredQuestions$1,
                          currentPreview: {
                            TAG: /* QuestionPage */1,
                            _0: questionTemplates,
                            _1: question$p,
                            _2: state.customerInfo
                          },
                          graph: graph$p$1,
                          customerInfo: state.customerInfo,
                          theme: state.theme,
                          client: state.client,
                          transactionStatus: state.transactionStatus,
                          surveyLanguages: state.surveyLanguages,
                          language: state.language,
                          applicationSettings: state.applicationSettings
                        };
                }
                if (state.preview) {
                  
                } else {
                  submitQuestionResponses(true, state);
                }
                return {
                        id: state.id,
                        name: state.name,
                        logo: state.logo,
                        preview: state.preview,
                        welcome: state.welcome,
                        questions: state.questions,
                        thankYouList: state.thankYouList,
                        answeredQuestions: state.answeredQuestions,
                        currentPreview: {
                          TAG: /* ThankYou */2,
                          _0: Belt_List.head(state.thankYouList)
                        },
                        graph: state.graph,
                        customerInfo: state.customerInfo,
                        theme: state.theme,
                        client: state.client,
                        transactionStatus: state.transactionStatus,
                        surveyLanguages: state.surveyLanguages,
                        language: state.language,
                        applicationSettings: state.applicationSettings
                      };
            case /* ThankYou */2 :
                return state;
            
          }
      case /* PrevPage */5 :
          var page$2 = action._0;
          if (typeof page$2 === "number") {
            return {
                    id: state.id,
                    name: state.name,
                    logo: state.logo,
                    preview: state.preview,
                    welcome: state.welcome,
                    questions: state.questions,
                    thankYouList: state.thankYouList,
                    answeredQuestions: state.answeredQuestions,
                    currentPreview: {
                      TAG: /* Welcome */0,
                      _0: state.welcome
                    },
                    graph: state.graph,
                    customerInfo: state.customerInfo,
                    theme: state.theme,
                    client: state.client,
                    transactionStatus: state.transactionStatus,
                    surveyLanguages: state.surveyLanguages,
                    language: state.language,
                    applicationSettings: state.applicationSettings
                  };
          }
          switch (page$2.TAG | 0) {
            case /* QuestionPage */1 :
                var question$2 = page$2._1;
                if (question$2 === undefined) {
                  return state;
                }
                var match$1 = LogicGraph$ReactHooksTemplate.getPrevNode(question$2, state.graph);
                if (match$1 !== undefined && match$1.TAG !== /* ThankYou */0) {
                  return {
                          id: state.id,
                          name: state.name,
                          logo: state.logo,
                          preview: state.preview,
                          welcome: state.welcome,
                          questions: state.questions,
                          thankYouList: state.thankYouList,
                          answeredQuestions: Belt_List.keep(state.answeredQuestions, (function (x) {
                                  return Caml_obj.caml_notequal(x, Question$ReactHooksTemplate.uuid(question$2));
                                })),
                          currentPreview: {
                            TAG: /* QuestionPage */1,
                            _0: questionTemplates,
                            _1: match$1._0,
                            _2: state.customerInfo
                          },
                          graph: SurveyLogic$ReactHooksTemplate.addLogicToEdges(state.questions, SurveyLogic$ReactHooksTemplate.changeEdges(state.name, state.questions, state.thankYouList, addQuestionsToGraph(state.questions))),
                          customerInfo: state.customerInfo,
                          theme: state.theme,
                          client: state.client,
                          transactionStatus: state.transactionStatus,
                          surveyLanguages: state.surveyLanguages,
                          language: state.language,
                          applicationSettings: state.applicationSettings
                        };
                }
                return {
                        id: state.id,
                        name: state.name,
                        logo: state.logo,
                        preview: state.preview,
                        welcome: state.welcome,
                        questions: state.questions,
                        thankYouList: state.thankYouList,
                        answeredQuestions: Belt_List.keep(state.answeredQuestions, (function (x) {
                                return Caml_obj.caml_notequal(x, Question$ReactHooksTemplate.uuid(question$2));
                              })),
                        currentPreview: {
                          TAG: /* Welcome */0,
                          _0: state.welcome
                        },
                        graph: state.graph,
                        customerInfo: state.customerInfo,
                        theme: state.theme,
                        client: state.client,
                        transactionStatus: state.transactionStatus,
                        surveyLanguages: state.surveyLanguages,
                        language: state.language,
                        applicationSettings: state.applicationSettings
                      };
                break;
            case /* Welcome */0 :
            case /* ThankYou */2 :
                return state;
            
          }
          break;
      
    }
  }
}

function defaultWelcome(param) {
  return Welcome$ReactHooksTemplate.make("Your opinion matters!", "Rate our products and services.", "Take the survey", param);
}

var defaultThankYou = ThankYou$ReactHooksTemplate.make("Thank you for taking the time to give your valuable feedback!");

function defaultOpinionScale(param) {
  var arg = OpinionScaleLabel$ReactHooksTemplate.make("Left", "Center", "Right");
  var arg$1 = RandomId$ReactHooksTemplate.generate(undefined);
  return function (param) {
    return function (param$1) {
      return OpinionScale$ReactHooksTemplate.make("Likely to recommend", "NPS Score", param, param$1, arg, arg$1);
    };
  };
}

function defaultStarRating(param) {
  return StarRating$ReactHooksTemplate.make("Star Rating", "Rate our services", RandomId$ReactHooksTemplate.generate(undefined));
}

function defaultLongText(param) {
  var arg = RandomId$ReactHooksTemplate.generate(undefined);
  return function (param) {
    return function (param$1) {
      return LongText$ReactHooksTemplate.make("Long Text Question", "Share the reasons for your rating", param, param$1, arg);
    };
  };
}

function defaultShortText(param) {
  var arg = RandomId$ReactHooksTemplate.generate(undefined);
  return function (param) {
    return function (param$1) {
      return ShortText$ReactHooksTemplate.make("Short Text Question", "Enter Short Text..", param, param$1, arg);
    };
  };
}

function saveForm(surveyId, welcome, questions, thankYou, logicalJumpGroup) {
  var params = {};
  params["survey"] = surveyToJson(welcome, questions, thankYou, logicalJumpGroup);
  return Http$ReactHooksTemplate.fetchPostJSON("/api/v1/surveys/" + surveyId + "/bulk_create", params);
}

function SurveyView(Props) {
  var survey = Props.survey;
  var client = Props.client;
  var transactionStatus = Props.transactionStatus;
  var surveyLanguages = Props.surveyLanguages;
  var language = Props.language;
  var customerInfo = Props.customerInfo;
  var preview = Props.preview;
  var paramsOpt = Props.params;
  var token = Props.token;
  var applicationSettings = Props.applicationSettings;
  var showPreviewOpt = Props.showPreview;
  var params = paramsOpt !== undefined ? paramsOpt : "";
  var showPreview = showPreviewOpt !== undefined ? showPreviewOpt : false;
  var params$1 = Querystringify.parse(params);
  var emptyState_graph = LogicGraph$ReactHooksTemplate.empty(undefined);
  var emptyState_customerInfo = CustomerInfo$ReactHooksTemplate.empty(undefined);
  var emptyState_theme = SurveyTheme$ReactHooksTemplate.empty(undefined);
  var emptyState_client = Organization$ReactHooksTemplate.empty(undefined);
  var emptyState_applicationSettings = ApplicationSettings$ReactHooksTemplate.empty(undefined);
  var emptyState = {
    id: token,
    name: "",
    logo: undefined,
    preview: preview,
    welcome: undefined,
    questions: /* [] */0,
    thankYouList: /* [] */0,
    answeredQuestions: /* [] */0,
    currentPreview: /* Empty */0,
    graph: emptyState_graph,
    customerInfo: emptyState_customerInfo,
    theme: emptyState_theme,
    client: emptyState_client,
    transactionStatus: "",
    surveyLanguages: /* [] */0,
    language: SurveyLanguage$ReactHooksTemplate.$$default,
    applicationSettings: emptyState_applicationSettings
  };
  var match = React.useReducer(reducer, emptyState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return /* Loading */0;
      });
  var setMode = match$1[1];
  var x = Js_dict.get(params$1, "ltr");
  var nps = x !== undefined ? x : Js_dict.get(params$1, "smiley_rating");
  React.useEffect((function () {
          Curry._1(dispatch, {
                TAG: /* Initialize */0,
                _0: token,
                _1: Survey$ReactHooksTemplate.name(survey),
                _2: Survey$ReactHooksTemplate.logo(survey),
                _3: Survey$ReactHooksTemplate.welcome(survey),
                _4: Survey$ReactHooksTemplate.questions(survey),
                _5: Survey$ReactHooksTemplate.thankYouList(survey),
                _6: customerInfo,
                _7: Survey$ReactHooksTemplate.surveyTheme(survey),
                _8: client,
                _9: transactionStatus,
                _10: surveyLanguages,
                _11: language,
                _12: applicationSettings
              });
          
        }), [
        survey,
        showPreview
      ]);
  React.useEffect((function () {
          switch (transactionStatus) {
            case "closed" :
            case "completed" :
                RescriptReactRouter.push("/surveys/410");
                break;
            default:
              var exit = 0;
              if (nps !== undefined && nps !== "") {
                var firstQuestion = Belt_List.head(Survey$ReactHooksTemplate.questions(survey));
                if (firstQuestion !== undefined) {
                  var questionType = Question$ReactHooksTemplate.questionType(firstQuestion);
                  switch (questionType.TAG | 0) {
                    case /* Nps */4 :
                        var nps$1 = questionType._0;
                        var answer = Caml_format.caml_float_of_string(nps) | 0;
                        if (answer >= 0 && answer < 11) {
                          var updatedNps = Nps$ReactHooksTemplate.answered(nps$1) && Nps$ReactHooksTemplate.answerToString(nps$1) === String(answer) ? Nps$ReactHooksTemplate.setAnswer(Nps$ReactHooksTemplate.setAnswer(nps$1, answer), answer) : Nps$ReactHooksTemplate.setAnswer(nps$1, answer);
                          var question = Question$ReactHooksTemplate.setQuestionType(firstQuestion, {
                                TAG: /* Nps */4,
                                _0: updatedNps
                              });
                          Curry._1(dispatch, {
                                TAG: /* UpdateQuestion */1,
                                _0: question
                              });
                          Curry._1(dispatch, {
                                TAG: /* NextPage */4,
                                _0: {
                                  TAG: /* QuestionPage */1,
                                  _0: questionTemplates,
                                  _1: question,
                                  _2: state.customerInfo
                                }
                              });
                        } else {
                          Curry._1(dispatch, /* PreviewQuestions */1);
                        }
                        break;
                    case /* SmileyRating */7 :
                        var smileyRating = questionType._0;
                        var answer$1 = List.find_opt((function (x) {
                                return SmileyRating$ReactHooksTemplate.ratingTypeToRating(x) === Caml_format.caml_int_of_string(nps);
                              }), SmileyRating$ReactHooksTemplate.ratingTypeCollection(smileyRating));
                        if (answer$1 !== undefined) {
                          var updatedSmileyRating = SmileyRating$ReactHooksTemplate.setAnswer(smileyRating, answer$1);
                          var question$1 = Question$ReactHooksTemplate.setQuestionType(firstQuestion, {
                                TAG: /* SmileyRating */7,
                                _0: updatedSmileyRating
                              });
                          Curry._1(dispatch, {
                                TAG: /* UpdateQuestion */1,
                                _0: question$1
                              });
                          Curry._1(dispatch, {
                                TAG: /* NextPage */4,
                                _0: {
                                  TAG: /* QuestionPage */1,
                                  _0: questionTemplates,
                                  _1: question$1,
                                  _2: state.customerInfo
                                }
                              });
                        } else {
                          Curry._1(dispatch, /* PreviewQuestions */1);
                        }
                        break;
                    default:
                      Curry._1(dispatch, /* PreviewQuestions */1);
                  }
                }
                
              } else {
                exit = 1;
              }
              if (exit === 1) {
                var lastAnsweredQuestion = Belt_List.reduce(Survey$ReactHooksTemplate.questions(survey), undefined, (function (x, y) {
                        if (Question$ReactHooksTemplate.answered(y)) {
                          return y;
                        } else {
                          return x;
                        }
                      }));
                if (lastAnsweredQuestion !== undefined) {
                  Curry._1(dispatch, {
                        TAG: /* NextPage */4,
                        _0: {
                          TAG: /* QuestionPage */1,
                          _0: questionTemplates,
                          _1: lastAnsweredQuestion,
                          _2: customerInfo
                        }
                      });
                }
                
              }
              
          }
          Curry._1(setMode, (function (param) {
                  return /* Ready */1;
                }));
          
        }), []);
  var onNextButtonClick = function (param) {
    return Curry._1(dispatch, {
                TAG: /* NextPage */4,
                _0: state.currentPreview
              });
  };
  var onPrevButtonClick = function (param) {
    return Curry._1(dispatch, {
                TAG: /* PrevPage */5,
                _0: state.currentPreview
              });
  };
  var surveyPreview = function (type_) {
    var match = state.currentPreview;
    var page;
    page = typeof match === "number" || !(match.TAG === /* QuestionPage */1 && match._1 === undefined) ? state.currentPreview : ({
          TAG: /* ThankYou */2,
          _0: Belt_List.head(state.thankYouList)
        });
    var match$1 = state.currentPreview;
    var showPreviousButton;
    if (typeof match$1 === "number" || match$1.TAG !== /* QuestionPage */1) {
      showPreviousButton = false;
    } else {
      var q = match$1._1;
      showPreviousButton = q !== undefined ? LogicGraph$ReactHooksTemplate.getPrevNode(q, state.graph) !== undefined : false;
    }
    var match$2 = state.currentPreview;
    var showSubmitButton;
    if (typeof match$2 === "number" || match$2.TAG !== /* QuestionPage */1) {
      showSubmitButton = false;
    } else {
      var q$1 = match$2._1;
      if (q$1 !== undefined) {
        var match$3 = LogicGraph$ReactHooksTemplate.getEdges(q$1, state.graph);
        showSubmitButton = match$3 ? false : true;
      } else {
        showSubmitButton = false;
      }
    }
    var match$4 = state.currentPreview;
    var showNextButton;
    if (typeof match$4 === "number" || match$4.TAG !== /* QuestionPage */1) {
      showNextButton = false;
    } else {
      var q$2 = match$4._1;
      showNextButton = q$2 !== undefined ? Question$ReactHooksTemplate.answered(q$2) || !Question$ReactHooksTemplate.mandatory(q$2) : false;
    }
    return React.createElement(React.Fragment, undefined, React.createElement(SurveyPreviewHtmlRenderer$ReactHooksTemplate.make, {
                    page: page,
                    logo: state.logo,
                    theme: state.theme,
                    client: state.client,
                    onNextButtonClick: onNextButtonClick,
                    onPrevButtonClick: onPrevButtonClick,
                    handleQuestionPreview: (function (y) {
                        Curry._1(dispatch, {
                              TAG: /* UpdateQuestion */1,
                              _0: y
                            });
                        var nps = Question$ReactHooksTemplate.questionType(y);
                        switch (nps.TAG | 0) {
                          case /* OpinionScale */0 :
                              var opinionScale = nps._0;
                              if (OpinionScale$ReactHooksTemplate.autoAdvance(opinionScale) && OpinionScale$ReactHooksTemplate.answered(opinionScale)) {
                                return Curry._1(dispatch, {
                                            TAG: /* NextPage */4,
                                            _0: page
                                          });
                              } else {
                                return ;
                              }
                          case /* Nps */4 :
                              var nps$1 = nps._0;
                              if (Nps$ReactHooksTemplate.autoAdvance(nps$1) && Nps$ReactHooksTemplate.answered(nps$1)) {
                                return Curry._1(dispatch, {
                                            TAG: /* NextPage */4,
                                            _0: page
                                          });
                              } else {
                                return ;
                              }
                          default:
                            return ;
                        }
                      }),
                    onLanguageChange: (function (e) {
                        var languageName = e.target.value;
                        var language = List.find((function (x) {
                                return SurveyLanguage$ReactHooksTemplate.name(x) === languageName;
                              }), state.surveyLanguages);
                        return Curry._1(dispatch, {
                                    TAG: /* ChangeLanguage */2,
                                    _0: language
                                  });
                      }),
                    handleStart: (function (param) {
                        return Curry._1(dispatch, /* PreviewQuestions */1);
                      }),
                    type_: type_,
                    showPreviousButton: showPreviousButton,
                    showNextButton: showNextButton,
                    showSubmitButton: showSubmitButton,
                    languageSelected: state.language,
                    surveyLanguages: state.surveyLanguages
                  }));
  };
  switch (match$1[0]) {
    case /* Loading */0 :
        return React.createElement("div", {
                    className: "h-screen w-full animated fadeIn"
                  }, React.createElement(Loading$ReactHooksTemplate.make, {
                        text: "Loading Survey..."
                      }));
    case /* Ready */1 :
        if (preview) {
          return React.createElement(React.Fragment, undefined, surveyPreview(/* Modal */1));
        } else {
          return React.createElement(React.Fragment, undefined, surveyPreview(/* Page */0));
        }
    case /* Saving */2 :
        return React.createElement("p", undefined, "Saving survey in progress...");
    
  }
}

var yesbankQuestiontemplate = "\n  <div class=\"flex flex-col h-full min-h-screen\">\n    <div class=\"h-80-px small:h-60-px mb-10 small:mb-12\">\n      <div id=\"logo\">\n        logo\n      </div>\n    </div>\n    <div class=\"mx-12 small:mx-4 flex flex-col\">\n      <div class=\"pl-142-px small:pl-4 pr-80-px small:pr-4 pt-24 small:pt-4 question-box-gradient rounded-lg mb-8 overflow-y-auto pb-16\n    small:pb-12\" style=\"height: calc(100vh - 250px);\">\n        <div id=\"question\">question</div>\n      </div>\n    </div>\n\n    <div class=\"flex justify-center items-center small:mb-8 mb-4\">\n      <div id=\"previousButton\"></div>\n      <div id=\"nextButton\"></div>\n    </div>\n  </div>\n";

var make = SurveyView;

exports.yesbankQuestiontemplate = yesbankQuestiontemplate;
exports.defaultQuestiontemplate = defaultQuestiontemplate;
exports.bhimaQuestiontemplate = bhimaQuestiontemplate;
exports.questionTemplates = questionTemplates;
exports.questionsOrPageToString = questionsOrPageToString;
exports.surveyToJson = surveyToJson;
exports.addQuestionsToGraph = addQuestionsToGraph;
exports.surveyAnswers = surveyAnswers;
exports.submitQuestionResponses = submitQuestionResponses;
exports.reducer = reducer;
exports.defaultWelcome = defaultWelcome;
exports.defaultThankYou = defaultThankYou;
exports.defaultOpinionScale = defaultOpinionScale;
exports.defaultStarRating = defaultStarRating;
exports.defaultLongText = defaultLongText;
exports.defaultShortText = defaultShortText;
exports.saveForm = saveForm;
exports.make = make;
/* defaultThankYou Not a pure module */
