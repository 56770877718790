// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Moment = require("moment");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Modal$ReactHooksTemplate = require("./Modal.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var TextInputNew$ReactHooksTemplate = require("../common/TextInputNew.bs.js");
var SurveyPreviewWrapper$ReactHooksTemplate = require("./SurveyPreviewWrapper.bs.js");

function decodeSurvey(json) {
  var partial_arg = Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, undefined);
  var partial_arg$1 = function (param) {
    return Json_decode.withDefault(partial_arg, Domain$ReactHooksTemplate.$$String.Email.decode, param);
  };
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          updatedAt: Moment(new Date(Json_decode.field("updated_at", Json_decode.string, json))),
          createdBy: Json_decode.field("creator", (function (param) {
                  return Json_decode.field("email", partial_arg$1, param);
                }), json)
        };
}

function SurveyCreate(Props) {
  var toggleSurveyCreate = Props.toggleSurveyCreate;
  var projectId = Props.projectId;
  var match = React.useState(function () {
        return Survey$ReactHooksTemplate.empty(undefined);
      });
  var setSurvey = match[1];
  var survey = match[0];
  var match$1 = React.useState(function () {
        return Organization$ReactHooksTemplate.empty(undefined);
      });
  var setClient = match$1[1];
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setSurveyTemplates = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setTemplatePage = match$3[1];
  var templatePage = match$3[0];
  var match$4 = React.useState(function () {
        return Survey$ReactHooksTemplate.empty(undefined);
      });
  var setSelectedTemplate = match$4[1];
  var selectedTemplate = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var setTemplate = match$5[1];
  var template = match$5[0];
  var match$6 = React.useState(function () {
        return false;
      });
  var setShowPreview = match$6[1];
  var showPreview = match$6[0];
  var match$7 = React.useState(function () {
        return "";
      });
  var setErrors = match$7[1];
  var togglePreview = function (param) {
    Curry._1(setShowPreview, (function (param) {
            return !showPreview;
          }));
    
  };
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/template_list", (function (param) {
                    return Json_decode.field("surveys", (function (param) {
                                  return Json_decode.list(Survey$ReactHooksTemplate.fromJson, param);
                                }), param);
                  })).then(function (surveys) {
                return Promise.resolve(Curry._1(setSurveyTemplates, (function (param) {
                                  return surveys;
                                })));
              });
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/organizations/0", (function (param) {
                    return Json_decode.field("organization", Organization$ReactHooksTemplate.decode, param);
                  })).then(function (organization) {
                return Promise.resolve(Curry._1(setClient, (function (param) {
                                  return organization;
                                })));
              });
          
        }), []);
  var randomBgColor = function (param) {
    return Belt_Array.getExn(Belt_Array.shuffle($$Array.of_list({
                        hd: "bg-lemonchifon",
                        tl: {
                          hd: "bg-azureishwhite",
                          tl: {
                            hd: "bg-azureishwhitesolid",
                            tl: {
                              hd: "bg-brightgray",
                              tl: /* [] */0
                            }
                          }
                        }
                      })), 0);
  };
  var chooseTemplate = function (template, preview) {
    if (preview === true) {
      Curry._1(setTemplate, (function (param) {
              return true;
            }));
      return Curry._1(setSelectedTemplate, (function (param) {
                    return template;
                  }));
    } else if (Survey$ReactHooksTemplate.id(selectedTemplate) === Survey$ReactHooksTemplate.id(template)) {
      Curry._1(setTemplate, (function (param) {
              return false;
            }));
      return Curry._1(setSelectedTemplate, (function (param) {
                    return Survey$ReactHooksTemplate.empty(undefined);
                  }));
    } else {
      Curry._1(setTemplate, (function (param) {
              return true;
            }));
      return Curry._1(setSelectedTemplate, (function (param) {
                    return template;
                  }));
    }
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Modal$ReactHooksTemplate.make, {
                  show: showPreview,
                  children: React.createElement("div", {
                        className: "w-screen bg-white pt-6"
                      }, React.createElement("div", {
                            className: ""
                          }, React.createElement(SurveyPreviewWrapper$ReactHooksTemplate.make, {
                                survey: selectedTemplate,
                                client: match$1[0],
                                showPreview: showPreview
                              }))),
                  handleClose: (function (param) {
                      return togglePreview(undefined);
                    })
                }), React.createElement("div", {
                  className: "flex flex-col justify-between h-full"
                }, templatePage ? React.createElement("div", {
                        className: "p-8 border-t"
                      }, React.createElement("div", {
                            className: "text-xl font-medium mb-8"
                          }, "Explore Survey Library"), React.createElement("div", {
                            className: "flex flex-wrap gap-4"
                          }, $$Array.of_list(Belt_List.map(match$2[0], (function (template) {
                                      return React.createElement("div", {
                                                  className: "rounded-lg border min-w-230-px " + (
                                                    Survey$ReactHooksTemplate.id(selectedTemplate) === template.id ? "border-bleudefrance" : "border-gray-300"
                                                  )
                                                }, React.createElement("div", {
                                                      className: "flex justify-center px-16 py-12 font-semibold text-base rounded-t-lg " + randomBgColor(undefined),
                                                      onClick: (function (param) {
                                                          return chooseTemplate(template, false);
                                                        })
                                                    }, template.name), React.createElement("div", {
                                                      className: "bg-white text-aurometalsaurus text-10-px p-4 rounded-b-lg flex justify-between"
                                                    }, React.createElement("div", {
                                                          className: "flex items-center"
                                                        }, "Last Modified on ", Belt_Option.getExn(template.updatedAt).format("ll")), React.createElement("img", {
                                                          className: "mr-1",
                                                          src: Image$ReactHooksTemplate.previewEye,
                                                          onClick: (function (param) {
                                                              chooseTemplate(template, true);
                                                              return togglePreview(undefined);
                                                            })
                                                        })));
                                    }))))) : React.createElement("div", {
                        className: "p-8 border-t"
                      }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                            label: "Survey Name",
                            value: Survey$ReactHooksTemplate.name(survey),
                            handleChange: (function (param) {
                                var name = param.target.value;
                                return Curry._1(setSurvey, (function (param) {
                                              return Survey$ReactHooksTemplate.setName(survey, name);
                                            }));
                              }),
                            maxLength: 100
                          }), Survey$ReactHooksTemplate.name(survey).length === 0 ? React.createElement("div", {
                              className: "my-2"
                            }, React.createElement("p", {
                                  className: "text-red-500 text-xs float-right"
                                }, "Name can't be empty")) : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                      className: "p-8 flex border-t border-gray-200 gap-3 font-medium\n                      absolute bottom-0 text-base w-full z-30 justify-end "
                    }, templatePage ? React.createElement(React.Fragment, undefined, React.createElement("button", {
                                className: "flex py-3 px-6 text-base font-semibold justify-center",
                                id: "close_modal",
                                onClick: (function (param) {
                                    return Curry._1(setTemplatePage, (function (param) {
                                                  return false;
                                                }));
                                  })
                              }, "Back"), React.createElement("button", {
                                className: "border border-gray-400\n                  flex rounded py-3 px-6 text-base font-semibold justify-center",
                                id: "create_from_scratch",
                                onClick: (function (param) {
                                    var params = {};
                                    params["survey"] = Survey$ReactHooksTemplate.toJson(survey);
                                    Http$ReactHooksTemplate.fetchPostJSON("/api/v1/projects/" + projectId + "/surveys", params).then(function (response) {
                                          var status = Json_decode.field("status", (function (param) {
                                                  return Json_decode.withDefault("failed", Json_decode.string, param);
                                                }), response);
                                          if (status === "success") {
                                            var createdSurvey = Json_decode.field("survey", Survey$ReactHooksTemplate.fromJson, response);
                                            var x = createdSurvey.id;
                                            if (x !== undefined) {
                                              RescriptReactRouter.push("/projects/" + (String(projectId) + ("/surveys/edit/" + String(x))));
                                            } else {
                                              Curry._1(toggleSurveyCreate, undefined);
                                            }
                                          } else {
                                            Curry._1(setErrors, (function (param) {
                                                    return "Something went wrong. Please contact admin";
                                                  }));
                                          }
                                          return Promise.resolve(undefined);
                                        });
                                    
                                  })
                              }, "Create from Scratch"), React.createElement("button", {
                                className: "bg-palatinateblue border border-gray-400\n                  flex rounded py-3 px-6 text-base font-semibold text-white justify-center " + (
                                  template ? "" : "disabled-input"
                                ),
                                id: "choose_template",
                                disabled: !template,
                                onClick: (function (param) {
                                    var templateId = Survey$ReactHooksTemplate.id(selectedTemplate);
                                    console.log("Testtt");
                                    var params = {};
                                    params["survey"] = Survey$ReactHooksTemplate.toJson(survey);
                                    params["template_id"] = String(Belt_Option.getExn(templateId));
                                    Http$ReactHooksTemplate.fetchPostJSON("/api/v1/projects/" + projectId + "/create_from_template", params).then(function (response) {
                                          var status = Json_decode.field("status", (function (param) {
                                                  return Json_decode.withDefault("failed", Json_decode.string, param);
                                                }), response);
                                          console.log(response);
                                          if (status === "success") {
                                            var createdSurvey = Json_decode.field("survey", Survey$ReactHooksTemplate.fromJson, response);
                                            var x = createdSurvey.id;
                                            if (x !== undefined) {
                                              RescriptReactRouter.push("/projects/" + (String(projectId) + ("/surveys/edit/" + String(x))));
                                            } else {
                                              Curry._1(toggleSurveyCreate, undefined);
                                            }
                                          } else {
                                            Curry._1(setErrors, (function (param) {
                                                    return "Something went wrong. Please contact admin";
                                                  }));
                                          }
                                          return Promise.resolve(undefined);
                                        });
                                    
                                  })
                              }, "Choose Template")) : React.createElement(React.Fragment, undefined, React.createElement("button", {
                                className: "border border-gray-400\n                flex rounded py-3 px-6 text-base font-semibold justify-center",
                                id: "close_modal",
                                onClick: (function (param) {
                                    return Curry._1(toggleSurveyCreate, undefined);
                                  })
                              }, "Discard"), React.createElement("button", {
                                className: "bg-palatinateblue border border-gray-400\n                  flex rounded py-3 px-6 text-base font-semibold text-white justify-center " + (
                                  Survey$ReactHooksTemplate.name(survey).length === 0 ? "disabled-input" : ""
                                ),
                                disabled: Survey$ReactHooksTemplate.name(survey).length === 0,
                                onClick: (function (param) {
                                    return Curry._1(setTemplatePage, (function (param) {
                                                  return true;
                                                }));
                                  })
                              }, "Next")))));
}

var make = SurveyCreate;

exports.decodeSurvey = decodeSurvey;
exports.make = make;
/* react Not a pure module */
