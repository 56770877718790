// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Image$ReactHooksTemplate = require("./image.bs.js");
var CheckboxInput$ReactHooksTemplate = require("./CheckboxInput.bs.js");

function MultiSelectDropDown(Props) {
  var defaultPlaceholder = Props.defaultPlaceholder;
  var options = Props.options;
  var selectedOptions = Props.selectedOptions;
  var handleChange = Props.handleChange;
  var match = React.useState(function () {
        return false;
      });
  var setToggleOptions = match[1];
  var toggleOptions = match[0];
  var handleOptionChange = function (option) {
    if (Belt_List.has(selectedOptions, option, (function (a, b) {
              return a === b;
            }))) {
      return Curry._1(handleChange, Belt_List.keep(selectedOptions, (function (x) {
                        return x !== option;
                      })));
    } else {
      return Curry._1(handleChange, Belt_List.concat(selectedOptions, {
                      hd: option,
                      tl: /* [] */0
                    }));
    }
  };
  return React.createElement("div", {
              className: "flex flex-col border pl-3 pr-1 py-2 bordergray-400\n                      w-3/5 bg-white rounded capitalize mr-6 text-sm multi-select-dropdown",
              tabIndex: 100,
              onFocus: (function (param) {
                  return Curry._1(setToggleOptions, (function (param) {
                                return true;
                              }));
                }),
              onBlur: (function (param) {
                  return Curry._1(setToggleOptions, (function (param) {
                                return false;
                              }));
                })
            }, React.createElement("div", {
                  className: "flex justify-between"
                }, React.createElement("div", {
                      className: "text-sm flex flex-wrap gap-2"
                    }, selectedOptions === /* [] */0 ? defaultPlaceholder : $$Array.of_list(Belt_List.map(selectedOptions, (function (option) {
                                  return React.createElement("div", {
                                              className: "flex gap-1 p-1 border border-gray-400 rounded"
                                            }, option, React.createElement("div", {
                                                  className: "flex items-center align-middle place-items-center",
                                                  onClick: (function (param) {
                                                      return handleOptionChange(option);
                                                    })
                                                }, React.createElement("img", {
                                                      className: "h-6-px",
                                                      src: Image$ReactHooksTemplate.closeIcon
                                                    })));
                                })))), React.createElement("div", {
                      className: "flex items-center align-middle place-items-center min-w-20-px",
                      onClick: (function (param) {
                          return Curry._1(setToggleOptions, (function (param) {
                                        return !toggleOptions;
                                      }));
                        })
                    }, toggleOptions ? React.createElement("img", {
                            className: "h-7-px",
                            src: Image$ReactHooksTemplate.chevronUp
                          }) : React.createElement("img", {
                            className: "h-7-px filter-gray-400",
                            src: Image$ReactHooksTemplate.chevronDown
                          }))), React.createElement("ul", {
                  className: "multi-select-dropdown-list " + (
                    toggleOptions ? "block" : "hidden"
                  )
                }, $$Array.of_list(Belt_List.map(options, (function (option) {
                            return React.createElement("li", {
                                        className: "multi-select-dropdown-option"
                                      }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                            checked: Belt_List.has(selectedOptions, option, (function (a, b) {
                                                    return a === b;
                                                  })),
                                            label: option,
                                            handleChange: (function (param) {
                                                return handleOptionChange(option);
                                              }),
                                            checkboxClassName: "custom_checkbox",
                                            labelClassName: "text-sm"
                                          }));
                          })))));
}

var make = MultiSelectDropDown;

exports.make = make;
/* react Not a pure module */
