// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Image$ReactHooksTemplate = require("./image.bs.js");

function makeOptionItems(options, selectedOptions) {
  return List.map((function (x) {
                return {
                        name: x,
                        selected: List.exists((function (y) {
                                return y === x;
                              }), selectedOptions)
                      };
              }), options);
}

function MultipleSelectBox(Props) {
  var options = Props.options;
  var placeholder = Props.placeholder;
  var handleChange = Props.handleChange;
  var selectedOptionsOpt = Props.selectedOptions;
  var classNameOpt = Props.className;
  var selectedOptions = selectedOptionsOpt !== undefined ? selectedOptionsOpt : /* [] */0;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var optionItems = makeOptionItems(options, selectedOptions);
  var match = React.useState(function () {
        return {
                open_: false
              };
      });
  var setState = match[1];
  var state = match[0];
  var domElement = React.useRef(null);
  var handleOutsideClick = function ($$event) {
    var targetElement = $$event.target;
    var selectBoxElement = Belt_Option.getExn(Caml_option.nullable_to_opt(domElement.current));
    if (!selectBoxElement.contains(targetElement)) {
      return Curry._1(setState, (function (param) {
                    return {
                            open_: false
                          };
                  }));
    }
    
  };
  React.useEffect((function () {
          document.addEventListener("mousedown", handleOutsideClick);
          return (function (param) {
                    document.removeEventListener("mousedown", handleOutsideClick);
                    
                  });
        }), []);
  var handleClick = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    return Curry._1(setState, (function (state) {
                  return {
                          open_: !state.open_
                        };
                }));
  };
  var listBoxCSS = state.open_ ? "block" : "hidden";
  var tmp;
  var exit = 0;
  if (placeholder !== undefined) {
    if (selectedOptions) {
      exit = 1;
    } else {
      tmp = placeholder;
    }
  } else if (selectedOptions) {
    exit = 1;
  } else {
    tmp = "";
  }
  if (exit === 1) {
    tmp = [
      React.createElement("p", {
            className: "bg-ghostwhite text-center px-3 rounded-2xl border border-gray-400 mr-2 mb-2"
          }, selectedOptions.hd),
      $$Array.of_list(List.map((function (x) {
                  return React.createElement("p", {
                              className: "bg-ghostwhite text-center px-3 rounded-2xl border border-gray-400 mr-2 mb-2"
                            }, x);
                }), selectedOptions.tl))
    ];
  }
  return React.createElement("div", {
              ref: domElement,
              className: "relative"
            }, React.createElement("div", {
                  className: "cursor-pointer " + className,
                  onClick: handleClick
                }, React.createElement("div", undefined, state.open_ ? React.createElement("img", {
                            className: "float-right py-2",
                            src: Image$ReactHooksTemplate.chevronUp
                          }) : React.createElement("img", {
                            className: "float-right py-2",
                            src: Image$ReactHooksTemplate.chevronDown
                          })), React.createElement("div", undefined, React.createElement("div", {
                          className: "text-sm"
                        }, React.createElement("div", {
                              className: "flex flex-wrap"
                            }, tmp)))), React.createElement("div", {
                  className: "flex flex-col border z-50 bg-white w-10/12 overflow-auto cursor-pointer rounded " + listBoxCSS,
                  style: {
                    maxHeight: "40vh",
                    position: "absolute"
                  }
                }, $$Array.of_list(Belt_List.mapWithIndex(optionItems, (function (i, x) {
                            return React.createElement("div", {
                                        key: String(i),
                                        className: x.selected ? "text-left text-white bg-palatinateblue px-4 py-1" : "hover:bg-gray-300 text-left px-4 py-1",
                                        value: x.name,
                                        onClick: (function (param) {
                                            return Curry._1(handleChange, x.name);
                                          })
                                      }, x.name);
                          })))));
}

var make = MultipleSelectBox;

exports.makeOptionItems = makeOptionItems;
exports.make = make;
/* react Not a pure module */
