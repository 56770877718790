// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Action$ReactHooksTemplate = require("./Action.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var LogicCondition$ReactHooksTemplate = require("./LogicCondition.bs.js");

function id(t) {
  return t.id;
}

function trigger(t) {
  return t.trigger;
}

function conditions(t) {
  return t.conditions;
}

function actions(t) {
  return t.actions;
}

function setTrigger(t, trigger) {
  return {
          id: t.id,
          trigger: trigger,
          priority: t.priority,
          conditions: t.conditions,
          actions: t.actions
        };
}

function setConditions(conditions, t) {
  return {
          id: t.id,
          trigger: t.trigger,
          priority: t.priority,
          conditions: conditions,
          actions: t.actions
        };
}

function setActions(actions, t) {
  return {
          id: t.id,
          trigger: t.trigger,
          priority: t.priority,
          conditions: t.conditions,
          actions: actions
        };
}

function make(trigger, conditions, priority, actions) {
  return {
          id: undefined,
          trigger: trigger,
          priority: priority,
          conditions: conditions,
          actions: actions
        };
}

function decodeTrigger(json, questions, welcome) {
  var triggerType = Json_decode.field("trigger_type", Json_decode.string, json);
  var triggerId = Json_decode.field("trigger_id", Json_decode.$$int, json);
  switch (triggerType) {
    case "Question" :
        return {
                TAG: /* Question */1,
                _0: List.find((function (n) {
                        return Belt_Option.getExn(Question$ReactHooksTemplate.id(n)) === triggerId;
                      }), questions)
              };
    case "Welcome" :
        return {
                TAG: /* Welcome */0,
                _0: Belt_Option.getExn(welcome)
              };
    default:
      return Js_exn.raiseError("Unknown trigger type");
  }
}

function decode(json, questions, welcome, thankYouList) {
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          trigger: decodeTrigger(json, questions, welcome),
          priority: Json_decode.field("priority", Json_decode.$$int, json),
          conditions: Json_decode.field("conditions", (function (param) {
                  return Json_decode.list((function (json) {
                                return LogicCondition$ReactHooksTemplate.decode(json, questions);
                              }), param);
                }), json),
          actions: Json_decode.field("actions", (function (param) {
                  return Json_decode.list((function (json) {
                                return Action$ReactHooksTemplate.decode(json, questions, thankYouList);
                              }), param);
                }), json)
        };
}

function availableOptionsForLhs(t, questions) {
  var xs = t.trigger;
  if (xs.TAG === /* Welcome */0) {
    return /* [] */0;
  }
  var xs$1 = xs._0;
  return List.flatten(List.mapi((function (index, x) {
                    if (Caml_obj.caml_equal(xs$1, x)) {
                      return Belt_Option.getExn(Belt_List.splitAt(questions, index + 1 | 0))[0];
                    } else {
                      return /* [] */0;
                    }
                  }), questions));
}

function toJson(destroyOpt, t) {
  var destroy = destroyOpt !== undefined ? destroyOpt : false;
  var match = t.trigger;
  var triggerType;
  triggerType = match.TAG === /* Welcome */0 ? "Welcome" : "Question";
  var x = t.trigger;
  var triggerId;
  triggerId = x.TAG === /* Welcome */0 ? Welcome$ReactHooksTemplate.id(x._0) : Question$ReactHooksTemplate.id(x._0);
  var encodedActions = Belt_List.toArray(Belt_List.map(t.actions, (function (x) {
              return Action$ReactHooksTemplate.toJson(undefined, x);
            })));
  var encodedConditions = Belt_List.toArray(Belt_List.map(t.conditions, (function (x) {
              return LogicCondition$ReactHooksTemplate.toJson(undefined, x);
            })));
  return Json_encode.object_({
              hd: [
                "id",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), t.id)
              ],
              tl: {
                hd: [
                  "priority",
                  t.priority
                ],
                tl: {
                  hd: [
                    "trigger_type",
                    triggerType
                  ],
                  tl: {
                    hd: [
                      "trigger_id",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), triggerId)
                    ],
                    tl: {
                      hd: [
                        "actions_attributes",
                        encodedActions
                      ],
                      tl: {
                        hd: [
                          "conditions_attributes",
                          encodedConditions
                        ],
                        tl: {
                          hd: [
                            "_destroy",
                            destroy
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

exports.id = id;
exports.trigger = trigger;
exports.conditions = conditions;
exports.actions = actions;
exports.setTrigger = setTrigger;
exports.setConditions = setConditions;
exports.setActions = setActions;
exports.make = make;
exports.decode = decode;
exports.availableOptionsForLhs = availableOptionsForLhs;
exports.toJson = toJson;
/* Action-ReactHooksTemplate Not a pure module */
