// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var RatingGroup$ReactHooksTemplate = require("./RatingGroup.bs.js");

function RatingGroupEditor(Props) {
  var ratingGroup = Props.ratingGroup;
  var onRatingGroupChange = Props.onRatingGroupChange;
  var compareRatingGroups = Belt_List.cmpByLength(RatingGroup$ReactHooksTemplate.nonEmptyGroups(ratingGroup), RatingGroup$ReactHooksTemplate.groups(ratingGroup));
  var hasInvalidChoice = compareRatingGroups !== 0;
  return React.createElement("div", undefined, React.createElement("button", {
                  className: "bg-ghostwhite border border-gray-400\n            flex rounded p-2 text-sm mt-0" + (
                    hasInvalidChoice ? " disabled-input" : " "
                  ),
                  disabled: hasInvalidChoice,
                  onClick: (function (param) {
                      return Curry._1(onRatingGroupChange, RatingGroup$ReactHooksTemplate.setGroups(ratingGroup, Belt_List.concat({
                                          hd: [
                                            RandomId$ReactHooksTemplate.toString(RandomId$ReactHooksTemplate.generate(undefined)),
                                            ""
                                          ],
                                          tl: /* [] */0
                                        }, RatingGroup$ReactHooksTemplate.groups(ratingGroup))));
                    })
                }, React.createElement("div", {
                      className: "flex items-center justify-between gap-1 text-darkcharcoal"
                    }, React.createElement("img", {
                          src: Image$ReactHooksTemplate.plusCircle
                        }), React.createElement("p", undefined, "Add option"))), React.createElement("div", {
                  className: "mb-7 mt-4"
                }, $$Array.of_list(List.mapi((function (i, param) {
                            var choice = param[1];
                            var groupId = param[0];
                            return React.createElement("div", undefined, React.createElement("div", {
                                            className: "mb-4"
                                          }, React.createElement("div", {
                                                className: "flex justify-between mb-2"
                                              }, React.createElement("div", {
                                                    className: "text-darkcharcoal text-sm font-medium"
                                                  }, "Label " + String(i + 1 | 0)), React.createElement("img", {
                                                    className: "h-12-px",
                                                    src: Image$ReactHooksTemplate.trash,
                                                    onClick: (function (param) {
                                                        return Curry._1(onRatingGroupChange, RatingGroup$ReactHooksTemplate.removeGroup(groupId, RatingGroup$ReactHooksTemplate.groups(ratingGroup), ratingGroup));
                                                      })
                                                  })), React.createElement("input", {
                                                className: choice === "" ? "-40-px py-2 px-4 rounded bg-white border border-red-500 focus:border-red-500 text-xs w-full" : "-40-px py-2 px-4 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                                                placeholder: "Enter Label " + String(i + 1 | 0),
                                                type: "text",
                                                value: choice,
                                                onChange: (function ($$event) {
                                                    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
                                                    return Curry._1(onRatingGroupChange, RatingGroup$ReactHooksTemplate.setGroups(ratingGroup, List.map((function (group) {
                                                                          var id = group[0];
                                                                          if (id === groupId) {
                                                                            return [
                                                                                    id,
                                                                                    value
                                                                                  ];
                                                                          } else {
                                                                            return group;
                                                                          }
                                                                        }), RatingGroup$ReactHooksTemplate.groups(ratingGroup))));
                                                  })
                                              }), React.createElement("p", {
                                                className: choice === "" ? "text-red-500 text-xs float-right" : "hidden"
                                              }, "Label can't be blank")));
                          }), List.rev(RatingGroup$ReactHooksTemplate.groups(ratingGroup))))));
}

var make = RatingGroupEditor;

exports.make = make;
/* react Not a pure module */
