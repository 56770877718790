// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Querystringify = require("querystringify");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Project$ReactHooksTemplate = require("./project.bs.js");
var SurveyList$ReactHooksTemplate = require("./SurveyList.bs.js");
var ProjectForm$ReactHooksTemplate = require("./ProjectForm.bs.js");
var ProjectList$ReactHooksTemplate = require("./ProjectList.bs.js");
var Notification$ReactHooksTemplate = require("../common/Notification.bs.js");

function fetchProjects(currentSearchKey) {
  var url = currentSearchKey !== undefined ? "/api/v1/projects/?search=" + Caml_option.valFromOption(currentSearchKey) : "/api/v1/projects/";
  return Http$ReactHooksTemplate.fetchGetJSONDecode(url, (function (param) {
                return Json_decode.field("projects", (function (param) {
                              return Json_decode.list(Project$ReactHooksTemplate.fromJson, param);
                            }), param);
              }));
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function Projects(Props) {
  var urlParams = Props.urlParams;
  var match = React.useState(function () {
        return /* GetSurveys */0;
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setSelectedProject = match$1[1];
  var selectedProject = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setNotification = match$2[1];
  var notification = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setAlert = match$3[1];
  var match$4 = React.useState(function () {
        return "";
      });
  var setErrors = match$4[1];
  var match$5 = React.useState(function () {
        return /* Create */0;
      });
  var setAction = match$5[1];
  var action = match$5[0];
  var match$6 = React.useState(function () {
        return false;
      });
  var setToggleProjectForm = match$6[1];
  var queryParams = Querystringify.parse(urlParams);
  var x = Js_dict.get(queryParams, "search");
  var currentSearchKey = x !== undefined && x !== "" ? x : undefined;
  var match$7 = React.useState(function () {
        return currentSearchKey;
      });
  var setSearchKey = match$7[1];
  var searchKey = match$7[0];
  var x$1 = Js_dict.get(queryParams, "selectedProjectId");
  var selectedProjectId = x$1 !== undefined && x$1 !== "" ? Caml_format.caml_int_of_string(x$1) : undefined;
  var handleSearch = function (param) {
    if (searchKey !== undefined) {
      return RescriptReactRouter.push("/projects/all?search=" + searchKey);
    } else {
      return RescriptReactRouter.push("/projects/all?");
    }
  };
  var toggleProjectCreate = function (param) {
    Curry._1(setAction, (function (param) {
            return /* Create */0;
          }));
    return Curry._1(setToggleProjectForm, (function (param) {
                  return true;
                }));
  };
  React.useEffect((function () {
          fetchProjects(currentSearchKey).then(function (projects) {
                Curry._1(setState, (function (param) {
                        return /* Ready */{
                                _0: projects
                              };
                      }));
                if (selectedProjectId !== undefined) {
                  var project = List.find_opt((function (x) {
                          return Caml_obj.caml_equal(Project$ReactHooksTemplate.id(x), selectedProjectId);
                        }), projects);
                  if (project !== undefined) {
                    Curry._1(setSelectedProject, (function (param) {
                            return project;
                          }));
                  } else {
                    var redirectUrl = searchKey !== undefined ? "/projects/all?search=" + searchKey : "/projects/all";
                    setTimeout((function (param) {
                            return RescriptReactRouter.push(redirectUrl);
                          }), 4000);
                    Curry._1(setAlert, (function (param) {
                            return true;
                          }));
                  }
                } else {
                  Curry._1(setSelectedProject, (function (param) {
                          return Belt_List.head(projects);
                        }));
                }
                return Promise.resolve(Curry._1(setSearchKey, (function (param) {
                                  return currentSearchKey;
                                })));
              });
          
        }), [
        action,
        currentSearchKey,
        selectedProjectId
      ]);
  var handelProjectSubmission = function (project) {
    var projectId = Project$ReactHooksTemplate.id(project);
    var params = {};
    params["project"] = Project$ReactHooksTemplate.toJson(project);
    var formSubmitUrl = action ? Http$ReactHooksTemplate.fetchPutJSON("/api/v1/projects/" + projectId, params) : Http$ReactHooksTemplate.fetchPostJSON("/api/v1/projects", params);
    formSubmitUrl.then(function (response) {
          var status = Json_decode.field("status", (function (param) {
                  return Json_decode.withDefault("failed", Json_decode.string, param);
                }), response);
          if (status === "success") {
            window.location.reload();
          } else {
            Curry._1(setErrors, (function (param) {
                    return "Something went wrong. Please contact admin";
                  }));
          }
          return Promise.resolve(undefined);
        });
    
  };
  if (state) {
    return React.createElement("div", {
                className: "min-h-screen"
              }, notification !== undefined ? React.createElement("div", {
                      className: "pt-1"
                    }, React.createElement(Notification$ReactHooksTemplate.make, {
                          text: notification[0],
                          isError: notification[1],
                          handleClose: (function (param) {
                              return Curry._1(setNotification, (function (param) {
                                            
                                          }));
                            })
                        })) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                    className: "flex min-h-screen"
                  }, React.createElement("div", {
                        className: "w-1/6 bg-ghostwhiteblue min-h-screen"
                      }, React.createElement(ProjectList$ReactHooksTemplate.make, {
                            searchKey: searchKey,
                            setSearchKey: setSearchKey,
                            handleSearch: handleSearch,
                            projects: state._0,
                            selectedProjectId: selectedProjectId,
                            setSelectedProject: setSelectedProject,
                            alert: match$3[0],
                            toggleProjectCreate: toggleProjectCreate
                          })), React.createElement("div", {
                        className: "w-5/6 min-h-screen"
                      }, selectedProject !== undefined ? React.createElement("div", {
                              className: "flex items-center border-b border-gray-300 px-30-px py-16-px justify-between"
                            }, React.createElement("div", {
                                  className: "flex flex-row text-xl font-semibold py-3-px space-x-1"
                                }, React.createElement("h2", undefined, Project$ReactHooksTemplate.name(selectedProject)), React.createElement("button", {
                                      onClick: (function (param) {
                                          Curry._1(setAction, (function (param) {
                                                  return /* Update */1;
                                                }));
                                          return Curry._1(setToggleProjectForm, (function (param) {
                                                        return true;
                                                      }));
                                        })
                                    }, React.createElement("img", {
                                          className: "h-14-px mr-2-px",
                                          src: Image$ReactHooksTemplate.edit
                                        })))) : React.createElement(React.Fragment, undefined), selectedProject !== undefined ? React.createElement(SurveyList$ReactHooksTemplate.make, {
                              selectedProject: selectedProject
                            }) : React.createElement(React.Fragment, undefined))), match$6[0] ? React.createElement("div", {
                      className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
                      style: {
                        height: "calc(100vh + 5rem)"
                      }
                    }, React.createElement("div", {
                          className: "bg-white modal-project z-10 shadow relative border border-gray-300 pointer-events-auto"
                        }, React.createElement("div", {
                              className: "p-8 text-lg font-bold flex justify-between"
                            }, action ? React.createElement("div", undefined, "Update Project") : React.createElement("div", undefined, "Create New Project"), React.createElement("img", {
                                  className: "h-14-px float-right",
                                  src: Image$ReactHooksTemplate.closeIcon,
                                  onClick: (function (param) {
                                      return Curry._1(setToggleProjectForm, (function (param) {
                                                    return false;
                                                  }));
                                    })
                                })), React.createElement("div", {
                              className: "flex flex-col justify-between h-full"
                            }, React.createElement(ProjectForm$ReactHooksTemplate.make, {
                                  toggleProjectForm: (function (param) {
                                      return Curry._1(setToggleProjectForm, (function (param) {
                                                    return false;
                                                  }));
                                    }),
                                  selectedProject: action ? Belt_Option.getExn(selectedProject) : Project$ReactHooksTemplate.empty(undefined),
                                  onSubmit: handelProjectSubmission
                                })))) : React.createElement(React.Fragment, undefined));
  } else {
    return React.createElement(React.Fragment, undefined, "getting surveys");
  }
}

var emptyPageInfo = {
  total: "1",
  per_page: "1"
};

var make = Projects;

exports.fetchProjects = fetchProjects;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.emptyPageInfo = emptyPageInfo;
exports.make = make;
/* react Not a pure module */
