// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var React = require("react");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var TableAttribute$ReactHooksTemplate = require("./tableAttribute.bs.js");

function TableComponent(Props) {
  var attribute = Props.attribute;
  var chartHeight = Props.chartHeight;
  return React.createElement("div", {
              className: " " + chartHeight + " w-full overflow-y-auto "
            }, React.createElement("table", {
                  className: "w-full h-full text-center border-collapse\n            table-fixed px-2 pb-2"
                }, React.createElement("thead", {
                      className: "bg-gray-200 h-10 border rounded text-sm sticky top-0"
                    }, $$Array.of_list(List.map((function (x) {
                                return React.createElement("th", {
                                            className: "p-10-px sticky top-0"
                                          }, React.createElement("div", {
                                                className: "flex justify-center"
                                              }, React.createElement("p", undefined, x), React.createElement("img", {
                                                    className: "h-16-px mt-2-px ml-4-px",
                                                    src: Image$ReactHooksTemplate.heading
                                                  })));
                              }), TableAttribute$ReactHooksTemplate.headers(attribute)))), React.createElement("tbody", {
                      className: "font-semibold text-16-px"
                    }, $$Array.of_list(List.map((function (xs) {
                                return React.createElement("tr", {
                                            className: "border rounded border-gray-200"
                                          }, $$Array.of_list(List.map((function (x) {
                                                      return React.createElement("td", {
                                                                  className: "p-10-px"
                                                                }, React.createElement("p", undefined, x));
                                                    }), xs)));
                              }), TableAttribute$ReactHooksTemplate.rows(attribute))))));
}

var make = TableComponent;

exports.make = make;
/* react Not a pure module */
