// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Recharts = require("recharts");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Querystringify = require("querystringify");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var User$ReactHooksTemplate = require("./user.bs.js");
var Recharts$ReactHooksTemplate = require("../dashboard/common/Recharts.bs.js");
var Pagination$ReactHooksTemplate = require("../common/Pagination.bs.js");
var SearchForm$ReactHooksTemplate = require("../surveys/SearchForm.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var UserListItem$ReactHooksTemplate = require("./UserListItem.bs.js");
var UserListHeader$ReactHooksTemplate = require("./UserListHeader.bs.js");
var OverlayClosable$ReactHooksTemplate = require("../common/OverlayClosable.bs.js");
var ConfirmationDialog$ReactHooksTemplate = require("./ConfirmationDialog.bs.js");

function deleteUser(user) {
  var userId = String(User$ReactHooksTemplate.id(user));
  return Http$ReactHooksTemplate.fetchDeleteJSON("/api/v1/users/" + userId);
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* LoadUsers */0 :
          return {
                  users: state.users,
                  userToDelete: state.userToDelete,
                  statusCount: state.statusCount,
                  loadingUsers: true,
                  loadingStatusCount: state.loadingStatusCount
                };
      case /* LoadStatusCount */1 :
          return {
                  users: state.users,
                  userToDelete: state.userToDelete,
                  statusCount: state.statusCount,
                  loadingUsers: state.loadingUsers,
                  loadingStatusCount: true
                };
      case /* CancelDelete */2 :
          return {
                  users: state.users,
                  userToDelete: undefined,
                  statusCount: state.statusCount,
                  loadingUsers: state.loadingUsers,
                  loadingStatusCount: state.loadingStatusCount
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* DisplayList */0 :
          return {
                  users: action._0,
                  userToDelete: state.userToDelete,
                  statusCount: state.statusCount,
                  loadingUsers: false,
                  loadingStatusCount: state.loadingStatusCount
                };
      case /* DisplayStatusCount */1 :
          return {
                  users: state.users,
                  userToDelete: state.userToDelete,
                  statusCount: action._0,
                  loadingUsers: state.loadingUsers,
                  loadingStatusCount: false
                };
      case /* DisplayDeleteConfirmation */2 :
          return {
                  users: state.users,
                  userToDelete: action._0,
                  statusCount: state.statusCount,
                  loadingUsers: state.loadingUsers,
                  loadingStatusCount: state.loadingStatusCount
                };
      
    }
  }
}

function totalPages(total_records, perPage) {
  return Caml_int32.div(total_records - 1 | 0, perPage) + 1 | 0;
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

var initialState = {
  users: /* [] */0,
  userToDelete: undefined,
  statusCount: {
    active: 0,
    inActive: 0
  },
  loadingUsers: true,
  loadingStatusCount: true
};

var searchPlaceholder = "Search Users";

function UserList(Props) {
  var search = Props.search;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setSearchKey = match$1[1];
  var searchKey = match$1[0];
  var queryParams = Querystringify.parse(search);
  var x = Js_dict.get(queryParams, "page");
  var currentPage;
  if (x !== undefined && x !== "") {
    var page = Belt_Int.fromString(x);
    currentPage = page !== undefined ? page : 1;
  } else {
    currentPage = 1;
  }
  var x$1 = Js_dict.get(queryParams, "search");
  var currentSearchKey = x$1 !== undefined && x$1 !== "" ? x$1 : undefined;
  var match$2 = React.useState(function () {
        return {
                total: "1",
                perPage: "1",
                currentPage: currentPage
              };
      });
  var setPageInfo = match$2[1];
  var pageInfo = match$2[0];
  var fetchUsers = function (param) {
    var pageNumber = String(pageInfo.currentPage);
    var url = currentSearchKey !== undefined ? "/api/v1/users?page=" + pageNumber + "&search=" + currentSearchKey : "/api/v1/users?page=" + pageNumber;
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader("" + url).then(function (param) {
                var headers = param[0];
                var x = headers.get("Total");
                var total = x !== null ? (
                    x === null ? undefined : Caml_option.some(x)
                  ) : "1";
                var x$1 = headers.get("Per-Page");
                var perPage = x$1 !== null ? (
                    x$1 === null ? undefined : Caml_option.some(x$1)
                  ) : "1";
                var users = param[1].then(function (json) {
                      return Promise.resolve(Json_decode.field("users", (function (param) {
                                        return Json_decode.list(User$ReactHooksTemplate.decode, param);
                                      }), json));
                    });
                Curry._1(setSearchKey, (function (param) {
                        return currentSearchKey;
                      }));
                return Promise.resolve([
                            users,
                            [
                              total,
                              perPage
                            ]
                          ]);
              });
  };
  var match$3 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var handlePageChange = function (pageNumber) {
    var pageNumber$1 = String(pageNumber);
    if (searchKey !== undefined) {
      RescriptReactRouter.push("/users/all?page=" + pageNumber$1 + "&search=" + searchKey);
    } else {
      RescriptReactRouter.push("/users/all?page=" + pageNumber$1);
    }
    Curry._1(setPageInfo, (function (pageInfo) {
            return {
                    total: pageInfo.total,
                    perPage: pageInfo.perPage,
                    currentPage: Caml_format.caml_int_of_string(pageNumber$1)
                  };
          }));
    window.scrollTo(0.0, 0.0);
    
  };
  var handleSearch = function (param) {
    if (searchKey !== undefined) {
      return RescriptReactRouter.push("/users/all?search=" + searchKey);
    } else {
      return RescriptReactRouter.push("/users/all?");
    }
  };
  React.useEffect((function () {
          Curry._1(dispatch, /* LoadStatusCount */1);
          var url = currentSearchKey !== undefined ? "/api/v1/users/status_wise_count?search=" + currentSearchKey : "/api/v1/users/status_wise_count";
          Http$ReactHooksTemplate.fetchGetJSON("" + url).then(function (json) {
                var statusCount_active = Belt_Option.getWithDefault(Json_decode.field("count", (function (param) {
                            return Json_decode.optional((function (param) {
                                          return Json_decode.field("true", Json_decode.$$int, param);
                                        }), param);
                          }), json), 0);
                var statusCount_inActive = Belt_Option.getWithDefault(Json_decode.field("count", (function (param) {
                            return Json_decode.optional((function (param) {
                                          return Json_decode.field("false", Json_decode.$$int, param);
                                        }), param);
                          }), json), 0);
                var statusCount = {
                  active: statusCount_active,
                  inActive: statusCount_inActive
                };
                Curry._1(setSearchKey, (function (param) {
                        return currentSearchKey;
                      }));
                Curry._1(dispatch, {
                      TAG: /* DisplayStatusCount */1,
                      _0: statusCount
                    });
                return Promise.resolve(undefined);
              });
          
        }), [currentSearchKey]);
  React.useEffect((function () {
          Curry._1(dispatch, /* LoadUsers */0);
          fetchUsers(undefined).then(function (param) {
                var paginationInfo = param[1];
                var perPage = paginationInfo[1];
                var total = paginationInfo[0];
                Curry._1(setPageInfo, (function (state) {
                        return {
                                total: total,
                                perPage: perPage,
                                currentPage: state.currentPage
                              };
                      }));
                return param[0].then(function (xs) {
                            return Promise.resolve(Curry._1(dispatch, {
                                            TAG: /* DisplayList */0,
                                            _0: xs
                                          }));
                          });
              });
          
        }), [
        currentPage,
        currentSearchKey
      ]);
  var handleCancelDelete = function (param) {
    return Curry._1(dispatch, /* CancelDelete */2);
  };
  var data = function (statusCount) {
    return [
            {
              name: "active",
              count: statusCount.active
            },
            {
              name: "inactive",
              count: statusCount.inActive
            }
          ];
  };
  var summary = function (param) {
    return React.createElement("div", {
                className: "h-32 w-1/3 mb-4 bg-white rounded-sm shadow border-l-8 border-teal-600 mr-20-px p-8"
              }, state.loadingStatusCount ? React.createElement("p", undefined, "Loading data") : React.createElement(Recharts.ResponsiveContainer, {
                      children: React.createElement(Recharts.BarChart, {
                            data: data(state.statusCount),
                            margin: Recharts$ReactHooksTemplate.Margin.make(0, 0, 0, 40),
                            layout: "vertical",
                            children: null
                          }, React.createElement(Recharts.XAxis, Curry._7(Recharts$ReactHooksTemplate.XAxis.makeProps(undefined)(undefined, "count", undefined, undefined, undefined), undefined, undefined, undefined, undefined, "number", undefined, undefined)), React.createElement(Recharts.YAxis, Curry.app(Recharts$ReactHooksTemplate.YAxis.makeProps(/* Num */{
                                          _0: 0
                                        })(1, "name", undefined, undefined, undefined, undefined), [
                                    (function (key) {
                                        return key.toLocaleUpperCase();
                                      }),
                                    undefined,
                                    undefined,
                                    "category",
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined
                                  ])), React.createElement(Recharts.Tooltip, {}), React.createElement(Recharts.Bar, {
                                dataKey: "count",
                                barSize: 200,
                                fill: "#ff7300"
                              }))
                    }));
  };
  var tmp;
  if (state.loadingUsers) {
    tmp = React.createElement(React.Fragment, undefined, summary(undefined), React.createElement("p", undefined, "Fetching users..."));
  } else {
    var user = state.userToDelete;
    var tmp$1;
    if (user !== undefined) {
      var partial_arg = state.users;
      tmp$1 = React.createElement(OverlayClosable$ReactHooksTemplate.make, {
            hidden: false,
            children: React.createElement(ConfirmationDialog$ReactHooksTemplate.make, {
                  user: user,
                  handleCancel: handleCancelDelete,
                  handleDelete: (function (param) {
                      deleteUser(user).then(function (param) {
                              return Promise.resolve(Curry._1(dispatch, {
                                              TAG: /* DisplayList */0,
                                              _0: partial_arg
                                            }));
                            }).then(function (param) {
                            return Promise.resolve((window.location.reload(), undefined));
                          });
                      
                    }),
                  handleClose: handleCancelDelete
                })
          });
    } else {
      tmp$1 = React.createElement(React.Fragment, undefined, summary(undefined), React.createElement("div", {
                className: "flex flex-row justify-end mb-10-px relative w-full"
              }, React.createElement(SearchForm$ReactHooksTemplate.make, {
                    searchKey: searchKey,
                    setSearchKey: setSearchKey,
                    handleSearch: handleSearch,
                    searchPlaceholder: searchPlaceholder
                  })), React.createElement(UserListHeader$ReactHooksTemplate.make, {}), React.createElement("div", {
                className: "shadow bg-white"
              }, $$Array.of_list(List.map((function (user) {
                          return React.createElement(UserListItem$ReactHooksTemplate.make, {
                                      email: User$ReactHooksTemplate.email(user),
                                      active: user.active,
                                      roles: user.roles,
                                      dataRestrictions: user.dataRestrictions,
                                      lastSignInAt: user.lastSignInAt,
                                      handleDelete: (function (param) {
                                          return Curry._1(dispatch, {
                                                      TAG: /* DisplayDeleteConfirmation */2,
                                                      _0: user
                                                    });
                                        }),
                                      handleEdit: (function (param) {
                                          return RescriptReactRouter.push("/users/edit/" + String(User$ReactHooksTemplate.id(user)));
                                        }),
                                      key: String(User$ReactHooksTemplate.id(user))
                                    });
                        }), state.users))));
    }
    tmp = React.createElement(React.Fragment, undefined, tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "m-30-px"
                }, Curry._1(match$3[0], /* AddUser */6) ? React.createElement("div", {
                        className: "flex justify-between items-center mb-30-px "
                      }, React.createElement("h2", {
                            className: "mb-30-px text-2xl"
                          }, "Users"), React.createElement("a", {
                            className: "text-white bg-primary hover:bg-teal-700 py-6-px px-20-px rounded shadow",
                            href: "/users/new"
                          }, "Add New User")) : React.createElement("div", {
                        className: "flex justify-between items-center mb-30-px "
                      }, React.createElement("h2", {
                            className: "mb-30-px text-2xl"
                          }, "Users")), tmp), React.createElement("div", {
                  className: "flex justify-end"
                }, React.createElement(Pagination$ReactHooksTemplate.make, {
                      total: totalPages(Caml_format.caml_int_of_string(pageInfo.total), Caml_format.caml_int_of_string(pageInfo.perPage)),
                      currentPage: currentPage,
                      handlePageChange: handlePageChange
                    })));
}

var emptyPageInfo = {
  total: "1",
  perPage: "1",
  currentPage: 1
};

var make = UserList;

exports.deleteUser = deleteUser;
exports.reducer = reducer;
exports.totalPages = totalPages;
exports.emptyPageInfo = emptyPageInfo;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.initialState = initialState;
exports.searchPlaceholder = searchPlaceholder;
exports.make = make;
/* react Not a pure module */
