// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var NpsLabel$ReactHooksTemplate = require("./NpsLabel.bs.js");

function NpsEditor(Props) {
  var nps = Props.nps;
  var onNpsChange = Props.onNpsChange;
  var leftCount = NpsLabel$ReactHooksTemplate.left(Nps$ReactHooksTemplate.label(nps)).length;
  var rightCount = NpsLabel$ReactHooksTemplate.right(Nps$ReactHooksTemplate.label(nps)).length;
  var onLabelChange = function (f, $$event) {
    var formTargetValue = Utils$ReactHooksTemplate.formTargetValue($$event);
    if (Utils$ReactHooksTemplate.checkMaxLength24(formTargetValue)) {
      return Curry._1(onNpsChange, Nps$ReactHooksTemplate.setLabel(nps, Curry._2(f, Nps$ReactHooksTemplate.label(nps), formTargetValue)));
    }
    
  };
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "mb-4"
                }, React.createElement("div", {
                      className: "text-darkcharcoal text-sm mb-2 font-medium flex justify-between"
                    }, "Left Label", React.createElement("p", {
                          className: "text-gray-500 text-xs p-1 flex justify-end"
                        }, String(leftCount) + (" / " + String(24)))), React.createElement("input", {
                      className: "h-40-px py-2 px-4 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                      placeholder: "Enter Left Label",
                      type: "text",
                      value: NpsLabel$ReactHooksTemplate.left(Nps$ReactHooksTemplate.label(nps)),
                      onChange: (function (param) {
                          return onLabelChange(NpsLabel$ReactHooksTemplate.setLeft, param);
                        })
                    })), React.createElement("div", {
                  className: "mb-4"
                }, React.createElement("div", {
                      className: "text-darkcharcoal text-sm mb-2 font-medium flex justify-between"
                    }, "Right Label", React.createElement("p", {
                          className: "text-gray-500 text-xs p-1 flex justify-end"
                        }, String(rightCount) + (" / " + String(24)))), React.createElement("input", {
                      className: "h-40-px py-2 px-4 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                      placeholder: "Enter Right Label",
                      type: "text",
                      value: NpsLabel$ReactHooksTemplate.right(Nps$ReactHooksTemplate.label(nps)),
                      onChange: (function (param) {
                          return onLabelChange(NpsLabel$ReactHooksTemplate.setRight, param);
                        })
                    })));
}

var maxCount = 24;

var make = NpsEditor;

exports.maxCount = maxCount;
exports.make = make;
/* react Not a pure module */
