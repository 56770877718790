// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var OptionGroups$ReactHooksTemplate = require("../common/optionGroups.bs.js");
var LogicCondition$ReactHooksTemplate = require("./LogicCondition.bs.js");
var FilteredSelectBox$ReactHooksTemplate = require("./FilteredSelectBox.bs.js");
var ListConditionForm$ReactHooksTemplate = require("./ListConditionForm.bs.js");
var NumberConditionForm$ReactHooksTemplate = require("./NumberConditionForm.bs.js");
var StringConditionForm$ReactHooksTemplate = require("./StringConditionForm.bs.js");

function ConditionForm(Props) {
  var questions = Props.questions;
  var condition = Props.condition;
  var updateLogicCondition = Props.updateLogicCondition;
  var updateCondition = function (conditionType) {
    return Curry._1(updateLogicCondition, LogicCondition$ReactHooksTemplate.setConditionType(condition, conditionType));
  };
  var renderConditionTypeForm = function (condition) {
    var x = LogicCondition$ReactHooksTemplate.conditionType(condition);
    switch (x.TAG | 0) {
      case /* NumberCondition */0 :
          return React.createElement(NumberConditionForm$ReactHooksTemplate.make, {
                      numberCondition: x._0,
                      updateCondition: updateCondition
                    });
      case /* StringCondition */1 :
          return React.createElement(StringConditionForm$ReactHooksTemplate.make, {
                      stringCondition: x._0,
                      updateCondition: updateCondition
                    });
      case /* ListCondition */2 :
          return React.createElement(ListConditionForm$ReactHooksTemplate.make, {
                      listCondition: x._0,
                      updateCondition: updateCondition
                    });
      
    }
  };
  var handleLhsChange = function (updatedOptions) {
    var tmp;
    if (updatedOptions.TAG === /* Questions */0) {
      var question = Belt_List.head(updatedOptions._0);
      tmp = question !== undefined ? LogicCondition$ReactHooksTemplate.emptyCondition(Caml_option.some(condition.id), question) : condition;
    } else {
      tmp = condition;
    }
    return Curry._1(updateLogicCondition, tmp);
  };
  return React.createElement("div", {
              className: "bg-white pt-3"
            }, React.createElement("div", {
                  className: "flex items-center"
                }, React.createElement("div", {
                      className: "pl-2"
                    }, React.createElement("div", {
                          className: "h-20-px font-normal text-xs text-aurometalsaurus "
                        }, "Condition"), React.createElement("div", {
                          className: "text-base font-medium border border-gray-300 text-darkcharcoal h-40-px w-34-px p-3 rounded-4px"
                        }, "If")), React.createElement("div", {
                      className: "pl-2"
                    }, React.createElement("div", {
                          className: "w-110-px h-20-px font-normal text-xs text-aurometalsaurus "
                        }, "Question"), React.createElement(FilteredSelectBox$ReactHooksTemplate.make, {
                          options: OptionGroups$ReactHooksTemplate.make(questions, /* [] */0),
                          placeholder: "select questions",
                          onChange: handleLhsChange,
                          className: "h-40-px w-160-px py-2 px-1 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs",
                          selectedOption: {
                            TAG: /* Questions */0,
                            _0: {
                              hd: LogicCondition$ReactHooksTemplate.lhs(condition),
                              tl: /* [] */0
                            }
                          }
                        })), renderConditionTypeForm(condition)));
}

var make = ConditionForm;

exports.make = make;
/* react Not a pure module */
