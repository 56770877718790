// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");

function LongTextDefaultForm(Props) {
  var longText = Props.longText;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var handleOnchange = function ($$event) {
    var value = $$event.target.value;
    var firstChar = value[0];
    var check = Belt_Array.getBy([
          "<",
          "=",
          "+",
          "-",
          "@"
        ], (function (x) {
            return firstChar === x;
          }));
    if (check !== undefined) {
      return Curry._1(onChange, LongText$ReactHooksTemplate.setAnswer(longText, ""));
    } else {
      return Curry._1(onChange, LongText$ReactHooksTemplate.setAnswer(longText, value));
    }
  };
  return React.createElement("div", {
              className: "w-full py-8 px-16 small:px-6 border border-gray-400 rounded-lg"
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "text-lg font-medium text-darkcharcoal py-6"
                    }, LongText$ReactHooksTemplate.getTextTranslation(longText, SurveyLanguage$ReactHooksTemplate.name(language))), React.createElement("div", {
                      className: "text-base font-normal text-darkcharcoal pb-8"
                    }, LongText$ReactHooksTemplate.getDescriptionTranslation(longText, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("div", {
                  className: "flex w-full pb-6"
                }, React.createElement("textarea", {
                      className: "py-2 px-4 bg-alicebluepurple focus:border-gray-600 text-xs w-full",
                      maxLength: 3500,
                      placeholder: LongText$ReactHooksTemplate.getPlaceholderTranslation(longText, SurveyLanguage$ReactHooksTemplate.name(language)),
                      rows: 5,
                      value: Belt_Option.getWithDefault(LongText$ReactHooksTemplate.answer(longText), ""),
                      onChange: handleOnchange
                    })), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = LongTextDefaultForm;

exports.make = make;
/* react Not a pure module */
