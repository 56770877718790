// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("./http.bs.js");
var Image$ReactHooksTemplate = require("./image.bs.js");
var Vendor$ReactHooksTemplate = require("./vendor.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var DeviseSignOut$ReactHooksTemplate = require("./DeviseSignOut.bs.js");

var emptyState_vendor = Vendor$ReactHooksTemplate.make(undefined);

var emptyState = {
  vendor: emptyState_vendor,
  selected: "dashboard",
  clientName: ""
};

function decodeLogo(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field("url", Json_decode.string, param);
              }), json);
}

function decodeClientName(json) {
  return Json_decode.field("name", Json_decode.string, json);
}

function Header$QuickSurveyDropdown(Props) {
  var selected = Props.selected;
  return React.createElement("div", {
              className: "dropdown mr-20-px"
            }, React.createElement("div", {
                  className: "flex items-center font " + (
                    selected === "quick_survey" ? "font-extrabold" : ""
                  )
                }, React.createElement("p", {
                      className: "mr-5-px"
                    }, "Quick Survey")), React.createElement("ul", {
                  className: "dropdown-list shadow text-gray-800 text-sm"
                }, React.createElement("li", {
                      className: "hover:bg-gray-300"
                    }, React.createElement("a", {
                          className: "block p-10-px",
                          href: "/surveys/quick_survey"
                        }, "New Quick Survey")), React.createElement("li", {
                      className: "hover:bg-gray-300"
                    }, React.createElement("a", {
                          className: "block p-10-px",
                          href: "/quick_survey/report"
                        }, "Reports"))), React.createElement("hr", {
                  className: "bg-white border-white w-full rounded mr-20-px\n        header_select_higlighter " + (
                    selected !== "quick_survey" ? "hidden" : ""
                  )
                }));
}

var QuickSurveyDropdown = {
  make: Header$QuickSurveyDropdown
};

function Header$SettingsDropdown(Props) {
  var hasPermission = Props.hasPermission;
  var flagEnabled = Props.flagEnabled;
  return React.createElement("div", {
              className: "dropdown"
            }, React.createElement("img", {
                  className: "h-16-px",
                  src: Image$ReactHooksTemplate.cog
                }), React.createElement("div", {
                  className: "dropdown-list right-0 shadow text-gray-800 text-sm"
                }, React.createElement("ul", undefined, React.createElement("li", {
                          className: "hover:bg-gray-300"
                        }, Curry._1(hasPermission, /* ShowUser */9) || Curry._1(hasPermission, /* AddUser */6) || Curry._1(hasPermission, /* UpdateUser */8) || Curry._1(hasPermission, /* DeleteUser */7) ? React.createElement("a", {
                                className: "block p-10-px",
                                href: "/users/all"
                              }, "Users") : React.createElement(React.Fragment, undefined)), React.createElement("li", {
                          className: "hover:bg-gray-300"
                        }, Curry._1(hasPermission, /* ShowRole */13) || Curry._1(hasPermission, /* AddRole */10) || Curry._1(hasPermission, /* UpdateRole */12) || Curry._1(hasPermission, /* DeleteRole */11) ? React.createElement("a", {
                                className: "block p-10-px",
                                href: "/roles/all"
                              }, "Roles") : React.createElement(React.Fragment, undefined)), React.createElement("li", {
                          className: "hover:bg-gray-300"
                        }, Curry._1(hasPermission, /* ShowUser */9) || Curry._1(hasPermission, /* AddUser */6) || Curry._1(hasPermission, /* UpdateUser */8) || Curry._1(hasPermission, /* DeleteUser */7) ? React.createElement("a", {
                                className: "block p-10-px",
                                href: "/admin/view"
                              }, "Admin Pages") : React.createElement(React.Fragment, undefined)), React.createElement("li", {
                          className: "hover:bg-gray-300"
                        }, Curry._1(hasPermission, /* ShowKpi */21) || Curry._1(hasPermission, /* AddKpi */22) || Curry._1(hasPermission, /* UpdateKpi */23) || Curry._1(hasPermission, /* DeleteKpi */24) ? React.createElement("a", {
                                className: "block p-10-px",
                                href: "/users/kpi"
                              }, "KPI Management") : React.createElement(React.Fragment, undefined)), Curry._1(flagEnabled, /* GeneralSettingsUI */3) ? React.createElement("li", {
                            className: "hover:bg-gray-300"
                          }, React.createElement("a", {
                                className: "block p-10-px",
                                href: "/general_settings"
                              }, "General Settings")) : React.createElement(React.Fragment, undefined), React.createElement("li", {
                          className: "hover:bg-gray-300"
                        }, React.createElement("a", {
                              className: "block p-10-px",
                              href: "/users/update_password"
                            }, "Change Password"))), React.createElement("div", {
                      className: "h-1-px bg-gray-300"
                    }), React.createElement("div", {
                      className: "hover:bg-gray-300 p-10-px"
                    }, React.createElement(DeviseSignOut$ReactHooksTemplate.make, {}))));
}

var SettingsDropdown = {
  make: Header$SettingsDropdown
};

function Header(Props) {
  var parent = Props.parent;
  var flagEnabled = Props.flagEnabled;
  var match = React.useState(function () {
        return emptyState;
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var hasPermission = match$1[0];
  React.useEffect((function () {
          console.log("Parent");
          console.log(parent);
          Http$ReactHooksTemplate.fetchGetJSON("/api/v1/clients/client_name_and_logo").then(function (json) {
                return Promise.resolve(Curry._1(setState, (function (param) {
                                  return {
                                          vendor: Vendor$ReactHooksTemplate.make(decodeLogo(json)),
                                          selected: parent,
                                          clientName: Json_decode.field("name", Json_decode.string, json)
                                        };
                                })));
              });
          
        }), []);
  var logo = Vendor$ReactHooksTemplate.getLogo(state.vendor);
  return React.createElement("div", {
              className: state.clientName === "ncell" ? "flex flex-col fixed w-full z-10 p-16-px bg-purple-900" : "flex flex-col fixed w-full z-10 p-16-px bg-pinetree"
            }, React.createElement("div", {
                  className: "flex"
                }, React.createElement("div", {
                      className: "flex items-center justify-between text-white text-16-px\n        w-full z-10 leading-tight font-thin"
                    }, React.createElement("div", {
                          className: "flex items-center"
                        }, React.createElement("img", {
                              className: "h-32-px pr-4 mr-2",
                              src: Image$ReactHooksTemplate.geckolystLogo
                            }), logo !== undefined ? React.createElement("img", {
                                className: "h-32-px pl-4 border-l border-teal-200 mr-8",
                                src: logo
                              }) : React.createElement("div", {
                                className: "h-32-px pl-4 border-l border-teal-200 mr-8"
                              }), Curry._1(hasPermission, /* ShowDashboard */0) ? React.createElement("div", {
                                className: "mr-20-px"
                              }, React.createElement("a", {
                                    className: state.selected === "dashboard" ? "font-extrabold" : "",
                                    href: "/dashboard"
                                  }, "Dashboard"), React.createElement("hr", {
                                    className: "bg-white border-white w-full rounded mr-20-px\n                    header_select_higlighter " + (
                                      state.selected !== "dashboard" ? "hidden" : ""
                                    )
                                  })) : React.createElement(React.Fragment, undefined), Curry._1(hasPermission, /* ShowSurvey */5) || Curry._1(hasPermission, /* DeleteSurvey */4) || Curry._1(hasPermission, /* BuildSurvey */3) ? React.createElement("div", {
                                className: "mr-20-px"
                              }, React.createElement("a", {
                                    className: state.selected === "surveys" ? "font-extrabold" : "",
                                    href: "/projects/all"
                                  }, "Surveys"), React.createElement("hr", {
                                    className: "bg-white border-white w-full rounded mr-20-px\n                    header_select_higlighter " + (
                                      state.selected !== "surveys" ? "hidden" : ""
                                    )
                                  })) : React.createElement(React.Fragment, undefined), Curry._1(hasPermission, /* ShowCampaign */14) || Curry._1(hasPermission, /* CreateCampaign */15) ? React.createElement("div", {
                                className: "mr-20-px"
                              }, React.createElement("a", {
                                    className: state.selected === "campaigns" ? "font-extrabold" : "",
                                    href: "/campaigns/all"
                                  }, "Campaigns"), React.createElement("hr", {
                                    className: "bg-white border-white w-full rounded mr-20-px\n                    header_select_higlighter " + (
                                      state.selected !== "campaigns" ? "hidden" : ""
                                    )
                                  })) : React.createElement(React.Fragment, undefined), Curry._1(flagEnabled, /* Reports */2) ? React.createElement("div", {
                                className: "mr-20-px"
                              }, React.createElement("a", {
                                    className: state.selected === "reports" ? "font-extrabold" : "",
                                    href: "/reports"
                                  }, "Reports"), React.createElement("hr", {
                                    className: "bg-white border-white w-full rounded mr-20-px\n                  header_select_higlighter " + (
                                      state.selected !== "reports" ? "hidden" : ""
                                    )
                                  })) : React.createElement(React.Fragment, undefined), Curry._1(hasPermission, /* ShowQuickSurvey */16) ? React.createElement(Header$QuickSurveyDropdown, {
                                selected: state.selected
                              }) : React.createElement(React.Fragment, undefined), Curry._1(hasPermission, /* ResolveTickets */2) ? (
                            Curry._1(hasPermission, /* ManageTickets */1) ? React.createElement("div", {
                                    className: "mr-20-px"
                                  }, React.createElement("a", {
                                        className: state.selected === "tickets" ? "font-extrabold" : "",
                                        href: "/tickets/manage/all"
                                      }, "Tickets"), React.createElement("hr", {
                                        className: "bg-white border-white w-full rounded mr-20-px\n                  header_select_higlighter " + (
                                          state.selected !== "tickets" ? "hidden" : ""
                                        )
                                      })) : React.createElement("div", {
                                    className: "mr-20-px"
                                  }, React.createElement("a", {
                                        className: state.selected === "tickets" ? "font-extrabold" : "",
                                        href: "/tickets/work/all"
                                      }, "Tickets"), React.createElement("hr", {
                                        className: "bg-white border-white w-full rounded mr-20-px\n                  header_select_higlighter " + (
                                          state.selected !== "tickets" ? "hidden" : ""
                                        )
                                      }))
                          ) : React.createElement(React.Fragment, undefined), Curry._1(hasPermission, /* EditTextCategories */20) && Curry._1(flagEnabled, /* ManualTextCategorization */1) ? React.createElement("div", {
                                className: "mr-20-px"
                              }, React.createElement("a", {
                                    className: state.selected === "comment_categorization" ? "font-extrabold" : "",
                                    href: "/category_edit"
                                  }, "Comment Categorisation"), React.createElement("hr", {
                                    className: "bg-white border-white w-full rounded mr-20-px\n                    header_select_higlighter " + (
                                      state.selected !== "comment_categorization" ? "hidden" : ""
                                    )
                                  })) : React.createElement(React.Fragment, undefined), Curry._1(hasPermission, /* AddUser */6) && Curry._1(flagEnabled, /* TelephonicSurvey */4) ? React.createElement("div", {
                                className: "mr-20-px"
                              }, React.createElement("a", {
                                    className: state.selected === "telephonic_surveys" ? "font-extrabold" : "",
                                    href: "/campaigns/telephonic_surveys"
                                  }, "Telephonic Surveys"), React.createElement("hr", {
                                    className: "bg-white border-white w-full rounded mr-20-px\n                    header_select_higlighter " + (
                                      state.selected !== "telephonic_surveys" ? "hidden" : ""
                                    )
                                  })) : React.createElement(React.Fragment, undefined), Curry._1(hasPermission, /* AddUser */6) && Curry._1(flagEnabled, /* InsightsDashboard */5) ? React.createElement("div", {
                                className: "mr-20-px"
                              }, React.createElement("a", {
                                    className: state.selected === "insights" ? "font-extrabold" : "",
                                    href: "/insights/dashboard"
                                  }, "Insights"), React.createElement("hr", {
                                    className: "bg-white border-white w-full rounded mr-20-px\n                    header_select_higlighter " + (
                                      state.selected !== "insights" ? "hidden" : ""
                                    )
                                  })) : React.createElement(React.Fragment, undefined)), React.createElement("div", undefined, React.createElement(Header$SettingsDropdown, {
                              hasPermission: hasPermission,
                              flagEnabled: flagEnabled
                            })))));
}

var make = Header;

exports.emptyState = emptyState;
exports.decodeLogo = decodeLogo;
exports.decodeClientName = decodeClientName;
exports.QuickSurveyDropdown = QuickSurveyDropdown;
exports.SettingsDropdown = SettingsDropdown;
exports.make = make;
/* emptyState Not a pure module */
