// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");

function LongTextEditor(Props) {
  var longText = Props.longText;
  var onLongTextChange = Props.onLongTextChange;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "mb-4"
                }, React.createElement("div", {
                      className: "text-darkcharcoal text-sm mb-2 font-medium"
                    }, "Placeholder"), React.createElement("input", {
                      className: "h-40-px py-2 px-4 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                      placeholder: "Placeholder",
                      type: "text",
                      value: LongText$ReactHooksTemplate.placeholder(longText),
                      onChange: (function ($$event) {
                          return Curry._1(onLongTextChange, LongText$ReactHooksTemplate.setPlaceholder(longText, Utils$ReactHooksTemplate.formTargetValue($$event)));
                        })
                    })));
}

var make = LongTextEditor;

exports.make = make;
/* react Not a pure module */
