// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Csat$ReactHooksTemplate = require("./csat.bs.js");

function CsatEditor(Props) {
  var csat = Props.csat;
  var onCsatChange = Props.onCsatChange;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "mb-4 pb-4 border-b border-gray-300"
                }, React.createElement("div", {
                      className: "flex justify-between"
                    }, React.createElement("div", {
                          className: "text-darkcharcoal text-sm font-medium"
                        }, "Switch Order"), React.createElement("label", {
                          className: "switch"
                        }, React.createElement("input", {
                              checked: !Csat$ReactHooksTemplate.negativeToPositive(csat),
                              type: "checkbox",
                              onChange: (function (param) {
                                  return Curry._1(onCsatChange, Csat$ReactHooksTemplate.toggleNegativeToPositive(csat));
                                })
                            }), React.createElement("span", {
                              className: "slider round"
                            })))));
}

var make = CsatEditor;

exports.make = make;
/* react Not a pure module */
