// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Role$ReactHooksTemplate = require("./role.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var RoleListItem$ReactHooksTemplate = require("./RoleListItem.bs.js");

function fetchRoles(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/roles", (function (param) {
                return Json_decode.field("roles", (function (param) {
                              return Json_decode.list(Role$ReactHooksTemplate.decode, param);
                            }), param);
              }));
}

function reducer(state, action) {
  return /* FetchRolesCompleted */{
          _0: action._0
        };
}

function RoleList(Props) {
  var match = React.useReducer(reducer, /* FetchRolesInProgress */0);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var hasPermission = match$1[0];
  React.useEffect((function () {
          fetchRoles(undefined).then(function (roles) {
                return Promise.resolve(Curry._1(dispatch, /* DisplayList */{
                                _0: roles
                              }));
              });
          
        }), []);
  if (state) {
    return React.createElement("div", undefined, Curry._1(hasPermission, /* ShowRole */13) ? React.createElement("div", {
                      className: "p-20-px"
                    }, Curry._1(hasPermission, /* AddRole */10) ? React.createElement("div", {
                            className: "flex justify-between items-center mb-30-px "
                          }, React.createElement("h2", {
                                className: "mb-32-px text-2xl"
                              }, "Roles"), React.createElement("a", {
                                className: "text-white bg-teal-500 hover:bg-teal-700 py-6-px px-20-px",
                                href: "/roles/new"
                              }, "Add new Role")) : React.createElement(React.Fragment, undefined), React.createElement("table", {
                          className: "w-full mt-10-px bg-white"
                        }, React.createElement("thead", {
                              className: "border border-gray-400 bg-ghostwhite text-aurometalsaurus rounded-t-lg"
                            }, React.createElement("tr", undefined, React.createElement("th", {
                                      className: "text-left w-1/6 font-thin py-10-px pl-4"
                                    }, "Name"), React.createElement("th", {
                                      className: "text-left w-1/6 font-thin py-10-px pl-4"
                                    }, "Description"), React.createElement("th", {
                                      className: "text-left w-3/6 font-thin py-10-px pl-4"
                                    }, "Permissions"), React.createElement("th", {
                                      className: "text-left w-1/6 font-thin py-10-px pl-4"
                                    }, "Created At"))), React.createElement("tbody", {
                              className: "border border-gray-400"
                            }, $$Array.of_list(List.map((function (role) {
                                        return React.createElement(RoleListItem$ReactHooksTemplate.make, {
                                                    role: role,
                                                    key: String(Role$ReactHooksTemplate.id(role))
                                                  });
                                      }), state._0))))) : React.createElement("div", {
                      className: "h-screen w-full animated fadeIn"
                    }, React.createElement(Loading$ReactHooksTemplate.make, {
                          img: Image$ReactHooksTemplate.unAuthorized,
                          text: "401: Unauthorized!!",
                          loading: false
                        })));
  } else if (Curry._1(hasPermission, /* ShowRole */13)) {
    return React.createElement("p", undefined, "Fetching roles...");
  } else {
    return React.createElement("div", {
                className: "h-screen w-full animated fadeIn"
              }, React.createElement(Loading$ReactHooksTemplate.make, {
                    img: Image$ReactHooksTemplate.unAuthorized,
                    text: "401: Unauthorized!!",
                    loading: false
                  }));
  }
}

var make = RoleList;

exports.fetchRoles = fetchRoles;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
