// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var NumberCondition$ReactHooksTemplate = require("./numberCondition.bs.js");

function NumberConditionForm(Props) {
  var numberCondition = Props.numberCondition;
  var updateCondition = Props.updateCondition;
  var operator = NumberCondition$ReactHooksTemplate.operator(numberCondition);
  var rhs = String(NumberCondition$ReactHooksTemplate.rhs(numberCondition));
  var availableOperators = NumberCondition$ReactHooksTemplate.availableOperators(undefined);
  var match = NumberCondition$ReactHooksTemplate.answerRange(numberCondition.lhs);
  var min = match[0];
  var rhsOptions = Belt_List.makeBy((match[1] - min | 0) + 1 | 0, (function (i) {
          return i + min | 0;
        }));
  var handleOperatorChange = function ($$event) {
    var selectedOperator = Utils$ReactHooksTemplate.formTargetValue($$event);
    var updatedCondition = NumberCondition$ReactHooksTemplate.setOperator(numberCondition, selectedOperator);
    return Curry._1(updateCondition, {
                TAG: /* NumberCondition */0,
                _0: updatedCondition
              });
  };
  var handleRhsChange = function ($$event) {
    var selectedRhs = Caml_format.caml_int_of_string(Utils$ReactHooksTemplate.formTargetValue($$event));
    var updatedCondition = NumberCondition$ReactHooksTemplate.setRhs(numberCondition, selectedRhs);
    return Curry._1(updateCondition, {
                TAG: /* NumberCondition */0,
                _0: updatedCondition
              });
  };
  return React.createElement("div", {
              className: "flex items-center"
            }, React.createElement("div", {
                  className: "pl-2"
                }, React.createElement("div", {
                      className: "w-48-px h-20-px font-normal text-xs text-aurometalsaurus"
                    }, "Condition"), React.createElement("select", {
                      className: "h-40-px w-110-px py-2 px-1 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs",
                      placeholder: "Select Operator",
                      value: operator,
                      onChange: handleOperatorChange
                    }, $$Array.of_list(Belt_List.map(availableOperators, (function (operator) {
                                return React.createElement("option", {
                                            key: operator,
                                            className: "text-left bg-white-300 px-2 py-1 hover:bg-gray-300",
                                            value: operator
                                          }, NumberCondition$ReactHooksTemplate.humanizeOperator(operator));
                              }))))), React.createElement("div", {
                  className: "pl-2"
                }, React.createElement("div", {
                      className: "w-48-px h-20-px font-normal text-xs text-aurometalsaurus"
                    }, "Answer"), React.createElement("select", {
                      className: "h-40-px w-80-px py-2 px-1 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs",
                      placeholder: "Select Answer",
                      value: rhs,
                      onChange: handleRhsChange
                    }, $$Array.of_list(Belt_List.map(rhsOptions, (function (x) {
                                return React.createElement("option", {
                                            key: String(x),
                                            className: "text-left bg-white-300 px-2 py-1 hover:bg-gray-300",
                                            value: String(x)
                                          }, String(x));
                              }))))));
}

var make = NumberConditionForm;

exports.make = make;
/* react Not a pure module */
