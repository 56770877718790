// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var AlertComponent$ReactHooksTemplate = require("../common/AlertComponent.bs.js");
var GenerateTickets$ReactHooksTemplate = require("./GenerateTickets.bs.js");
var ApplicationSetting$ReactHooksTemplate = require("./applicationSetting.bs.js");
var SavePartialResponses$ReactHooksTemplate = require("./SavePartialResponses.bs.js");

var initialState_organization = Organization$ReactHooksTemplate.empty(undefined);

var initialState = {
  organization: initialState_organization,
  notification: undefined,
  edited: false
};

function OrganizationConfigurations(Props) {
  var match = React.useState(function () {
        return initialState;
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSON("/api/v1/organizations/1").then(function (json) {
                  return Promise.resolve(Curry._1(setState, (function (state) {
                                    return {
                                            organization: Json_decode.field("organization", Organization$ReactHooksTemplate.decode, json),
                                            notification: state.notification,
                                            edited: state.edited
                                          };
                                  })));
                }).catch(function (err) {
                return Promise.resolve((console.log(err), undefined));
              });
          
        }), []);
  var onChange = function (id, setting, enforce) {
    var newApplicationSettings = List.map((function (x) {
            if (ApplicationSetting$ReactHooksTemplate.id(x) === id && !ApplicationSetting$ReactHooksTemplate.enforced(x)) {
              return ApplicationSetting$ReactHooksTemplate.setEnforce(enforce, ApplicationSetting$ReactHooksTemplate.setIndividualSetting(setting, x));
            } else {
              return x;
            }
          }), Organization$ReactHooksTemplate.applicationSettings(state.organization));
    return Curry._1(setState, (function (state) {
                  return {
                          organization: Organization$ReactHooksTemplate.setApplicationSettings(state.organization, newApplicationSettings),
                          notification: state.notification,
                          edited: true
                        };
                }));
  };
  var updateOrganizationSettings = function (e) {
    e.preventDefault();
    var organizationId = Belt_Option.getWithDefault(Organization$ReactHooksTemplate.id(state.organization), 0);
    var params = {};
    params["organization"] = Organization$ReactHooksTemplate.encode(state.organization);
    Http$ReactHooksTemplate.fetchPutJSON("/api/v1/organizations/" + String(organizationId), params).then(function (response) {
          var status = Json_decode.field("status", (function (param) {
                  return Json_decode.withDefault("error", Json_decode.string, param);
                }), response);
          var tmp;
          switch (status) {
            case "error" :
                var errorMessage = Json_decode.field("error", Json_decode.string, response);
                tmp = Curry._1(setState, (function (state) {
                        return {
                                organization: state.organization,
                                notification: {
                                  TAG: /* Failure */2,
                                  _0: errorMessage
                                },
                                edited: state.edited
                              };
                      }));
                break;
            case "failure" :
                tmp = Curry._1(setState, (function (state) {
                        return {
                                organization: state.organization,
                                notification: {
                                  TAG: /* Failure */2,
                                  _0: "Invalid settings"
                                },
                                edited: state.edited
                              };
                      }));
                break;
            case "success" :
                tmp = Curry._1(setState, (function (param) {
                        return {
                                organization: Json_decode.field("organization", Organization$ReactHooksTemplate.decode, response),
                                notification: {
                                  TAG: /* Success */1,
                                  _0: "Settings Updated"
                                },
                                edited: false
                              };
                      }));
                break;
            default:
              tmp = Curry._1(setState, (function (state) {
                      return {
                              organization: state.organization,
                              notification: {
                                TAG: /* Failure */2,
                                _0: "Something went wrong. Please try\n          again later"
                              },
                              edited: state.edited
                            };
                    }));
          }
          return Promise.resolve(tmp);
        });
    
  };
  var alertType = state.notification;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "border-b border-gray-300 px-5 py-6 flex justify-between\n    w-full"
                }, React.createElement("div", {
                      className: "text-darkcharcoal font-bold text-xl"
                    }, "Organization Configurations"), alertType !== undefined ? React.createElement(AlertComponent$ReactHooksTemplate.make, {
                        alertType: alertType,
                        handleClose: (function (param) {
                            return Curry._1(setState, (function (state) {
                                          return {
                                                  organization: state.organization,
                                                  notification: undefined,
                                                  edited: state.edited
                                                };
                                        }));
                          })
                      }) : React.createElement(React.Fragment, undefined), React.createElement("button", {
                      className: state.edited ? "border rounded p-2 text-sm text-darkcharcoal bg-white" : "border text-gray-400 rounded p-2 text-sm disabled-input",
                      disabled: !state.edited,
                      onClick: updateOrganizationSettings
                    }, "Update")), React.createElement("div", {
                  className: "p-8 w-4/5"
                }, $$Array.of_list(List.map((function (x) {
                            var y = ApplicationSetting$ReactHooksTemplate.individualSetting(x);
                            var tmp;
                            if (y.TAG === /* SavePartialResponse */0) {
                              var partial_arg = ApplicationSetting$ReactHooksTemplate.id(x);
                              tmp = React.createElement(SavePartialResponses$ReactHooksTemplate.make, {
                                    setting: y._0,
                                    enforce: ApplicationSetting$ReactHooksTemplate.enforce(x),
                                    enforced: ApplicationSetting$ReactHooksTemplate.enforced(x),
                                    onChange: (function (param, param$1) {
                                        return onChange(partial_arg, param, param$1);
                                      })
                                  });
                            } else {
                              var partial_arg$1 = ApplicationSetting$ReactHooksTemplate.id(x);
                              tmp = React.createElement(GenerateTickets$ReactHooksTemplate.make, {
                                    setting: y._0,
                                    enforce: ApplicationSetting$ReactHooksTemplate.enforce(x),
                                    enforced: ApplicationSetting$ReactHooksTemplate.enforced(x),
                                    onChange: (function (param, param$1) {
                                        return onChange(partial_arg$1, param, param$1);
                                      })
                                  });
                            }
                            return React.createElement("div", {
                                        className: "mb-16"
                                      }, tmp);
                          }), Organization$ReactHooksTemplate.applicationSettings(state.organization)))));
}

var make = OrganizationConfigurations;

exports.initialState = initialState;
exports.make = make;
/* initialState Not a pure module */
