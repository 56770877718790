// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Project$ReactHooksTemplate = require("./project.bs.js");
var TextInputNew$ReactHooksTemplate = require("../common/TextInputNew.bs.js");

function ProjectForm(Props) {
  var toggleProjectForm = Props.toggleProjectForm;
  var selectedProject = Props.selectedProject;
  var onSubmit = Props.onSubmit;
  var match = React.useState(function () {
        return selectedProject;
      });
  var setProject = match[1];
  var project = match[0];
  return React.createElement("div", {
              className: "flex flex-col justify-between h-full"
            }, React.createElement("div", {
                  className: "p-8 border-t"
                }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                      label: "Project Name",
                      value: Project$ReactHooksTemplate.name(project),
                      handleChange: (function (param) {
                          var name = param.target.value;
                          return Curry._1(setProject, (function (param) {
                                        return Project$ReactHooksTemplate.setName(project, name);
                                      }));
                        }),
                      maxLength: 100
                    }), Project$ReactHooksTemplate.name(project).length === 0 ? React.createElement("div", {
                        className: "my-2"
                      }, React.createElement("p", {
                            className: "text-red-500 text-xs float-right"
                          }, "Name can't be empty")) : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                  className: "p-8 flex border-t border-gray-200 gap-3 font-medium\n                    absolute bottom-0 text-base w-full z-30 "
                }, React.createElement("button", {
                      className: "bg-ghostwhite border border-gray-400\n        flex rounded py-3 px-6 text-xs w-full justify-center",
                      id: "close_modal",
                      onClick: (function (param) {
                          return Curry._1(toggleProjectForm, undefined);
                        })
                    }, "Discard"), React.createElement("button", {
                      className: "bg-palatinateblue border border-gray-400\n        flex rounded py-3 px-6 text-xs text-white w-full justify-center " + (
                        Project$ReactHooksTemplate.name(project).length === 0 ? "disabled-input" : ""
                      ),
                      disabled: Project$ReactHooksTemplate.name(project).length === 0,
                      onClick: (function (param) {
                          return Curry._1(onSubmit, project);
                        })
                    }, "Save")));
}

var make = ProjectForm;

exports.make = make;
/* react Not a pure module */
