// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Logic$ReactHooksTemplate = require("./Logic.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var LogicForm$ReactHooksTemplate = require("./LogicForm.bs.js");

function LogicPage(Props) {
  var survey = Props.survey;
  var updateSurvey = Props.updateSurvey;
  var match = React.useState(function () {
        
      });
  var setActiveQuestion = match[1];
  var activeQuestion = match[0];
  var logicWithIndex = function (question) {
    return List.hd(List.filter(function (param) {
                      return Caml_obj.caml_equal(Logic$ReactHooksTemplate.trigger(param[1]), {
                                  TAG: /* Question */1,
                                  _0: question
                                });
                    })(List.mapi((function (i, x) {
                          return [
                                  i,
                                  x
                                ];
                        }), Survey$ReactHooksTemplate.logics(survey))));
  };
  return React.createElement("div", {
              className: "flex flex-col px-16 py-8"
            }, React.createElement("div", {
                  className: "w-110-px h-40-px"
                }, React.createElement("div", {
                      className: "w-48-px h-40-px py-2 border-b-2 border-pigmentblue text-lg font-semibold"
                    }, "Logic")), $$Array.of_list(List.mapi((function (index, x) {
                        var tmp;
                        if (Caml_obj.caml_equal(activeQuestion, x)) {
                          var match = logicWithIndex(x);
                          var logicIndex = match[0];
                          tmp = React.createElement(LogicForm$ReactHooksTemplate.make, {
                                survey: survey,
                                logic: match[1],
                                updateLogic: (function (param) {
                                    return Curry._1(updateSurvey, Survey$ReactHooksTemplate.setLogics(survey, List.mapi((function (i, logic) {
                                                          if (i === logicIndex) {
                                                            return param;
                                                          } else {
                                                            return logic;
                                                          }
                                                        }), Survey$ReactHooksTemplate.logics(survey))));
                                  })
                              });
                        } else {
                          tmp = React.createElement(React.Fragment, undefined);
                        }
                        return React.createElement("div", {
                                    key: String(index),
                                    className: "bg-ghostwhiteblue w-full min-h-110-px my-2 p-4 rounded"
                                  }, React.createElement("div", {
                                        className: "flex justify-between items-center pb-3"
                                      }, React.createElement("div", {
                                            className: "flex items-center text-base font-normal"
                                          }, String(index + 1 | 0) + ". " + Question$ReactHooksTemplate.text(x)), React.createElement("div", {
                                            className: "flex justify-end bg-ghostwhite border border-gray-400\n                    shadow-inner flex rounded py-2 px-3 text-xs font-normal\n                    cursor-pointer",
                                            id: String(index),
                                            onClick: (function (param) {
                                                if (activeQuestion !== undefined && Caml_obj.caml_equal(activeQuestion, x)) {
                                                  return Curry._1(setActiveQuestion, (function (param) {
                                                                
                                                              }));
                                                } else {
                                                  if (!List.exists((function (x$1) {
                                                            return Caml_obj.caml_equal(Logic$ReactHooksTemplate.trigger(x$1), {
                                                                        TAG: /* Question */1,
                                                                        _0: x
                                                                      });
                                                          }), Survey$ReactHooksTemplate.logics(survey))) {
                                                    var logic = Survey$ReactHooksTemplate.emptyLogic(survey, 1, x);
                                                    Curry._1(updateSurvey, Survey$ReactHooksTemplate.setLogics(survey, List.append(Survey$ReactHooksTemplate.logics(survey), {
                                                                  hd: logic,
                                                                  tl: /* [] */0
                                                                })));
                                                  }
                                                  return Curry._1(setActiveQuestion, (function (param) {
                                                                return x;
                                                              }));
                                                }
                                              })
                                          }, React.createElement("img", {
                                                className: "h-14-px mr-2-px",
                                                src: Image$ReactHooksTemplate.plusCircle
                                              }), "Add Logic")), tmp);
                      }), Survey$ReactHooksTemplate.questions(survey))));
}

var make = LogicPage;

exports.make = make;
/* react Not a pure module */
