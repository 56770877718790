// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var ReactCsv = require("react-csv");
var FileSaver = require("file-saver");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var DomToImage = require("dom-to-image");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Chart$ReactHooksTemplate = require("./chart.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var ChartId$ReactHooksTemplate = require("./chartId.bs.js");
var Loading$ReactHooksTemplate = require("./Loading.bs.js");
var MapAttribute$ReactHooksTemplate = require("./mapAttribute.bs.js");
var MapComponent$ReactHooksTemplate = require("./MapComponent.bs.js");
var TableAttribute$ReactHooksTemplate = require("./tableAttribute.bs.js");
var TableComponent$ReactHooksTemplate = require("./TableComponent.bs.js");
var BarChartComponent$ReactHooksTemplate = require("./BarChartComponent.bs.js");
var PieChartComponent$ReactHooksTemplate = require("./PieChartComponent.bs.js");
var AreaChartComponent$ReactHooksTemplate = require("./AreaChartComponent.bs.js");
var LineChartComponent$ReactHooksTemplate = require("./LineChartComponent.bs.js");
var ScoreCardComponent$ReactHooksTemplate = require("./ScoreCardComponent.bs.js");
var WordcloudComponent$ReactHooksTemplate = require("./WordcloudComponent.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");
var ComposedChartComponent$ReactHooksTemplate = require("./ComposedChartComponent.bs.js");
var RadialBarChartComponent$ReactHooksTemplate = require("./RadialBarChartComponent.bs.js");

var initialState = {
  loading: true,
  chart: undefined,
  drillDownKey: undefined
};

function reducer(state, x) {
  if (typeof x === "number") {
    return {
            loading: true,
            chart: state.chart,
            drillDownKey: state.drillDownKey
          };
  } else if (x.TAG === /* FetchChartCompleted */0) {
    return {
            loading: false,
            chart: x._0,
            drillDownKey: state.drillDownKey
          };
  } else {
    return {
            loading: state.loading,
            chart: state.chart,
            drillDownKey: x._0
          };
  }
}

function ChartComponent(Props) {
  var chartId = Props.chartId;
  var drillDownChartDetails = Props.drillDownChartDetails;
  var onClick = Props.onClick;
  var onEnlarge = Props.onEnlarge;
  var scoreCardOpt = Props.scoreCard;
  var enlargedOpt = Props.enlarged;
  var insightOpt = Props.insight;
  var campaignOpt = Props.campaign;
  var scoreCard = scoreCardOpt !== undefined ? scoreCardOpt : false;
  var enlarged = enlargedOpt !== undefined ? enlargedOpt : false;
  var insight = insightOpt !== undefined ? insightOpt : false;
  var campaign = campaignOpt !== undefined ? campaignOpt : false;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var fetchVisualization = function (param) {
    Curry._1(dispatch, /* FetchChartInProgress */0);
    var url = insight ? "/api/v1/insights/show_visualization?visualization=" + ChartId$ReactHooksTemplate.name(chartId) : (
        campaign ? "/api/v1/campaigns/visualization_data?question_id=" + (String(ChartId$ReactHooksTemplate.id(chartId)) + ("&campaign_id=" + (String(ChartId$ReactHooksTemplate.dashboardId(chartId)) + ("&name=" + ChartId$ReactHooksTemplate.name(chartId))))) : "/api/v1/dashboards/" + (String(ChartId$ReactHooksTemplate.dashboardId(chartId)) + ("/visualizations/" + String(ChartId$ReactHooksTemplate.id(chartId))))
      );
    return Http$ReactHooksTemplate.fetchGetJSONDecode(url, Chart$ReactHooksTemplate.fromJson).then(function (xs) {
                  return Promise.resolve((setTimeout((function (param) {
                                      return Curry._1(dispatch, {
                                                  TAG: /* FetchChartCompleted */0,
                                                  _0: Caml_option.some(xs)
                                                });
                                    }), Utils$ReactHooksTemplate.timeout), undefined));
                }).catch(function (err) {
                console.log(err);
                return Promise.resolve(Curry._1(dispatch, {
                                TAG: /* FetchChartCompleted */0,
                                _0: undefined
                              }));
              });
  };
  React.useEffect((function () {
          if (drillDownChartDetails !== undefined) {
            var key = drillDownChartDetails[1];
            if (key === "") {
              Curry._1(dispatch, {
                    TAG: /* DrillDown */1,
                    _0: key
                  });
            }
            if (drillDownChartDetails[0] !== ChartId$ReactHooksTemplate.id(chartId)) {
              fetchVisualization(undefined);
            } else {
              Curry._1(dispatch, {
                    TAG: /* DrillDown */1,
                    _0: key
                  });
            }
          } else {
            fetchVisualization(undefined);
          }
          
        }), [drillDownChartDetails]);
  var chartElement = React.useRef(null);
  var loadChart = function (chart) {
    var chartWidth;
    if (enlarged) {
      chartWidth = "w-full";
    } else {
      var mapAttribute = Chart$ReactHooksTemplate.type_(chart);
      if (typeof mapAttribute === "number") {
        chartWidth = "w-1/2";
      } else {
        switch (mapAttribute.TAG | 0) {
          case /* ScoreCard */7 :
              chartWidth = "w-1/2";
              break;
          case /* Map */8 :
              var w = MapAttribute$ReactHooksTemplate.width(mapAttribute._0);
              chartWidth = w !== undefined ? w : "w-1/2";
              break;
          case /* Table */9 :
              var w$1 = TableAttribute$ReactHooksTemplate.width(mapAttribute._0);
              chartWidth = w$1 !== undefined ? w$1 : "w-1/2";
              break;
          default:
            var w$2 = GenericChartAttribute$ReactHooksTemplate.width(mapAttribute._0);
            chartWidth = w$2 !== undefined ? w$2 : "w-1/2";
        }
      }
    }
    var chartHeight;
    if (enlarged) {
      var match = Chart$ReactHooksTemplate.type_(chart);
      if (typeof match === "number") {
        chartHeight = "h-96";
      } else {
        switch (match.TAG | 0) {
          case /* Map */8 :
          case /* Table */9 :
              chartHeight = "h-full";
              break;
          default:
            chartHeight = "h-96";
        }
      }
    } else {
      var mapAttribute$1 = Chart$ReactHooksTemplate.type_(chart);
      if (typeof mapAttribute$1 === "number") {
        chartHeight = "h-64";
      } else {
        switch (mapAttribute$1.TAG | 0) {
          case /* ScoreCard */7 :
              chartHeight = "h-64";
              break;
          case /* Map */8 :
              var h = MapAttribute$ReactHooksTemplate.height(mapAttribute$1._0);
              chartHeight = h !== undefined ? h : "h-64";
              break;
          case /* Table */9 :
              var h$1 = TableAttribute$ReactHooksTemplate.height(mapAttribute$1._0);
              chartHeight = h$1 !== undefined ? h$1 : "h-64";
              break;
          default:
            var w$3 = GenericChartAttribute$ReactHooksTemplate.height(mapAttribute$1._0);
            chartHeight = w$3 !== undefined ? w$3 : "h-64";
        }
      }
    }
    var chartAttribute = Chart$ReactHooksTemplate.type_(chart);
    var tmp;
    if (typeof chartAttribute === "number") {
      tmp = React.createElement(React.Fragment, undefined);
    } else {
      switch (chartAttribute.TAG | 0) {
        case /* LineChart */0 :
            tmp = React.createElement(LineChartComponent$ReactHooksTemplate.make, {
                  chartAttribute: chartAttribute._0,
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* AreaChart */1 :
            tmp = React.createElement(AreaChartComponent$ReactHooksTemplate.make, {
                  chartAttribute: chartAttribute._0,
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* PieChart */2 :
            tmp = React.createElement(PieChartComponent$ReactHooksTemplate.make, {
                  chartAttribute: chartAttribute._0,
                  onClick: onClick,
                  chartHeight: chartHeight
                });
            break;
        case /* BarChart */3 :
            tmp = React.createElement(BarChartComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart),
                  chartAttribute: chartAttribute._0,
                  drillDownKey: state.drillDownKey,
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* RadialBarChart */4 :
            tmp = React.createElement(RadialBarChartComponent$ReactHooksTemplate.make, {
                  chartHeight: chartHeight
                });
            break;
        case /* Wordcloud */5 :
            tmp = React.createElement(WordcloudComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart),
                  chartAttribute: chartAttribute._0
                });
            break;
        case /* ComposedChart */6 :
            tmp = React.createElement(ComposedChartComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart),
                  chartAttribute: chartAttribute._0,
                  drillDownKey: state.drillDownKey,
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* ScoreCard */7 :
            tmp = React.createElement(React.Fragment, undefined);
            break;
        case /* Map */8 :
            tmp = React.createElement(MapComponent$ReactHooksTemplate.make, {
                  mapAttribute: chartAttribute._0,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* Table */9 :
            tmp = React.createElement(TableComponent$ReactHooksTemplate.make, {
                  attribute: chartAttribute._0,
                  chartHeight: chartHeight
                });
            break;
        
      }
    }
    var rightPosition = enlarged ? "right-0" : "right-32-px";
    var rightPosition$1 = enlarged ? "right-32-px" : "right-64-px";
    var mapAttribute$2 = Chart$ReactHooksTemplate.type_(chart);
    var tmp$1;
    if (typeof mapAttribute$2 === "number") {
      tmp$1 = React.createElement(React.Fragment, undefined);
    } else {
      switch (mapAttribute$2.TAG | 0) {
        case /* ScoreCard */7 :
            tmp$1 = React.createElement(React.Fragment, undefined);
            break;
        case /* Map */8 :
            tmp$1 = React.createElement("div", {
                  className: " absolute top-0 " + rightPosition$1 + " p-16-px"
                }, React.createElement(ReactCsv.CSVLink, {
                      data: MapAttribute$ReactHooksTemplate.data(mapAttribute$2._0),
                      separator: ",",
                      filename: Chart$ReactHooksTemplate.title(chart) + ".csv",
                      children: React.createElement("img", {
                            className: "w-5",
                            src: Image$ReactHooksTemplate.csvDownloadButton
                          })
                    }));
            break;
        case /* Table */9 :
            tmp$1 = React.createElement("div", {
                  className: " absolute top-0 " + rightPosition$1 + " p-16-px\n                      z-50"
                }, React.createElement(ReactCsv.CSVLink, {
                      data: TableAttribute$ReactHooksTemplate.data(mapAttribute$2._0),
                      separator: ",",
                      filename: Chart$ReactHooksTemplate.title(chart) + ".csv",
                      children: React.createElement("img", {
                            className: "w-5",
                            src: Image$ReactHooksTemplate.csvDownloadButton
                          })
                    }));
            break;
        default:
          tmp$1 = React.createElement("div", {
                className: " absolute top-0 " + rightPosition$1 + " p-16-px"
              }, React.createElement(ReactCsv.CSVLink, {
                    data: GenericChartAttribute$ReactHooksTemplate.data(mapAttribute$2._0),
                    separator: ",",
                    filename: Chart$ReactHooksTemplate.title(chart) + ".csv",
                    children: React.createElement("img", {
                          className: "w-5",
                          src: Image$ReactHooksTemplate.csvDownloadButton
                        })
                  }));
      }
    }
    return React.createElement("div", {
                className: "" + chartWidth + " pb-16-px pr-16-px rounded-sm animated fadeIn\n    "
              }, React.createElement("div", {
                    className: "relative"
                  }, React.createElement("div", {
                        ref: chartElement,
                        className: "border border-gray-400 rounded bg-white"
                      }, React.createElement("h4", {
                            className: "animated fadeIn font-bold text-charcoal p-20-px flex"
                          }, React.createElement("img", {
                                alt: "doubleMinimize",
                                src: Image$ReactHooksTemplate.doubleMinimize
                              }), Chart$ReactHooksTemplate.title(chart)), React.createElement("div", {
                            className: "w-full animated fadeIn"
                          }, tmp)), enlarged ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
                          className: "absolute top-0 right-0 p-16-px"
                        }, React.createElement("button", {
                              onClick: (function (e) {
                                  e.preventDefault();
                                  return Curry._1(onEnlarge, undefined);
                                })
                            }, React.createElement("img", {
                                  className: "w-5",
                                  alt: "Expand",
                                  src: Image$ReactHooksTemplate.expand
                                }))), React.createElement("div", {
                        className: " absolute top-0 " + rightPosition + " p-16-px"
                      }, React.createElement("button", {
                            onClick: (function (e) {
                                e.preventDefault();
                                var ref_ = Belt_Option.getExn(Caml_option.nullable_to_opt(chartElement.current));
                                DomToImage.toBlob(ref_).then(function (response) {
                                      return Promise.resolve((FileSaver.saveAs(response, "chart.png"), undefined));
                                    });
                                
                              })
                          }, React.createElement("img", {
                                className: "w-5",
                                src: Image$ReactHooksTemplate.pngDownloadButton
                              }))), tmp$1));
  };
  var tmp;
  if (state.loading) {
    tmp = React.createElement(Loading$ReactHooksTemplate.make, {});
  } else {
    var chart = state.chart;
    if (chart !== undefined) {
      var chart$1 = Caml_option.valFromOption(chart);
      var chartAttribute = Chart$ReactHooksTemplate.type_(chart$1);
      tmp = typeof chartAttribute === "number" || chartAttribute.TAG !== /* ScoreCard */7 ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
              className: "w-full"
            }, React.createElement(ScoreCardComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart$1),
                  chartAttribute: chartAttribute._0,
                  onClick: onClick
                }));
    } else {
      tmp = "No Data Loaded for the chart";
    }
  }
  var loadScoreCard = React.createElement("div", {
        className: "min-w-265-px"
      }, tmp);
  var loadOtherCharts;
  if (state.loading) {
    loadOtherCharts = React.createElement("div", {
          className: "w-1/2 pb-16-px pr-16-px rounded-sm animated fadeIn "
        }, React.createElement(Loading$ReactHooksTemplate.make, {
              img: Image$ReactHooksTemplate.dashboardLoading,
              text: "Loading chart"
            }));
  } else {
    var chart$2 = state.chart;
    loadOtherCharts = chart$2 !== undefined ? loadChart(Caml_option.valFromOption(chart$2)) : "No Data Loaded for the chart";
  }
  if (scoreCard) {
    return loadScoreCard;
  } else {
    return loadOtherCharts;
  }
}

var make = ChartComponent;

exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
