// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Campaign$ReactHooksTemplate = require("./campaign.bs.js");
var HtmlToPdf$ReactHooksTemplate = require("../dashboard/dashboard/htmlToPdf.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var CampaignConfiguration$ReactHooksTemplate = require("./CampaignConfiguration.bs.js");

var initialState = Campaign$ReactHooksTemplate.empty(undefined);

function Campaigns(Props) {
  var content = Props.content;
  var campaignId = Props.campaignId;
  var match = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var hasPermission = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowConfigurations = match$1[1];
  var showConfigurations = match$1[0];
  var match$2 = React.useState(function () {
        return initialState;
      });
  var setCampaign = match$2[1];
  var campaign = match$2[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$3 = url.path;
  var selected;
  if (match$3) {
    switch (match$3.hd) {
      case "campaign" :
          var match$4 = match$3.tl;
          if (match$4) {
            if (match$4.hd === "reports") {
              var match$5 = match$4.tl;
              selected = match$5 && !match$5.tl ? "reports" : "";
            } else {
              var match$6 = match$4.tl;
              if (match$6) {
                switch (match$6.hd) {
                  case "campaign_report" :
                      selected = match$6.tl ? "" : "Campaign Report";
                      break;
                  case "data_field_reports" :
                      selected = match$6.tl ? "" : "Data Field Report";
                      break;
                  case "nps_trend" :
                      selected = match$6.tl ? "" : "Nps Trend";
                      break;
                  case "response_mode_reports" :
                      selected = match$6.tl ? "" : "Response Mode Report";
                      break;
                  default:
                    selected = "";
                }
              } else {
                selected = "";
              }
            }
          } else {
            selected = "";
          }
          break;
      case "campaigns" :
          var match$7 = match$3.tl;
          if (match$7) {
            var match$8 = match$7.tl;
            if (match$8) {
              switch (match$8.hd) {
                case "dashboard" :
                    selected = match$8.tl ? "" : "dashboard";
                    break;
                case "distributions" :
                    selected = match$8.tl ? "" : "distributions";
                    break;
                default:
                  selected = match$7.hd === "report_downloads" && !match$7.tl.tl ? "report_downloads" : "";
              }
            } else {
              selected = "";
            }
          } else {
            selected = "";
          }
          break;
      default:
        selected = "";
    }
  } else {
    selected = "";
  }
  React.useEffect((function () {
          var url = "/api/v1/campaigns/" + campaignId;
          Http$ReactHooksTemplate.fetchGetJSON(url).then(function (json) {
                  return Promise.resolve(Curry._1(setCampaign, (function (param) {
                                    return Json_decode.field("campaign", Campaign$ReactHooksTemplate.decode, json);
                                  })));
                }).catch(function (err) {
                return Promise.resolve((console.log(err), undefined));
              });
          
        }), []);
  var onUpdate = function (campaign) {
    return Curry._1(setCampaign, (function (param) {
                  return campaign;
                }));
  };
  return React.createElement("div", {
              className: "flex"
            }, React.createElement("div", {
                  className: "min-h-screen w-16 bg-ghostwhite border-r border-gray-300"
                }, Curry._1(hasPermission, /* ShowCampaign */14) ? React.createElement("a", {
                        href: "/campaigns/" + campaignId + "/dashboard"
                      }, React.createElement("div", {
                            className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                              selected === "dashboard" ? "bg-palatinateblue border-palatinateblue" : ""
                            )
                          }, React.createElement("img", {
                                className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                                  selected === "dashboard" ? "filter-white" : ""
                                ),
                                title: "Campaign Dashboard",
                                src: Image$ReactHooksTemplate.dashboard
                              }))) : React.createElement(React.Fragment, undefined), Curry._1(hasPermission, /* ShowDistribution */17) ? React.createElement("a", {
                        href: "/campaigns/" + campaignId + "/distributions"
                      }, React.createElement("div", {
                            className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                              selected === "distributions" ? "bg-palatinateblue border-palatinateblue" : ""
                            )
                          }, React.createElement("img", {
                                className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                                  selected === "distributions" ? "filter-white" : ""
                                ),
                                title: "Campaign Distributions",
                                src: Image$ReactHooksTemplate.distributions
                              }))) : React.createElement(React.Fragment, undefined), Curry._1(hasPermission, /* ShowReport */19) ? React.createElement("a", {
                        href: "/campaign/reports/" + campaignId
                      }, React.createElement("div", {
                            className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                              selected !== "distributions" && selected !== "report_downloads" && selected !== "dashboard" ? "bg-palatinateblue border-palatinateblue" : ""
                            )
                          }, React.createElement("img", {
                                className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                                  selected !== "distributions" && selected !== "report_downloads" && selected !== "dashboard" ? "filter-white" : ""
                                ),
                                title: "Campaign Reports",
                                src: Image$ReactHooksTemplate.reports
                              }))) : React.createElement(React.Fragment, undefined), Curry._1(hasPermission, /* ShowReport */19) ? React.createElement("a", {
                        href: "/campaigns/report_downloads/" + campaignId
                      }, React.createElement("div", {
                            className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                              selected === "report_downloads" ? "bg-palatinateblue border-palatinateblue" : ""
                            )
                          }, React.createElement("img", {
                                className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                                  selected === "report_downloads" ? "filter-white" : ""
                                ),
                                title: "Report Downloads",
                                src: Image$ReactHooksTemplate.downloadBox
                              }))) : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                  className: "w-screen"
                }, React.createElement("div", {
                      className: "flex items-center h-16 border-b border-gray-300 p-20-px justify-between"
                    }, React.createElement("div", {
                          className: "flex items-center text-sm font-normal leading-tight\n        tracking-normal text-left"
                        }, React.createElement("a", {
                              className: "mr-1",
                              href: "/campaigns/all"
                            }, React.createElement("h2", {
                                  className: "hover:underline hover:underline-offset-4"
                                }, "Campaigns")), React.createElement("img", {
                              className: "mr-1 h-3 w-4 mt-1",
                              src: Image$ReactHooksTemplate.arrowRight
                            }), selected !== "distributions" && selected !== "report_downloads" && selected !== "reports" ? React.createElement(React.Fragment, undefined, React.createElement("a", {
                                    className: "mr-1",
                                    href: "/campaign/reports/" + campaignId
                                  }, React.createElement("h2", {
                                        className: "hover:underline hover:underline-offset-4"
                                      }, Campaign$ReactHooksTemplate.name(campaign))), React.createElement("img", {
                                    className: "mr-1 h-3 w-4 mt-1",
                                    src: Image$ReactHooksTemplate.arrowRight
                                  }), React.createElement("h2", undefined, selected)) : React.createElement("h2", undefined, Campaign$ReactHooksTemplate.name(campaign))), React.createElement("div", {
                          className: "flex justify-end"
                        }, selected === "dashboard" ? React.createElement("div", undefined, React.createElement("button", {
                                    className: "p-2 mr-4",
                                    id: "pdf_download",
                                    onClick: (function (param) {
                                        var element = Belt_Option.getExn(Caml_option.nullable_to_opt(document.querySelector("#dashboard")));
                                        HtmlToPdf$ReactHooksTemplate.generateDashboardPdf(element);
                                        
                                      })
                                  }, React.createElement("img", {
                                        className: "h-16-px",
                                        src: Image$ReactHooksTemplate.download
                                      }))) : React.createElement(React.Fragment, undefined), showConfigurations ? React.createElement("div", undefined, React.createElement(CampaignConfiguration$ReactHooksTemplate.make, {
                                    campaign: campaign,
                                    onClose: (function (param) {
                                        return Curry._1(setShowConfigurations, (function (param) {
                                                      return !showConfigurations;
                                                    }));
                                      }),
                                    onUpdate: onUpdate
                                  })) : React.createElement(React.Fragment, undefined), React.createElement("button", {
                              className: "bg-ghostwhite border border-gray-400\n                shadow-inner flex rounded py-2 px-3 text-xs  ml-2",
                              onClick: (function (param) {
                                  return Curry._1(setShowConfigurations, (function (param) {
                                                return !showConfigurations;
                                              }));
                                })
                            }, React.createElement("div", {
                                  className: "flex gap-1 text-darkcharcoal text-xs"
                                }, React.createElement("img", {
                                      className: "h-14-px mr-2-px",
                                      src: Image$ReactHooksTemplate.cogTransparent
                                    }), React.createElement("p", undefined, "Configuration"))))), content));
}

var make = Campaigns;

exports.initialState = initialState;
exports.make = make;
/* initialState Not a pure module */
