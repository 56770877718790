// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var DropDown$ReactHooksTemplate = require("./DropDown.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var QuestionTextPreview$ReactHooksTemplate = require("./QuestionTextPreview.bs.js");

function DropDownPreview(Props) {
  var dropDown = Props.dropDown;
  var surveyTheme = Props.surveyTheme;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var handleOnChange = function ($$event) {
    var choiceId = $$event.target.value;
    var selectedChoice = List.find((function (x) {
            return RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(x)) === choiceId;
          }), DropDown$ReactHooksTemplate.choices(dropDown));
    return Curry._1(onChange, DropDown$ReactHooksTemplate.setAnswer(dropDown, selectedChoice));
  };
  var matchChoicesWithAnswer = function (choice) {
    var xs = DropDown$ReactHooksTemplate.getAnswer(dropDown);
    if (xs !== undefined) {
      return Belt_List.has(xs, choice, (function (xs, ys) {
                    return Caml_obj.caml_equal(DropDown$ReactHooksTemplate.choiceId(xs), DropDown$ReactHooksTemplate.choiceId(ys));
                  }));
    } else {
      return false;
    }
  };
  var onTitleChange = function ($$event) {
    return Curry._1(onChange, DropDown$ReactHooksTemplate.setText(dropDown, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  var onDescriptionChange = function ($$event) {
    return Curry._1(onChange, DropDown$ReactHooksTemplate.setDescription(dropDown, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  var answer = DropDown$ReactHooksTemplate.getAnswer(dropDown);
  var tmp;
  if (answer !== undefined) {
    var choice = Belt_List.head(answer);
    tmp = choice !== undefined ? RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(choice)) : "";
  } else {
    tmp = "Choose and option";
  }
  var match = DropDown$ReactHooksTemplate.getAnswer(dropDown);
  var answer$1 = DropDown$ReactHooksTemplate.getAnswer(dropDown);
  var tmp$1;
  if (answer$1 !== undefined) {
    var choice$1 = Belt_List.head(answer$1);
    tmp$1 = choice$1 !== undefined && matchChoicesWithAnswer(choice$1) && DropDown$ReactHooksTemplate.getAllowNonSpecifiedText(choice$1) ? React.createElement("div", undefined, React.createElement("input", {
                className: "h-48-px py-2 px-4 bg-alicebluepurple border-b border-gray-500 focus:border-gray-600 text-xs w-full",
                placeholder: "Specify if others",
                type: "text",
                onChange: (function (param) {
                    return Curry._1(onChange, DropDown$ReactHooksTemplate.setNotSpecifiedTextForAnswer(dropDown, DropDown$ReactHooksTemplate.setNotSpecifiedText(Utils$ReactHooksTemplate.formTargetValue(param), choice$1)));
                  })
              })) : React.createElement(React.Fragment, undefined);
  } else {
    tmp$1 = React.createElement(React.Fragment, undefined);
  }
  return React.createElement("div", {
              className: "w-full p-16"
            }, React.createElement(QuestionTextPreview$ReactHooksTemplate.make, {
                  text: DropDown$ReactHooksTemplate.text(dropDown),
                  description: DropDown$ReactHooksTemplate.description(dropDown),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme,
                  onTitleChange: onTitleChange,
                  onDescriptionChange: onDescriptionChange
                }), React.createElement("div", {
                  className: "flex flex-col"
                }, React.createElement("div", {
                      className: "mb-2"
                    }, React.createElement("div", {
                          className: "text-aurometalsaurus text-sm mb-2 font-normal"
                        }, "Dropdown"), React.createElement("select", {
                          className: "block border px-3 py-2 border-gray-400\n                  w-2/5 bg-white rounded capitalize mr-6 text-sm ",
                          value: tmp,
                          onChange: handleOnChange
                        }, match !== undefined ? React.createElement(React.Fragment, undefined) : React.createElement("option", {
                                key: "0",
                                value: "0"
                              }, "Choose and option"), $$Array.of_list(Belt_List.map(DropDown$ReactHooksTemplate.nonEmptyChoices(dropDown), (function (choice) {
                                    return React.createElement("option", {
                                                key: RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(choice)),
                                                value: RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(choice))
                                              }, DropDown$ReactHooksTemplate.getChoiceText(choice));
                                  }))))), tmp$1), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {}) : React.createElement(React.Fragment, undefined));
}

var make = DropDownPreview;

exports.make = make;
/* react Not a pure module */
