// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function ShortTextEditor(Props) {
  return React.createElement("div", undefined);
}

var make = ShortTextEditor;

exports.make = make;
/* react Not a pure module */
