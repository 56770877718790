// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var ReactHtmlParser = require("react-html-parser");
var ReactHtmlParser$1 = require("react-html-parser").default;
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");
var RatingGroup$ReactHooksTemplate = require("./RatingGroup.bs.js");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");
var WelcomeForm$ReactHooksTemplate = require("./WelcomeForm.bs.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var ThankYouForm$ReactHooksTemplate = require("./ThankYouForm.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var WelcomeFormBhima$ReactHooksTemplate = require("./WelcomeFormBhima.bs.js");
var ThankYouFormBhima$ReactHooksTemplate = require("./ThankYouFormBhima.bs.js");
var ThankYouFormYesbank$ReactHooksTemplate = require("./ThankYouFormYesbank.bs.js");

function domIdFortemplateIdentifiers(x) {
  switch (x) {
    case /* Logo */0 :
        return "logo";
    case /* Question */1 :
        return "question";
    case /* Language */2 :
        return "language";
    case /* ProgressBar */3 :
        return "progressBar";
    case /* NextButtom */4 :
        return "nextButton";
    case /* WelcomeButton */5 :
        return "welcomeButton";
    case /* PreviousButton */6 :
        return "previousButton";
    case /* Unknown */7 :
        return "";
    
  }
}

function convertDomIdToTemplateIdentifier(id) {
  if (id === "logo") {
    return /* Logo */0;
  } else if (id === "language") {
    return /* Language */2;
  } else if (id === "question") {
    return /* Question */1;
  } else if (id === "progressBar") {
    return /* ProgressBar */3;
  } else if (id === "nextButton") {
    return /* NextButtom */4;
  } else if (id === "previousButton") {
    return /* PreviousButton */6;
  } else {
    return /* Unknown */7;
  }
}

function decodeNodeAttributes(json) {
  var id = Json_decode.optional((function (param) {
          return Json_decode.field("id", Json_decode.string, param);
        }), json);
  return convertDomIdToTemplateIdentifier(Belt_Option.getWithDefault(id, ""));
}

function decodeLogo(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field("url", Json_decode.string, param);
              }), json);
}

function calculateContainerHeight(type_) {
  switch (type_) {
    case /* Page */0 :
        return "";
    case /* Modal */1 :
        return "calc(100vh - 32px)";
    case /* SideBar */2 :
        return "calc(100vh - 190px)";
    
  }
}

function calculateWelcomeContainerHeight(type_) {
  switch (type_) {
    case /* Page */0 :
        return {
                height: "calc(100vh - 112px)"
              };
    case /* Modal */1 :
        return {
                height: "calc(100vh - 144px)"
              };
    case /* SideBar */2 :
        return {
                height: "calc(100vh - 270px)"
              };
    
  }
}

function getQuestionFromPage(page) {
  if (typeof page === "number" || page.TAG !== /* QuestionPage */1) {
    return ;
  } else {
    return Caml_option.some(page._1);
  }
}

function SurveyPreviewHtmlRenderer(Props) {
  var page = Props.page;
  var logo = Props.logo;
  var theme = Props.theme;
  var client = Props.client;
  var onNextButtonClick = Props.onNextButtonClick;
  var onPrevButtonClick = Props.onPrevButtonClick;
  var handleQuestionPreview = Props.handleQuestionPreview;
  var onLanguageChange = Props.onLanguageChange;
  var handleStart = Props.handleStart;
  var type_ = Props.type_;
  var showPreviousButtonOpt = Props.showPreviousButton;
  var showNextButtonOpt = Props.showNextButton;
  var showSubmitButtonOpt = Props.showSubmitButton;
  var languageSelectedOpt = Props.languageSelected;
  var surveyLanguages = Props.surveyLanguages;
  var showPreviousButton = showPreviousButtonOpt !== undefined ? showPreviousButtonOpt : true;
  var showNextButton = showNextButtonOpt !== undefined ? showNextButtonOpt : true;
  var showSubmitButton = showSubmitButtonOpt !== undefined ? showSubmitButtonOpt : true;
  var languageSelected = languageSelectedOpt !== undefined ? languageSelectedOpt : SurveyLanguage$ReactHooksTemplate.$$default;
  var languageSelectionYesbank = function (param) {
    if (surveyLanguages && surveyLanguages.tl) {
      return React.createElement("div", {
                  className: "mb-5 small:mb-6"
                }, React.createElement("div", {
                      className: "float-right p-1 w-2/12 small:w-1/2 border\n        border-blue-400 h-52-px"
                    }, React.createElement("select", {
                          className: "bg-lochmara h-full text-center text-white w-full outline-none",
                          placeholder: "Select language",
                          onChange: onLanguageChange
                        }, Belt_List.toArray(List.map((function (x) {
                                    return React.createElement("option", {
                                                selected: SurveyLanguage$ReactHooksTemplate.name(languageSelected) === SurveyLanguage$ReactHooksTemplate.name(x)
                                              }, SurveyLanguage$ReactHooksTemplate.name(x));
                                  }), surveyLanguages)))));
    }
    return React.createElement("div", {
                className: "mb-5 small:mb-6"
              });
  };
  var languageSelectionBhima = function (param) {
    if (surveyLanguages && surveyLanguages.tl) {
      return React.createElement("div", {
                  className: "max-w-840-px mx-auto px-1 h-40-px py-1 flex flex-col\n      items-end"
                }, React.createElement("div", {
                      className: "w-3/12 small:w-1/3"
                    }, React.createElement("select", {
                          className: "h-full text-center w-full outline-none shadow-inner\n            border text-sm cursor-pointer",
                          placeholder: "Select language",
                          onChange: onLanguageChange
                        }, Belt_List.toArray(Belt_List.mapWithIndex(surveyLanguages, (function (id, x) {
                                    return React.createElement("option", {
                                                key: String(id),
                                                selected: SurveyLanguage$ReactHooksTemplate.name(languageSelected) === SurveyLanguage$ReactHooksTemplate.name(x)
                                              }, SurveyLanguage$ReactHooksTemplate.name(x));
                                  }))))));
    }
    return React.createElement("div", {
                className: "max-w-840-px mx-auto px-60-px h-40-px py-1"
              });
  };
  var languageSelection = function (param) {
    if (surveyLanguages && surveyLanguages.tl) {
      return React.createElement("div", {
                  className: "max-w-440-px mx-auto h-32-px p-1 flex flex-col items-end"
                }, React.createElement("div", {
                      className: "w-1/3"
                    }, React.createElement("select", {
                          className: "h-full text-sm border cursor-pointer text-center w-full outline-none shadow-inner",
                          placeholder: "Select language",
                          onChange: onLanguageChange
                        }, Belt_List.toArray(Belt_List.mapWithIndex(surveyLanguages, (function (id, x) {
                                    return React.createElement("option", {
                                                key: String(id),
                                                selected: SurveyLanguage$ReactHooksTemplate.name(languageSelected) === SurveyLanguage$ReactHooksTemplate.name(x)
                                              }, SurveyLanguage$ReactHooksTemplate.name(x));
                                  }))))));
    }
    return React.createElement("div", {
                className: "max-w-440-px mx-auto h-32-px small:h-0 p-1"
              });
  };
  var logoElement = function (client, logo) {
    var match = SurveyTheme$ReactHooksTemplate.logoAlignment(theme);
    var logoAlignmentCss;
    if (match !== undefined) {
      switch (match) {
        case "end" :
            logoAlignmentCss = "justify-end items-end";
            break;
        case "start" :
            logoAlignmentCss = "justify-start items-start";
            break;
        default:
          logoAlignmentCss = "justify-center items-center";
      }
    } else {
      logoAlignmentCss = "justify-center items-center";
    }
    if (logo === undefined) {
      return React.createElement(React.Fragment, undefined);
    }
    var match$1 = Organization$ReactHooksTemplate.name(client);
    if (match$1 === "bhima") {
      return React.createElement("img", {
                  className: "h-100-px small:h-80-px mx-auto",
                  src: logo
                });
    } else {
      return React.createElement("div", {
                  className: "flex flex-col " + logoAlignmentCss
                }, React.createElement("img", {
                      className: "h-52-px small:h-32-px",
                      src: logo
                    }));
    }
  };
  var nextButtonElement = function (enabled, bgColor, textColor, text) {
    if (enabled) {
      var match = Organization$ReactHooksTemplate.name(client);
      if (match === "bhima") {
        return React.createElement("button", {
                    className: " bg-" + bgColor + " shadow w-24 h-12 rounded text-" + textColor,
                    onClick: (function (param) {
                        return Curry._1(onNextButtonClick, undefined);
                      })
                  }, text);
      } else {
        return React.createElement("button", {
                    className: " flex-grow h-48-px rounded-lg text-white\n                bg-opacity-100 ",
                    style: {
                      backgroundColor: SurveyTheme$ReactHooksTemplate.nextButtonColor(theme)
                    },
                    onClick: (function (param) {
                        return Curry._1(onNextButtonClick, undefined);
                      })
                  }, text);
      }
    }
    var match$1 = Organization$ReactHooksTemplate.name(client);
    if (match$1 === "bhima") {
      return React.createElement("button", {
                  className: " bg-" + bgColor + " border border-black w-24 h-12\n                            text-" + textColor + " rounded cursor-not-allowed"
                }, text);
    } else {
      return React.createElement("button", {
                  className: " flex-grow h-48-px\n              text-white rounded-lg cursor-not-allowed bg-opacity-25",
                  style: {
                    backgroundColor: SurveyTheme$ReactHooksTemplate.nextButtonColor(theme),
                    opacity: "0.25"
                  }
                }, text);
    }
  };
  var previousButtonElement = function (enabled, borderColor) {
    if (!enabled) {
      return React.createElement(React.Fragment, undefined);
    }
    var match = Organization$ReactHooksTemplate.name(client);
    if (match === "bhima") {
      return React.createElement("div", {
                  className: "pr-30-px"
                }, React.createElement("button", {
                      className: "border border-" + borderColor + " w-24 h-12 rounded text-" + borderColor,
                      onClick: (function (param) {
                          return Curry._1(onPrevButtonClick, undefined);
                        })
                    }, SurveyLanguage$ReactHooksTemplate.message(languageSelected, "back")));
    } else {
      return React.createElement("div", {
                  className: "w-2/6 pr-16-px"
                }, React.createElement("button", {
                      className: "border border-herringsilver w-full h-48-px rounded-lg\n            text-" + borderColor + " border-solid bg-white bg-opacity-100 font-semibold\n            text-base leading-6 tracking-wide",
                      onClick: (function (param) {
                          return Curry._1(onPrevButtonClick, undefined);
                        })
                    }, SurveyLanguage$ReactHooksTemplate.message(languageSelected, "back")));
    }
  };
  var questionElement = function (customerInfo, language, question) {
    var nps = Question$ReactHooksTemplate.questionType(question);
    var question$1;
    switch (nps.TAG | 0) {
      case /* OpinionScale */0 :
          var match = Organization$ReactHooksTemplate.name(client);
          question$1 = match === "bhima" ? ({
                mandatory: question.mandatory,
                questionType: {
                  TAG: /* OpinionScale */0,
                  _0: OpinionScale$ReactHooksTemplate.setAutoAdvance(true, OpinionScale$ReactHooksTemplate.setRepresentation(nps._0, /* WithColor */1))
                },
                id: question.id
              }) : question;
          break;
      case /* LongText */2 :
          var match$1 = Organization$ReactHooksTemplate.name(client);
          question$1 = match$1 === "bhima" ? ({
                mandatory: question.mandatory,
                questionType: {
                  TAG: /* LongText */2,
                  _0: LongText$ReactHooksTemplate.setRepresentation(nps._0, /* BlackBackground */2)
                },
                id: question.id
              }) : question;
          break;
      case /* Nps */4 :
          var nps$1 = nps._0;
          var match$2 = Organization$ReactHooksTemplate.name(client);
          switch (match$2) {
            case "bhima" :
                question$1 = {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* Nps */4,
                    _0: Nps$ReactHooksTemplate.setAutoAdvance(true, Nps$ReactHooksTemplate.setRepresentation(nps$1, /* WithColor */1))
                  },
                  id: question.id
                };
                break;
            case "yesbank" :
                question$1 = {
                  mandatory: question.mandatory,
                  questionType: {
                    TAG: /* Nps */4,
                    _0: Nps$ReactHooksTemplate.setAutoAdvance(false, Nps$ReactHooksTemplate.setRepresentation(nps$1, /* WithColor */1))
                  },
                  id: question.id
                };
                break;
            default:
              question$1 = question;
          }
          break;
      case /* RatingGroup */6 :
          var match$3 = Organization$ReactHooksTemplate.name(client);
          question$1 = match$3 === "bhima" ? ({
                mandatory: question.mandatory,
                questionType: {
                  TAG: /* RatingGroup */6,
                  _0: RatingGroup$ReactHooksTemplate.setRepresentation(nps._0, /* ColorCode */1)
                },
                id: question.id
              }) : question;
          break;
      case /* MultipleSelect */9 :
      case /* DropDown */10 :
          Organization$ReactHooksTemplate.name(client);
          question$1 = question;
          break;
      default:
        question$1 = question;
    }
    return React.createElement("div", {
                key: RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(question$1))
              }, Question$ReactHooksTemplate.toForm(Curry.__1(handleQuestionPreview), customerInfo, theme, language, client, question$1));
  };
  var buttonBgColor = function (nodeId) {
    if (Organization$ReactHooksTemplate.name(client).toLowerCase() === "yesbank") {
      return "lochmara";
    } else if (Organization$ReactHooksTemplate.name(client).toLowerCase() === "bhima") {
      return "yellowsea";
    } else {
      return "radicalred";
    }
  };
  var borderColor = function (nodeId) {
    if (Organization$ReactHooksTemplate.name(client).toLowerCase() === "yesbank") {
      return "black";
    } else {
      Organization$ReactHooksTemplate.name(client).toLowerCase() === "bhima";
      return "black";
    }
  };
  var textColor = function (nodeId) {
    if (Organization$ReactHooksTemplate.name(client).toLowerCase() === "bhima" || Organization$ReactHooksTemplate.name(client).toLowerCase() !== "yesbank") {
      return "black";
    } else {
      return "white";
    }
  };
  var progressBarElement = React.createElement("div", {
        className: "flex items-center"
      }, React.createElement("div", {
            className: "p-4"
          }, React.createElement("progress", {
                className: "h-2 w-16",
                max: "100",
                value: "80"
              })), React.createElement("div", {
            className: "text-white text-sm"
          }, "1 / 5"));
  var transform = function (customerInfo, node, index) {
    var match = decodeNodeAttributes(node.attribs);
    switch (match) {
      case /* Logo */0 :
          return logoElement(client, logo);
      case /* Question */1 :
          var x = getQuestionFromPage(page);
          var tmp;
          if (x !== undefined) {
            var x$1 = Caml_option.valFromOption(x);
            tmp = x$1 !== undefined ? questionElement(customerInfo, languageSelected, x$1) : React.createElement(React.Fragment, undefined);
          } else {
            tmp = React.createElement(React.Fragment, undefined);
          }
          return React.createElement("div", {
                      className: "w-full"
                    }, tmp);
      case /* Language */2 :
          return languageSelection(undefined);
      case /* ProgressBar */3 :
          return React.createElement(React.Fragment, undefined, progressBarElement);
      case /* NextButtom */4 :
          return React.createElement(React.Fragment, undefined, showSubmitButton ? nextButtonElement(showNextButton, buttonBgColor(/* NextButtom */4), textColor(/* NextButtom */4), SurveyLanguage$ReactHooksTemplate.message(languageSelected, "submit")) : nextButtonElement(showNextButton, buttonBgColor(/* NextButtom */4), textColor(/* NextButtom */4), SurveyLanguage$ReactHooksTemplate.message(languageSelected, "next")));
      case /* WelcomeButton */5 :
          return React.createElement(React.Fragment, undefined);
      case /* PreviousButton */6 :
          return React.createElement(React.Fragment, undefined, previousButtonElement(showPreviousButton, borderColor(/* PreviousButton */6)));
      case /* Unknown */7 :
          return ReactHtmlParser.convertNodeToElement(node, index, (function (param, param$1) {
                        return transform(customerInfo, param, param$1);
                      }));
      
    }
  };
  var defaultWelcome = Welcome$ReactHooksTemplate.make("Your opinion matters!", "", "Take the survey", Image$ReactHooksTemplate.surveyWelcomeBg);
  var welcomePreviewItem = function (welcome) {
    return Belt_Option.mapWithDefault(welcome, null, (function (param) {
                  return React.createElement("div", {
                              className: "h-full"
                            }, welcome !== undefined ? React.createElement(WelcomeForm$ReactHooksTemplate.make, {
                                    default: defaultWelcome,
                                    welcome: Caml_option.valFromOption(welcome),
                                    handleStart: handleStart,
                                    buttonBgColor: SurveyTheme$ReactHooksTemplate.nextButtonColor(theme),
                                    language: languageSelected
                                  }) : React.createElement(WelcomeForm$ReactHooksTemplate.make, {
                                    default: defaultWelcome,
                                    welcome: defaultWelcome,
                                    handleStart: handleStart,
                                    buttonBgColor: SurveyTheme$ReactHooksTemplate.nextButtonColor(theme),
                                    language: languageSelected
                                  }));
                }));
  };
  var welcomePreviewItemBhima = function (welcome, textColor) {
    return Belt_Option.mapWithDefault(welcome, null, (function (param) {
                  return React.createElement("div", {
                              className: "h-full"
                            }, welcome !== undefined ? React.createElement(WelcomeFormBhima$ReactHooksTemplate.make, {
                                    default: defaultWelcome,
                                    welcome: Caml_option.valFromOption(welcome),
                                    textColor: textColor,
                                    buttonBgColor: SurveyTheme$ReactHooksTemplate.nextButtonColor(theme),
                                    handleStart: handleStart,
                                    language: languageSelected
                                  }) : React.createElement(WelcomeFormBhima$ReactHooksTemplate.make, {
                                    default: defaultWelcome,
                                    welcome: defaultWelcome,
                                    textColor: textColor,
                                    buttonBgColor: SurveyTheme$ReactHooksTemplate.nextButtonColor(theme),
                                    handleStart: handleStart,
                                    language: languageSelected
                                  }));
                }));
  };
  var defaultThankYou = ThankYou$ReactHooksTemplate.make("Thank you for taking the time to give your valuable feedback!");
  var thankYouPreviewItem = function (thankYou) {
    return Belt_Option.mapWithDefault(thankYou, null, (function (thankYou) {
                  return React.createElement(ThankYouForm$ReactHooksTemplate.make, {
                              default: defaultThankYou,
                              thankYou: thankYou
                            });
                }));
  };
  var thankYouPreviewItemBhima = function (thankYou, textColor) {
    return Belt_Option.mapWithDefault(thankYou, null, (function (thankYou) {
                  return React.createElement(ThankYouFormBhima$ReactHooksTemplate.make, {
                              default: defaultThankYou,
                              thankYou: thankYou,
                              textColor: textColor,
                              language: languageSelected
                            });
                }));
  };
  var thankYouPreviewItemYesbank = function (thankYou) {
    return Belt_Option.mapWithDefault(thankYou, null, (function (thankYou) {
                  return React.createElement(ThankYouFormYesbank$ReactHooksTemplate.make, {
                              default: defaultThankYou,
                              thankYou: thankYou,
                              language: languageSelected
                            });
                }));
  };
  var tmp;
  if (Organization$ReactHooksTemplate.name(client) === Organization$ReactHooksTemplate.emptyString) {
    tmp = React.createElement("div", {
          className: "h-screen w-full animated fadeIn"
        }, React.createElement(Loading$ReactHooksTemplate.make, {
              text: "Loading Survey.."
            }));
  } else {
    var tmp$1;
    if (typeof page === "number") {
      tmp$1 = React.createElement(React.Fragment, undefined);
    } else {
      switch (page.TAG | 0) {
        case /* Welcome */0 :
            var welcome = page._0;
            tmp$1 = Organization$ReactHooksTemplate.name(client).toLowerCase() === "yesbank" ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: "min-h-screen"
                      }, languageSelectionYesbank(undefined), React.createElement("div", {
                            className: "max-w-440-px mx-auto px-40-px small:px-20-px small:pb-80-px"
                          }, React.createElement("div", {
                                className: "h-84-px small:h-64-px py-16-px"
                              }, React.createElement("div", {
                                    id: "logo"
                                  }, logoElement(client, logo))), welcomePreviewItem(welcome)))) : (
                Organization$ReactHooksTemplate.name(client).toLowerCase() === "bhima" ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: "min-h-screen"
                          }, languageSelectionBhima(undefined), React.createElement("div", {
                                className: "w-full pb-20-px absolute"
                              }, logoElement(client, logo)), React.createElement("div", {
                                className: "max-w-840-px mx-auto px-60-px min-h-screen"
                              }, welcomePreviewItemBhima(welcome, textColor({
                                        TAG: /* Welcome */0,
                                        _0: welcome
                                      }))))) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: "min-h-screen"
                          }, languageSelection(undefined), React.createElement("div", {
                                className: "max-w-440-px mx-auto px-40-px small:px-20-px small:pb-80-px"
                              }, React.createElement("div", {
                                    className: "h-84-px small:h-64-px py-16-px"
                                  }, React.createElement("div", {
                                        id: "logo"
                                      }, logoElement(client, logo))), welcomePreviewItem(welcome))))
              );
            break;
        case /* QuestionPage */1 :
            var customerInfo = page._2;
            var templates = page._0;
            var template = Organization$ReactHooksTemplate.name(client).toLowerCase() === "bhima" ? templates.bhima : (Organization$ReactHooksTemplate.name(client).toLowerCase() === "yesbank", templates.default);
            tmp$1 = ReactHtmlParser$1(template, {
                  transform: (function (param, param$1) {
                      return transform(customerInfo, param, param$1);
                    })
                });
            break;
        case /* ThankYou */2 :
            var thankYou = page._0;
            var clientName = Organization$ReactHooksTemplate.name(client).toLowerCase();
            switch (clientName) {
              case "bhima" :
                  tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: "min-h-screen"
                          }, React.createElement("div", {
                                className: "pt-40-px w-full pb-20-px absolute"
                              }, logoElement(client, logo)), thankYouPreviewItemBhima(thankYou, textColor({
                                    TAG: /* ThankYou */2,
                                    _0: thankYou
                                  }))));
                  break;
              case "yesbank" :
                  tmp$1 = React.createElement("div", {
                        className: "bg-gray-100"
                      }, React.createElement("div", {
                            className: "h-84-px small:h-64-px py-24-px"
                          }, logoElement(client, logo)), React.createElement("div", {
                            style: calculateWelcomeContainerHeight(type_)
                          }, thankYouPreviewItemYesbank(thankYou)));
                  break;
              default:
                tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                          className: "min-h-screen"
                        }, React.createElement("div", {
                              className: "max-w-440-px mx-auto px-40-px small:px-20-px pt-32-px small:pt-0\n    small:pb-80-px"
                            }, React.createElement("div", {
                                  className: "h-84-px small:h-64-px py-16-px"
                                }, React.createElement("div", {
                                      id: "logo"
                                    }, logoElement(client, logo))), thankYouPreviewItem(thankYou))));
            }
            break;
        
      }
    }
    tmp = React.createElement(React.Fragment, undefined, tmp$1);
  }
  return React.createElement("div", {
              className: "bg-white",
              dir: SurveyLanguage$ReactHooksTemplate.name(languageSelected) === "Arabic" ? "rtl" : "",
              style: {
                color: SurveyTheme$ReactHooksTemplate.questionColor(theme),
                fontFamily: SurveyTheme$ReactHooksTemplate.font(theme),
                height: calculateContainerHeight(type_)
              }
            }, tmp);
}

var make = SurveyPreviewHtmlRenderer;

exports.domIdFortemplateIdentifiers = domIdFortemplateIdentifiers;
exports.convertDomIdToTemplateIdentifier = convertDomIdToTemplateIdentifier;
exports.decodeNodeAttributes = decodeNodeAttributes;
exports.decodeLogo = decodeLogo;
exports.calculateContainerHeight = calculateContainerHeight;
exports.calculateWelcomeContainerHeight = calculateWelcomeContainerHeight;
exports.getQuestionFromPage = getQuestionFromPage;
exports.make = make;
/* react Not a pure module */
